import React from 'react';
import { Descriptions, Avatar, Tag, Space, Row, Col, Typography, Statistic, Divider, Form, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Driver } from '../types';
import { DRIVER_STATUS_COLORS, type DriverStatusType } from '../utils/driverConstants';
import { formatDate } from '../utils/formatters';
import { Modal } from '../../../components/common/Modal';

interface DriverModalProps {
  driver: Driver | null;
  visible: boolean;
  onClose: () => void;
}

const { Title, Text } = Typography;

export const DriverModal: React.FC<DriverModalProps> = ({
  driver,
  visible,
  onClose
}) => {
  if (!driver) return null;

  return (
    <Form.Provider>
      <Modal
        title={
          <Space align="center" size="middle">
            <Avatar 
              size={64} 
              src={driver.avatar}
              icon={!driver.avatar && <UserOutlined />}
            >
              {!driver.avatar && driver.name?.charAt(0)}
            </Avatar>
            <div>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {driver.name}
              </Typography.Title>
              <Tag color={DRIVER_STATUS_COLORS[driver.status as DriverStatusType]}>
                {driver.status.charAt(0).toUpperCase() + driver.status.slice(1)}
              </Tag>
            </div>
          </Space>
        }
        open={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Close
          </Button>
        ]}
        maskClosable={false}
        width={800}
        destroyOnClose
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Statistic 
                title="Total Loads" 
                value={driver.total_loads} 
                valueStyle={{ color: '#1890ff' }}
              />
            </Col>
            <Col span={8}>
              <Statistic 
                title="Total Tonnes" 
                value={driver.total_tonnes} 
                precision={1}
                valueStyle={{ color: '#1890ff' }}
              />
            </Col>
            <Col span={8}>
              <Statistic 
                title="Efficiency Score" 
                value={driver.efficiency_score} 
                precision={2}
                valueStyle={{ color: '#1890ff' }}
                suffix="%"
              />
            </Col>
          </Row>

          <Divider style={{ margin: '12px 0' }} />

          <Descriptions 
            column={2}
            labelStyle={{ padding: '12px 0' }}
            contentStyle={{ padding: '12px 0' }}
          >
            <Descriptions.Item label="License Type">{driver.license_type || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="License Number">{driver.license_number || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Location">{driver.location_name || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Fleet Number">{driver.fleet_number || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Email">{driver.email || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Phone">{driver.phone_number || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Emergency Contact">{driver.emergency_contact_name || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Emergency Phone">{driver.emergency_contact_number || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Last Active">{driver.last_active ? formatDate(driver.last_active) : 'N/A'}</Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
    </Form.Provider>
  );
};