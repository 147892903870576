import { supabase } from '../../../lib/supabaseClient';
import { DocketData } from '../types';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { PrintTemplate } from '../components/PrintTemplate';
import { isPrinterAvailable, printContent } from '../../../services/printingService';

export const getNextDocketNumber = async (): Promise<number | null> => {
  try {
    const { data: maxDocket } = await supabase
      .from('pulse_dockets')
      .select('docket_number')
      .order('docket_number', { ascending: false })
      .limit(1)
      .single();

    return (maxDocket?.docket_number || 0) + 1;
  } catch (error) {
    console.error('Error getting next docket number:', error);
    return null;
  }
};

export const calculateTruckProgressive = async (truckId: string, jobId: string) => {
  try {
    // Get all dockets for this truck and job
    const { data: dockets, error: docketsError } = await supabase
      .from('pulse_dockets')
      .select('quantity')
      .eq('truck_id', truckId)
      .eq('job_id', jobId)
      .not('is_credit', 'eq', true);

    if (docketsError) throw docketsError;

    // Calculate total progressive with careful parsing and validation
    const progressive = dockets?.reduce((total, docket) => {
      const quantity = parseFloat(docket.quantity || '0');
      return isNaN(quantity) ? total : total + quantity;
    }, 0) || 0;

    return progressive;
  } catch (error) {
    console.error('Error calculating truck progressive:', error);
    throw error;
  }
};

export const recalculateJobProgressive = async (jobId: string) => {
  try {
    // Get job details first
    const { data: jobData, error: jobError } = await supabase
      .from('jobs')
      .select('quantity')
      .eq('id', jobId)
      .single();

    if (jobError) throw jobError;
    if (!jobData) throw new Error('Job not found');

    // Get all dockets for this job
    const { data: dockets, error: docketsError } = await supabase
      .from('pulse_dockets')
      .select('quantity, truck_id')
      .eq('job_id', jobId)
      .not('is_credit', 'eq', true);

    if (docketsError) throw docketsError;

    // Calculate total progressive with careful parsing and validation
    const progressive = dockets?.reduce((total, docket) => {
      const quantity = parseFloat(docket.quantity || '0');
      return isNaN(quantity) ? total : total + quantity;
    }, 0) || 0;

    const totalQuantity = parseFloat(jobData.quantity) || 0;
    const remaining = Math.max(0, totalQuantity - progressive);

    // Update job with new progressive and remaining, ensuring consistent string format
    const { error: updateError } = await supabase
      .from('jobs')
      .update({ 
        progressive: progressive.toFixed(2)
      })
      .eq('id', jobId);

    if (updateError) throw updateError;

    // Try to update remaining if the column exists
    try {
      await supabase
        .from('jobs')
        .update({ 
          remaining: remaining.toFixed(2)
        })
        .eq('id', jobId);
    } catch (error) {
      console.warn('Could not update remaining column:', error);
      // Continue anyway since this is not critical
    }

    // Get unique truck IDs
    const truckIds = Array.from(new Set(dockets?.map(d => d.truck_id) || []));

    // Update progressive for each truck
    await Promise.all(truckIds.map(async (truckId) => {
      const truckProgressive = await calculateTruckProgressive(truckId, jobId);
      const { error } = await supabase
        .from('pulse_dockets')
        .update({ progressive: truckProgressive.toFixed(2) })
        .eq('truck_id', truckId)
        .eq('job_id', jobId);

      if (error) throw error;
    }));

    return progressive;
  } catch (error) {
    console.error('Error recalculating job progressive:', error);
    throw error;
  }
};

export const createDocket = async (docketData: {
  job_id: string;
  truck_id: string;
  fleet_number: string;
  customer_id: string;
  delivery_address: string;
  product_id: string;
  gross_weight: number;
  tare_weight: number;
  net_weight: number;
  is_split_load: boolean;
  split_load_part: 'truck' | 'trailer' | null;
}) => {
  console.log('createDocket called with data:', docketData);

  // Validate net_weight is positive
  if (docketData.net_weight <= 0) {
    console.error('Invalid net weight:', docketData.net_weight);
    throw new Error('Net weight must be greater than 0');
  }

  const nextDocketNumber = await getNextDocketNumber();
  if (!nextDocketNumber) {
    throw new Error('Failed to get next docket number');
  }

  console.log('Got next docket number:', nextDocketNumber);

  // Get previous dockets for this truck and job to calculate progressive
  const { data: prevDockets, error: prevDocketsError } = await supabase
    .from('pulse_dockets')
    .select('quantity')
    .eq('truck_id', docketData.truck_id)
    .eq('job_id', docketData.job_id)
    .not('is_credit', 'eq', true);

  if (prevDocketsError) {
    console.error('Error fetching previous dockets:', prevDocketsError);
    throw prevDocketsError;
  }

  console.log('Previous dockets:', prevDockets);

  // Calculate progressive including the new docket's quantity
  const previousTotal = prevDockets?.reduce((total, docket) => {
    const docketQuantity = parseFloat(docket.quantity || '0');
    return isNaN(docketQuantity) ? total : total + docketQuantity;
  }, 0) || 0;

  console.log('Previous total:', previousTotal);
  
  const progressive = previousTotal + (docketData.gross_weight - docketData.tare_weight);

  console.log('Calculated progressive:', progressive);

  // Ensure all numeric values are properly converted and validated
  const netWeight = Number(docketData.net_weight);
  if (isNaN(netWeight) || netWeight <= 0) {
    throw new Error('Invalid net weight value');
  }

  const grossWeight = Number(docketData.gross_weight);
  const tareWeight = Number(docketData.tare_weight);

  // Get truck details to validate weight limits
  const { data: truckDetails, error: truckError } = await supabase
    .from('trucks')
    .select('type, body_max_gross, trailer_max_gross, combined_max_gross')
    .eq('id', docketData.truck_id)
    .single();

  if (truckError) {
    console.error('Error fetching truck details:', truckError);
    throw new Error('Failed to fetch truck details for weight validation');
  }

  // Validate weights against truck limits
  if (truckDetails.type === 'Truck and Dog' || truckDetails.type === 'Semi') {
    // For combination vehicles
    if (docketData.is_split_load) {
      if (docketData.split_load_part === 'truck' && grossWeight > truckDetails.body_max_gross) {
        throw new Error(`Body gross weight (${grossWeight}) exceeds maximum allowed (${truckDetails.body_max_gross})`);
      }
      if (docketData.split_load_part === 'trailer' && grossWeight > truckDetails.trailer_max_gross) {
        throw new Error(`Trailer gross weight (${grossWeight}) exceeds maximum allowed (${truckDetails.trailer_max_gross})`);
      }
    } else {
      if (grossWeight > truckDetails.combined_max_gross) {
        throw new Error(`Combined gross weight (${grossWeight}) exceeds maximum allowed (${truckDetails.combined_max_gross})`);
      }
    }
  } else {
    // For single unit vehicles
    if (grossWeight > truckDetails.body_max_gross) {
      throw new Error(`Gross weight (${grossWeight}) exceeds maximum allowed (${truckDetails.body_max_gross})`);
    }
  }

  const docketToInsert = {
    docket_number: nextDocketNumber,
    job_id: docketData.job_id,
    truck_id: docketData.truck_id,
    fleet_number: docketData.fleet_number,
    customer_id: docketData.customer_id,
    delivery_address: docketData.delivery_address,
    product_id: docketData.product_id,
    gross_weight: grossWeight,
    tare_weight: tareWeight,
    net_weight: netWeight,
    body_gross: docketData.is_split_load ? 
      (docketData.split_load_part === 'trailer' ? null : grossWeight) : 
      (truckDetails.type === 'Truck and Dog' || truckDetails.type === 'Semi' ? null : grossWeight),
    body_tare: docketData.is_split_load ? 
      (docketData.split_load_part === 'trailer' ? null : tareWeight) : 
      (truckDetails.type === 'Truck and Dog' || truckDetails.type === 'Semi' ? null : tareWeight),
    trailer_gross: docketData.is_split_load ? 
      (docketData.split_load_part === 'truck' ? null : grossWeight) : 
      null,
    trailer_tare: docketData.is_split_load ? 
      (docketData.split_load_part === 'truck' ? null : tareWeight) : 
      null,
    total_gross: grossWeight,
    total_tare: tareWeight,
    quantity: netWeight,  
    progressive: progressive.toFixed(2),
    is_split_load: docketData.is_split_load,
    split_load_part: docketData.split_load_part,
    created_at: new Date().toISOString(),
    is_xbin: false,
    is_credit: false,
    status: 'completed'
  };

  console.log('Attempting to insert docket with data:', docketToInsert);

  // First, let's check the table structure
  const { data: tableInfo, error: tableError } = await supabase
    .from('pulse_dockets')
    .select('quantity, body_gross, body_tare, total_gross, total_tare')
    .limit(1);

  if (tableError) {
    console.error('Error checking table structure:', tableError);
  } else {
    console.log('Table structure sample:', tableInfo);
  }

  const { data: newDocket, error: docketError } = await supabase
    .from('pulse_dockets')
    .insert([docketToInsert])
    .select('*')
    .single();

  if (docketError) {
    console.error('Error creating docket:', docketError);
    throw docketError;
  }

  console.log('Inserted docket result:', newDocket);

  // Validate that quantity was saved correctly
  if (!newDocket || typeof newDocket.quantity !== 'number' || Math.abs(newDocket.quantity - netWeight) > 0.01) {
    console.error('Docket quantity validation failed:', { 
      saved: newDocket?.quantity, 
      expected: netWeight,
      docketData: newDocket,
      originalData: docketToInsert
    });
    throw new Error('Failed to save docket quantity correctly');
  }

  // Update job progressive and remaining values
  await recalculateJobProgressive(docketData.job_id);

  return newDocket;
};

export const updateJobProgressive = async (jobId: string, progressive: number) => {
  const { error } = await supabase
    .from('jobs')
    .update({ progressive: progressive.toString() })
    .eq('id', jobId);

  if (error) throw error;
};

export const printDocket = (docketData: any, type: 'docket' | 'xbinDocket' = 'docket') => {
  console.log('PrintDocket received data:', docketData);
  return new Promise<void>(async (resolve, reject) => {
    try {
      if (!isPrinterAvailable()) {
        reject(new Error('Printing is only available in the desktop application'));
        return;
      }

      // Create a container for the print content
      const printContainer = document.createElement('div');
      printContainer.id = 'print-container';
      document.body.appendChild(printContainer);

      // Add print styles
      const style = document.createElement('style');
      style.textContent = `
        @page {
          size: A4;
          margin: 0mm !important;
        }
        #print-container {
          width: 210mm;
          height: 297mm;
          margin: 0 !important;
          padding: 0 !important;
          position: relative;
          page-break-after: always;
        }
      `;
      document.head.appendChild(style);

      // Create a root and render the component
      const root = ReactDOM.createRoot(printContainer);
      root.render(
        React.createElement(PrintTemplate, { docketData, type })
      );

      // Wait for render and then print
      setTimeout(async () => {
        try {
          await printContent(printContainer.outerHTML);
          console.log('Print completed successfully');
        } catch (error) {
          console.error('Print error:', error);
          reject(error);
        } finally {
          // Always cleanup
          root.unmount();
          document.body.removeChild(printContainer);
          document.head.removeChild(style);
          resolve();
        }
      }, 1000); // Wait for render

    } catch (error) {
      console.error('Setup error:', error);
      reject(error);
    }
  });
};
