import { useState, useEffect, useCallback } from 'react';
import { App } from 'antd';
import { PostgrestError } from '@supabase/supabase-js';
import { supabase } from '../../../lib/supabaseClient';
import { Driver, DriverFormData, DriverFilters, DriverStatus } from '../types';

// Types and Interfaces
interface SupabaseLocation {
    id: string;
    name: string;
}

interface SupabaseTruck {
    id: string;
    fleet_number: string;
}

interface SupabaseAssignment {
    id: string;
    truck_id: string;
    status: string;
    truck: SupabaseTruck | null;
    start_time: string;
    end_time: string | null;
}

interface SupabaseDriver {
    id: string;
    name: string;
    fleet_number: string;
    phone_number: string;
    avatar: string | null;
    notes: string | null;
    status: string;
    license_number: string;
    license_type: string;
    email: string;
    address: string;
    date_added: string;
    dob: string | null;
    emergency_contact_name: string | null;
    emergency_contact_number: string | null;
    location_id: string;
    location: SupabaseLocation | null;
    current_assignment: SupabaseAssignment | null;
}

interface UseDriversReturn {
    drivers: Driver[];
    loading: boolean;
    error: string | null;
    refreshDrivers: () => Promise<void>;
    addDriver: (driver: DriverFormData) => Promise<Driver>;
    updateDriver: (id: string, { status }: { status: DriverStatus }) => Promise<Driver>;
    deleteDriver: (id: string) => Promise<void>;
    updateFilters: (filters: Partial<DriverFilters>) => void;
    filters: DriverFilters;
    getDriver: (id: string) => Promise<{ data: Driver | null; loading: boolean }>;
}

// Constants
const DRIVER_SELECT_QUERY = `
    id,
    name,
    fleet_number,
    phone_number,
    avatar,
    notes,
    status,
    license_number,
    license_type,
    email,
    address,
    date_added,
    dob,
    emergency_contact_name,
    emergency_contact_number,
    location_id,
    location:locations(*),
    assignments!assignments_driver_id_fkey(
        id,
        truck_id,
        status,
        trucks!assignments_truck_id_fkey(
            id,
            fleet_number
        ),
        start_time,
        end_time
    )
`;

/**
 * Transforms raw Supabase driver data into a structured SupabaseDriver object
 * Handles null values and ensures type safety
 * 
 * @param data - Raw driver data from Supabase
 * @returns {SupabaseDriver} Structured driver data with proper typing
 * @throws {Error} If required fields are missing
 */
const transformSupabaseDriver = (data: any): SupabaseDriver => {
    if (!data?.id || !data?.name) {
        throw new Error('Invalid driver data: missing required fields');
    }

    return {
        id: data.id,
        name: data.name,
        fleet_number: data.fleet_number || '',
        phone_number: data.phone_number || '',
        avatar: data.avatar || null,
        notes: data.notes || null,
        status: data.status || 'Available',
        license_number: data.license_number || '',
        license_type: data.license_type || '',
        email: data.email || '',
        address: data.address || '',
        date_added: data.date_added || new Date().toISOString(),
        dob: data.dob || null,
        emergency_contact_name: data.emergency_contact_name || null,
        emergency_contact_number: data.emergency_contact_number || null,
        location_id: data.location_id || '',
        location: data.location || null,
        current_assignment: data.current_assignment || null,
    };
};

/**
 * Transforms a SupabaseDriver object into the application's Driver type
 * Formats dates, handles relationships, and ensures data consistency
 * 
 * @param driver - SupabaseDriver object with raw data
 * @returns {Driver} Formatted driver object for application use
 */
const transformToDriver = (driver: SupabaseDriver): Driver => {
    return {
        id: driver.id,
        name: driver.name,
        fleet_number: driver.fleet_number,
        phone_number: driver.phone_number,
        avatar: driver.avatar,
        notes: driver.notes,
        status: driver.status as DriverStatus,
        license_number: driver.license_number,
        license_type: driver.license_type,
        email: driver.email,
        address: driver.address,
        date_added: new Date(driver.date_added).toISOString(),
        dob: driver.dob ? new Date(driver.dob).toISOString() : null,
        emergency_contact_name: driver.emergency_contact_name,
        emergency_contact_number: driver.emergency_contact_number,
        location_id: driver.location_id,
        location: driver.location ? {
            id: driver.location.id,
            name: driver.location.name,
        } : undefined,
        current_assignment: driver.current_assignment ? {
            id: driver.current_assignment.id,
            driver_id: driver.id,
            truck_id: driver.current_assignment.truck_id,
            status: driver.current_assignment.status,
            start_time: new Date(driver.current_assignment.start_time).toISOString(),
            end_time: driver.current_assignment.end_time ? 
                new Date(driver.current_assignment.end_time).toISOString() : null,
            truck: driver.current_assignment.truck ? {
                id: driver.current_assignment.truck.id,
                fleet_number: driver.current_assignment.truck.fleet_number,
            } : undefined
        } : undefined,
    };
};

/**
 * Custom hook for managing driver data
 * Provides CRUD operations, filtering, and real-time updates
 * 
 * Features:
 * - Fetch and manage driver data with relationships
 * - Add, update, and delete drivers
 * - Filter drivers by status, location, and search term
 * - Real-time updates when data changes
 * - Error handling and loading states
 * - Authentication check
 * 
 * @returns {UseDriversReturn} Object containing driver data and operations
 */
export const useDrivers = (): UseDriversReturn => {
    const { notification } = App.useApp();
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [filters, setFilters] = useState<DriverFilters>({});

    /**
     * Fetches drivers from Supabase with optional filters
     * Handles data transformation and error states
     */
    const getDrivers = useCallback(async (currentFilters?: DriverFilters): Promise<void> => {
        setLoading(true);
        setError(null);

        try {
            let query = supabase
                .from('drivers')
                .select(DRIVER_SELECT_QUERY)
                .neq('status', 'Inactive');  // Exclude inactive drivers

            // Apply filters
            if (currentFilters?.status) {
                query = query.eq('status', currentFilters.status);
            }
            if (currentFilters?.location_id) {
                query = query.eq('location_id', currentFilters.location_id);
            }
            if (currentFilters?.search) {
                query = query.or(
                    `name.ilike.%${currentFilters.search}%,` +
                    `fleet_number.ilike.%${currentFilters.search}%,` +
                    `license_number.ilike.%${currentFilters.search}%`
                );
            }

            const { data, error: fetchError } = await query;

            if (fetchError) throw fetchError;

            const driversWithAssignments = data?.map(driver => {
                const activeAssignment = driver.assignments?.find(
                    (a: any) => a.status === 'active' && !a.end_time
                );
                
                return {
                    ...driver,
                    current_assignment: activeAssignment ? {
                        ...activeAssignment,
                        truck: activeAssignment.trucks,
                        trucks: undefined
                    } : null,
                    assignments: undefined
                };
            });

            setDrivers(driversWithAssignments?.map(transformSupabaseDriver).map(transformToDriver) || []);
        } catch (err) {
            const error = err as PostgrestError;
            console.error('Error fetching drivers:', error);
            setError(error.message || 'Failed to fetch drivers');
            notification.error({
                message: 'Failed to Fetch Drivers',
                description: error.message || 'An error occurred while fetching drivers.',
                placement: 'topRight',
                key: 'fetch-drivers-error',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
        } finally {
            setLoading(false);
        }
    }, [notification]);

    /**
     * Fetches a single driver by ID
     * Returns the driver data and loading state
     */
    const getDriver = async (id: string): Promise<{ data: Driver | null; loading: boolean }> => {
        try {
            const { data, error: fetchError } = await supabase
                .from('drivers')
                .select(DRIVER_SELECT_QUERY)
                .eq('id', id)
                .maybeSingle();

            if (fetchError) throw fetchError;
            if (!data) return { data: null, loading: false };

            const activeAssignment = data.assignments?.find(
                (a: any) => a.status === 'active' && !a.end_time
            );

            const driverData = {
                ...data,
                current_assignment: activeAssignment ? {
                    ...activeAssignment,
                    truck: activeAssignment.trucks,
                    trucks: undefined
                } : null,
                assignments: undefined
            };

            const supabaseDriver = transformSupabaseDriver(driverData);
            return { data: transformToDriver(supabaseDriver), loading: false };
        } catch (err) {
            const error = err as PostgrestError;
            console.error('Error fetching driver:', error);
            notification.error({
                message: 'Failed to Fetch Driver',
                description: error.message || 'An error occurred while fetching driver details.',
                placement: 'topRight',
                key: 'fetch-driver-error',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            return { data: null, loading: false };
        }
    };

    /**
     * Adds a new driver to the database
     * Automatically refreshes the driver list on success
     */
    const addDriver = async (driverData: DriverFormData): Promise<Driver> => {
        try {
            const { data, error } = await supabase
                .from('drivers')
                .insert([driverData])
                .select()
                .single();

            if (error) throw error;
            await getDrivers(filters);
            notification.success({
                message: 'Driver Added',
                description: 'New driver has been added successfully.',
                placement: 'topRight',
                key: 'add-driver-success',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            return transformToDriver(transformSupabaseDriver(data));
        } catch (err) {
            const error = err as PostgrestError;
            console.error('Error adding driver:', error);
            notification.error({
                message: 'Failed to Add Driver',
                description: error.message || 'An error occurred while adding the driver.',
                placement: 'topRight',
                key: 'add-driver-error',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            throw error;
        }
    };

    /**
     * Updates an existing driver
     * Automatically refreshes the driver list on success
     */
    const updateDriver = async (id: string, { status }: { status: DriverStatus }): Promise<Driver> => {
        try {
            // First, get the current driver to get the truck ID
            const { data: currentDriver } = await supabase
                .from('drivers')
                .select(DRIVER_SELECT_QUERY)
                .eq('id', id)
                .single();

            if (!currentDriver) throw new Error('Driver not found');

            // Use the stored procedure to update both driver and truck status
            const { error } = await supabase
                .rpc('update_driver_status', { 
                    _driver_id: id,
                    _new_status: status
                });

            if (error) throw error;

            // Fetch the updated driver
            const { data: updatedDriver, error: fetchError } = await supabase
                .from('drivers')
                .select(DRIVER_SELECT_QUERY)
                .eq('id', id)
                .single();

            if (fetchError) throw fetchError;
            if (!updatedDriver) throw new Error('Driver not found after update');

            await getDrivers(filters);  // Refresh full driver list after update
            notification.success({
                message: 'Driver Updated',
                description: 'Driver details have been updated successfully.',
                placement: 'topRight',
                key: 'update-driver-success',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            return transformToDriver(transformSupabaseDriver(updatedDriver));
        } catch (err) {
            console.error('Error updating driver:', err);
            notification.error({
                message: 'Failed to Update Driver',
                description: (err as Error).message || 'An error occurred while updating the driver.',
                placement: 'topRight',
                key: 'update-driver-error',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            throw err;
        }
    };

    /**
     * Archives a driver by setting their status to Inactive
     * Automatically refreshes the driver list on success
     */
    const deleteDriver = useCallback(async (id: string) => {
        try {
            const { error } = await supabase
                .from('drivers')
                .update({ 
                    status: 'Inactive',
                    deleted_at: new Date().toISOString()
                })
                .eq('id', id);

            if (error) throw error;

            notification.success({
                message: 'Driver Archived',
                description: 'Driver has been archived successfully.',
                placement: 'topRight',
                key: 'archive-driver-success',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });

            await getDrivers();
        } catch (err) {
            const archiveError = err as PostgrestError;
            console.error('Error archiving driver:', archiveError);
            notification.error({
                message: 'Failed to Archive Driver',
                description: archiveError.message || 'An error occurred while archiving the driver.',
                placement: 'topRight',
                key: 'archive-driver-error',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            throw archiveError;
        }
    }, [getDrivers, notification]);

    /**
     * Updates the current filter state and refreshes data
     */
    const updateFilters = useCallback((newFilters: Partial<DriverFilters>): void => {
        setFilters(prev => {
            const updatedFilters = { ...prev, ...newFilters };
            getDrivers(updatedFilters);
            return updatedFilters;
        });
    }, [getDrivers]);

    // Initial data fetch and authentication check
    useEffect(() => {
        const checkAuthAndFetch = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session?.access_token) {
                getDrivers(filters);
            } else {
                setError('Not authenticated');
                notification.error({
                    message: 'Please Sign In',
                    description: 'Please sign in to view drivers.',
                    placement: 'topRight',
                    key: 'auth-error',
                    style: {
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                });
            }
        };

        checkAuthAndFetch();
    }, [getDrivers, filters, notification]);

    return {
        drivers,
        loading,
        error,
        refreshDrivers: () => getDrivers(filters),
        addDriver,
        updateDriver,
        deleteDriver,
        updateFilters,
        filters,
        getDriver
    };
};
