import React, { useRef, useCallback, useEffect } from 'react';
import { Input, Button, Card, Typography, Spin, InputNumber, Select, Row, Col, Progress, Statistic, Tag, Form, Switch, Alert } from 'antd';
import { SearchOutlined, PrinterOutlined, TruckOutlined, BarChartOutlined, LineChartOutlined, ContainerOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useDocket } from '../context/DocketContext';
import { usePrinting } from '../../../contexts/PrintingContext';
import './DocketForm.css';
import dayjs from 'dayjs';

const { Text, Title } = Typography;
const { Option } = Select;

const DocketForm: React.FC = () => {
  const {
    loading,
    jobData,
    truckData,
    customerData,
    productData,
    searchValue,
    grossWeight,
    netWeight,
    isSplitLoad,
    splitLoadPart,
    allocatedTrucks,
    setSearchValue,
    handleSearch,
    setGrossWeight: handleGrossWeightChange,
    setSplitLoadPart,
    setIsSplitLoad,
    handleSaveAndPrint
  } = useDocket();
  const { setIsPrinting } = usePrinting();

  const searchInputRef = useRef<InputRef>(null);
  const grossWeightInputRef = useRef<any>(null);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const success = await handleSearch();
      if (success) {
        grossWeightInputRef.current?.focus();
      }
    }
  };

  const handleGrossWeightKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && grossWeight) {
      handleSaveAndPrintWithPrintingState();
    }
  };

  const handleSearchClick = async () => {
    const success = await handleSearch();
    if (success) {
      grossWeightInputRef.current?.focus();
    }
  };

  const handleSaveAndPrintWithPrintingState = async () => {
    setIsPrinting(true);
    try {
      await handleSaveAndPrint();
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <div className="docket-container">
      <div className="docket-content">
        <div className="search-section">
          <Title level={4} className="card-title">Enter Truck Number</Title>
          <Input.Search
            ref={searchInputRef}
            placeholder="Enter truck number or registration"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearchClick}
            onKeyPress={handleKeyPress}
            loading={loading}
            maxLength={10}
            size="large"
          />
        </div>

        {jobData && customerData && productData && truckData && (
          <div className="details-section">
            <Card className="details-card">
              <Title level={4} className="card-title">Job Details</Title>
              <Row gutter={[24, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <div className="detail-label">Job Number</div>
                  <div className="detail-value">{jobData.job_number}</div>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <div className="detail-label">Customer</div>
                  <div className="detail-value">{customerData.customer_name}</div>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <div className="detail-label">Product</div>
                  <div className="detail-value">
                    {productData.name} 
                    {productData.type && <Tag color="blue" style={{ marginLeft: 8 }}>{productData.type}</Tag>}
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="detail-label">Delivery Address</div>
                  <div className="detail-value">{jobData.delivery_address}</div>
                </Col>
                <Col xs={24} sm={12}>
                  <div className="detail-label">Fleet Number</div>
                  <div className="detail-value">
                    <Tag color="green">{truckData?.fleet_number}</Tag>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card className="weight-details-card">
              <Title level={4} className="card-title">Weight Details</Title>
              
              <div className="split-load-section">
                <Row align="middle" gutter={[16, 16]}>
                  <Col span={12}>
                    <Text strong>Split Load</Text>
                    <Switch
                      checked={isSplitLoad}
                      onChange={(checked) => {
                        setIsSplitLoad(checked);
                        if (!checked) setSplitLoadPart(null);
                      }}
                      style={{ marginLeft: 16 }}
                    />
                  </Col>
                  {isSplitLoad && (
                    <Col span={24}>
                      <div className="split-load-options">
                        <Card
                          className={`split-load-card ${splitLoadPart === 'truck' ? 'selected' : ''}`}
                          onClick={() => setSplitLoadPart('truck')}
                        >
                          <TruckOutlined className="split-load-icon" />
                          <div>Truck Only</div>
                          <Text type="secondary">{truckData?.body_tare?.toFixed(2)}t tare</Text>
                        </Card>
                        <Card
                          className={`split-load-card ${splitLoadPart === 'trailer' ? 'selected' : ''}`}
                          onClick={() => setSplitLoadPart('trailer')}
                        >
                          <ContainerOutlined className="split-load-icon" />
                          <div>Trailer Only</div>
                          <Text type="secondary">{truckData?.trailer_tare?.toFixed(2)}t tare</Text>
                        </Card>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>

              <div className="weight-input-container">
                <div className="detail-label">Gross Weight (tonnes)</div>
                <InputNumber
                  ref={grossWeightInputRef}
                  className="weight-input"
                  value={grossWeight}
                  onChange={handleGrossWeightChange}
                  onKeyPress={handleGrossWeightKeyPress}
                  min={0}
                  max={100}
                  step={0.01}
                  precision={2}
                  placeholder="Enter gross weight"
                />
              </div>

              {netWeight !== null && (
                <div className="weight-result">
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <Statistic
                        title="Gross Weight"
                        value={grossWeight || 0}
                        precision={2}
                        suffix="t"
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Tare Weight"
                        value={isSplitLoad ? 
                          (splitLoadPart === 'truck' ? truckData?.body_tare || 0 : truckData?.trailer_tare || 0) : 
                          ((truckData?.body_tare || 0) + (truckData?.trailer_tare || 0))}
                        precision={2}
                        suffix="t"
                      />
                    </Col>
                    <Col span={8}>
                      <Statistic
                        title="Net Weight"
                        value={netWeight}
                        precision={2}
                        suffix="t"
                        valueStyle={{ color: '#1890ff' }}
                      />
                    </Col>
                  </Row>
                </div>
              )}

              <div className="action-buttons">
                <Button 
                  type="primary"
                  icon={<PrinterOutlined />}
                  onClick={handleSaveAndPrintWithPrintingState}
                  disabled={!netWeight}
                  size="large"
                >
                  Save & Print
                </Button>
              </div>
            </Card>
          </div>
        )}

        {jobData && customerData && productData && truckData && (
          <div className="side-panels">
            <Card className="job-progress-card">
              <Title level={4} className="card-title">
                <BarChartOutlined /> Job Progress
              </Title>
              {jobData && (
                <div>
                  <Statistic
                    title="Progress"
                    value={Math.round((parseFloat(jobData.progressive) / parseFloat(jobData.quantity)) * 100)}
                    suffix="%"
                    valueStyle={{ color: '#1890ff' }}
                  />
                  <Progress
                    percent={Math.round((parseFloat(jobData.progressive) / parseFloat(jobData.quantity)) * 100)}
                    status="active"
                    strokeColor={{
                      '0%': '#87d068',
                      '100%': '#108ee9',
                    }}
                  />
                  <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                    <Col span={12}>
                      <Statistic
                        title="Ordered"
                        value={parseFloat(jobData.quantity)}
                        precision={2}
                        suffix="t"
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Delivered"
                        value={jobData.progressive}
                        precision={2}
                        suffix="t"
                        valueStyle={{ color: '#52c41a' }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </Card>

            <Card className="truck-allocation-card">
              <Title level={4} className="card-title">
                <TruckOutlined /> Allocated Trucks
              </Title>
              {jobData?.trucks_allocated && (
                <div className="truck-list">
                  {(Array.isArray(jobData.trucks_allocated) ? jobData.trucks_allocated : []).map((fleetNumber: string) => {
                    const truck = allocatedTrucks.find((t: any) => t.fleet_number === fleetNumber);
                    const truckProgressive = allocatedTrucks
                      .filter(t => t.fleet_number === fleetNumber)
                      .reduce((sum, t) => sum + (t.todays_deliveries || 0), 0);
                    
                    return (
                      <div key={fleetNumber} className="truck-item">
                        <div className="truck-info">
                          <Tag icon={<TruckOutlined />} color="blue">
                            Fleet #{fleetNumber}
                          </Tag>
                          <div className="truck-delivery-time">
                            {truck?.last_delivery_time ? 
                              `Last delivery: ${dayjs(truck.last_delivery_time).format('HH:mm')}` : 
                              'No deliveries yet'}
                          </div>
                        </div>
                        <div className="truck-stats">
                          <Statistic
                            value={truckProgressive}
                            precision={1}
                            suffix="t"
                            valueStyle={{ fontSize: '16px' }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocketForm;
