// Function to check if a word is likely an acronym (non-dictionary word)
const isLikelyAcronym = (word: string): boolean => {
  // Check if word is 2-5 letters and contains only letters
  return /^[A-Za-z]{2,5}$/.test(word);
};

// Function to format text with acronyms in uppercase
export const formatWithAcronyms = (text: string | null | undefined): string => {
  if (!text) return '';
  
  return text.split(/\s+/).map(word => {
    if (isLikelyAcronym(word)) {
      return word.toUpperCase();
    }
    // For non-acronyms, capitalize first letter only
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};
