import React, { useMemo, useState } from 'react';
import { Table, Tag, Space, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, CarOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Driver, DriverStatus } from '../types';
import { getStatusColor } from '../utils/driverUtils';

export interface DriverTableProps {
    drivers: Driver[];
    loading: boolean;
    onEdit: (driver: Driver) => void;
    onDelete: (driver: Driver) => void;
    onAssign: (driver: Driver) => void;
    onViewDetails: (driver: Driver) => void;
}

export const DriverTable: React.FC<DriverTableProps> = ({
    drivers,
    loading,
    onEdit,
    onDelete,
    onAssign,
    onViewDetails,
}: DriverTableProps) => {
    const [pageSize, setPageSize] = useState(10);

    const uniqueLocations = useMemo(() => {
        const locations = drivers
            .map(driver => ({
                id: driver.location_id,
                name: driver.location?.name || 'Unknown Location'
            }))
            .filter((location): location is { id: string; name: string } => location.id != null);
        return Array.from(new Set(locations.map(l => JSON.stringify(l))))
            .map(str => JSON.parse(str));
    }, [drivers]);

    const locationOptions = useMemo(() => {
        return [
            { value: '', text: 'All Locations' },
            ...uniqueLocations.map(location => ({
                value: location.id,
                text: location.name
            }))
        ];
    }, [uniqueLocations]);

    const columns: ColumnsType<Driver> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            render: (phone: string | undefined) => phone || '-',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (email: string | undefined) => email || '-',
        },
        {
            title: 'License Number',
            dataIndex: 'license_number',
            key: 'license_number',
            render: (license: string | undefined) => license || '-',
        },
        {
            title: 'Location',
            dataIndex: 'location_id',
            key: 'location',
            render: (locationId: string | undefined, record: Driver) => record.location?.name || 'Unknown Location',
            filters: locationOptions,
            onFilter: (value, record) => value === '' ? true : record.location_id === value,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: DriverStatus) => {
                const color = getStatusColor(status);
                return (
                    <Tag color={color} key={status}>
                        {status}
                    </Tag>
                );
            },
            filters: [
                { text: 'Available', value: 'Available' },
                { text: 'Annual Leave', value: 'Annual Leave' },
                { text: 'Sick', value: 'Sick' },
                { text: 'Wet Day', value: 'Wet Day' },
                { text: 'Inactive', value: 'Inactive' },
                { text: 'SubContracting', value: 'SubContracting' }
            ],
            onFilter: (value, record) => record.status === value,
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            title: 'Assigned Truck',
            key: 'current_assignment',
            render: (_, record) => (
                record.current_assignment ? (
                    <Tag color="processing">
                        {record.current_assignment.truck?.fleet_number || 'Unknown Truck'}
                    </Tag>
                ) : (
                    <Tag>None</Tag>
                )
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="small">
                    <Tooltip title="Assign to Truck">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#1890ff' }}
                            icon={<CarOutlined />}
                            onClick={() => onAssign(record)}
                        />
                    </Tooltip>
                    <Tooltip title="View Details">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#52c41a' }}
                            icon={<EyeOutlined />}
                            onClick={() => onViewDetails(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#faad14' }}
                            icon={<EditOutlined />}
                            onClick={() => onEdit(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Remove">
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete(record)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Table<Driver>
            columns={columns}
            dataSource={drivers}
            loading={loading}
            rowKey="id"
            pagination={{
                total: drivers.length,
                pageSize: pageSize,
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} drivers`,
                onShowSizeChange: (_, size) => setPageSize(size),
                position: ['bottomCenter']
            }}
            scroll={{ y: 700 }}
            size="middle"
            style={{ width: '100%', height: '100%' }}
        />
    );
};
