import React, { useEffect, useState } from 'react';
import {
  Card,
  Tabs,
  Table,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Space,
  Typography,
  InputNumber
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Database } from '../../../types/database.types';
import { ProductPriceRule, CustomerPriceOverride, JobPriceOverride } from '../types';
import { formatCurrency, formatDate } from '../../../utils/formatters';
import type { TabsProps } from 'antd';
import dayjs from 'dayjs';

const { Title } = Typography;

interface ProductPricingProps {
  productId: string;
}

type Tables = Database['public']['Tables'];
type PriceRuleRow = Tables['product_price_rules']['Row'];
type CustomerOverrideRow = Tables['customer_price_overrides']['Row'];
type JobOverrideRow = Tables['job_price_overrides']['Row'];

export const ProductPricing: React.FC<ProductPricingProps> = ({ productId }) => {
  const supabase = useSupabaseClient<Database>();
  const [activeTab, setActiveTab] = useState<string>('1');
  const [priceRules, setPriceRules] = useState<PriceRuleRow[]>([]);
  const [customerOverrides, setCustomerOverrides] = useState<CustomerOverrideRow[]>([]);
  const [jobOverrides, setJobOverrides] = useState<JobOverrideRow[]>([]);
  const [customers, setCustomers] = useState<Array<{ id: string; customer_name: string }>>([]);
  const [jobs, setJobs] = useState<Array<{ id: string; name: string }>>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState<'base' | 'customer' | 'job'>('base');
  const [editItem, setEditItem] = useState<PriceRuleRow | CustomerOverrideRow | JobOverrideRow | null>(null);

  const fetchPriceRules = async () => {
    const { data, error } = await supabase
      .from('product_price_rules')
      .select()
      .eq('product_id', productId)
      .order('effective_from', { ascending: false });

    if (!error && data) {
      setPriceRules(data);
    }
  };

  const fetchCustomerOverrides = async () => {
    const { data, error } = await supabase
      .from('customer_price_overrides')
      .select(`
        *,
        customers (
          customer_name
        )
      `)
      .eq('product_id', productId)
      .order('effective_from', { ascending: false });

    if (!error && data) {
      setCustomerOverrides(data);
    }
  };

  const fetchJobOverrides = async () => {
    const { data, error } = await supabase
      .from('job_price_overrides')
      .select(`
        *,
        jobs (
          name
        )
      `)
      .eq('product_id', productId)
      .order('effective_from', { ascending: false });

    if (!error && data) {
      setJobOverrides(data);
    }
  };

  const fetchCustomers = async () => {
    const { data, error } = await supabase
      .from('customers')
      .select('id, customer_name')
      .order('customer_name');

    if (!error && data) {
      setCustomers(data);
    }
  };

  const fetchJobs = async () => {
    const { data, error } = await supabase
      .from('jobs')
      .select('id, name')
      .order('name');

    if (!error && data) {
      setJobs(data);
    }
  };

  useEffect(() => {
    fetchPriceRules();
    fetchCustomerOverrides();
    fetchJobOverrides();
    fetchCustomers();
    fetchJobs();
  }, [productId]);

  const handleAdd = (mode: 'base' | 'customer' | 'job') => {
    setEditMode(mode);
    setEditItem(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleEdit = (item: PriceRuleRow | CustomerOverrideRow | JobOverrideRow, mode: 'base' | 'customer' | 'job') => {
    setEditMode(mode);
    setEditItem(item);
    form.setFieldsValue({
      price: mode === 'base' ? (item as PriceRuleRow).base_price : (item as CustomerOverrideRow | JobOverrideRow).price,
      effectiveFrom: dayjs(item.effective_from),
      effectiveTo: item.effective_to ? dayjs(item.effective_to) : null,
      notes: 'notes' in item ? item.notes : undefined,
      customerId: 'customer_id' in item ? item.customer_id : undefined,
      jobId: 'job_id' in item ? item.job_id : undefined,
    });
    setIsModalVisible(true);
  };

  const handleDelete = async (id: string, table: 'product_price_rules' | 'customer_price_overrides' | 'job_price_overrides') => {
    Modal.confirm({
      title: 'Are you sure you want to delete this price rule?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const { error } = await supabase
          .from(table)
          .delete()
          .eq('id', id);

        if (!error) {
          switch (table) {
            case 'product_price_rules':
              await fetchPriceRules();
              break;
            case 'customer_price_overrides':
              await fetchCustomerOverrides();
              break;
            case 'job_price_overrides':
              await fetchJobOverrides();
              break;
          }
        }
      },
    });
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const table = editMode === 'base' ? 'product_price_rules' :
        editMode === 'customer' ? 'customer_price_overrides' : 'job_price_overrides';

      const data = {
        product_id: productId,
        [editMode === 'base' ? 'base_price' : 'price']: values.price,
        effective_from: values.effectiveFrom.toISOString(),
        effective_to: values.effectiveTo?.toISOString() || null,
        is_active: true,
        ...(editMode === 'customer' && { customer_id: values.customerId }),
        ...(editMode === 'job' && { job_id: values.jobId }),
        ...(editMode !== 'base' && { notes: values.notes }),
      };

      if (editItem) {
        await supabase
          .from(table)
          .update(data)
          .eq('id', editItem.id);
      } else {
        await supabase
          .from(table)
          .insert([data]);
      }

      setIsModalVisible(false);
      fetchPriceRules();
      fetchCustomerOverrides();
      fetchJobOverrides();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const basePriceColumns = [
    {
      title: 'Base Price',
      dataIndex: 'base_price',
      key: 'base_price',
      render: (price: number) => formatCurrency(price),
    },
    {
      title: 'Effective From',
      dataIndex: 'effective_from',
      key: 'effective_from',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Effective To',
      dataIndex: 'effective_to',
      key: 'effective_to',
      render: (date: string | null) => date ? formatDate(date) : 'Ongoing',
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active: boolean) => active ? 'Active' : 'Inactive',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: PriceRuleRow) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record, 'base')}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id, 'product_price_rules')}
          />
        </Space>
      ),
    },
  ];

  const customerOverrideColumns = [
    {
      title: 'Customer',
      dataIndex: ['customers', 'customer_name'],
      key: 'customer',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => formatCurrency(price),
    },
    {
      title: 'Effective From',
      dataIndex: 'effective_from',
      key: 'effective_from',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Effective To',
      dataIndex: 'effective_to',
      key: 'effective_to',
      render: (date: string | null) => date ? formatDate(date) : 'Ongoing',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: CustomerOverrideRow) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record, 'customer')}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id, 'customer_price_overrides')}
          />
        </Space>
      ),
    },
  ];

  const jobOverrideColumns = [
    {
      title: 'Job',
      dataIndex: ['jobs', 'name'],
      key: 'job',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => formatCurrency(price),
    },
    {
      title: 'Effective From',
      dataIndex: 'effective_from',
      key: 'effective_from',
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Effective To',
      dataIndex: 'effective_to',
      key: 'effective_to',
      render: (date: string | null) => date ? formatDate(date) : 'Ongoing',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: JobOverrideRow) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record, 'job')}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id, 'job_price_overrides')}
          />
        </Space>
      ),
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Base Prices',
      children: (
        <div>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => handleAdd('base')}>
              Add Base Price
            </Button>
          </div>
          <Table
            dataSource={priceRules}
            columns={basePriceColumns}
            rowKey="id"
          />
        </div>
      ),
    },
    {
      key: '2',
      label: 'Customer Overrides',
      children: (
        <div>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => handleAdd('customer')}>
              Add Customer Override
            </Button>
          </div>
          <Table
            dataSource={customerOverrides}
            columns={customerOverrideColumns}
            rowKey="id"
          />
        </div>
      ),
    },
    {
      key: '3',
      label: 'Job Overrides',
      children: (
        <div>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={() => handleAdd('job')}>
              Add Job Override
            </Button>
          </div>
          <Table
            dataSource={jobOverrides}
            columns={jobOverrideColumns}
            rowKey="id"
          />
        </div>
      ),
    },
  ];

  return (
    <Card>
      <Title level={4}>Product Pricing</Title>
      <Tabs activeKey={activeTab} items={items} onChange={(key) => setActiveTab(key)} />
      
      <Modal
        title={`${editItem ? 'Edit' : 'Add'} ${editMode === 'base' ? 'Base Price' : 
          editMode === 'customer' ? 'Customer Override' : 'Job Override'}`}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: 'Please enter the price' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>

          <Form.Item
            name="effectiveFrom"
            label="Effective From"
            rules={[{ required: true, message: 'Please select the effective from date' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="effectiveTo"
            label="Effective To"
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          {editMode !== 'base' && (
            <Form.Item
              name="notes"
              label="Notes"
            >
              <Input.TextArea />
            </Form.Item>
          )}

          {editMode === 'customer' && (
            <Form.Item
              name="customerId"
              label="Customer"
              rules={[{ required: true, message: 'Please select a customer' }]}
            >
              <Select
                placeholder="Select a customer"
                showSearch
                optionFilterProp="children"
              >
                {customers.map(customer => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.customer_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {editMode === 'job' && (
            <Form.Item
              name="jobId"
              label="Job"
              rules={[{ required: true, message: 'Please select a job' }]}
            >
              <Select
                placeholder="Select a job"
                showSearch
                optionFilterProp="children"
              >
                {jobs.map(job => (
                  <Select.Option key={job.id} value={job.id}>
                    {job.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Card>
  );
};
