interface ABNResponse {
  Names: Array<{
    Abn: string;
    Name: string;
  }>;
}

interface ABNDetails {
  EntityName: string;
  BusinessName: string;
  EntityType: string;
  EntityStatus: string;
  AddressDetails?: {
    State: string;
    Postcode: string;
    AddressLine1: string;
    Suburb: string;
  };
}

export const searchABN = async (query: string) => {
  try {
    // First, get ABN results
    const abnResponse = await fetch(
      `https://abr.business.gov.au/json/MatchingNames.aspx?name=${encodeURIComponent(
        query
      )}&maxResults=10&guid=${process.env.REACT_APP_ABN_GUID}&callback=callback`
    );
    const abnText = await abnResponse.text();
    const abnJsonStr = abnText.replace(/^callback\((.*)\)$/, '$1');
    const abnData = JSON.parse(abnJsonStr);
    
    return { data: abnData };
  } catch (error) {
    console.error('Error searching ABN:', error);
    throw error;
  }
};
