import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('Supabase Key length:', process.env.REACT_APP_SUPABASE_ANON_KEY?.length);

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: window.localStorage,
  },
  global: {
    headers: {
      'X-Client-Info': 'allocateit-frontend',
    },
  },
  realtime: {
    params: {
      eventsPerSecond: 10,
    },
    logger: (log: unknown) => {
      if (typeof log === 'string') {
        console.log('Supabase Realtime:', log);
      } else if (log && typeof log === 'object') {
        const { timestamp, level, msg, ...rest } = log as any;
        console.log('Supabase Realtime:', level || 'INFO', msg || '', {
          timestamp,
          ...rest,
          raw: log
        });
      }
    },
  },
});

// Add a helper function to check connection and auth status
export const checkConnection = async (): Promise<boolean> => {
  try {
    console.log('Checking Supabase connectivity...');
    
    // Try to make a simple public query to verify connection
    const { data, error: queryError } = await supabase
      .from('customers')
      .select('count', { count: 'exact', head: true })
      .limit(1);

    if (queryError) {
      console.error('Database connectivity test failed:', queryError.message, queryError);
      return false;
    }

    console.log('Supabase connection check passed');
    return true;
  } catch (error) {
    console.error('Connection check failed:', error);
    return false;
  }
};
