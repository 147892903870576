import { useCallback } from 'react';
import { App } from 'antd';
import dayjs from 'dayjs';
import { supabase } from '../../../lib/supabaseClient';
import { createDocket as createDocketService, printDocket as printDocketService } from '../services/docketService';
import { JobData, TruckData, CustomerData, ProductData } from '../types';

export const useDocketOperations = () => {
  const { notification } = App.useApp();

  const handleSearch = useCallback(async (
    searchValue: string,
    setLoading: (loading: boolean) => void,
    setJobData: (data: JobData | null) => void,
    setTruckData: (data: TruckData | null) => void,
    setCustomerData: (data: CustomerData | null) => void,
    setProductData: (data: ProductData | null) => void,
    setAllocatedTrucks: (trucks: any[]) => void,
    setJobDetails: (details: any) => void
  ): Promise<boolean> => {
    if (!searchValue.trim()) {
      notification.warning({
        message: 'Input Required',
        description: 'Please enter a truck number',
        placement: 'topRight',
        style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
      });
      return false;
    }

    try {
      setLoading(true);
      const today = dayjs().format('YYYY-MM-DD');

      // Find the truck
      const { data: truckData, error: truckError } = await supabase
        .from('trucks')
        .select('id, fleet_number, body_tare, trailer_tare, truck_rego, type, body_max_gross, trailer_max_gross, combined_max_gross')
        .or(`fleet_number.ilike.%${searchValue}%,truck_rego.ilike.%${searchValue.toUpperCase()}%`)
        .single();

      if (truckError || !truckData) {
        notification.error({
          message: 'Truck Not Found',
          description: 'No matching truck found',
          placement: 'topRight',
          style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
        });
        return false;
      }

      // Get jobs for today
      const { data: jobs, error: jobError } = await supabase
        .from('jobs')
        .select(`
          id, job_number, customer_id, delivery_address, product,
          quantity, trucks_allocated, progressive, order_number,
          suburb, truck_type, delivery_date, location_id, status
        `)
        .eq('delivery_date', today);

      if (jobError) throw jobError;

      const jobData = jobs?.find(job => 
        job.trucks_allocated && 
        Array.isArray(job.trucks_allocated) && 
        job.trucks_allocated.includes(truckData.fleet_number)
      );

      if (!jobData) {
        notification.error({
          message: 'Job Not Found',
          description: 'No job found for this truck today',
          placement: 'topRight',
          style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
        });
        return false;
      }

      // Get customer data
      const { data: customerData, error: customerError } = await supabase
        .from('customers')
        .select('*')
        .eq('id', jobData.customer_id)
        .single();

      if (customerError) throw customerError;

      // Get product data
      const { data: products, error: productError } = await supabase
        .from('products')
        .select('*');

      if (productError) throw productError;

      console.log('Job product:', jobData.product);
      console.log('All products:', products);

      // Extract base product name (everything before any dash)
      const baseProductName = jobData.product.split('-')[0].trim();
      console.log('Looking for base product:', baseProductName);
      console.log('Available product names:', products.map(p => p.name));

      // Try different matching strategies
      let foundProduct = products.find(p => p.name.toLowerCase() === baseProductName.toLowerCase());
      
      if (!foundProduct) {
        // Try matching without case and with flexible whitespace
        foundProduct = products.find(p => 
          p.name.toLowerCase().replace(/\s+/g, '') === baseProductName.toLowerCase().replace(/\s+/g, '')
        );
      }

      if (!foundProduct) {
        // Try partial matching
        foundProduct = products.find(p => 
          p.name.toLowerCase().includes(baseProductName.toLowerCase()) ||
          baseProductName.toLowerCase().includes(p.name.toLowerCase())
        );
      }

      if (!foundProduct) {
        console.error('Product not found. Details:', {
          searchedName: baseProductName,
          availableProducts: products.map(p => p.name),
          originalProduct: jobData.product
        });
        notification.error({
          message: 'Product Not Found',
          description: `Could not find product "${baseProductName}" in the database. Original product: ${jobData.product}`,
          placement: 'topRight',
          style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
        });
        return false;
      }

      console.log('Found product:', foundProduct);

      // Get location data
      const { data: locationData, error: locationError } = await supabase
        .from('locations')
        .select('name')
        .eq('id', jobData.location_id)
        .single();

      if (locationError || !locationData?.name) {
        notification.error({
          message: 'Location Error',
          description: 'Failed to fetch location information',
          placement: 'topRight',
          style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
        });
        return false;
      }

      // Get truck progress
      const { data: truckProgress, error: progressError } = await supabase
        .from('pulse_dockets')
        .select('id, truck_id, quantity, created_at, fleet_number')
        .eq('job_id', jobData.id)
        .order('created_at', { ascending: false });

      if (!progressError && truckProgress) {
        const processedTruckProgress = truckProgress.map(progress => ({
          id: progress.id,
          fleet_number: progress.fleet_number,
          status: 'active' as const,
          todays_deliveries: progress.quantity || 0,
          total_delivered: progress.quantity || 0,
          last_delivery_time: progress.created_at
        }));
        setAllocatedTrucks(processedTruckProgress);
      }

      // Update all state
      setJobData({
        id: jobData.id,
        job_number: jobData.job_number,
        customer_id: jobData.customer_id,
        delivery_address: jobData.delivery_address,
        product: jobData.product,
        quantity: jobData.quantity,
        trucks_allocated: jobData.trucks_allocated,
        progressive: jobData.progressive || '0',
        order_number: jobData.order_number,
        suburb: jobData.suburb,
        plant: [{ name: locationData.name }],
        truck_type: jobData.truck_type,
        locations: locationData
      });
      setTruckData({
        id: truckData.id,
        fleet_number: truckData.fleet_number,
        body_tare: truckData.body_tare || 0,
        trailer_tare: truckData.trailer_tare || 0,
        truck_rego: truckData.truck_rego,
        type: truckData.type,
        body_max_gross: truckData.body_max_gross || 0,
        trailer_max_gross: truckData.trailer_max_gross || 0,
        combined_max_gross: truckData.combined_max_gross || 0
      });
      setCustomerData(customerData);
      setProductData(foundProduct);
      setJobDetails({
        quantity_ordered: parseFloat(jobData.quantity),
        progressive: parseFloat(jobData.progressive || '0'),
        daily_average: 0,
        days_remaining: 0
      });

      return true;
    } catch (error) {
      console.error('Error searching:', error);
      notification.error({
        message: 'Search Error',
        description: 'Failed to search for truck',
        placement: 'topRight',
        style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
      });
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSaveAndPrint = useCallback(async (
    jobData: JobData | null,
    truckData: TruckData | null,
    customerData: CustomerData | null,
    productData: ProductData | null,
    grossWeight: number | null,
    netWeight: number | null,
    isSplitLoad: boolean,
    splitLoadPart: 'truck' | 'trailer' | null,
    onDocketCreated?: () => void,
    resetForm?: () => void
  ) => {
    if (!jobData || !truckData || !customerData || !productData || !grossWeight || !netWeight) {
      notification.error({
        message: 'Form Error',
        description: 'Please fill in all required fields',
        placement: 'topRight',
        style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
      });
      return;
    }

    const currentTare = isSplitLoad ? 
      (splitLoadPart === 'truck' ? truckData.body_tare : truckData.trailer_tare) : 
      (truckData.body_tare + (truckData.trailer_tare || 0));

    console.log('Weight values:', {
      grossWeight,
      currentTare,
      netWeight,
      isSplitLoad,
      splitLoadPart
    });

    try {
      // Create the docket first
      const docket = await createDocketService({
        job_id: jobData.id,
        truck_id: truckData.id,
        fleet_number: truckData.fleet_number,
        customer_id: customerData.id,
        delivery_address: jobData.delivery_address,
        product_id: productData.id,
        gross_weight: grossWeight,
        tare_weight: currentTare,
        net_weight: netWeight,
        is_split_load: isSplitLoad,
        split_load_part: splitLoadPart,
      });

      // Call onDocketCreated immediately after docket creation
      onDocketCreated?.();
      resetForm?.();

      // Show success notification for docket creation
      notification.success({
        message: 'Docket Saved',
        description: 'Docket saved successfully',
        placement: 'topRight',
        style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
      });

      // Print docket asynchronously
      printDocketService({
        docket_number: docket.docket_number,
        plant: jobData.locations?.name || jobData.plant?.[0]?.name || '',
        customer: customerData.customer_name,
        customer_number: customerData.customer_number,
        delivery_address: jobData.delivery_address,
        suburb: jobData.suburb || '',
        order_number: jobData.order_number || '',
        product_name: productData.name,
        product_type: productData.type || '',
        product_code: productData.product_code,
        lot_number: productData.lot_number,
        job_number: jobData.job_number.toString(),
        fleet_number: truckData.fleet_number,
        progressive: docket.progressive,
        gross_weight: Number(grossWeight),
        tare_weight: Number(currentTare),
        net_weight: Number(netWeight),
        quantity: Number(netWeight)
      }).catch(error => {
        console.error('Error printing docket:', error);
        notification.error({
          message: 'Print Error',
          description: 'Failed to print docket, but docket was saved successfully',
          placement: 'topRight',
          style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
        });
      });

    } catch (error) {
      console.error('Error saving docket:', error);
      notification.error({
        message: 'Docket Error',
        description: 'Failed to save docket',
        placement: 'topRight',
        style: { borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.15)' }
      });
    }
  }, []);

  return {
    handleSearch,
    handleSaveAndPrint
  };
};
