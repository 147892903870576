import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import KarremanLogo from '../../../images/Karreman.jpeg';
import BSILogo from '../../../images/BSI9001.jpg';

interface PrintTemplateProps {
  docketData: {
    docket_number: string;
    plant: string;
    customer: string;
    customer_number: string;
    delivery_address: string;
    suburb?: string;
    order_number: string;
    product_name: string;
    product_type: string;
    product_code: string;
    lot_number: string;
    job_number?: string;
    xbin_job_number?: string;
    fleet_number: string;
    call_sign?: string;
    carter?: string;
    progressive: string;
    gross_weight: number;
    tare_weight: number;
    net_weight: number;
    quantity: number;
    previous_progressive?: number;
    site_instructions?: string;
    kilometres?: number;
    location: string;
    registration?: string;
    location_id: string;
    truck_registration: string;
  };
  type: 'docket' | 'xbinDocket';
}

const styles = `
  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    html, body {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      color-adjust: exact;
    }
    
    .print-template {
      width: 100% !important;
      min-height: 100% !important;
      margin: 0 !important;
      padding: 5mm !important;
      box-sizing: border-box !important;
      page-break-after: always;
    }

    .header {
      display: grid !important;
      grid-template-columns: 150px 1fr 150px !important;
      align-items: center !important;
      margin-bottom: 20px !important;
      gap: 20px !important;
    }

    .logo-container {
      display: block !important;
      width: 150px !important;
      height: 80px !important;
      position: relative !important;
    }

    .logo {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      object-fit: contain !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      color-adjust: exact !important;
    }

    img {
      display: block !important;
      max-width: 100% !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      color-adjust: exact !important;
    }

    .logo-container {
      display: block !important;
      margin: 10px 0 !important;
    }

    .logo {
      display: block !important;
      max-height: 50px !important;
      width: auto !important;
      object-fit: contain !important;
    }
  }

  .print-template {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 5mm;
    font-family: Arial, sans-serif;
    background: white;
    box-sizing: border-box;
  }

  .logo-container {
    display: block;
    margin: 10px 0;
  }

  .logo {
    display: block;
    max-height: 50px;
    width: auto;
    object-fit: contain;
  }

  .header {
    display: grid;
    grid-template-columns: 150px 1fr 150px;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid black;
    gap: 20px;
  }

  .company-details-container {
    text-align: center;
  }

  .company-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
    text-align: center;
    letter-spacing: 1px;
  }

  .company-details {
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
  }

  .time-date-row {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    margin-bottom: 10px;
  }

  .time-date-box {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }

  .weights-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .weights-cell {
    padding: 8px;
    border-right: 1px solid black;
  }

  .highlighted {
    background-color: #e6e6e6;
  }

  .additional-charges {
    margin-top: 20px;
    border: 1px solid black;
  }

  .charges-header {
    padding: 8px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid black;
  }

  .charges-grid {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .charges-times {
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .charges-totals {
    border-left: 1px solid black;
  }

  .charges-total-row {
    padding: 8px;
    border-bottom: 1px solid black;
  }

  .signature-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 20px;
  }

  .signature-box {
    text-align: center;
  }

  .signature-line {
    margin-top: 40px;
    border-top: 1px solid black;
    padding-top: 5px;
    font-size: 12px;
  }

  .footer {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
  }

  .docket-grid {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
  }

  .docket-row {
    display: grid;
    grid-template-columns: 70% 30%;
    border-bottom: 1px solid black;
  }

  .docket-cell {
    padding: 8px;
    border-right: 1px solid black;
    min-height: 30px;
    position: relative;
  }

  .docket-cell:last-child {
    border-right: none;
  }

  .docket-label {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }

  .docket-value {
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const PrintTemplate: React.FC<PrintTemplateProps> = ({ docketData, type = 'docket' }) => {
  console.log('PrintTemplate received docketData:', docketData);
  console.log('Location value in template:', docketData.location);
  console.log('Plant value in template:', docketData.plant);
  console.log('PrintTemplate received weight data:', {
    gross: docketData.gross_weight,
    tare: docketData.tare_weight,
    net: docketData.net_weight,
    calculatedNet: docketData.gross_weight - docketData.tare_weight
  });

  return (
    <div className="print-template">
      <style>{styles}</style>
      
      <div className="header">
        <div className="logo-container">
          <img 
            src={KarremanLogo}
            alt="Karreman Logo" 
            className="logo"
          />
        </div>
        <div className="company-details-container">
          <div className="company-title">KARREMAN QUARRIES PTY. LTD.</div>
          <div className="company-details">
            Karreman Quarries Pty.Ltd. ACN 010 168 742<br />
            ATF Karreman Quarries Trust ABN 42 482 600 638<br />
            PO Box 716, Cleveland Qld 4163<br />
            Phone: (07) 3206 6311 Fax: (07) 3206 6136<br />
            WEIGHBRIDGE: (07) 3206 6155
          </div>
        </div>
        <div className="logo-container">
          <img 
            src={BSILogo}
            alt="BSI Logo" 
            className="logo"
          />
        </div>
      </div>

      <div className="time-date-row">
        <div className="time-date-box">
          <div className="docket-label">TIME OUT</div>
          <div className="docket-value">{dayjs().format('HH:mm')}</div>
        </div>
        <div className="time-date-box">
          <div className="docket-label">DATE</div>
          <div className="docket-value">{dayjs().format('DD-MMM-YYYY')}</div>
        </div>
        <div className="time-date-box">
          <div className="docket-label">PLANT</div>
          <div className="docket-value">{docketData.plant}</div>
        </div>
      </div>

      <div className="docket-grid">
        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">CUSTOMER</div>
            <div className="docket-value">{docketData.customer}</div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">CUSTOMER No.</div>
            <div className="docket-value">{docketData.customer_number}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">DELIVERY TO</div>
            <div className="docket-value">{docketData.delivery_address}</div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">ORDER No.</div>
            <div className="docket-value">{docketData.order_number}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">STREET</div>
            <div className="docket-value"></div>
          </div>
          <div className="docket-cell highlighted">
            <div className="docket-label">DOCKET No.</div>
            <div className="docket-value">{docketData.docket_number}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">SUBURB</div>
            <div className="docket-value">{docketData.suburb || ''}</div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">KILOMETRES</div>
            <div className="docket-value">{docketData.kilometres}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">SITE INSTRUCTIONS</div>
            <div className="docket-value">{docketData.site_instructions}</div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">PRODUCT CODE</div>
            <div className="docket-value">{docketData.product_code}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">CARTER</div>
            <div className="docket-value">{docketData.carter}</div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">LOT No.</div>
            <div className="docket-value">{docketData.lot_number}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <div>
              <div className="docket-label">REGISTRATION No.</div>
              <div className="docket-value">{docketData.truck_registration || docketData.registration}</div>
            </div>
            <div>
              <div className="docket-label">CALL SIGN</div>
              <div className="docket-value">{docketData.fleet_number}</div>
            </div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">JOB No.</div>
            <div className="docket-value">
              {type === 'xbinDocket' ? docketData.xbin_job_number : docketData.job_number}
            </div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">PRODUCTION INSTRUCTIONS</div>
            <div className="docket-value">{docketData.product_name} {docketData.product_type}</div>
          </div>
          <div className="docket-cell">
            <div>
              <div className="docket-label">GROSS</div>
              <div className="docket-value">{Number(docketData.gross_weight).toFixed(2)}</div>
            </div>
            <div style={{ marginTop: '8px' }}>
              <div className="docket-label">TARE</div>
              <div className="docket-value">{Number(docketData.tare_weight).toFixed(2)}</div>
            </div>
            <div style={{ marginTop: '8px' }} className="highlighted">
              <div className="docket-label">NETT</div>
              <div className="docket-value">
                {Number(docketData.net_weight).toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell">
            <div className="docket-label">PREVIOUS PROGRESSIVE</div>
            <div className="docket-value">{(parseFloat(docketData.progressive) - docketData.net_weight).toFixed(2)}</div>
          </div>
          <div className="docket-cell">
            <div className="docket-label">THIS PROGRESSIVE</div>
            <div className="docket-value">{parseFloat(docketData.progressive).toFixed(2)}</div>
          </div>
        </div>

        <div className="docket-row">
          <div className="docket-cell"></div>
          <div className="docket-cell">
            <div className="docket-label">SUB TOTAL</div>
          </div>
        </div>
      </div>

      <div className="additional-charges">
        <div className="charges-header">ADDITIONAL CHARGES</div>
        <div className="charges-grid">
          <div className="charges-times">
            <div>
              <span className="docket-label">TIME IN</span>
              <span style={{ marginLeft: '20px' }}>_____________</span>
            </div>
            <div>
              <span className="docket-label">TIME OUT</span>
              <span style={{ marginLeft: '20px' }}>_____________</span>
            </div>
          </div>
          <div className="charges-totals">
            <div className="charges-total-row">
              <div className="docket-label">G.S.T 10%</div>
            </div>
            <div className="charges-total-row">
              <div className="docket-label">JOB TOTAL</div>
            </div>
            <div style={{ padding: '8px', textAlign: 'center' }}>
              Material Received and Checked
            </div>
          </div>
        </div>
      </div>

      <div className="signature-section">
        <div className="signature-box">
          <div className="signature-line">
            Signature For and On Behalf of Purchaser
          </div>
        </div>
        <div className="signature-box">
          <div className="signature-line">
            Signature For and On Behalf of Purchaser
          </div>
        </div>
      </div>

      <div className="footer">
        Terms and Conditions see: https://www.karreman.com.au/index.php/terms-and-conditions
      </div>
    </div>
  );
};