import { TruckStatus } from '../../trucks/types';

export const STATUS_COLORS = {
  // Truck statuses
  'Available': '#7afa8e',
  'InUse': '#7adcfa',
  'Workshop': '#faab7a',
  'SubContracting': '#fa7a7a',
  'Inactive': '#f5f5f5',

  // Delivery statuses
  'completed': '#7afa8e',
  'active': '#7adcfa',
  'on-hold': '#faab7a',
  'cancelled': '#fa7a7a',
  'moved': '#7adcfa',
  'entered': '#f5f5f5',
  'started': '#7adcfa'
} as const;

export type StatusType = keyof typeof STATUS_COLORS;

export type DeliveryStatusType = 
  | 'completed'
  | 'active'
  | 'on-hold'
  | 'cancelled'
  | 'moved'
  | 'entered'
  | 'started';

export const REFRESH_INTERVAL = 30000; // 30 seconds