import React, { useState, useEffect } from 'react';
import { Form, Select, Input, Typography, Space, Tag, App, Button } from 'antd';
import { Driver } from '../types';
import { supabase } from '../../../lib/supabaseClient';
import { useAssignments } from '../../trucks/hooks/useAssignments';
import dayjs from 'dayjs';
import { Modal } from '../../../components/common/Modal';

const { Option } = Select;
const { Text } = Typography;

interface Props {
    visible: boolean;
    onCancel: () => void;
    onComplete: () => void;
    driver: Driver | null;
}

interface Truck {
    id: string;
    fleet_number: string;
    status: string;
    current_driver: {
        name: string;
    } | null;
}

interface SupabaseTruck {
    id: string;
    fleet_number: string;
    status: string;
    current_assignment: {
        driver: {
            name: string;
        };
    }[] | null;
}

const AssignmentModal: React.FC<Props> = ({
    visible,
    onCancel,
    onComplete,
    driver
}) => {
    const [form] = Form.useForm();
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [loading, setLoading] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [pendingAssignment, setPendingAssignment] = useState<any>(null);
    const [selectedTruck, setSelectedTruck] = useState<Truck | null>(null);
    const [currentDriver, setCurrentDriver] = useState<{ name: string } | null>(null);
    const { createAssignment } = useAssignments();
    const { message } = App.useApp();

    useEffect(() => {
        if (visible) {
            form.resetFields();
            fetchTrucks();
        }
    }, [visible]);

    const fetchTrucks = async () => {
        setLoading(true);
        try {
            const { data, error: trucksError } = await supabase
                .from('trucks')
                .select(`
                    id,
                    fleet_number,
                    status,
                    current_assignment:assignments(
                        driver:drivers!assignments_driver_id_fkey(
                            name
                        )
                    )
                `)
                .eq('status', 'Available')
                .is('current_assignment.end_time', null);

            if (trucksError) throw trucksError;

            const trucksData = data as unknown as SupabaseTruck[];
            const availableTrucks: Truck[] = (trucksData || []).map(truck => ({
                id: truck.id,
                fleet_number: truck.fleet_number,
                status: truck.status,
                current_driver: truck.current_assignment?.[0]?.driver || null
            }));

            setTrucks(availableTrucks);
        } catch (error) {
            console.error('Error fetching trucks:', error);
            message.error('Failed to fetch available trucks');
        } finally {
            setLoading(false);
        }
    };

    const handleTruckSelect = (value: string) => {
        const truck = trucks.find(t => t.id === value);
        if (truck) {
            setSelectedTruck(truck);
        } else {
            setSelectedTruck(null);
        }
    };

    const handleSubmit = async (values: any) => {
        if (!driver?.id) {
            message.error('No driver selected');
            return;
        }

        try {
            await createAssignment({
                driver_id: driver.id,
                truck_id: values.truck_id,
                notes: values.notes || '',
                start_time: dayjs().toISOString()
            });
            
            form.resetFields();
            message.success('Truck assigned successfully');
            onComplete();
            onCancel();
        } catch (error: any) {
            if (error.message === 'DRIVER_HAS_ASSIGNMENT') {
                const selectedTruck = trucks.find(t => t.id === values.truck_id);
                setSelectedTruck(selectedTruck || null);
                setCurrentDriver(error.currentDriver);
                setPendingAssignment(values);
                setConfirmModalVisible(true);
            } else {
                console.error('Assignment error:', error);
                message.error('Failed to assign truck');
            }
        }
    };

    const handleConfirmReassign = async () => {
        if (!pendingAssignment || !driver?.id) {
            message.error('No assignment data');
            return;
        }
        
        try {
            await createAssignment({
                driver_id: driver.id,
                truck_id: pendingAssignment.truck_id,
                notes: pendingAssignment.notes || '',
                start_time: dayjs().toISOString(),
                force: true
            });
            
            message.success('Truck reassigned successfully');
            setConfirmModalVisible(false);
            onComplete();
            onCancel();
        } catch (error) {
            console.error('Reassignment error:', error);
            message.error('Failed to reassign truck');
        }
    };

    const handleCancelReassign = () => {
        setConfirmModalVisible(false);
        setPendingAssignment(null);
    };

    return (
        <App>
            <Modal
                title={<Space>
                    Assign Truck to Driver
                    <Tag color="blue">{driver?.name}</Tag>
                </Space>}
                open={visible}
                footer={null}
                onCancel={onCancel}
                confirmLoading={loading}
                width={720}
                centered
                destroyOnClose
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    preserve={false}
                >
                    <Form.Item
                        name="truck_id"
                        label="Truck"
                        rules={[{ required: true, message: 'Please select a truck' }]}
                    >
                        <Select
                            loading={loading}
                            placeholder="Select a truck"
                            optionFilterProp="children"
                            onChange={handleTruckSelect}
                            showSearch
                        >
                            {trucks.map(truck => (
                                <Select.Option key={truck.id} value={truck.id}>
                                    {truck.fleet_number}
                                    {truck.current_driver && (
                                        <Text type="secondary">
                                            {' '}(Current: {truck.current_driver.name})
                                        </Text>
                                    )}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="notes"
                        label="Notes"
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Assign
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Confirm Reassignment"
                open={confirmModalVisible}
                onOk={handleConfirmReassign}
                onCancel={handleCancelReassign}
                okText="Reassign"
                cancelText="Cancel"
            >
                <p>
                    This driver is currently assigned to another truck.
                    Do you want to reassign them to the selected truck?
                </p>
            </Modal>
        </App>
    );
};

export default AssignmentModal;
