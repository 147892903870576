import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Select, DatePicker, Button, Modal, Typography, Row, Col, FormInstance } from 'antd';
import { DriverFormData, DriverFormState } from '../types';
import { supabase } from '../../../lib/supabaseClient';
import dayjs from 'dayjs';
import { phoneValidationRules, formatPhoneNumber } from '../../../constants/validation';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { LICENSE_CLASSES } from '../constants';
import { validateDriverStatus } from '../utils/validation';
import './DriverForm.css';

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

const DATE_FORMAT = 'DD/MM/YYYY';

interface Location {
    id: string;
    name: string;
}

interface DriverFormProps {
    /** Whether the modal is visible */
    visible: boolean;
    /** Callback function when form is submitted */
    onSubmit: (values: DriverFormData) => void;
    /** Callback function when form is cancelled */
    onCancel: () => void;
    /** Mode of the form - create new driver or edit existing */
    mode: 'create' | 'edit';
    /** ID of the driver to edit (only required in edit mode) */
    driverId?: string;
    /** Form instance passed from parent */
    form: FormInstance<DriverFormState>;
}

/**
 * Form component for creating and editing driver information
 * Handles all driver-related fields including personal info, contact details,
 * license information, and emergency contacts
 */
export const DriverForm: React.FC<DriverFormProps> = ({
    visible,
    onSubmit,
    onCancel,
    mode,
    driverId,
    form
}) => {
    const [locations, setLocations] = useState<Location[]>([]);
    const [loading, setLoading] = useState(false);

    // Debounced phone number formatting
    const handlePhoneChange = useCallback((fieldName: string, value: string) => {
        const formatted = formatPhoneNumber(value);
        if (formatted !== value) {
            form.setFieldsValue({ [fieldName]: formatted });
        }
    }, [form]);

    // Fetch driver data when editing
    useEffect(() => {
        const fetchDriverData = async () => {
            if (!driverId || mode !== 'edit' || !visible) return;

            try {
                setLoading(true);
                const { data, error } = await supabase
                    .from('drivers')
                    .select('*')
                    .eq('id', driverId)
                    .single();

                if (error) {
                    console.error('Error fetching driver:', error.message);
                    return;
                }

                if (data) {
                    const formattedData = {
                        ...data,
                        dob: data.dob ? dayjs(data.dob) : undefined,
                        date_added: data.date_added ? dayjs(data.date_added) : dayjs(),
                        emergency_contact_name: data.emergency_contact_name || undefined,
                        emergency_contact_number: data.emergency_contact_number || undefined,
                        notes: data.notes || undefined,
                        avatar: data.avatar || undefined,
                        location_id: data.location_id || undefined
                    };
                    form.setFieldsValue(formattedData);
                }
            } catch (error) {
                console.error('Error fetching driver:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDriverData();
    }, [visible, driverId, mode, form]);

    // Fetch locations
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const { data, error } = await supabase
                    .from('locations')
                    .select('id, name')
                    .order('name');
                
                if (error) throw error;
                setLocations(data || []);
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };

        if (visible) {
            fetchLocations();
        }
    }, [visible]);

    const handleSubmit = async (values: DriverFormState) => {
        try {
            setLoading(true);
            const formattedValues: DriverFormData = {
                ...values,
                dob: values.dob ? dayjs(values.dob).format('YYYY-MM-DD') : undefined,
                date_added: values.date_added ? dayjs(values.date_added).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
                emergency_contact_name: values.emergency_contact_name || undefined,
                emergency_contact_number: values.emergency_contact_number || undefined,
                notes: values.notes || undefined,
                avatar: values.avatar || undefined,
            };

            await onSubmit(formattedValues);
        } catch (error) {
            console.error('Form submission failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const renderPersonalInfoSection = () => (
        <div className="form-section">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter the driver name' }]} 
                    >
                        <Input placeholder="Enter driver name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Fleet Number"
                        shouldUpdate={(prevValues, curValues) => prevValues.fleet_number !== curValues.fleet_number}
                    >
                        {({ getFieldValue }) => (
                            <Text className="fleet-number">
                                {getFieldValue('fleet_number') || '-'}
                            </Text>
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { type: 'email', message: 'Please enter a valid email' }
                        ]}
                    >
                        <Input placeholder="Enter email" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="phone_number"
                        label="Phone Number"
                        rules={phoneValidationRules('phone number')}
                    >
                        <Input 
                            placeholder="Enter phone number" 
                            onChange={(e) => handlePhoneChange('phone_number', e.target.value)}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="address" label="Address">
                <TextArea placeholder="Enter address" rows={3} />
            </Form.Item>
        </div>
    );

    const renderDatesSection = () => (
        <div className="form-section">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="dob" label="Date of Birth">
                        <DatePicker 
                            className="full-width"
                            format={DATE_FORMAT}
                            placeholder="Select date of birth"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="date_added" label="Date Added">
                        <DatePicker 
                            className="full-width"
                            format={DATE_FORMAT}
                            placeholder="Select date added"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const renderLicenseSection = () => (
        <div className="form-section">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="license_number" label="License Number">
                        <Input placeholder="Enter license number" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="license_type" label="License Type">
                        <Select placeholder="Select license type">
                            {LICENSE_CLASSES.map((licenseClass) => (
                                <Option key={licenseClass} value={licenseClass}>{licenseClass}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const renderEmergencyContactSection = () => (
        <div className="form-section">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="emergency_contact_name" label="Emergency Contact Name">
                        <Input placeholder="Enter emergency contact name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="emergency_contact_number" label="Emergency Contact Number">
                        <Input 
                            placeholder="Enter emergency contact number" 
                            onChange={(e) => handlePhoneChange('emergency_contact_number', e.target.value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const renderStatusSection = () => (
        <div className="form-section">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[{ required: true, message: 'Please select a status' }]}
                    >
                        <Select>
                            <Option value="Available">Available</Option>
                            <Option value="Annual Leave">Annual Leave</Option>
                            <Option value="Sick">Sick</Option>
                            <Option value="Wet Day">Wet Day</Option>
                            <Option value="Inactive">Inactive</Option>
                            <Option value="SubContracting">SubContracting</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="location_id"
                        label="Location"
                        rules={[{ required: true, message: 'Please select the location' }]}
                    >
                        <Select 
                            placeholder="Select location"
                            showSearch
                            optionFilterProp="label"
                            options={locations.map(location => ({
                                value: location.id,
                                label: location.name
                            }))}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const renderNotesSection = () => (
        <div className="form-section">
            <Form.Item name="notes" label="Notes">
                <TextArea placeholder="Enter any additional notes" rows={4} />
            </Form.Item>
        </div>
    );

    return (
        <Modal
            title={mode === 'create' ? 'Add New Driver' : 'Edit Driver'}
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={800}
            destroyOnClose
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                preserve={false}
                initialValues={{
                    status: 'Available',
                    date_added: dayjs()
                }}
            >
                <Text type="secondary" className="form-description">
                    {mode === 'edit' 
                        ? 'Update the driver details below. Fields marked with * are required.'
                        : 'Fill in the driver details below. Fields marked with * are required.'}
                </Text>
                
                {renderPersonalInfoSection()}
                {renderDatesSection()}
                {renderLicenseSection()}
                {renderEmergencyContactSection()}
                {renderStatusSection()}
                {renderNotesSection()}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {mode === 'create' ? 'Add Driver' : 'Save Changes'}
                    </Button>
                    <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
