import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Radio, Space, Alert, Checkbox, Row, Col } from 'antd';
import type { FormInstance } from 'antd/lib/form';

interface QuantityInputProps {
  form: FormInstance;
}

const TRUCK_CAPACITIES = {
  'Truck and Dog': 38,
  'Body Truck': 12,
  'Semi Tipper': 28,
  '8 Wheeler': 27,
  '10 Wheeler': 20,
} as const;

const TRUCK_TYPE_ABBREVIATIONS = {
  'Truck and Dog': 'TD',
  'Body Truck': 'BT',
  'Semi Tipper': 'ST',
  '8 Wheeler': '8W',
  '10 Wheeler': '10W',
} as const;

const LOADS_PER_TRUCK_PER_DAY = 5; // Number of loads when truck is turning all day

export const QuantityInput: React.FC<QuantityInputProps> = ({ form }) => {
  const [quantityMode, setQuantityMode] = useState<'specific' | 'trucks'>('specific');
  const [isTurning, setIsTurning] = useState(false);
  const truckType = Form.useWatch('truck_type', form);
  const numTrucks = Form.useWatch('num_trucks', form);
  const truckCapacity = truckType ? TRUCK_CAPACITIES[truckType as keyof typeof TRUCK_CAPACITIES] : 0;

  // Initialize form values
  useEffect(() => {
    form.setFieldsValue({
      quantity_display_mode: 'specific',
      quantity: form.getFieldValue('quantity') || 0
    });
  }, []); // Only run once on mount

  const handleModeChange = (mode: 'specific' | 'trucks') => {
    setQuantityMode(mode);
    form.setFieldsValue({
      quantity_display_mode: mode,
      // Reset the other mode's values
      ...(mode === 'specific' ? {
        num_trucks: undefined,
        trucks_display: undefined,
        is_turning: false
      } : {
        quantity: undefined
      })
    });
  };

  const handleQuantityChange = (value: number | null) => {
    form.setFieldsValue({
      quantity: value || 0,
      quantity_display_mode: 'specific'
    });
  };

  const handleTurningChange = (checked: boolean) => {
    setIsTurning(checked);
    form.setFieldsValue({
      is_turning: checked
    });
  };

  const calculateQuantity = (trucks: number, capacity: number, turning: boolean) => {
    return trucks * capacity * (turning ? LOADS_PER_TRUCK_PER_DAY : 1);
  };

  return (
    <div style={{ minHeight: quantityMode === 'trucks' ? '200px' : '120px' }}>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', alignItems: 'center', marginBottom: '16px' }}>
            <Radio.Group 
              value={quantityMode} 
              onChange={(e) => handleModeChange(e.target.value)}
              buttonStyle="solid"
              style={{ display: 'flex', flexWrap: 'nowrap' }}
            >
              <Radio.Button value="specific" style={{ flex: 1, textAlign: 'center', minWidth: '140px', whiteSpace: 'nowrap' }}>
                Specific Quantity
              </Radio.Button>
              <Radio.Button value="trucks" style={{ flex: 1, textAlign: 'center', minWidth: '140px', whiteSpace: 'nowrap' }}>
                Number of Trucks
              </Radio.Button>
            </Radio.Group>
          </div>
        </Col>
        
        {quantityMode === 'trucks' ? (
          <>
            <Col span={12}>
              <Form.Item
                name="num_trucks"
                label="Number of Trucks"
                rules={[
                  { required: true, message: 'Please enter number of trucks' },
                  { type: 'number', min: 1, message: 'Must be at least 1 truck' },
                  { type: 'number', max: 20, message: 'Cannot exceed 20 trucks' }
                ]}
              >
                <InputNumber
                  min={1}
                  max={20}
                  style={{ width: '100%' }}
                  placeholder="Enter number of trucks"
                  disabled={!truckType}
                  precision={0}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Turning All Day"
                tooltip="Enable if trucks will be turning all day (5 loads per truck)"
              >
                <Checkbox
                  checked={isTurning}
                  onChange={(e) => handleTurningChange(e.target.checked)}
                  disabled={!truckType || !numTrucks}
                >
                  Turning All Day
                </Checkbox>
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <Form.Item
              name="quantity"
              label="Specific Quantity"
              rules={[
                { required: true, message: 'Please enter quantity' },
                { type: 'number', min: 1, message: 'Quantity must be greater than 0' }
              ]}
              initialValue={0}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                precision={0}
                placeholder="Enter specific quantity"
                onChange={handleQuantityChange}
              />
            </Form.Item>
          </Col>
        )}

        {truckType && numTrucks && quantityMode === 'trucks' && (
          <Col span={24}>
            <Alert
              message={
                <span>
                  Estimated Quantity: <strong>{calculateQuantity(numTrucks, truckCapacity, isTurning)} tonnes</strong>
                  {isTurning && <span> ({numTrucks} trucks × {truckCapacity}t × {LOADS_PER_TRUCK_PER_DAY} loads)</span>}
                  {!isTurning && <span> ({numTrucks} trucks × {truckCapacity}t × 1 load)</span>}
                </span>
              }
              type="info"
            />
          </Col>
        )}

        {/* Hidden fields for form submission */}
        <Form.Item name="quantity_display_mode" hidden initialValue="specific">
          <Input />
        </Form.Item>
        <Form.Item name="trucks_display" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="is_turning" hidden initialValue={false}>
          <Input />
        </Form.Item>
      </Row>
    </div>
  );
};

export default QuantityInput;