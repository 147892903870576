  import React, { useState } from 'react';
import { Modal, Tabs, Button, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Customer, CustomerContact } from '../types';
import CustomerContacts from './CustomerContacts';
import { CustomerProducts } from './CustomerProducts';
import { CustomerView } from './CustomerView';

interface CustomerDetailsProps {
  customer: Customer | null;
  open: boolean;
  onClose: () => void;
  onEdit: (customer: Customer) => void;
  onAddContact: () => void;
  onEditContact: (contact: CustomerContact) => void;
  onDeleteContact: (contactId: string) => void;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  customer,
  open,
  onClose,
  onEdit,
  onAddContact,
  onEditContact,
  onDeleteContact,
}) => {
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  if (!customer) return null;

  return (
    <Modal
      title={customer.customer_name}
      open={open}
      onCancel={onClose}
      footer={
        <Button 
          type="primary" 
          icon={<EditOutlined />}
          onClick={() => onEdit(customer)}
        >
          Edit Customer
        </Button>
      }
      width={800}
      style={{ top: 20 }}
      bodyStyle={{ 
        padding: 0,
        maxHeight: 'calc(100vh - 200px)',
        overflow: 'auto'
      }}
    >
      <Tabs 
        activeKey={activeTab} 
        onChange={handleTabChange}
        items={[
          {
            key: '1',
            label: 'Details',
            children: <CustomerView customer={customer} />
          },
          {
            key: '2',
            label: 'Contacts',
            children: (
              <CustomerContacts
                contacts={customer.contacts ?? []}
                onAddContact={onAddContact}
                onEditContact={onEditContact}
                onDeleteContact={onDeleteContact}
              />
            )
          },
          {
            key: '3',
            label: 'Products',
            children: <CustomerProducts customerId={customer.id} />
          }
        ]}
        style={{ padding: '0 24px' }}
      />
    </Modal>
  );
};

export default CustomerDetails;
