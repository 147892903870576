import { Driver, Assignment, DriverAvailability, DriverStatus } from '../types';
import { LICENSE_EXPIRY_WARNING_DAYS, ASSIGNMENT_MAX_HOURS } from '../constants';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const getStatusColor = (status: DriverStatus): string => {
    switch (status) {
        case 'Available':
            return '#52c41a'; // green
        case 'Annual Leave':
            return '#faad14'; // yellow
        case 'Sick':
            return '#f5222d'; // red
        case 'Wet Day':
            return '#1890ff'; // blue
        case 'Inactive':
            return '#d9d9d9'; // grey
        case 'SubContracting':
            return '#722ed1'; // purple
        default:
            return '#d9d9d9';
    }
};

export const getStatusBgColor = (status: DriverStatus): string => {
    switch (status) {
        case 'Available':
            return '#f6ffed';
        case 'Annual Leave':
            return '#fff7e6';
        case 'Sick':
            return '#fff1f0';
        case 'Wet Day':
            return '#e6f7ff';
        case 'Inactive':
            return '#f5f5f5';
        case 'SubContracting':
            return '#f9f0ff';
        default:
            return '#f0f0f0';
    }
};

export const formatDateTime = (date: string | null | undefined): string => {
    if (!date) return '';
    return dayjs(date).format('DD/MM/YYYY HH:mm');
};

export const formatDate = (date: string | null | undefined): string => {
    if (!date) return '';
    return dayjs(date).format('DD/MM/YYYY');
};

export const isAssignmentActive = (assignment: Assignment): boolean => {
    return !assignment.end_time && assignment.status.toLowerCase() === 'active';
};

export const isDriverAvailable = (driver: Driver): boolean => {
    return driver.status === 'Available' && !driver.current_assignment;
};

export const isLicenseExpiringSoon = (expiryDate: string): boolean => {
    if (!expiryDate) return false;
    const today = new Date();
    const expiry = new Date(expiryDate);
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(today.getDate() + LICENSE_EXPIRY_WARNING_DAYS);
    return expiry <= thirtyDaysFromNow;
};

export const isLicenseExpired = (expiryDate: string): boolean => {
    return dayjs(expiryDate).isBefore(dayjs(), 'day');
};

export const getAssignmentDuration = (assignment: Assignment): string => {
    const start = dayjs(assignment.start_time);
    const end = assignment.end_time ? dayjs(assignment.end_time) : dayjs();
    const hours = end.diff(start, 'hour');
    
    if (hours < 24) {
        return `${hours} hours`;
    } else {
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        return `${days} days${remainingHours > 0 ? ` ${remainingHours} hours` : ''}`;
    }
};

export const hasExceededMaxHours = (assignment: Assignment): boolean => {
    if (!assignment.start_time || assignment.end_time) return false;
    
    const start = dayjs(assignment.start_time);
    const now = dayjs();
    const hours = now.diff(start, 'hour');
    
    return hours >= ASSIGNMENT_MAX_HOURS;
};

export const getUpcomingAvailability = (
    availabilities: DriverAvailability[]
): DriverAvailability | undefined => {
    const now = dayjs();
    return availabilities
        .filter(a => dayjs(a.start_date).isAfter(now))
        .sort((a, b) => dayjs(a.start_date).unix() - dayjs(b.start_date).unix())[0];
};

export const formatDriverIdentifier = (driver: Driver): string => {
    return driver.name;
};

export const formatLicenseClasses = (classes: string[]): string => {
    return classes.join(', ');
};

export const calculateBreakTime = (assignments: Assignment[]): number => {
    let totalWorkTime = 0;
    assignments.forEach(assignment => {
        if (assignment.end_time) {
            const duration = dayjs(assignment.end_time).diff(
                dayjs(assignment.start_time),
                'hour'
            );
            totalWorkTime += duration;
        }
    });
    return totalWorkTime;
};

export const checkAssignmentOverlap = (
    assignments: Assignment[],
    startTime: string,
    endTime?: string | null
): boolean => {
    const newStart = dayjs(startTime);
    const newEnd = endTime ? dayjs(endTime) : dayjs().add(12, 'hours');

    return assignments.some(assignment => {
        const existingStart = dayjs(assignment.start_time);
        const existingEnd = assignment.end_time 
            ? dayjs(assignment.end_time)
            : dayjs().add(12, 'hours');

        return (
            (newStart.isAfter(existingStart) && newStart.isBefore(existingEnd)) ||
            (newEnd.isAfter(existingStart) && newEnd.isBefore(existingEnd)) ||
            (existingStart.isAfter(newStart) && existingStart.isBefore(newEnd)) ||
            (existingEnd.isAfter(newStart) && existingEnd.isBefore(newEnd))
        );
    });
};
