import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './lib/supabaseClient';
import './index.css';
import Login from './components/Login';
import { Dashboard } from './components/dashboard';
import { DailyPlanner } from './components/DailyPlanner/DailyPlanner';
import Customers from './components/Customers';
import Products from './components/Products';
import { DriversPage } from './components/drivers/DriversPage';
import { TrucksPage } from './components/trucks/TrucksPage';
import Dockets from './components/dockets/components/Dockets';
import DocketLayout from './components/dockets/components/DocketLayout';
import Layout from './components/Layout';
import UserProfile from './components/UserProfile';
import { AuthProvider } from './contexts/AuthContext';
import { PrintingProvider } from './contexts/PrintingContext';
import ProtectedRoute from './components/ProtectedRoute';
import { App as AntApp } from 'antd';

const App: React.FC = () => {
  return (
    <SessionContextProvider supabaseClient={supabase}>
      <AuthProvider>
        <PrintingProvider>
          <AntApp>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/dockets" element={<ProtectedRoute><DocketLayout /></ProtectedRoute>}>
                <Route index element={<Dockets />} />
              </Route>
              <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="planner" element={<DailyPlanner />} />
                <Route path="customers" element={<Customers />} />
                <Route path="products/*" element={<Products />} />
                <Route path="drivers" element={<DriversPage />} />
                <Route path="trucks" element={<TrucksPage />} />
                <Route path="profile" element={<UserProfile />} />
              </Route>
              <Route path="/redirect" element={<ProtectedRoute>
                {() => {
                  const redirectPath = localStorage.getItem('redirectPath') || '/dashboard';
                  localStorage.removeItem('redirectPath');
                  return <Navigate to={redirectPath} replace />;
                }}
              </ProtectedRoute>} />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </AntApp>
        </PrintingProvider>
      </AuthProvider>
    </SessionContextProvider>
  );
};

export default App;
