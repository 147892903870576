import React from 'react';
import { Descriptions, Typography, Card, Space, Tag, Divider } from 'antd';
import { PhoneOutlined, MailOutlined, GlobalOutlined, BankOutlined, HomeOutlined } from '@ant-design/icons';
import { Customer } from '../types';

interface CustomerViewProps {
  customer: Customer;
}

export const CustomerView: React.FC<CustomerViewProps> = ({ customer }) => {
  return (
    <div style={{ padding: '0 24px 24px' }}>
      <Typography.Title level={5} style={{ marginBottom: 24 }}>Business Information</Typography.Title>
      <Descriptions column={1} size="small" labelStyle={{ fontWeight: 500 }}>
        <Descriptions.Item label={<Space><BankOutlined /> Business Name</Space>}>
          <Typography.Text strong>{customer.customer_name}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="ABN">
          {customer.abn}
        </Descriptions.Item>
        <Descriptions.Item label="Account Type">
          <Tag color="blue">{customer.account_type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={customer.status === 'Active' ? 'green' : 'red'}>
            {customer.status}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <Tag color="purple">{customer.type}</Tag>
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Typography.Title level={5} style={{ marginBottom: 24 }}>Contact Information</Typography.Title>
      <Descriptions column={1} size="small" labelStyle={{ fontWeight: 500 }}>
        <Descriptions.Item label={<Space><PhoneOutlined /> Phone</Space>}>
          {customer.primary_contact_phone}
        </Descriptions.Item>
        <Descriptions.Item label={<Space><MailOutlined /> Email</Space>}>
          <Typography.Link href={`mailto:${customer.email}`}>
            {customer.email}
          </Typography.Link>
        </Descriptions.Item>
        <Descriptions.Item label={<Space><GlobalOutlined /> Website</Space>}>
          {customer.website && (
            <Typography.Link href={customer.website} target="_blank">
              {customer.website}
            </Typography.Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Domain">
          {customer.domain}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Typography.Title level={5} style={{ marginBottom: 24 }}>Address</Typography.Title>
      <Descriptions column={1} size="small" labelStyle={{ fontWeight: 500 }}>
        <Descriptions.Item label={<Space><HomeOutlined /> Street Address</Space>}>
          {customer.address}
        </Descriptions.Item>
        <Descriptions.Item label="Suburb">
          {customer.suburb}
        </Descriptions.Item>
        <Descriptions.Item label="State">
          {customer.state}
        </Descriptions.Item>
        <Descriptions.Item label="Postcode">
          {customer.postcode}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
