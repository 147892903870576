import React, { createContext, useContext } from 'react';
import { DocketContextType } from '../types';
import { useDocketState } from '../hooks/useDocketState';
import { useWeightCalculation } from '../hooks/useWeightCalculation';
import { useDocketOperations } from '../hooks/useDocketOperations';

const DocketContext = createContext<DocketContextType | undefined>(undefined);

export const DocketProvider: React.FC<{ children: React.ReactNode; onDocketCreated?: () => void }> = ({ 
  children, 
  onDocketCreated 
}) => {
  const {
    loading,
    setLoading,
    searchValue,
    setSearchValue,
    jobData,
    setJobData,
    truckData,
    setTruckData,
    customerData,
    setCustomerData,
    productData,
    setProductData,
    grossWeight,
    setGrossWeight,
    netWeight,
    setNetWeight,
    jobDetails,
    setJobDetails,
    allocatedTrucks,
    setAllocatedTrucks,
    isSplitLoad,
    setIsSplitLoad,
    splitLoadPart,
    setSplitLoadPart,
    resetForm,
  } = useDocketState();

  const { calculateNetWeight } = useWeightCalculation();
  const { handleSearch: searchOperation, handleSaveAndPrint: saveAndPrintOperation } = useDocketOperations();

  const handleGrossWeightChange = (value: number | null) => {
    setGrossWeight(value);
    if (value === null) {
      setNetWeight(null);
    } else {
      const calculatedNet = calculateNetWeight(value, truckData, isSplitLoad, splitLoadPart);
      setNetWeight(calculatedNet);
    }
  };

  const handleSearch = async () => {
    const success = await searchOperation(
      searchValue,
      setLoading,
      setJobData,
      setTruckData,
      setCustomerData,
      setProductData,
      setAllocatedTrucks,
      setJobDetails
    );
    return success;
  };

  const handleSaveAndPrint = async () => {
    await saveAndPrintOperation(
      jobData,
      truckData,
      customerData,
      productData,
      grossWeight,
      netWeight,
      isSplitLoad,
      splitLoadPart,
      onDocketCreated,
      resetForm
    );
  };

  const value = {
    loading,
    jobData,
    truckData,
    customerData,
    productData,
    searchValue,
    grossWeight,
    netWeight,
    isSplitLoad,
    splitLoadPart,
    jobDetails,
    allocatedTrucks,
    setSearchValue,
    setGrossWeight: handleGrossWeightChange,
    handleSearch,
    handleSaveAndPrint,
    setSplitLoadPart,
    setIsSplitLoad,
    resetForm,
    onDocketCreated,
  };

  return <DocketContext.Provider value={value}>{children}</DocketContext.Provider>;
};

export const useDocket = () => {
  const context = useContext(DocketContext);
  if (context === undefined) {
    throw new Error('useDocket must be used within a DocketProvider');
  }
  return context;
};
