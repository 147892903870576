import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import { Truck, Job, TruckStatus, JobStatus } from '../types';
import { message } from 'antd';

export const useTrucks = (fetchJobs: () => Promise<void>, selectedLocationId: string | null | undefined) => {
  const [trucks, setTrucks] = useState<Truck[]>([]);

  const fetchTrucks = useCallback(async () => {
    try {
      if (!selectedLocationId) {
        setTrucks([]);
        return;
      }

      const { data, error } = await supabase
        .from('trucks')
        .select('*')
        .eq('location_id', selectedLocationId)
        .order('fleet_number', { ascending: true });

      if (error) throw error;
      setTrucks(data || []);
    } catch (error) {
      message.error('Error fetching trucks');
    }
  }, [selectedLocationId]);

  useEffect(() => {
    fetchTrucks();
  }, [fetchTrucks, selectedLocationId]);

  const getTruckColor = (type: string): string => {
    switch (type.toLowerCase()) {
      case 'concrete':
        return 'blue';
      case 'pump':
        return 'green';
      case 'tipper':
        return 'orange';
      default:
        return 'default';
    }
  };

  const handleTruckSelection = async (value: string[], record: Job) => {
    const selectedTrucks = trucks.filter((truck: Truck) => value.includes(truck.fleet_number));
    const maintenanceTrucks = selectedTrucks.filter((truck: Truck) => truck.status === 'Workshop');

    if (maintenanceTrucks.length > 0) {
      const maintenanceTruckNumbers = maintenanceTrucks.map((truck: Truck) => truck.fleet_number).join(', ');
      message.warning(`Trucks ${maintenanceTruckNumbers} are under maintenance`);
    }

    try {
      // Convert truck IDs to fleet numbers for saving
      const fleetNumbers = selectedTrucks.map(truck => truck.fleet_number);
      
      // Update job with trucks and update status to 'started' if first truck allocated
      const { error } = await supabase
        .from('jobs')
        .update({ 
          trucks_allocated: fleetNumbers,
          ...(fleetNumbers.length > 0 && record.status === 'entered' ? { status: 'started' } : {})
        })
        .eq('id', record.id);

      if (error) throw error;

      // Update truck statuses
      const promises = selectedTrucks.map(truck => 
        supabase
          .from('trucks')
          .update({ status: 'InUse' as TruckStatus })
          .eq('fleet_number', truck.fleet_number)
      );

      const unselectedTrucks = trucks.filter(truck => 
        !value.includes(truck.fleet_number) && truck.status === 'InUse'
      );

      promises.push(...unselectedTrucks.map(truck => 
        supabase
          .from('trucks')
          .update({ status: 'Available' as TruckStatus })
          .eq('fleet_number', truck.fleet_number)
      ));

      await Promise.all(promises);
      await fetchJobs();
      await fetchTrucks();
    } catch (error) {
      message.error('Failed to update truck allocation');
    }
  };

  const resetTruckStatuses = useCallback(async () => {
    try {
      const { error } = await supabase
        .from('trucks')
        .update({ status: 'Available' as TruckStatus })
        .eq('location_id', selectedLocationId)
        .neq('status', 'Workshop');

      if (error) throw error;
      await fetchTrucks();
    } catch (error) {
      message.error('Failed to reset truck statuses');
    }
  }, [selectedLocationId, fetchTrucks]);

  useEffect(() => {
    if (selectedLocationId) {
      resetTruckStatuses();
    }
  }, [selectedLocationId, resetTruckStatuses]);

  return {
    trucks,
    fetchTrucks,
    getTruckColor,
    handleTruckSelection,
    resetTruckStatuses
  };
};
