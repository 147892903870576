import React, { useEffect, useState, useRef } from 'react';
import { Form, Divider, Space, Modal, Row, Col, Button, Typography } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { TagOutlined, PlusOutlined, CalendarOutlined, ClockCircleOutlined, TruckOutlined, ShopOutlined } from '@ant-design/icons';
import { Job, Truck, Customer, Product } from '../../types';
import { ContactDetails } from './ContactDetails';
import { DeliveryDetails } from './DeliveryDetails';
import { TransportDetails } from './TransportDetails';
import { JobDetails } from './JobDetails';
import { ProductDetails } from './ProductDetails';
import dayjs from 'dayjs';

interface JobFormProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (values: Job) => void;
  editingJob: Job | null;
  customers: Customer[];
  products: Product[];
  trucks: Truck[];
  nextJobNumber: number;
  initialTruckType?: string | null;
}

const JobForm: React.FC<JobFormProps> = ({
  visible,
  onCancel,
  onSave,
  editingJob,
  customers,
  products,
  trucks,
  nextJobNumber,
  initialTruckType
}) => {
  return (
    <Modal
      title={
        <Typography.Title level={4} style={{ margin: 0 }}>
          {editingJob ? 'Edit Job' : 'Create New Job'}
        </Typography.Title>
      }
      open={visible}
      onCancel={onCancel}
      width={800}
      footer={null}
      destroyOnClose
    >
      {visible && (
        <ModalContent
          onCancel={onCancel}
          onSave={onSave}
          editingJob={editingJob}
          customers={customers}
          products={products}
          trucks={trucks}
          nextJobNumber={nextJobNumber}
          initialTruckType={initialTruckType}
        />
      )}
    </Modal>
  );
};

const ModalContent: React.FC<Omit<JobFormProps, 'visible'>> = ({
  onCancel,
  onSave,
  editingJob,
  customers,
  products,
  trucks,
  nextJobNumber,
  initialTruckType
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingJob) {
      form.setFieldsValue({
        ...editingJob,
        delivery_date: editingJob.delivery_date ? dayjs(editingJob.delivery_date) : null,
        delivery_time: editingJob.delivery_time || null,
        product: editingJob.product ? editingJob.product.toUpperCase() : null,
        quantity: editingJob.quantity ? parseFloat(editingJob.quantity.toString()) : null,
        quantity_display_mode: editingJob.quantity_display_mode || 'specific',
        trucks_display: editingJob.trucks_display || null,
        num_trucks: editingJob.num_trucks || null,
        is_turning: editingJob.is_turning || false
      });
    } else {
      form.setFieldsValue({
        job_number: nextJobNumber,
        status: 'entered',
        trucks_allocated: [],
        delivery_date: dayjs(),
        truck_type: initialTruckType || undefined
      });
    }
  }, [editingJob, form, nextJobNumber, initialTruckType]);

  useEffect(() => {
    const loadGooglePlaces = async () => {
      try {
        if (!window.google?.maps?.places) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
          script.async = true;
          
          await new Promise((resolve, reject) => {
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
          });
        }

        const input = document.getElementById('delivery_address');
        if (!input) return;

        const autocomplete = new google.maps.places.Autocomplete(input as HTMLInputElement, {
          types: ['address'],
          componentRestrictions: { country: 'AU' }
        });

        const placeChangedListener = () => {
          const place = autocomplete.getPlace();
          if (!place.address_components) return;

          let streetNumber = '';
          let streetName = '';
          let suburb = '';
          let state = '';
          let postcode = '';

          place.address_components.forEach(component => {
            if (component.types.includes('street_number')) {
              streetNumber = component.long_name;
            } else if (component.types.includes('route')) {
              streetName = component.long_name;
            } else if (component.types.includes('locality')) {
              suburb = component.long_name;
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.short_name;
            } else if (component.types.includes('postal_code')) {
              postcode = component.long_name;
            }
          });

          form.setFieldsValue({
            delivery_address: `${streetNumber} ${streetName}`,
            suburb,
            state,
            postcode
          });
        };

        autocomplete.addListener('place_changed', placeChangedListener);

        return () => {
          google.maps.event.clearInstanceListeners(input);
        };
      } catch (error) {
        console.error('Error in loadGooglePlaces:', error);
      }
    };

    const timer = setTimeout(loadGooglePlaces, 100);
    return () => clearTimeout(timer);
  }, [form]);

  const handleSubmit = async (values: any) => {
    try {
      const formattedValues = {
        ...values,
        quantity: values.quantity_display_mode === 'specific' ? values.quantity?.toString() : null,
        num_trucks: values.quantity_display_mode === 'trucks' ? values.num_trucks?.toString() : null,
        trucks_display: values.quantity_display_mode === 'trucks' ? values.trucks_display : null,
      };
      await onSave(formattedValues);
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      className="job-form"
      preserve={false}
    >
      <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', padding: '24px' }}>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <JobDetails
              form={form}
              customers={customers}
              nextJobNumber={nextJobNumber}
              editingJob={editingJob}
              onSubmit={onSave}
            />
          </Col>
        </Row>

        <Divider style={{ margin: '24px 0' }} />

        <Row gutter={[16, 24]}>
          <Col span={24}>
            <ProductDetails
              form={form}
              products={products}
            />
          </Col>
        </Row>

        <Divider style={{ margin: '24px 0' }} />

        <Row gutter={[16, 24]}>
          <Col span={24}>
            <ContactDetails 
              form={form} 
              customers={customers}
            />
          </Col>
        </Row>

        <Divider style={{ margin: '24px 0' }} />

        <Row gutter={[16, 24]}>
          <Col span={24}>
            <DeliveryDetails form={form} />
          </Col>
        </Row>

        <Divider style={{ margin: '24px 0' }} />

        <Row gutter={[16, 24]}>
          <Col span={24}>
            <TransportDetails
              form={form}
              trucks={trucks}
              initialTruckType={initialTruckType}
              editingJob={editingJob}
            />
          </Col>
        </Row>
      </div>
      <Divider style={{ margin: '24px 0 16px' }} />
      <div style={{ textAlign: 'right', padding: '0 24px 24px' }}>
        <Space>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            {editingJob ? 'Update Job' : 'Create Job'}
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default JobForm;