import React from 'react';
import { Card, Descriptions, Tag, Divider } from 'antd';
import { Driver } from '../types';
import { getStatusColor } from '../utils/driverUtils';
import './DriverDetails.css';

/**
 * Props for the DriverDetails component
 */
interface DriverDetailsProps {
    /** Driver object containing all driver information */
    driver: Driver;
    /** Optional callback when a truck is clicked */
    onTruckClick?: (truckId: string) => void;
    /** Optional callback when driver information is updated */
    onDriverUpdate?: () => void;
}

/**
 * Component for displaying detailed driver information
 * Shows personal information, status, assignment details, and notes
 * 
 * @param driver - Driver object containing all driver information
 * @param onTruckClick - Optional callback when a truck is clicked
 * @param onDriverUpdate - Optional callback when driver information is updated
 */
export const DriverDetails: React.FC<DriverDetailsProps> = ({ 
    driver, 
    onTruckClick, 
    onDriverUpdate 
}) => {

    /**
     * Formats a status string to proper case
     */
    const formatStatus = (status: string) => {
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    };

    /**
     * Gets the truck description for display
     * Returns 'Not assigned' if no truck is assigned
     */
    const getTruckDescription = (driver: Driver) => {
        if (!driver.current_assignment?.truck) {
            return 'Not assigned';
        }
        return driver.current_assignment.truck.fleet_number;
    };

    return (
        <div className="driver-details">
            <Card className="driver-details-card">
                <Descriptions column={2}>
                    <Descriptions.Item label="Name" className="driver-name">
                        {driver.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status" className="driver-status">
                        <Tag color={getStatusColor(driver.status as any)}>
                            {formatStatus(driver.status)}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Location" className="driver-location">
                        {driver.location?.name || driver.location_id || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email" className="driver-email">
                        {driver.email || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone" className="driver-phone">
                        {driver.phone_number || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="License Number" className="driver-license">
                        {driver.license_number || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="License Type" className="driver-license-type">
                        {driver.license_type || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item 
                        label="Current Truck" 
                        className="driver-truck"
                    >
                        <span 
                            onClick={() => driver.current_assignment?.truck && onTruckClick?.(driver.current_assignment.truck.id)}
                            className="truck-link"
                        >
                            {getTruckDescription(driver)}
                        </span>
                    </Descriptions.Item>
                </Descriptions>

                {driver.notes && (
                    <>
                        <Divider />
                        <Descriptions>
                            <Descriptions.Item label="Notes" className="driver-notes">
                                {driver.notes}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                )}
            </Card>
        </div>
    );
};
