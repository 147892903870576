import { useState } from 'react';
import { supabase } from '../../../lib/supabaseClient';

export type CreateAssignmentInput = {
    driver_id: string;
    truck_id: string;
    notes?: string;
    start_time?: string;
    force?: boolean;
};

export const useAssignments = () => {
    const [loading, setLoading] = useState(false);

    const createAssignment = async (input: CreateAssignmentInput) => {
        setLoading(true);
        try {
            // Get existing assignments for the driver
            const { data: existingDriverAssignment, error: driverError } = await supabase
                .from('assignments')
                .select(`
                    id,
                    driver_id,
                    truck_id,
                    truck:trucks(
                        fleet_number
                    )
                `)
                .eq('driver_id', input.driver_id)
                .is('end_time', null)
                .maybeSingle();

            if (driverError) throw driverError;

            // Get existing assignment for the truck
            const { data: existingTruckAssignment, error: truckError } = await supabase
                .from('assignments')
                .select('id, truck_id')
                .eq('truck_id', input.truck_id)
                .is('end_time', null)
                .maybeSingle();

            if (truckError) throw truckError;

            // If driver already has an active assignment and we're not forcing
            if (existingDriverAssignment && !input.force) {
                const error = new Error('DRIVER_HAS_ASSIGNMENT') as any;
                error.currentTruck = existingDriverAssignment.truck;
                // This is expected behavior - we'll show a confirmation modal
                console.debug('Driver has existing assignment:', { 
                    existingAssignment: existingDriverAssignment,
                    currentTruck: existingDriverAssignment.truck 
                });
                throw error;
            }

            // If forcing reassignment, end any existing assignments
            if (input.force) {
                // End driver's existing assignment if any
                if (existingDriverAssignment) {
                    const { error: endDriverError } = await supabase
                        .from('assignments')
                        .update({ end_time: input.start_time })
                        .eq('id', existingDriverAssignment.id);

                    if (endDriverError) throw endDriverError;
                }

                // End truck's existing assignment if any
                if (existingTruckAssignment) {
                    const { error: endTruckError } = await supabase
                        .from('assignments')
                        .update({ end_time: input.start_time })
                        .eq('id', existingTruckAssignment.id);

                    if (endTruckError) throw endTruckError;
                }
            }

            // Create the new assignment
            const { data, error } = await supabase
                .from('assignments')
                .insert([
                    {
                        driver_id: input.driver_id,
                        truck_id: input.truck_id,
                        notes: input.notes,
                        start_time: input.start_time
                    }
                ])
                .select(`
                    id,
                    driver_id,
                    truck_id,
                    status,
                    start_time,
                    end_time,
                    notes,
                    created_at,
                    updated_at,
                    drivers:drivers!assignments_driver_id_fkey (
                        id,
                        name,
                        status
                    ),
                    truck:trucks!assignments_truck_id_fkey (
                        id,
                        fleet_number
                    )
                `)
                .single();

            if (error) throw error;
            return data;
        } catch (error) {
            console.error('Assignment creation failed:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const reassignDriver = async (input: CreateAssignmentInput) => {
        setLoading(true);
        try {
            const timestamp = input.start_time || new Date().toISOString();

            // First, check and end any active assignments for this driver
            const { data: existingAssignments, error: checkError } = await supabase
                .from('assignments')
                .select('id')
                .eq('status', 'active')
                .is('end_time', null)
                .eq('driver_id', input.driver_id);

            if (checkError) throw checkError;

            if (existingAssignments && existingAssignments.length > 0) {
                // End the current assignment
                const { error: updateError } = await supabase
                    .from('assignments')
                    .update({
                        status: 'completed',
                        end_time: timestamp,
                        updated_at: timestamp
                    })
                    .eq('id', existingAssignments[0].id);

                if (updateError) throw updateError;
            }

            // Check if the new truck is available
            const { data: existingTruckAssignments, error: truckCheckError } = await supabase
                .from('assignments')
                .select('id')
                .eq('status', 'active')
                .is('end_time', null)
                .eq('truck_id', input.truck_id);

            if (truckCheckError) throw truckCheckError;

            if (existingTruckAssignments && existingTruckAssignments.length > 0) {
                throw new Error('This truck is currently assigned to another driver');
            }

            // Update truck status to 'InUse'
            const { error: updateTruckError } = await supabase
                .from('trucks')
                .update({ status: 'InUse' })
                .eq('id', input.truck_id);

            if (updateTruckError) {
                throw new Error(`Failed to update truck status: ${updateTruckError.message}`);
            }

            // Update driver status to 'Unavailable'
            const { error: updateDriverError } = await supabase
                .from('drivers')
                .update({ status: 'Unavailable' })
                .eq('id', input.driver_id);

            if (updateDriverError) {
                // If driver update fails, revert truck status
                await supabase
                    .from('trucks')
                    .update({ status: 'Available' })
                    .eq('id', input.truck_id);
                throw new Error(`Failed to update driver status: ${updateDriverError.message}`);
            }

            // Create the new assignment
            const { data: newAssignment, error: insertError } = await supabase
                .from('assignments')
                .insert({
                    driver_id: input.driver_id,
                    truck_id: input.truck_id,
                    status: 'active',
                    start_time: timestamp,
                    notes: input.notes || null,
                    created_at: timestamp,
                    updated_at: timestamp
                })
                .select(`
                    id,
                    driver_id,
                    truck_id,
                    status,
                    start_time,
                    end_time,
                    notes,
                    created_at,
                    updated_at,
                    drivers:drivers!assignments_driver_id_fkey (
                        id,
                        name,
                        status
                    ),
                    truck:trucks!assignments_truck_id_fkey (
                        id,
                        fleet_number,
                        status
                    )
                `)
                .single();

            if (insertError) {
                // If assignment creation fails, revert truck status
                await supabase
                    .from('trucks')
                    .update({ status: 'Available' })
                    .eq('id', input.truck_id);
                throw insertError;
            }

            return newAssignment;
        } catch (error) {
            console.error('Driver reassignment failed:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const getAssignmentHistory = async (truckId: string) => {
        try {
            const { data, error } = await supabase
                .from('assignments')
                .select(`
                    *,
                    drivers!assignments_driver_id_fkey (
                        id,
                        name,
                        status
                    ),
                    trucks!assignments_truck_id_fkey (
                        id,
                        fleet_number,
                        status
                    )
                `)
                .eq('truck_id', truckId)
                .order('start_time', { ascending: false });

            if (error) throw error;
            return data;
        } catch (error) {
            console.error('Error fetching assignment history:', error);
            throw error;
        }
    };

    const getDriverAssignmentHistory = async (driverId: string) => {
        try {
            const { data, error } = await supabase
                .from('assignments')
                .select(`
                    *,
                    drivers!assignments_driver_id_fkey (
                        id,
                        name,
                        status
                    ),
                    trucks!assignments_truck_id_fkey (
                        id,
                        fleet_number,
                        status
                    )
                `)
                .eq('driver_id', driverId)
                .order('start_time', { ascending: false });

            if (error) throw error;
            return data;
        } catch (error) {
            console.error('Error fetching driver assignment history:', error);
            throw error;
        }
    };

    return {
        loading,
        createAssignment,
        reassignDriver,
        getAssignmentHistory,
        getDriverAssignmentHistory
    };
};
