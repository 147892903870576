import React, { useState, useMemo } from 'react';
import { Button, Space, Row, Col, Card, Statistic, Input, Select, Spin, App, Form } from 'antd';
import {  
    CalendarOutlined, 
    MedicineBoxOutlined, 
    CloudOutlined,
    PlusOutlined,
    SearchOutlined,
    SortAscendingOutlined,
    StopOutlined,
    TeamOutlined,
    SwapOutlined
} from '@ant-design/icons';
import { DriverTable } from './components/DriverTable';
import { DriverForm } from './components/DriverForm';
import AssignmentModal from './components/AssignmentModal';
import { DriverDetailsModal } from './components/DriverDetailsModal';
import { DeleteDriverModal } from './components/DeleteDriverModal';
import { useDrivers } from './hooks/useDrivers';
import type { Driver, DriverStatus, DriverFormState, DriverFormData } from './types';
import dayjs from 'dayjs';
import './DriversPage.css';

const { Option } = Select;

interface DriverStatistic {
    title: string;
    status: DriverStatus;
    icon: React.ReactNode;
    color: string;
}

// Constants defining the visual representation of driver statuses
const DRIVER_STATISTICS: DriverStatistic[] = [
    { title: 'Available', status: 'Available', icon: <TeamOutlined />, color: '#52c41a' },
    { title: 'Annual Leave', status: 'Annual Leave', icon: <CalendarOutlined />, color: '#faad14' },
    { title: 'Sick', status: 'Sick', icon: <MedicineBoxOutlined />, color: '#f5222d' },
    { title: 'Wet Day', status: 'Wet Day', icon: <CloudOutlined />, color: '#1890ff' },
    { title: 'Inactive', status: 'Inactive', icon: <StopOutlined />, color: '#d9d9d9' },
    { title: 'SubContracting', status: 'SubContracting', icon: <SwapOutlined />, color: '#722ed1' }
];

// Sort options for the driver table
const SORT_OPTIONS = [
    { value: 'name', label: 'Name' },
    { value: 'status', label: 'Status' },
    { value: 'location_name', label: 'Location' }
];

/**
 * DriversPage Component
 * 
 * Main page for managing drivers. Features include:
 * - Dashboard with driver statistics by status
 * - Comprehensive driver table with sorting and filtering
 * - CRUD operations for driver management
 * - Driver assignment functionality
 * - Detailed driver information view
 * 
 * @returns {JSX.Element} The rendered DriversPage component
 */
export const DriversPage: React.FC = () => {
    const { message } = App.useApp();
    const { drivers, loading, refreshDrivers, addDriver, updateDriver, deleteDriver } = useDrivers();
    const [showDriverForm, setShowDriverForm] = useState(false);
    const [showAssignmentModal, setShowAssignmentModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
    const [searchText, setSearchText] = useState('');
    const [sortField, setSortField] = useState<keyof Driver>('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [selectedStatus, setSelectedStatus] = useState<DriverStatus | null>(null);
    const [driverForm] = Form.useForm<DriverFormState>();

    const handleOpenForm = (mode: 'create' | 'edit', driver?: Driver) => {
        if (mode === 'create') {
            driverForm.resetFields();
            driverForm.setFieldsValue({
                status: 'Available',
                date_added: dayjs()
            });
        }
        setSelectedDriver(driver || null);
        setShowDriverForm(true);
    };

    const handleCloseForm = () => {
        driverForm.resetFields();
        setShowDriverForm(false);
        setSelectedDriver(null);
    };

    /**
     * Handles the search functionality for drivers
     */
    const handleSearch = (value: string): void => {
        setSearchText(value);
    };

    /**
     * Handles the sorting of drivers by different fields
     * @param value - The field to sort by
     */
    const handleSort = (value: keyof Driver): void => {
        try {
            if (value === sortField) {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            } else {
                setSortField(value);
                setSortOrder('asc');
                message.success(`Sorted by ${value}`);
            }
        } catch (error) {
            message.error('Failed to sort drivers');
            console.error('Sort error:', error);
        }
    };

    /**
     * Opens the driver form in create mode
     */
    const handleAddDriver = (): void => {
        handleOpenForm('create');
    };

    /**
     * Opens the driver form in edit mode for a specific driver
     * @param driver - The driver to edit
     */
    const handleEditDriver = (driver: Driver): void => {
        handleOpenForm('edit', driver);
    };

    /**
     * Opens the driver details modal
     * @param driver - The driver to view details for
     */
    const handleViewDriver = (driver: Driver): void => {
        setSelectedDriver(driver);
        setShowDetailsModal(true);
    };

    /**
     * Opens the driver assignment modal
     */
    const handleAssignDriver = (driver: Driver): void => {
        setSelectedDriver(driver);
        setShowAssignmentModal(true);
    };

    /**
     * Opens the delete confirmation modal
     */
    const handleDeleteDriver = (driver: Driver): void => {
        setSelectedDriver(driver);
        setShowDeleteModal(true);
    };

    /**
     * Handles the driver archiving process with error handling
     */
    const handleConfirmDelete = async (): Promise<void> => {
        if (!selectedDriver) return;
        try {
            await deleteDriver(selectedDriver.id);
            message.success(`Driver ${selectedDriver.name} has been archived`);
            setShowDeleteModal(false);
            setSelectedDriver(null);
            refreshDrivers();
        } catch (error) {
            console.error('Error archiving driver:', error);
            message.error('Failed to archive driver');
        }
    };

    /**
     * Handles the driver form submission for both create and edit operations
     */
    const handleDriverFormSubmit = async (values: any): Promise<boolean> => {
        try {
            if (!selectedDriver) {
                await addDriver({
                    ...values,
                    status: values.status || 'Available',
                    date_added: values.date_added || dayjs().format('YYYY-MM-DD')
                });
                message.success('Driver added successfully');
            } else {
                // Just update the status - nothing else
                await updateDriver(selectedDriver.id, { status: values.status });
                message.success('Driver updated successfully');
            }
            handleCloseForm();
            await refreshDrivers();
            return true;
        } catch (error: any) {
            console.error('Driver form submission error:', error);
            message.error(error.message || 'Failed to save driver');
            return false;
        }
    };

    // Memoized filtered and sorted drivers list
    const filteredAndSortedDrivers = useMemo(() => {
        let filtered = [...(drivers || [])];

        // Apply status filter
        if (selectedStatus) {
            filtered = filtered.filter(driver => driver.status === selectedStatus);
        }

        // Apply search filter
        if (searchText) {
            const searchLower = searchText.toLowerCase();
            filtered = filtered.filter(driver =>
                (driver.name?.toLowerCase() || '').includes(searchLower) ||
                (driver.email?.toLowerCase() || '').includes(searchLower) ||
                (driver.phone_number?.toLowerCase() || '').includes(searchLower)
            );
        }

        // Apply sorting
        filtered.sort((a, b) => {
            const aValue = (a[sortField] || '').toString().toLowerCase();
            const bValue = (b[sortField] || '').toString().toLowerCase();
            return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });

        return filtered;
    }, [drivers, searchText, sortField, sortOrder, selectedStatus]);

    // Calculate driver statistics
    const driverStats = DRIVER_STATISTICS.map(stat => ({
        ...stat,
        value: drivers?.filter((d: Driver) => d.status === stat.status).length || 0
    }));

    return (
        <div className="drivers-container">
            <Spin spinning={loading} tip="Loading drivers..." className="drivers-spinner">
                <Space direction="vertical" className="drivers-space" size="middle">
                    {/* Statistics Cards */}
                    <div className="section-container">
                        <p className="status-cards-hint">Click cards to filter by status</p>
                        <Row gutter={[16, 16]} className="drivers-stats">
                            {driverStats.map(stat => (
                                <Col xs={24} sm={12} lg={8} xl={4} key={stat.status}>
                                    <Card 
                                        hoverable 
                                        className={`stat-card ${selectedStatus === stat.status ? 'selected' : ''}`}
                                        onClick={() => setSelectedStatus(selectedStatus === stat.status ? null : stat.status)}
                                    >
                                        <Statistic
                                            title={stat.title}
                                            value={stat.value}
                                            prefix={stat.icon}
                                            valueStyle={{ color: stat.color }}
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>

                    {/* Filter Controls */}
                    <div className="section-container">
                        <div className="filter-controls">
                            <Row gutter={[16, 16]} align="middle" className="drivers-actions">
                                <Col xs={24} sm={24} md={8}>
                                    <Input
                                        placeholder="Search by name, email, or phone..."
                                        prefix={<SearchOutlined />}
                                        onChange={e => handleSearch(e.target.value)}
                                        className="search-input"
                                        allowClear
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <Select
                                        className="sort-select"
                                        onChange={handleSort}
                                        value={sortField}
                                        style={{ width: '100%' }}
                                    >
                                        {SORT_OPTIONS.map(option => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col xs={24} sm={24} md={8} style={{ textAlign: 'right' }}>
                                    <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={handleAddDriver}
                                            className="add-button"
                                        >
                                            Add Driver
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    {/* Driver Table */}
                    <div className="section-container">
                        <DriverTable
                            drivers={filteredAndSortedDrivers}
                            loading={loading}
                            onEdit={handleEditDriver}
                            onDelete={handleDeleteDriver}
                            onAssign={handleAssignDriver}
                            onViewDetails={handleViewDriver}
                        />
                    </div>

                    {/* Modals */}
                    <Form.Provider>
                        <DriverForm
                            visible={showDriverForm}
                            onSubmit={handleDriverFormSubmit}
                            onCancel={handleCloseForm}
                            mode={selectedDriver ? 'edit' : 'create'}
                            driverId={selectedDriver?.id}
                            form={driverForm}
                        />
                    </Form.Provider>

                    <AssignmentModal
                        visible={showAssignmentModal}
                        onCancel={() => setShowAssignmentModal(false)}
                        onComplete={() => {
                            refreshDrivers();
                            setShowAssignmentModal(false);
                        }}
                        driver={selectedDriver}
                    />

                    <DriverDetailsModal
                        visible={showDetailsModal}
                        driverId={selectedDriver?.id || null}
                        onClose={() => {
                            setShowDetailsModal(false);
                            setSelectedDriver(null);
                        }}
                        onEdit={handleEditDriver}
                    />

                    <DeleteDriverModal
                        visible={showDeleteModal}
                        driver={selectedDriver}
                        onConfirm={handleConfirmDelete}
                        onCancel={() => {
                            setShowDeleteModal(false);
                            setSelectedDriver(null);
                        }}
                    />
                </Space>
            </Spin>
        </div>
    );
};
