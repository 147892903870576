import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, Typography, Spin, Select, Checkbox, App } from 'antd';
import { supabase } from '../lib/supabaseClient';
import { useAuth } from '../contexts/AuthContext';

const { Title } = Typography;
const { Option } = Select;

const positionOptions = [
  'Weighbridge',
  'Transport',
  'Sales',
  'Workshop',
  'Administration',
  'Lab',
  'Driver'
];

const UserProfile: React.FC = () => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [locations, setLocations] = useState<{ id: string; name: string }[]>([]);

  const fetchLocations = useCallback(async () => {
    try {
      const { data, error } = await supabase.from('locations').select('id, name');
      if (error) throw error;
      setLocations(data || []);
    } catch (error) {
      console.error('Error fetching locations:', error);
      notification.error({
        message: 'Fetch Failed',
        description: 'Failed to fetch locations',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const fetchUserProfile = useCallback(async (userId: string) => {
    setLoading(true);
    try {
      console.log('Fetching profile for user ID:', userId);

      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('*, locations:location_id(id, name)')
        .eq('id', userId)
        .single();

      if (profileError) throw profileError;

      console.log('Fetched profile data:', profileData);

      if (profileData) {
        form.setFieldsValue({
          display_name: profileData.username,
          position: profileData.position,
          email: profileData.email,
          is_admin: profileData.role === 'admin',
          location_id: profileData.location_id,
        });
        setIsAdmin(profileData.role === 'admin'); // Add this line
        console.log('Form values set:', {
          display_name: profileData.username,
          position: profileData.position,
          email: profileData.email,
          is_admin: profileData.role === 'admin',
          location_id: profileData.location_id,
        });
      }

      // Fetch all users regardless of the current user's role
      const { data: allUsers, error: usersError } = await supabase
        .from('profiles')
        .select('id, email, username');
      if (usersError) throw usersError;
      setUsers(allUsers || []);
      console.log('Fetched all users:', allUsers);

    } catch (error) {
      console.error('Error fetching user profile:', error);
      notification.error({
        message: 'Fetch Failed',
        description: 'Failed to fetch user profile',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } finally {
      setLoading(false);
    }
  }, [form]);

  useEffect(() => {
    if (user) {
      fetchUserProfile(user.id);
    }
  }, [user, fetchUserProfile]);

  const handleUpdateProfile = async (values: any) => {
    setLoading(true);
    try {
      if (!selectedUserId) throw new Error('No user selected');

      console.log('Updating profile for user ID:', selectedUserId);
      console.log('Update values:', values);

      const updateData: any = {
        username: values.display_name,
        email: values.email,
        location_id: values.location_id,
      };

      if (isAdmin) {
        updateData.position = values.position;
        updateData.role = values.is_admin ? 'admin' : 'user';
      }

      const { error: profileUpdateError } = await supabase
        .from('profiles')
        .update(updateData)
        .eq('id', selectedUserId);

      if (profileUpdateError) throw profileUpdateError;

      notification.success({
        message: 'Profile Updated',
        description: 'Profile updated successfully',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      fetchUserProfile(selectedUserId);
    } catch (error) {
      console.error('Error updating profile:', error);
      notification.error({
        message: 'Update Failed',
        description: `Failed to update profile: ${(error as Error).message}`,
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePassword = async (values: { currentPassword: string; newPassword: string }) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.updateUser({ 
        password: values.newPassword 
      });

      if (error) throw error;

      notification.success({
        message: 'Password Updated',
        description: 'Password updated successfully',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      form.resetFields(['currentPassword', 'newPassword', 'confirmPassword']);
    } catch (error) {
      console.error('Error updating password:', error);
      notification.error({
        message: 'Update Failed',
        description: `Failed to update password: ${(error as Error).message}`,
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (userId: string) => {
    console.log('User selection changed to:', userId);
    setSelectedUserId(userId);
    fetchUserProfile(userId);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <Title level={2}>User Profile</Title>
      
      {isAdmin && (
        <Form.Item label="Select User">
          <Select value={selectedUserId} onChange={handleUserChange}>
            {users.map(user => (
              <Option key={user.id} value={user.id}>{user.email} ({user.username})</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form form={form} onFinish={handleUpdateProfile} layout="vertical">
        <Form.Item name="display_name" label="Display Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="position" label="Position">
          {isAdmin ? (
            <Select>
              {positionOptions.map(option => (
                <Option key={option} value={option}>{option}</Option>
              ))}
            </Select>
          ) : (
            <Input disabled />
          )}
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
          <Input />
        </Form.Item>

<Form.Item name="location_id" label="Location" rules={[{ required: true, message: 'Please select a location' }]}>
          <Select placeholder="Select a location">
            {locations.map(location => (
              <Option key={location.id} value={location.id}>{location.name}</Option>
            ))}
          </Select>
        </Form.Item>

        {isAdmin && (
          <Form.Item name="is_admin" valuePropName="checked">
            <Checkbox>Is Admin</Checkbox>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form.Item>
      </Form>

      <Title level={3} style={{ marginTop: 24 }}>Change Password</Title>
      <Form onFinish={handleUpdatePassword} layout="vertical">
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[{ required: true, message: 'Please input your current password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: 'Please input your new password!' },
            { min: 6, message: 'Password must be at least 6 characters long' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserProfile;