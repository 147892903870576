import React from 'react';
import { Input, Typography, Space, Tag, Alert } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Truck } from '../types';
import { Modal } from '../../../components/common/Modal';

const { Text } = Typography;

interface DeleteTruckModalProps {
    visible: boolean;
    truck: Truck | null;
    onConfirm: () => void;
    onCancel: () => void;
}

export const DeleteTruckModal: React.FC<DeleteTruckModalProps> = ({
    visible,
    truck,
    onConfirm,
    onCancel,
}) => {
    const [confirmInput, setConfirmInput] = React.useState('');
    const [confirmDisabled, setConfirmDisabled] = React.useState(true);

    React.useEffect(() => {
        if (!visible) {
            setConfirmInput('');
            setConfirmDisabled(true);
        }
    }, [visible]);

    const handleFleetNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setConfirmInput(inputValue);
        setConfirmDisabled(inputValue !== truck?.fleet_number);
    };

    const handleOk = () => {
        if (!confirmDisabled) {
            onConfirm();
            setConfirmInput('');
        }
    };

    return (
        <Modal
            title={
                <Space align="center">
                    <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: '24px' }} />
                    <span>Archive Truck</span>
                    {truck && <Tag color="red">{truck.fleet_number}</Tag>}
                </Space>
            }
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
            okText="Archive Permanently"
            okButtonProps={{
                danger: true,
                disabled: confirmDisabled,
            }}
            cancelButtonProps={{
                type: 'text'
            }}
            width={520}
            centered
            destroyOnClose
        >
            <div style={{ marginBottom: 24 }}>
                <Alert
                    message="Warning: This action cannot be undone"
                    description={
                        <>
                            <p>Archiving this truck will hide it from the system, but maintain all historical records and assignments.</p>
                            <p>The truck will no longer be available for new assignments or modifications.</p>
                        </>
                    }
                    type="warning"
                    showIcon
                    style={{ marginBottom: 24 }}
                />
                
                <Text>
                    To confirm archiving, please type the truck's fleet number <Text strong>{truck?.fleet_number}</Text> below:
                </Text>
                
                <div style={{ marginTop: 16 }}>
                    <Input
                        placeholder="Enter truck's fleet number"
                        value={confirmInput}
                        onChange={handleFleetNumberChange}
                        status={confirmInput && confirmDisabled ? 'error' : undefined}
                    />
                </div>
            </div>
        </Modal>
    );
};
