import React from 'react';
import { Button, Space, Tag, Descriptions, Card, Row, Col, Typography } from 'antd';
import { CarOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Truck } from '../types';
import { getStatusColor } from '../utils/truckUtils';
import dayjs from 'dayjs'; // Import dayjs
import { Modal } from '../../../components/common/Modal';

const { Title, Text } = Typography;

interface ViewTruckModalProps {
    visible: boolean;
    truck: Truck | null;
    onEdit: () => void;
    onClose: () => void;
}

export const ViewTruckModal: React.FC<ViewTruckModalProps> = ({
    visible,
    truck,
    onEdit,
    onClose,
}) => {
    if (!truck) return null;

    const descriptionStyle = {
        backgroundColor: '#fafafa',
        padding: '24px',
        borderRadius: '8px',
        marginBottom: '16px',
    };

    const labelStyle = {
        fontWeight: 500,
        color: '#666',
    };

    const contentStyle = {
        minHeight: '32px',
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <Modal
            title={
                <Space>
                    <CarOutlined style={{ color: '#1890ff', fontSize: '20px' }} />
                    <Title level={4} style={{ margin: 0 }}>Truck Details</Title>
                </Space>
            }
            open={visible}
            onCancel={onClose}
            styles={{
                body: {
                    padding: '24px',
                    backgroundColor: '#f5f5f5'
                }
            }}
            footer={[
                <Button 
                    key="edit" 
                    type="primary" 
                    icon={<EditOutlined />}
                    onClick={onEdit}
                    style={{ backgroundColor: '#1890ff' }}
                >
                    Edit Details
                </Button>,
                <Button 
                    key="close" 
                    icon={<CloseOutlined />}
                    onClick={onClose}
                >
                    Close
                </Button>
            ]}
            width={800}
            centered
            destroyOnClose
        >
            <Card style={{ marginBottom: '16px' }}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Text type="secondary">Fleet Number</Text>
                        <Title level={4} style={{ margin: '4px 0' }}>{truck.fleet_number}</Title>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Text type="secondary">Status</Text><br />
                        <Tag color={getStatusColor(truck.status)} style={{ margin: '4px 0', padding: '4px 12px' }}>
                            {truck.status}
                        </Tag>
                    </Col>
                </Row>
            </Card>

            <Descriptions 
                bordered 
                column={2} 
                size="middle"
                labelStyle={labelStyle}
                contentStyle={contentStyle}
                style={descriptionStyle}
            >
                {/* Registration Section */}
                {truck.type === 'Truck and Dog' ? (
                    <>
                        <Descriptions.Item label="Truck Registration" span={1}>
                            {truck.truck_rego || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Dog Trailer Registration" span={1}>
                            {truck.trailer_rego || 'N/A'}
                        </Descriptions.Item>
                    </>
                ) : truck.type === 'Semi' ? (
                    <>
                        <Descriptions.Item label="Prime Mover Registration" span={1}>
                            {truck.truck_rego || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Trailer Registration" span={1}>
                            {truck.trailer_rego || 'N/A'}
                        </Descriptions.Item>
                    </>
                ) : truck.type === 'Side Tipper' ? (
                    <>
                        <Descriptions.Item label="Prime Mover Registration" span={1}>
                            {truck.truck_rego || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tipper Registration" span={1}>
                            {truck.trailer_rego || 'N/A'}
                        </Descriptions.Item>
                    </>
                ) : (
                    <Descriptions.Item label="Registration" span={2}>
                        {truck.truck_rego || 'N/A'}
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Type" span={1}>
                    <Tag color="blue" style={{ margin: 0 }}>
                        {truck.type}
                    </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Location" span={1}>
                    {truck.location_name || 'N/A'}
                </Descriptions.Item>

                {/* Weight Configuration Section */}
                {truck.type !== 'Semi' && (
                    <>
                        <Descriptions.Item label="Body Tare Weight" span={1}>
                            {truck.body_tare ? `${truck.body_tare.toFixed(1)}t` : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Body Max Gross" span={1}>
                            {truck.body_max_gross ? `${truck.body_max_gross.toFixed(1)}t` : 'N/A'}
                        </Descriptions.Item>
                    </>
                )}
                
                {(truck.type === 'Truck and Dog' || truck.type === 'Semi' || truck.type === 'Side Tipper') && (
                    <>
                        <Descriptions.Item 
                            label={
                                truck.type === 'Semi' ? 'Trailer Tare Weight' : 
                                truck.type === 'Side Tipper' ? 'Tipper Tare Weight' :
                                'Dog Trailer Tare'
                            } 
                            span={1}
                        >
                            {truck.trailer_tare ? `${truck.trailer_tare.toFixed(1)}t` : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item 
                            label={
                                truck.type === 'Semi' ? 'Trailer Max Gross' :
                                truck.type === 'Side Tipper' ? 'Tipper Max Gross' :
                                'Dog Trailer Max Gross'
                            } 
                            span={1}
                        >
                            {truck.trailer_max_gross ? `${truck.trailer_max_gross.toFixed(1)}t` : 'N/A'}
                        </Descriptions.Item>
                    </>
                )}

                <Descriptions.Item label="Combined Max Gross" span={2}>
                    {truck.combined_max_gross ? `${truck.combined_max_gross.toFixed(1)}t` : 'N/A'}
                </Descriptions.Item>
            </Descriptions>

            {truck.current_assignment && (
                <Card style={{ marginTop: '16px' }}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Text type="secondary">Current Assignment</Text>
                            <Title level={5} style={{ margin: '4px 0' }}>
                                {truck.current_assignment.driver?.name || 'Unassigned'}
                            </Title>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Text type="secondary">Start Time</Text>
                            <Title level={5} style={{ margin: '4px 0' }}>
                                {dayjs(truck.current_assignment.start_time).format('DD/MM/YYYY HH:mm')}
                            </Title>
                        </Col>
                    </Row>
                </Card>
            )}

            {truck.notes && (
                <Card title="Notes" size="small" style={{ marginTop: '16px' }}>
                    <Text>{truck.notes}</Text>
                </Card>
            )}
        </Modal>
    );
};
