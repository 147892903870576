import React, { useState, useMemo, useEffect } from 'react';
import { Button, Space, Row, Col, Card, Statistic, Input, Select, Spin, App, Form, Alert, Typography, notification } from 'antd';
import { 
    CheckCircleOutlined,
    CarOutlined,
    ToolOutlined,
    StopOutlined,
    PlusOutlined,
    SearchOutlined,
    SortAscendingOutlined,
    MinusCircleOutlined,
    UserOutlined
} from '@ant-design/icons';
import { TruckTable } from './components/TruckTable';
import { TruckForm } from './components/TruckForm';
import { DeleteTruckModal } from './components/DeleteTruckModal';
import { AssignTruckModal } from './components/AssignTruckModal';
import { ViewTruckModal } from './components/ViewTruckModal';
import { useTrucks } from './hooks/useTrucks';
import type { Truck, TruckStatus, TruckFormData, TruckFilters } from './types';
import dayjs, { Dayjs } from 'dayjs';
import './TrucksPage.css';

const { Option } = Select;

interface TruckStatistic {
    title: string;
    status: TruckStatus;
    icon: React.ReactNode;
    color: string;
}

const TRUCK_STATISTICS: TruckStatistic[] = [
    { title: 'Available', status: 'Available', icon: <CheckCircleOutlined />, color: '#52c41a' },
    { title: 'In Use', status: 'InUse', icon: <CarOutlined />, color: '#1890ff' },
    { title: 'Workshop', status: 'Workshop', icon: <ToolOutlined />, color: '#faad14' },
    { title: 'SubContracting', status: 'SubContracting', icon: <UserOutlined />, color: '#722ed1' },
    { title: 'Inactive', status: 'Inactive', icon: <MinusCircleOutlined />, color: '#d9d9d9' }
];

const SORT_OPTIONS = [
    { value: 'fleet_number', label: 'Fleet Number' },
    { value: 'status', label: 'Status' },
    { value: 'location_name', label: 'Location' }
];

interface TruckFormValues extends Omit<TruckFormData, 'year'> {
    year?: Dayjs;
}

/**
 * TrucksPage Component
 * 
 * Main page for managing trucks. Features include:
 * - Dashboard with truck statistics by status
 * - Comprehensive truck table with sorting and filtering
 * - CRUD operations for truck management
 * - Truck assignment functionality
 * - Detailed truck information view
 * 
 * @returns {JSX.Element} The rendered TrucksPage component
 */
export const TrucksPage: React.FC = () => {
    const { message: messageApi, notification: notificationApi } = App.useApp();
    const { trucks, loading, error, addTruck, updateTruck, deleteTruck, fetchTrucks, updateFilters, filters } = useTrucks();
    const [showTruckForm, setShowTruckForm] = useState(false);
    const [showAssignmentModal, setShowAssignmentModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTruck, setSelectedTruck] = useState<Truck | null>(null);
    const [searchText, setSearchText] = useState('');
    const [sortField, setSortField] = useState<keyof Truck>('fleet_number');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [selectedStatus, setSelectedStatus] = useState<TruckStatus | null>(null);
    const [truckForm] = Form.useForm<TruckFormValues>();
    const [allTrucks, setAllTrucks] = useState<Truck[]>([]);

    // Keep allTrucks updated with the unfiltered truck list
    useEffect(() => {
        if (!filters.status) {
            setAllTrucks(trucks);
        }
    }, [trucks, filters.status]);

    const handleOpenForm = (mode: 'create' | 'edit', truck?: Truck) => {
        if (mode === 'create') {
            truckForm.resetFields();
            truckForm.setFieldsValue({
                status: 'Available'
            });
        } else if (truck) {
            const { id, current_assignment, ...formData } = truck;
            truckForm.setFieldsValue(formData);
        }
        setSelectedTruck(truck || null);
        setShowTruckForm(true);
    };

    const handleCloseForm = () => {
        truckForm.resetFields();
        setShowTruckForm(false);
        setSelectedTruck(null);
    };

    const handleSearch = (value: string) => {
        setSearchText(value);
    };

    const handleStatusFilter = (status: TruckStatus) => {
        const newStatus = status === selectedStatus ? null : status;
        setSelectedStatus(newStatus);
        updateFilters({ ...filters, status: newStatus || undefined });
    };

    const handleSort = (value: string) => {
        if (value === sortField) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(value as keyof Truck);
            setSortOrder('asc');
        }
    };

    const handleSubmitForm = async (formValues: TruckFormValues) => {
        try {
            // Convert form values to TruckFormData
            const truckData: TruckFormData = {
                ...formValues
            };

            if (selectedTruck) {
                await updateTruck(selectedTruck.id, truckData);
                notificationApi.success({
                    message: 'Truck Updated',
                    description: 'The truck has been updated successfully.',
                    placement: 'topRight',
                    style: {
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                });
            } else {
                await addTruck(truckData);
                notificationApi.success({
                    message: 'Truck Added',
                    description: 'The truck has been added successfully.',
                    placement: 'topRight',
                    style: {
                        borderRadius: '8px',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                });
            }
            handleCloseForm();
            await fetchTrucks();
        } catch (error) {
            console.error('Error submitting truck:', error);
            notificationApi.error({
                message: 'Error',
                description: 'Failed to save truck. Please try again.',
                placement: 'topRight',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
        }
    };

    const handleDeleteTruck = async (truck: Truck) => {
        try {
            await deleteTruck(truck.id);
            setShowDeleteModal(false);
            notificationApi.success({
                message: 'Truck Archived',
                description: 'The truck has been archived successfully.',
                placement: 'topRight',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
            await fetchTrucks();
        } catch (error) {
            console.error('Error archiving truck:', error);
            notificationApi.error({
                message: 'Error',
                description: 'Failed to archive truck. Please try again.',
                placement: 'topRight',
                style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
            });
        }
    };

    const statistics = useMemo(() => {
        const trucksToCount = allTrucks.length > 0 ? allTrucks : trucks;
        return TRUCK_STATISTICS.map(stat => ({
            ...stat,
            count: trucksToCount.filter(truck => truck.status === stat.status).length,
            onClick: () => handleStatusFilter(stat.status)
        }));
    }, [allTrucks, trucks]);

    const filteredTrucks = useMemo(() => {
        let result = [...trucks];

        if (selectedStatus) {
            result = result.filter(t => t.status === selectedStatus);
        }

        if (searchText) {
            const searchLower = searchText.toLowerCase();
            result = result.filter(t => 
                t.fleet_number?.toLowerCase().includes(searchLower) ||
                t.truck_rego?.toLowerCase().includes(searchLower) ||
                t.trailer_rego?.toLowerCase().includes(searchLower) ||
                t.type?.toLowerCase().includes(searchLower) ||
                t.location_name?.toLowerCase().includes(searchLower)
            );
        }

        if (sortField) {
            result.sort((a, b) => {
                const aValue = String(a[sortField] || '');
                const bValue = String(b[sortField] || '');
                return sortOrder === 'asc' 
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            });
        }

        return result;
    }, [trucks, selectedStatus, searchText, sortField, sortOrder]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="trucks-container">
            <div className="trucks-content">
                <div className="trucks-header">
                    <Typography.Title level={2} className="trucks-title">Trucks</Typography.Title>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => handleOpenForm('create')}
                        className="add-truck-button"
                    >
                        Add Truck
                    </Button>
                </div>

                {error && (
                    <Alert
                        message="Error"
                        description={error}
                        type="error"
                        showIcon
                        style={{ marginBottom: 24 }}
                    />
                )}

                <div className="trucks-table-container">
                    <Spin spinning={loading} size="large">
                        <Space direction="vertical" className="trucks-space" size={24}>
                            {/* Statistics Cards */}
                            <div className="section-container">
                                <p className="status-cards-hint">Click cards to filter by status</p>
                                <Row gutter={[16, 16]} className="trucks-stats">
                                    {statistics.map(stat => (
                                        <Col xs={24} sm={12} lg={8} xl={4} key={stat.status}>
                                            <Card
                                                className={`stat-card ${selectedStatus === stat.status ? 'selected' : ''}`}
                                                onClick={() => handleStatusFilter(stat.status)}
                                                hoverable
                                            >
                                                <Statistic
                                                    title={stat.title}
                                                    value={stat.count}
                                                    prefix={React.cloneElement(stat.icon as React.ReactElement, {
                                                        style: { color: stat.color }
                                                    })}
                                                />
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            {/* Filter Controls */}
                            <div className="section-container">
                                <div className="filter-controls">
                                    <Row gutter={[16, 16]} className="trucks-actions" align="middle">
                                        <Col xs={24} sm={24} md={8}>
                                            <Input.Search
                                                placeholder="Search trucks..."
                                                value={searchText}
                                                onChange={(e) => handleSearch(e.target.value)}
                                                style={{ width: '100%' }}
                                            />
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Sort by..."
                                                value={sortField}
                                                onChange={setSortField}
                                            >
                                                {SORT_OPTIONS.map(option => (
                                                    <Option key={option.value} value={option.value}>{option.label}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} style={{ textAlign: 'right' }}>
                                            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={() => handleOpenForm('create')}
                                                    className="add-button"
                                                >
                                                    Add Truck
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            {/* Truck Table */}
                            <div className="section-container">
                                <Row>
                                    <Col span={24}>
                                        {loading ? (
                                            <div className="loading-container">
                                                <Spin size="large" />
                                            </div>
                                        ) : (
                                            <TruckTable
                                                trucks={filteredTrucks}
                                                loading={loading}
                                                onEdit={truck => handleOpenForm('edit', truck)}
                                                onDelete={truck => {
                                                    setSelectedTruck(truck);
                                                    setShowDeleteModal(true);
                                                }}
                                                onAssign={truck => {
                                                    setSelectedTruck(truck);
                                                    setShowAssignmentModal(true);
                                                }}
                                                onViewDetails={truck => {
                                                    setSelectedTruck(truck);
                                                    setShowViewModal(true);
                                                }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>

                            {/* Modals */}
                            {showTruckForm && (
                                <TruckForm
                                    visible={showTruckForm}
                                    onSubmit={handleSubmitForm}
                                    onCancel={handleCloseForm}
                                    initialValues={selectedTruck || undefined}
                                    form={truckForm}
                                    mode={selectedTruck ? 'edit' : 'create'}
                                />
                            )}

                            <DeleteTruckModal
                                visible={showDeleteModal}
                                truck={selectedTruck}
                                onCancel={() => setShowDeleteModal(false)}
                                onConfirm={() => selectedTruck && handleDeleteTruck(selectedTruck)}
                            />

                            <AssignTruckModal
                                visible={showAssignmentModal && selectedTruck !== null}
                                truck={selectedTruck || undefined}
                                onCancel={() => setShowAssignmentModal(false)}
                                onComplete={() => {
                                    setShowAssignmentModal(false);
                                    fetchTrucks();
                                }}
                            />

                            <ViewTruckModal
                                visible={showViewModal}
                                truck={selectedTruck}
                                onEdit={() => {
                                    setShowViewModal(false);
                                    if (selectedTruck) {
                                        handleOpenForm('edit', selectedTruck);
                                    }
                                }}
                                onClose={() => setShowViewModal(false)}
                            />
                        </Space>
                    </Spin>
                </div>
            </div>
        </div>
    );
};
