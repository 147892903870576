import React from 'react';
import { Space, Button, Tooltip } from 'antd';
import { 
  FileTextOutlined, 
  TruckOutlined, 
  UnorderedListOutlined, 
  PlusOutlined 
} from '@ant-design/icons';

interface DocketNavigationProps {
  currentPage: string;
  onPageChange: (page: string) => void;
}

const DocketNavigation: React.FC<DocketNavigationProps> = ({ currentPage, onPageChange }) => {
  const navigationItems = [
    {
      key: 'dockets',
      icon: <PlusOutlined />,
      label: 'New Docket',
      tooltip: 'Create a new docket',
      style: { backgroundColor: '#52c41a' }
    },
    {
      key: 'xbinDocket',
      icon: <FileTextOutlined />,
      label: 'Xbin Docket',
      tooltip: 'Create Xbin docket',
      style: { backgroundColor: '#1890ff' }
    },
    {
      key: 'xbinTruck',
      icon: <TruckOutlined />,
      label: 'Xbin Truck',
      tooltip: 'Manage Xbin truck',
      style: { backgroundColor: '#722ed1' }
    },
    {
      key: 'docketList',
      icon: <UnorderedListOutlined />,
      label: "View Today's Dockets",
      tooltip: "View and manage today's dockets",
      style: { backgroundColor: '#fa8c16' }
    }
  ];

  return (
    <Space size="middle" style={{ width: '100%', justifyContent: 'center', padding: '16px 0' }}>
      {navigationItems.map(item => (
        <Tooltip key={item.key} title={item.tooltip}>
          <Button
            type="primary"
            icon={item.icon}
            onClick={() => onPageChange(item.key)}
            style={{ 
              ...(currentPage === item.key ? item.style : { 
                backgroundColor: '#fff',
                borderColor: item.style.backgroundColor,
                color: item.style.backgroundColor
              })
            }}
          >
            {item.label}
          </Button>
        </Tooltip>
      ))}
    </Space>
  );
};

export default DocketNavigation;
