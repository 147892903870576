import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import { Truck, TruckFormData, TruckFilters, Assignment } from '../types';
import { PostgrestError } from '@supabase/supabase-js';

export const useTrucks = () => {
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [filters, setFilters] = useState<TruckFilters>({});

    const fetchTrucks = async () => {
        try {
            setLoading(true);
            let query = supabase
                .from('trucks')
                .select(`
                    *,
                    location:locations(name),
                    current_assignment:assignments!assignments_truck_id_fkey(
                        id,
                        driver_id,
                        start_time,
                        end_time,
                        status,
                        driver:drivers!assignments_driver_id_fkey(
                            id,
                            name,
                            fleet_number
                        )
                    )
                `)
                .neq('status', 'Inactive');  // Exclude inactive trucks

            if (filters.status) {
                query = query.eq('status', filters.status);
            }

            if (filters.location_id) {
                query = query.eq('location_id', filters.location_id);
            }

            if (filters.type) {
                query = query.eq('type', filters.type);
            }

            const { data, error: err } = await query;

            if (err) throw err;

            const transformedData = data?.map(truck => ({
                ...truck,
                location_name: truck.location?.name,
                current_assignment: truck.current_assignment?.find((a: Assignment) => a.status === 'active' && !a.end_time) || null
            })) || [];

            setTrucks(transformedData);
            setError(null);
        } catch (err) {
            console.error('Error fetching trucks:', err);
            setError('Failed to fetch trucks');
        } finally {
            setLoading(false);
        }
    };

    const addTruck = async (truckData: TruckFormData) => {
        try {
            const { data, error } = await supabase
                .from('trucks')
                .insert([truckData])
                .select()
                .single();

            if (error) throw error;
            await fetchTrucks();
            return data;
        } catch (error) {
            const err = error as PostgrestError;
            console.error('Error adding truck:', err);
            throw err;
        }
    };

    const updateTruck = async (truckId: string, truckData: Partial<TruckFormData>) => {
        try {
            const { data, error } = await supabase
                .from('trucks')
                .update(truckData)
                .eq('id', truckId)
                .select()
                .single();

            if (error) throw error;
            await fetchTrucks();
            return data;
        } catch (error) {
            const err = error as PostgrestError;
            console.error('Error updating truck:', err);
            throw err;
        }
    };

    const deleteTruck = async (id: string) => {
        try {
            const { error } = await supabase
                .from('trucks')
                .update({ 
                    status: 'Inactive',
                    deleted_at: new Date().toISOString()
                })
                .eq('id', id);

            if (error) throw error;
            await fetchTrucks();
        } catch (error) {
            console.error('Error archiving truck:', error);
            throw error;
        }
    };

    const updateFilters = useCallback((newFilters: Partial<TruckFilters>) => {
        setFilters(prev => ({ ...prev, ...newFilters }));
    }, []);

    useEffect(() => {
        const subscription = supabase
            .channel('trucks-channel')
            .on('postgres_changes', { event: '*', schema: 'public', table: 'trucks' }, () => {
                fetchTrucks();
            })
            .subscribe();

        fetchTrucks();

        return () => {
            subscription.unsubscribe();
        };
    }, [filters]);

    return {
        trucks,
        loading,
        error,
        addTruck,
        updateTruck,
        deleteTruck,
        fetchTrucks,
        updateFilters,
        filters
    };
};
