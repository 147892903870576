import { Truck, Assignment } from '../types';

export const calculateTotalTare = (truck: Truck): number => {
    // Calculate total tare from body tare and trailer tare
    const bodyTare = truck.body_tare || 0;
    const trailerTare = truck.trailer_tare || 0;
    return bodyTare + trailerTare;
};

export const calculateMaxGross = (truck: Truck): number => {
    return truck.combined_max_gross;
};

export const calculateRemainingCapacity = (truck: Truck, currentLoad: number): number => {
    const maxGross = calculateMaxGross(truck);
    const totalTare = calculateTotalTare(truck);
    return maxGross - totalTare - currentLoad;
};

export const getStatusColor = (status: string): string => {
    const statusColors: Record<string, string> = {
        'Available': 'green',
        'Unavailable': 'red',
        'Workshop': 'orange',
        'SubContracting': 'purple',
        'Inactive': 'grey',
    };
    return statusColors[status] || 'default';
};

export const formatDateTime = (date: string | null | undefined): string => {
    if (!date) return '';
    return new Date(date).toLocaleString();
};

export const isAssignmentActive = (assignment: Assignment): boolean => {
    return !assignment.end_time && assignment.status.toLowerCase() === 'active';
};

export const canAssignDriver = (truck: Truck): boolean => {
    return (
        truck.status === 'Available' &&
        (!truck.current_assignment || !isAssignmentActive(truck.current_assignment))
    );
};

export const canEditTruck = (truck: Truck): boolean => {
    return !truck.current_assignment || !isAssignmentActive(truck.current_assignment);
};

export const getAssignmentDuration = (assignment: Assignment): string => {
    const start = new Date(assignment.start_time);
    const end = assignment.end_time ? new Date(assignment.end_time) : new Date();
    const diffInHours = Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60));
    
    if (diffInHours < 24) {
        return `${diffInHours} hours`;
    }
    const days = Math.floor(diffInHours / 24);
    return `${days} days`;
};

export const formatTruckIdentifier = (truck: Truck): string => {
    if (truck.type === 'Truck and Dog') {
        return `${truck.fleet_number} (${truck.truck_rego}/${truck.trailer_rego})`;
    } else if (truck.type === 'Semi' || truck.type === 'Side Tipper') {
        return `${truck.fleet_number} (${truck.truck_rego}/${truck.trailer_rego})`;
    } else {
        return `${truck.fleet_number} (${truck.truck_rego})`;
    }
};
