import React from 'react';
import { Table, Tag, Space, Button, Select, Tooltip, Progress, App } from 'antd';
import type { TableProps, ColumnsType } from 'antd/es/table';
import { 
  DeleteOutlined, 
  EditOutlined, 
  EyeOutlined,
  ClockCircleOutlined,
  RightCircleOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined,
  CloseCircleOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { Job, Truck, JobStatus } from '../types';
import dayjs from 'dayjs';
import { supabase } from '../../../lib/supabaseClient';

const { Option } = Select;

// Truck capacity in cubic meters for each truck type
const TRUCK_CAPACITIES = {
  'Truck and Dog': 38,
  'Body Truck': 12,
  'Semi Tipper': 28,
  '8 Wheeler': 27,
  '10 Wheeler': 20,
} as const;

// Maximum number of loads a truck can do when turning all day
const LOADS_PER_TRUCK_PER_DAY = 5;

interface JobTableProps {
  jobs: Job[];
  loading: boolean;
  trucks: Truck[];
  onEdit: (job: Job) => void;
  onDelete: (id: string) => void;
  onViewDetails: (id: string) => void;
  handleTruckSelection: (value: string[], record: Job) => void;
  getTruckColor: (type: string) => string;
  filterDate: dayjs.Dayjs;
  fetchJobs: () => void; 
}

export const JobTable: React.FC<JobTableProps> = ({
  jobs,
  loading,
  trucks,
  onEdit,
  onDelete,
  onViewDetails,
  handleTruckSelection,
  getTruckColor,
  filterDate,
  fetchJobs 
}) => {
  const { notification } = App.useApp();

  // Function to get the color for a given job status
  const getStatusColor = (status: JobStatus): string => {
    switch (status) {
      case 'completed':
        return '#52c41a'; // green
      case 'started':
        return '#1677ff'; // blue
      case 'on-hold':
        return '#faad14'; // orange
      case 'cancelled':
        return '#ff4d4f'; // red
      case 'moved':
        return '#13c2c2'; // cyan
      default:
        return '#d9d9d9'; // grey
    }
  };

  // Function to get the text for a given job status
  const getStatusText = (status: JobStatus): string => {
    if (!status) return 'Unknown';
    return status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ');
  };

  // Function to get the icon for a given job status
  const getStatusIcon = (status: JobStatus): React.ReactNode => {
    switch (status) {
      case 'completed':
        return <CheckCircleOutlined />;
      case 'started':
        return <RightCircleOutlined />;
      case 'on-hold':
        return <PauseCircleOutlined />;
      case 'cancelled':
        return <CloseCircleOutlined />;
      case 'moved':
        return <SwapOutlined />;
      default:
        return <ClockCircleOutlined />;
    }
  };

  const handleAssignTruck = (jobId: number, truckId: number) => {
    // TODO: Implement truck assignment
    console.log('Assign truck', jobId, truckId);
  };

  const handleUnassignTruck = (jobId: number, truckId: number) => {
    // TODO: Implement truck unassignment
    console.log('Unassign truck', jobId, truckId);
  };

  // Helper function to get time priority for sorting
  const getTimePriority = (time: string | null): number => {
    if (!time) return 999; // Null/empty times go last
    if (time === 'First') return 0;
    if (time === 'AM') return 1;
    if (time === 'PM') return 3;
    if (time === 'Last') return 4;
    
    // Handle custom times (HH:mm format)
    if (time.includes(':')) {
      const [hours] = time.split(':').map(Number);
      if (hours < 12) return 2; // Before noon goes between AM and PM
      return 3.5; // After noon goes after PM but before Last
    }
    
    return 999; // Unknown format goes last
  };

  // Combined sorting function for status and time
  const sortJobs = (a: Job, b: Job): number => {
    // First, sort by completion status
    if (a.status === 'completed' && b.status !== 'completed') return 1;
    if (a.status !== 'completed' && b.status === 'completed') return -1;
    
    // If both jobs have the same completion status, sort by time
    return getTimePriority(a.delivery_time) - getTimePriority(b.delivery_time);
  };

  // Define the columns for the table
  const columns: ColumnsType<Job> = [
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Time</span>,
      dataIndex: 'delivery_time',
      key: 'time',
      align: 'left',
      width: 80,
      render: (text: string) => text || '',
      sorter: sortJobs,
      defaultSortOrder: 'ascend'
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Job #</span>,
      dataIndex: 'job_number',
      key: 'job',
      align: 'center',
      width: 70,
      render: (text: number) => text
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Customer</span>,
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 120,
      render: (text: string) => text,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      filterSearch: true,
      filters: Array.from(new Set(jobs.map(job => job.customer_name))).map(name => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => record.customer_name === String(value),
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Address</span>,
      dataIndex: 'delivery_address',
      key: 'delivery_address',
      width: 200,
      render: (text: string, record: Job) => (
        <>
          {text}
          {record.suburb && <span style={{ color: '#666' }}> ({record.suburb})</span>}
        </>
      ),
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Product</span>,
      dataIndex: 'product',
      key: 'product',
      width: 100,
      render: (product: string) => (product || '-').toUpperCase(),
      sorter: (a, b) => {
        const aProduct = a.product || '';
        const bProduct = b.product || '';
        return aProduct.localeCompare(bProduct);
      },
      filters: Array.from(
        new Set(
          jobs.filter(job => job.product).map(job => job.product as string)
        )
      ).map(name => ({
        text: name,
        value: name
      })),
      onFilter: (value, record) => record.product === value,
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Quantity</span>,
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (text: string, record: Job) => (
        <Tooltip title={`${record.quantity}t`}>
          {record.quantity_display_mode === 'trucks' ? record.trucks_display : `${text}t`}
        </Tooltip>
      ),
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Progress</span>,
      key: 'progress',
      width: 160,
      render: (_, record: Job) => {
        const delivered = Number(record.progressive || 0);
        const total = Number(record.quantity || 0);
        const percentage = total > 0 ? Math.min(Math.round((delivered / total) * 100), 100) : 0;
        
        return (
          <Tooltip title={`${percentage}% Complete`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span>{delivered.toFixed(1)}t / {total.toFixed(1)}t</span>
              <Progress 
                type="circle" 
                percent={percentage} 
                size={32}
                strokeColor={
                  percentage >= 100 ? '#52c41a' :
                  percentage >= 75 ? '#1890ff' :
                  percentage >= 50 ? '#faad14' : '#ff4d4f'
                }
                format={() => ''}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Status</span>,
      key: 'status',
      dataIndex: 'status',
      width: 120,
      render: (status: JobStatus, record: Job) => {
        const statusOptions = [
          { value: 'entered', label: <span style={{ whiteSpace: 'nowrap' }}><Tag color="default">Entered</Tag></span> },
          { value: 'started', label: <span style={{ whiteSpace: 'nowrap' }}><Tag color="processing">Started</Tag></span> },
          { value: 'completed', label: <span style={{ whiteSpace: 'nowrap' }}><Tag color="success">Completed</Tag></span> },
          { value: 'on-hold', label: <span style={{ whiteSpace: 'nowrap' }}><Tag color="warning">On Hold</Tag></span> },
          { value: 'cancelled', label: <span style={{ whiteSpace: 'nowrap' }}><Tag color="error">Cancelled</Tag></span> },
          { value: 'moved', label: <span style={{ whiteSpace: 'nowrap' }}><Tag color="cyan">Moved</Tag></span> }
        ];

        return (
          <Select
            value={status}
            onChange={async (newStatus) => {
              try {
                const { error } = await supabase
                  .from('jobs')
                  .update({ status: newStatus })
                  .eq('id', record.id);
                
                if (error) throw error;
                notification.success({
                  message: 'Status Updated',
                  description: `Job status updated to ${newStatus}`,
                  placement: 'topRight',
                  style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                  }
                });
                fetchJobs();
              } catch (error) {
                notification.error({
                  message: 'Update Failed',
                  description: 'Failed to update job status',
                  placement: 'topRight',
                  style: {
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                  }
                });
              }
            }}
            style={{ width: '100%' }}
            variant="borderless"
            options={statusOptions}
            onClick={(e) => e.stopPropagation()}
          />
        );
      },
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Trucks</span>,
      key: 'trucks',
      width: '25%',
      render: (_, record: Job) => {
        const assignedTrucks = record.trucks_allocated || [];
        const truckGroups = [];
        for (let i = 0; i < assignedTrucks.length; i += 10) {
          truckGroups.push(assignedTrucks.slice(i, i + 10));
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {truckGroups.map((group, groupIndex) => (
              <div key={groupIndex} style={{ display: 'grid', gridTemplateColumns: 'repeat(10, minmax(0, 1fr))', gap: '4px', marginBottom: '4px' }}>
                {group.map((fleetNumber: string, index: number) => {
                  const truck = trucks.find(t => t.fleet_number === fleetNumber);
                  return truck ? (
                    <Tag 
                      key={index}
                      color={getTruckColor(truck.type)}
                      style={{ 
                        margin: 0,
                        padding: '2px 8px',
                        border: '1px solid',
                        fontWeight: 500,
                        textAlign: 'center'
                      }}
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const newTrucks = record.trucks_allocated?.filter(t => t !== fleetNumber) || [];
                        handleTruckSelection(newTrucks, record);
                      }}
                    >
                      {truck.fleet_number}
                    </Tag>
                  ) : null;
                })}
              </div>
            ))}
            <div style={{ display: 'flex', gap: '4px' }}>
              <Select
                style={{ width: 120 }}
                placeholder="Add truck"
                onClick={(e) => e.stopPropagation()}
                showSearch
                optionFilterProp="children"
                onChange={(value: string) => {
                  const newTrucks = [...assignedTrucks, value];
                  handleTruckSelection(newTrucks, record);
                }}
              >
                {trucks
                  .filter(truck => !assignedTrucks.includes(truck.fleet_number))
                  .sort((a, b) => a.fleet_number.localeCompare(b.fleet_number))
                  .map(truck => (
                    <Select.Option key={truck.fleet_number} value={truck.fleet_number}>
                      {truck.fleet_number}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
          </div>
        );
      },
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }}>Actions</span>,
      key: 'actions',
      width: 120,
      render: (_, record: Job) => (
        <Space size="middle">
          <Button
            type="primary"
            style={{ backgroundColor: '#faad14' }}
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          />
          <Button
            type="primary"
            style={{ backgroundColor: '#52c41a' }}
            icon={<EyeOutlined />}
            onClick={() => onViewDetails(record.id)}
          />
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  // Filter jobs by date
  const filteredJobs = jobs.filter(job => 
    dayjs(job.delivery_date).format('YYYY-MM-DD') === filterDate.format('YYYY-MM-DD')
  );

  return (
    <Table
      columns={columns}
      dataSource={filteredJobs}
      rowKey="id"
      loading={loading}
      tableLayout="auto"
      scroll={{ y: 'calc(100vh - 260px)' }}
      pagination={false}
      size="small"
      onRow={(record) => ({
        style: {
          backgroundColor: `${getStatusColor(record.status)}10`,
          transition: 'background-color 0.3s'
        }
      })}
    />
  );
};
