import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import { Location } from '../types';
import { message } from 'antd';

export const useLocations = (userId: string | undefined) => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [userLocationId, setUserLocationId] = useState<string | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchLocations = useCallback(async () => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('locations')
        .select('id, name')
        .order('name');  // Order locations by name
      
      if (error) throw error;
      
      setLocations(data || []);

      // If we have locations but no selected location, try to use user's location or first one
      if (data && data.length > 0 && !selectedLocationId) {
        if (userId) {
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('location_id')
            .eq('id', userId)
            .single();
          
          if (profileError) {
            console.error('Error fetching user profile:', profileError);
          }
          
          if (profileData?.location_id) {
            setSelectedLocationId(profileData.location_id);
            setUserLocationId(profileData.location_id);
          } else {
            setSelectedLocationId(data[0].id);
          }
        } else {
          setSelectedLocationId(data[0].id);
        }
      }
    } catch (error) {
      console.error('Failed to fetch locations:', error);
      message.error('Failed to fetch locations');
    } finally {
      setLoading(false);
    }
  }, [selectedLocationId, userId]);

  useEffect(() => {
    fetchLocations();
  }, [userId, fetchLocations]);

  return {
    locations,
    userLocationId,
    selectedLocationId,
    setSelectedLocationId,
    loading
  };
};
