import { useCallback } from 'react';
import { TruckData } from '../types';
import { App } from 'antd';

export const useWeightCalculation = () => {
  const { notification } = App.useApp();

  const calculateNetWeight = useCallback((
    grossWeight: number | null,
    truckData: TruckData | null,
    isSplitLoad: boolean,
    splitLoadPart: 'truck' | 'trailer' | null
  ) => {
    console.log('Weight calculation inputs:', {
      grossWeight,
      truckData,
      isSplitLoad,
      splitLoadPart
    });

    if (!grossWeight || !truckData) {
      console.log('Missing required data:', { grossWeight, truckData });
      return null;
    }

    let currentTare: number;
    let maxGross: number;

    if (isSplitLoad && truckData.type === 'Truck and Dog') {
      if (splitLoadPart === 'truck') {
        currentTare = truckData.body_tare;
        maxGross = truckData.body_max_gross;
      } else {
        currentTare = truckData.trailer_tare;
        maxGross = truckData.trailer_max_gross;
      }
    } else {
      currentTare = truckData.body_tare + (truckData.trailer_tare || 0);
      maxGross = truckData.combined_max_gross;
    }

    console.log('Calculated tare and max gross:', {
      currentTare,
      maxGross,
      truckType: truckData.type,
      bodyTare: truckData.body_tare,
      trailerTare: truckData.trailer_tare
    });

    // Only show max gross error if we're at least close to a valid weight
    if (grossWeight > maxGross && grossWeight > currentTare) {
      console.log('Gross weight exceeds limit:', {
        grossWeight,
        maxGross,
        difference: grossWeight - maxGross
      });
      notification.error({
        message: 'Gross Weight Exceeds Limit',
        description: `Gross weight exceeds maximum limit of ${maxGross} tonnes`,
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      return null;
    }

    const calculatedNetWeight = Number((grossWeight - currentTare).toFixed(2));
    console.log('Net weight calculation:', {
      grossWeight,
      currentTare,
      calculatedNetWeight,
      calculation: `${grossWeight} - ${currentTare} = ${calculatedNetWeight}`,
      isGreaterThanZero: calculatedNetWeight > 0,
      typeofNetWeight: typeof calculatedNetWeight
    });

    // Only show net weight error if we're at least close to a valid weight
    const netWeightNum = Number(calculatedNetWeight);
    if ((isNaN(netWeightNum) || netWeightNum <= 0) && grossWeight > currentTare / 2) {
      console.log('Invalid net weight:', {
        calculatedNetWeight,
        netWeightNum,
        isNaN: isNaN(netWeightNum),
        comparison: `${netWeightNum} <= 0 = ${netWeightNum <= 0}`,
        grossWeight,
        currentTare
      });
      notification.error({
        message: 'Net Weight Error',
        description: 'Net weight must be greater than 0',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      return null;
    }

    return netWeightNum > 0 ? netWeightNum : null;
  }, []);

  return { calculateNetWeight };
};
