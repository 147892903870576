import { useState } from 'react';
import { JobData, TruckData, CustomerData, ProductData, JobDetails, TruckProgress } from '../types';

export const useDocketState = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [truckData, setTruckData] = useState<TruckData | null>(null);
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [productData, setProductData] = useState<ProductData | null>(null);
  const [grossWeight, setGrossWeight] = useState<number | null>(null);
  const [netWeight, setNetWeight] = useState<number | null>(null);
  const [jobDetails, setJobDetails] = useState<JobDetails | null>(null);
  const [allocatedTrucks, setAllocatedTrucks] = useState<TruckProgress[]>([]);
  const [isSplitLoad, setIsSplitLoad] = useState(false);
  const [splitLoadPart, setSplitLoadPart] = useState<'truck' | 'trailer' | null>(null);

  const resetForm = () => {
    setSearchValue('');
    setJobData(null);
    setTruckData(null);
    setCustomerData(null);
    setProductData(null);
    setGrossWeight(null);
    setNetWeight(null);
    setJobDetails(null);
    setAllocatedTrucks([]);
    setIsSplitLoad(false);
    setSplitLoadPart(null);
  };

  return {
    loading,
    setLoading,
    searchValue,
    setSearchValue,
    jobData,
    setJobData,
    truckData,
    setTruckData,
    customerData,
    setCustomerData,
    productData,
    setProductData,
    grossWeight,
    setGrossWeight,
    netWeight,
    setNetWeight,
    jobDetails,
    setJobDetails,
    allocatedTrucks,
    setAllocatedTrucks,
    isSplitLoad,
    setIsSplitLoad,
    splitLoadPart,
    setSplitLoadPart,
    resetForm,
  };
};
