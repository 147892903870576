import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Input, Typography, Space, Tag, App, Button } from 'antd';
import { Truck } from '../types';
import { supabase } from '../../../lib/supabaseClient';
import { useAssignments } from '../hooks/useAssignments';
import dayjs from 'dayjs';

const { Option } = Select;
const { Text } = Typography;

export interface AssignTruckModalProps {
    visible: boolean;
    onCancel: () => void;
    onComplete: () => void;
    truck?: Truck;
}

interface Driver {
    id: string;
    name: string;
    status: string;
    current_truck: {
        fleet_number: string;
    } | null;
}

// This matches the exact shape of the Supabase response
interface SupabaseDriver {
    id: string;
    name: string;
    status: string;
    current_assignment: Array<{
        truck: {
            fleet_number: string;
        };
    }> | null;
}

export const AssignTruckModal: React.FC<AssignTruckModalProps> = ({
    visible,
    onCancel,
    onComplete,
    truck
}) => {
    const [form] = Form.useForm();
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [loading, setLoading] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [pendingAssignment, setPendingAssignment] = useState<any>(null);
    const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
    const [currentTruck, setCurrentTruck] = useState<{ fleet_number: string } | null>(null);
    const { createAssignment } = useAssignments();
    const { message } = App.useApp();

    useEffect(() => {
        if (visible) {
            form.resetFields();
            fetchDrivers();
        }
    }, [visible]);

    const fetchDrivers = async () => {
        setLoading(true);
        try {
            // First, get all drivers
            const { data: driversData, error: driversError } = await supabase
                .from('drivers')
                .select(`
                    id,
                    name,
                    status
                `)
                .order('name');

            if (driversError) throw driversError;

            // Then, get current assignments for these drivers
            const { data: assignmentsData, error: assignmentsError } = await supabase
                .from('assignments')
                .select(`
                    driver_id,
                    truck:trucks!inner(
                        fleet_number
                    )
                `)
                .is('end_time', null);

            if (assignmentsError) throw assignmentsError;

            // Transform the data to match our Driver interface
            const transformedData: Driver[] = (driversData || []).map(driver => {
                const currentAssignment = assignmentsData?.find(
                    assignment => assignment.driver_id === driver.id
                );
                
                // Supabase returns truck as an array, but we know there's only one due to the inner join
                const currentTruck = currentAssignment?.truck[0];
                
                return {
                    id: driver.id,
                    name: driver.name,
                    status: driver.status,
                    current_truck: currentTruck ? {
                        fleet_number: currentTruck.fleet_number
                    } : null
                };
            });
            
            setDrivers(transformedData);
        } catch (err) {
            console.error('Error fetching drivers:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleDriverSelect = async (driverId: string) => {
        const driver = drivers.find(d => d.id === driverId);
        if (driver?.current_truck) {
            setSelectedDriver(driver);
            setConfirmModalVisible(true);
        } else {
            setSelectedDriver(driver || null);
        }
    };

    const handleSubmit = async (values: any) => {
        if (!truck?.id) {
            message.error('No truck selected');
            return;
        }

        try {
            await createAssignment({
                driver_id: values.driver_id,
                truck_id: truck.id,
                notes: values.notes || '',
                start_time: dayjs().toISOString()
            });
            
            message.success('Driver assigned successfully');
            onComplete();
            onCancel();
        } catch (error: any) {
            if (error.message === 'DRIVER_HAS_ASSIGNMENT') {
                // This is expected behavior when a driver needs to be reassigned
                const selectedDriver = drivers.find(d => d.id === values.driver_id);
                setSelectedDriver(selectedDriver || null);
                setCurrentTruck(error.currentTruck);
                setPendingAssignment(values);
                setConfirmModalVisible(true);
            } else {
                console.error('Assignment error:', error);
                message.error('Failed to assign driver');
            }
        }
    };

    const handleConfirmReassign = async () => {
        if (!pendingAssignment) {
            message.error('No assignment data');
            return;
        }

        if (!truck?.id) {
            message.error('No truck selected');
            return;
        }
        
        try {
            await createAssignment({
                driver_id: pendingAssignment.driver_id,
                truck_id: truck.id,
                notes: pendingAssignment.notes || '',
                start_time: dayjs().toISOString(),
                force: true // Force the reassignment
            });
            
            message.success('Driver reassigned successfully');
            setConfirmModalVisible(false);
            onComplete();
            onCancel();
        } catch (error) {
            console.error('Reassignment error:', error);
            message.error('Failed to reassign driver');
        }
    };

    const handleCancelReassign = () => {
        setConfirmModalVisible(false);
        setPendingAssignment(null);
    };

    return (
        <App>
            <Modal
                title={<Space>
                    Assign Driver to Truck
                    <Tag color="blue">{truck?.fleet_number}</Tag>
                </Space>}
                open={visible}
                footer={null}
                onCancel={onCancel}
                confirmLoading={loading}
                width={720}
                centered
                destroyOnClose
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                    preserve={false}
                >
                    <Form.Item
                        name="driver_id"
                        label="Driver"
                        rules={[{ required: true, message: 'Please select a driver' }]}
                    >
                        <Select
                            loading={loading}
                            placeholder="Select a driver"
                            optionFilterProp="children"
                            showSearch
                        >
                            {drivers.map(driver => (
                                <Select.Option key={driver.id} value={driver.id}>
                                    {driver.name}
                                    {driver.current_truck && (
                                        <Tag color="orange" style={{ marginLeft: 8 }}>
                                            Currently assigned to {driver.current_truck.fleet_number}
                                        </Tag>
                                    )}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="notes"
                        label="Notes"
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Assign
                            </Button>
                            <Button onClick={onCancel}>
                                Cancel
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Confirmation Modal */}
            {confirmModalVisible && (
                <Modal
                    title="Confirm Driver Reassignment"
                    open={confirmModalVisible}
                    onOk={handleConfirmReassign}
                    onCancel={handleCancelReassign}
                    okText="Yes, Reassign"
                    cancelText="No, Cancel"
                >
                    <p>
                        {selectedDriver?.name} is currently assigned to truck <Tag color="orange">{currentTruck?.fleet_number}</Tag>.
                        Do you want to reassign them to <Tag color="blue">{truck?.fleet_number}</Tag>?
                    </p>
                </Modal>
            )}
        </App>
    );
};
