import { Rule } from 'antd/lib/form';

export const validateTruckFleetNumber = (): Rule[] => {
    return [
        { required: true, message: 'Please enter a fleet number' },
        { pattern: /^[A-Z0-9-]+$/, message: 'Fleet number must contain only uppercase letters, numbers, and hyphens' },
        { max: 20, message: 'Fleet number cannot be longer than 20 characters' }
    ];
};

export const validateTruckType = (): Rule[] => {
    return [
        { required: true, message: 'Please select a truck type' }
    ];
};

export const validateTruckStatus = (): Rule[] => {
    return [
        { required: true, message: 'Please select a status' }
    ];
};

export const validateTruckRegistration = (): Rule[] => {
    return [
        { pattern: /^[A-Z0-9]+$/, message: 'Registration must contain only uppercase letters and numbers' },
        { max: 10, message: 'Registration cannot be longer than 10 characters' }
    ];
};

export const validateTruckNotes = (): Rule[] => {
    return [
        { max: 500, message: 'Notes cannot be longer than 500 characters' }
    ];
};
