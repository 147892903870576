import React from 'react';
import { Drawer, Row, Col, Space, Typography, Statistic, Card, Tag, Divider, Table, Progress, Empty, Alert } from 'antd';
import { 
  CarOutlined, 
  UserOutlined, 
  EnvironmentOutlined, 
  ClockCircleOutlined,
  ToolOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import type { TruckDrawerProps } from './types';
import { STATUS_COLORS } from '../utils/constants';
import { formatTime, formatDate } from '../utils/formatters';

const { Title, Text } = Typography;

export const TruckDrawer: React.FC<TruckDrawerProps> = ({ 
  truck, 
  visible, 
  onClose 
}) => {
  if (!truck) return null;

  const dailyDeliveries = truck.pulse_dockets?.filter(d => 
    new Date(d.created_at).toDateString() === new Date().toDateString()
  ) || [];

  const utilization = truck.max_gross 
    ? Math.min(((truck.daily_total || 0) / truck.max_gross) * 100, 100)
    : 0;

  const getStatusIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
      case 'maintenance':
        return <ToolOutlined style={{ color: '#faad14' }} />;
      default:
        return <ClockCircleOutlined style={{ color: '#1890ff' }} />;
    }
  };

  const columns = [
    {
      title: 'Time',
      dataIndex: 'created_at',
      key: 'time',
      width: 100,
      render: (date: string) => formatTime(date)
    },
    {
      title: 'Docket',
      dataIndex: 'docket_number',
      key: 'docket',
      width: 120
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (qty: number) => (
        <Text strong style={{ color: '#722ed1' }}>
          {qty}t
        </Text>
      )
    },
    {
      title: 'Address',
      dataIndex: 'delivery_address',
      key: 'address',
      ellipsis: true
    }
  ];

  const statusColor = truck.status ? STATUS_COLORS[truck.status.toLowerCase() as keyof typeof STATUS_COLORS] : undefined;

  return (
    <Drawer
      title={
        <Space>
          {getStatusIcon(truck.status || '')}
          <Text strong style={{ fontSize: '16px' }}>{truck.fleet_number}</Text>
          <Tag color={statusColor}>
            {truck.status?.toUpperCase()}
          </Tag>
        </Space>
      }
      width={700}
      open={visible}
      onClose={onClose}
      placement="right"
      maskClosable={false}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space align="center">
                <CarOutlined />
                <Text strong>{truck.type}</Text>
                <Divider type="vertical" />
                <Text type="secondary">Truck: {truck.truck_rego}</Text>
                {truck.trailer_rego && (
                  <>
                    <Divider type="vertical" />
                    <Text type="secondary">Trailer: {truck.trailer_rego}</Text>
                  </>
                )}
              </Space>

              <Divider style={{ margin: '8px 0' }} />

              <Space align="center">
                <UserOutlined style={{ color: '#1890ff' }} />
                <Text strong>{truck.driver_name || 'Unassigned'}</Text>
                <Divider type="vertical" />
                <EnvironmentOutlined style={{ color: '#52c41a' }} />
                <Text>{truck.location_name || 'Unknown Location'}</Text>
              </Space>
            </Space>
          </Card>
        </Col>

        <Col span={24}>
          <Card size="small">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Statistic 
                  title="Tare Weight" 
                  value={truck.tare || 0}
                  suffix="t"
                  precision={1}
                />
              </Col>
              <Col span={8}>
                <Statistic 
                  title="Max Gross" 
                  value={truck.max_gross || 0}
                  suffix="t"
                  precision={1}
                />
              </Col>
              <Col span={8}>
                <Statistic 
                  title="Today's Total" 
                  value={truck.daily_total || 0}
                  suffix="t"
                  precision={1}
                  valueStyle={{ color: '#722ed1' }}
                />
              </Col>
              <Col span={24}>
                <Text type="secondary">Daily Utilization</Text>
                <Progress 
                  percent={utilization}
                  status={utilization >= 90 ? 'exception' : utilization >= 70 ? 'normal' : 'success'}
                  strokeColor={
                    utilization >= 90 
                      ? '#f5222d' 
                      : utilization >= 70 
                        ? '#faad14' 
                        : '#52c41a'
                  }
                />
              </Col>
            </Row>
          </Card>
        </Col>

        {truck.current_assignment && (
          <Col span={24}>
            <Card 
              title={
                <Space>
                  <WarningOutlined style={{ color: '#1890ff' }} />
                  <Text strong>Current Assignment</Text>
                </Space>
              } 
              size="small"
            >
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <Text type="secondary">Job Number:</Text>
                  <br />
                  <Text strong>#{truck.current_assignment.job_number}</Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">Customer:</Text>
                  <br />
                  <Text strong>{truck.current_assignment.customer_name}</Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">Product:</Text>
                  <br />
                  <Text strong>{truck.current_assignment.product}</Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">Quantity:</Text>
                  <br />
                  <Text strong>{truck.current_assignment.quantity}t</Text>
                </Col>
                <Col span={24}>
                  <Text type="secondary">Status:</Text>
                  <br />
                  <Tag color="processing">{truck.current_assignment.status}</Tag>
                </Col>
              </Row>
            </Card>
          </Col>
        )}

        <Col span={24}>
          <Card
            title={
              <Space>
                <ClockCircleOutlined style={{ color: '#722ed1' }} />
                <Text strong>Today's Deliveries</Text>
                <Tag>{dailyDeliveries.length} deliveries</Tag>
              </Space>
            }
            size="small"
          >
            {dailyDeliveries.length > 0 ? (
              <Table 
                columns={columns}
                dataSource={dailyDeliveries}
                rowKey="id"
                size="small"
                pagination={false}
                scroll={{ y: 300 }}
              />
            ) : (
              <Empty 
                description="No deliveries today" 
                image={Empty.PRESENTED_IMAGE_SIMPLE} 
              />
            )}
          </Card>
        </Col>
      </Row>
    </Drawer>
  );
};