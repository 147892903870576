import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Tag, Modal, notification } from 'antd';
import { TagOutlined, WarningOutlined, EnvironmentOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd/lib/form';
import type { Job, Customer, Location } from '../../types';
import { useLocations } from '../../hooks/useLocations';
import { useAuth } from '../../../../contexts/AuthContext';

interface JobDetailsProps {
  form: FormInstance;
  editingJob: Job | null;
  customers?: Customer[];
  nextJobNumber: number;
  onSubmit: (values: any) => void;
}

export const JobDetails: React.FC<JobDetailsProps> = ({ 
  form, 
  editingJob,
  customers = [],
  nextJobNumber,
  onSubmit
}) => {
  const [showStatusWarning, setShowStatusWarning] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [customerConfirmed, setCustomerConfirmed] = useState(false);
  const { user } = useAuth();
  const { locations, userLocationId } = useLocations(user?.id);

  useEffect(() => {
    // Set default location to user's location if creating a new job
    if (!editingJob && userLocationId) {
      form.setFieldValue('location_id', userLocationId);
    }
    // If editing a job, set the location from the job data
    if (editingJob?.location_id) {
      form.setFieldValue('location_id', editingJob.location_id);
    }
  }, [userLocationId, editingJob, form]);

  const handleCustomerSelect = async (customerId: string) => {
    const selectedCustomer = customers.find(c => c.id === customerId);
    if (selectedCustomer) {
      if (selectedCustomer.status === 'Inactive' || selectedCustomer.status === 'Stop Credit') {
        form.setFields([{
          name: 'customer_id',
          errors: [`This customer has a ${selectedCustomer.status} status. Jobs cannot be created.`]
        }]);
        form.setFieldValue('customer_id', undefined);
        return;
      }
      // If customer status is valid, update the form
      setSelectedCustomer(selectedCustomer);
      form.setFieldValue('customer_id', customerId);
      form.setFields([{
        name: 'customer_id',
        errors: []
      }]);
    }
  };

  const handleFormSubmit = async (values: any) => {
    // Double check customer status before submission
    const customer = customers.find(c => c.id === values.customer_id);
    if (customer?.status === 'Inactive' || customer?.status === 'Stop Credit') {
      notification.error({
        message: 'Invalid Customer Status',
        description: `Cannot create job for customer with ${customer.status} status.`,
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      return;
    }
    
    // Continue with job creation if customer status is valid
    onSubmit(values);
  };

  const handleStatusWarningOk = () => {
    if (selectedCustomer) {
      setCustomerConfirmed(true);
      form.setFieldsValue({ customer_id: selectedCustomer.id });
      setShowStatusWarning(false);
    }
  };

  const handleStatusWarningCancel = () => {
    setShowStatusWarning(false);
    form.setFields([
      {
        name: 'customer_id',
        value: undefined,
        errors: undefined
      }
    ]);
    setSelectedCustomer(null);
    setCustomerConfirmed(false);
  };

  return (
    <>
      <Row gutter={[12, 24]}>
        <Col span={8}>
          <Form.Item 
            name="job_number" 
            label="Job Number"
            tooltip="Auto-generated job number"
            style={{ marginBottom: 24 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <Input
              prefix={<TagOutlined />}
              disabled
              placeholder="Auto-generated"
            />
          </Form.Item>
          <Form.Item 
            name="order_number" 
            label="Order Number"
            tooltip="Customer's order number or reference"
            style={{ marginBottom: 24 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <Input
              prefix={<TagOutlined />}
              placeholder="Enter order number"
            />
          </Form.Item>
          <div style={{ display: 'flex', gap: '24px' }}>
            <Form.Item
              name="status"
              label="Status"
              tooltip="Current status of the job"
              style={{ marginBottom: 0 }}
              labelCol={{ style: { padding: '0 0 8px 0' } }}
            >
              <Select style={{ width: '200px' }}>
                <Select.Option value="entered">
                  <Tag color="default">Entered</Tag>
                </Select.Option>
                <Select.Option value="started">
                  <Tag color="processing">Started</Tag>
                </Select.Option>
                <Select.Option value="completed">
                  <Tag color="success">Completed</Tag>
                </Select.Option>
                <Select.Option value="on-hold">
                  <Tag color="warning">On Hold</Tag>
                </Select.Option>
                <Select.Option value="cancelled">
                  <Tag color="error">Cancelled</Tag>
                </Select.Option>
                <Select.Option value="moved">
                  <Tag color="cyan">Moved</Tag>
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="location_id"
              label="Location"
              rules={[{ required: true, message: 'Please select a location' }]}
              style={{ marginBottom: 0 }}
              labelCol={{ style: { padding: '0 0 8px 0' } }}
            >
              <Select
                showSearch
                placeholder="Select a location"
                style={{ width: '200px' }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={locations.map(location => ({
                  value: location.id,
                  label: location.name
                }))}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <Form.Item
            name="customer_id"
            label="Customer"
            rules={[
              { required: true, message: 'Please select a customer' },
              {
                validator: async (_, value) => {
                  const customer = customers.find(c => c.id === value);
                  if (customer?.status === 'Stop Credit') {
                    throw new Error('This customer is on stop credit, please remove the stop credit before creating the job.');
                  }
                  if (customer?.status === 'Inactive') {
                    throw new Error('This customer is inactive, please reactivate the customer before creating the job.');
                  }
                }
              }
            ]}
            tooltip="Select the customer for this job"
            style={{ marginBottom: 0 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a customer"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              suffixIcon={<TagOutlined />}
              onSelect={handleCustomerSelect}
            >
              {customers.map((customer: Customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  <span style={{ 
                    color: customer.status === 'Stop Credit' || customer.status === 'Inactive' ? '#ff4d4f' : 'inherit' 
                  }}>
                    {customer.customer_name}
                  </span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Modal
        title={<><WarningOutlined style={{ color: '#faad14' }} /> Customer Status Warning</>}
        open={showStatusWarning}
        onOk={handleStatusWarningOk}
        onCancel={handleStatusWarningCancel}
        okText="Continue Anyway"
        cancelText="Cancel"
      >
        <p>
          {selectedCustomer?.status === 'Inactive' && 'This customer is currently inactive.'}
          {selectedCustomer?.status === 'Stop Credit' && 'This customer currently has a stop credit status.'}
        </p>
        <p>Are you sure you want to create a job for this customer?</p>
      </Modal>
    </>
  );
};