import React, { useEffect, useState } from 'react';
import { 
  Modal,
  Form, 
  Input, 
  InputNumber, 
  Radio, 
  Card, 
  Space, 
  Row, 
  Col,
  FormInstance,
  App
} from 'antd';
import {
  FileTextOutlined,
  NumberOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  GoldOutlined,
  ShopOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import type { ProductFormData } from '../types';

const { TextArea } = Input;

interface ProductFormProps {
  open: boolean;
  isEdit: boolean;
  title: string;
  initialValues?: ProductFormData;
  onSubmit: (values: ProductFormData) => Promise<void>;
  onCancel: () => void;
}

interface ProductFormContentProps extends Omit<ProductFormProps, 'open'> {
  form: FormInstance<ProductFormData>;
  selectedCategory: ProductFormData['category'] | undefined;
  handleCategoryChange: (value: ProductFormData['category']) => void;
}

const ProductFormContent: React.FC<ProductFormContentProps> = ({
  isEdit,
  title,
  initialValues,
  onSubmit,
  onCancel,
  form,
  selectedCategory,
  handleCategoryChange
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {/* Basic Information */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Product Name"
            name="name"
            rules={[{ required: true, message: 'Please enter product name' }]}
          >
            <Input prefix={<FileTextOutlined />} placeholder="Enter product name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Product Code"
            name="product_code"
          >
            <Input prefix={<NumberOutlined />} placeholder="Enter product code" />
          </Form.Item>
        </Col>
      </Row>

      {/* Category Selection */}
      <Form.Item
        name="category"
        label="Category"
        rules={[{ required: true, message: 'Please select a category' }]}
      >
        <Radio.Group onChange={(e) => handleCategoryChange(e.target.value)}>
          <Row gutter={[16, 16]}>
            {[
              { label: 'Road Base', value: 'RoadBase', icon: <EnvironmentOutlined />, color: '#1890ff' },
              { label: 'Aggregate', value: 'Aggregate', icon: <GoldOutlined />, color: '#52c41a' },
              { label: 'Sand', value: 'Sand', icon: <ShopOutlined />, color: '#faad14' },
              { label: 'Select Fill', value: 'Select Fill', icon: <BarChartOutlined />, color: '#722ed1' }
            ].map(({ label, value, icon, color }) => (
              <Col span={12} key={value}>
                <Card
                  hoverable
                  style={{
                    borderColor: form.getFieldValue('category') === value ? color : undefined,
                    cursor: 'pointer',
                    height: '100%'
                  }}
                  styles={{
                    body: {
                      padding: '16px',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center'
                    }
                  }}
                >
                  <Radio value={value}>
                    <Space size="middle">
                      {React.cloneElement(icon, { style: { color, fontSize: '20px' } })}
                      <span style={{ fontSize: '16px' }}>{label}</span>
                    </Space>
                  </Radio>
                </Card>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>

      {/* Type Selection for Aggregate */}
      {selectedCategory === 'Aggregate' && (
        <Form.Item
          name="type"
          label="Type"
          initialValue={initialValues?.type || ''}
        >
          <Radio.Group value={form.getFieldValue('type')}>
            <Row gutter={[16, 16]}>
              {[
                { label: 'None', value: '', icon: <GoldOutlined />, color: '#d9d9d9' },
                { label: 'Drainage', value: 'Drainage', icon: <GoldOutlined />, color: '#1890ff' },
                { label: 'Blue Metal', value: 'Blue Metal', icon: <GoldOutlined />, color: '#52c41a' },
                { label: 'Brown', value: 'Brown', icon: <GoldOutlined />, color: '#faad14' }
              ].map(({ label, value, icon, color }) => (
                <Col span={12} key={value === '' ? 'none' : value}>
                  <Card
                    hoverable
                    style={{
                      borderColor: form.getFieldValue('type') === value ? color : undefined,
                      cursor: 'pointer',
                      height: '100%'
                    }}
                    styles={{
                      body: {
                        padding: '16px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center'
                      }
                    }}
                  >
                    <Radio value={value}>
                      <Space size="middle">
                        {React.cloneElement(icon, { style: { color, fontSize: '20px' } })}
                        <span style={{ fontSize: '16px' }}>{label}</span>
                      </Space>
                    </Radio>
                  </Card>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      )}

      {/* Numerical Values */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item 
            label="Price per Tonne" 
            name="price_per_tonne"
            rules={[
              { type: 'number', max: 9999, message: 'Maximum value is 9999' }
            ]}
          >
            <InputNumber
              prefix={<DollarOutlined />}
              min={0}
              max={9999}
              step={0.01}
              precision={2}
              placeholder="0.00"
              controls={false}
              style={{ width: '120px' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label="Tonnes per Cubic Meter" 
            name="tonnes_per_cube"
            rules={[
              { type: 'number', max: 9999, message: 'Maximum value is 9999' }
            ]}
          >
            <InputNumber
              min={0}
              max={9999}
              step={0.1}
              precision={2}
              placeholder="0.00"
              controls={false}
              style={{ width: '120px' }}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Description */}
      <Form.Item label="Description" name="description">
        <TextArea 
          rows={4} 
          placeholder="Enter product description (optional)" 
        />
      </Form.Item>
    </div>
  );
};

export const ProductForm: React.FC<ProductFormProps> = ({
  open,
  isEdit,
  title,
  initialValues,
  onSubmit,
  onCancel
}) => {
  const [form] = Form.useForm<ProductFormData>();
  const [selectedCategory, setSelectedCategory] = useState<ProductFormData['category'] | undefined>(undefined);
  const { notification } = App.useApp();

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setSelectedCategory(undefined);
    }
  }, [open, form]);

  useEffect(() => {
    if (open && initialValues) {
      const values = {
        ...initialValues,
        type: initialValues.type || ''
      };
      form.setFieldsValue(values);
      setSelectedCategory(initialValues.category);
    }
  }, [open, initialValues, form]);

  const handleCategoryChange = (value: ProductFormData['category']) => {
    setSelectedCategory(value);
    if (value !== 'Aggregate') {
      form.setFieldValue('type', '');
    }
  };

  const handleSubmit = async (values: ProductFormData) => {
    try {
      await onSubmit(values);
      notification.success({
        message: isEdit ? 'Product Updated' : 'Product Created',
        description: isEdit ? 'Product has been updated successfully.' : 'Product has been created successfully.',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } catch (error) {
      notification.error({
        message: isEdit ? 'Update Failed' : 'Creation Failed',
        description: error instanceof Error ? error.message : 'An error occurred while processing your request.',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      throw error; // Re-throw to prevent modal from closing
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      onOk={() => form.submit()}
      okText={isEdit ? 'Update' : 'Create'}
      width={800}
      destroyOnClose
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        preserve={false}
      >
        <div>
          <ProductFormContent 
            isEdit={isEdit}
            title={title}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            form={form}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </div>
      </Form>
    </Modal>
  );
};
