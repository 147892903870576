export const DRIVER_STATUSES = [
    'Available',
    'Annual Leave',
    'Sick',
    'Wet Day',
    'Inactive',
    'SubContracting'
] as const;

export const LICENSE_CLASSES = [
    'LR',
    'MR',
    'HR',
    'HC',
    'MC',
] as const;

export const LICENSE_ENDORSEMENTS = [
    'B-Double',
    'Road Train',
    'Dangerous Goods',
    'Vehicle Recovery',
] as const;

export const AVAILABILITY_TYPES = [
    'Annual Leave',
    'Sick Leave',
    'Long Service',
    'Training',
] as const;

export const TABLE_PAGINATION = {
    defaultPageSize: 10,
    pageSizeOptions: ['10', '20', '50'],
    showSizeChanger: true,
    showTotal: (total: number) => `Total ${total} drivers`,
};

export const FORM_LAYOUT = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export const FORM_TAIL_LAYOUT = {
    wrapperCol: { offset: 8, span: 16 },
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const PHONE_PATTERN = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const LICENSE_NUMBER_PATTERN = /^[A-Z0-9]{5,15}$/;
export const EMPLOYEE_NUMBER_PATTERN = /^[A-Z0-9-]{2,10}$/;

export const DEFAULT_ERROR_MESSAGE = 'An error occurred. Please try again.';

export const MODAL_WIDTH = 800;

export const LICENSE_EXPIRY_WARNING_DAYS = 30;
export const ASSIGNMENT_MAX_HOURS = 12;
export const BREAK_REQUIRED_HOURS = 4;
