import React from 'react';
import { Form, Select, Row, Col, Button } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd/lib/form';
import { Job, Truck } from '../../types';

const { Option } = Select;

// Styled components for truck type buttons
// const TruckTypeButton = styled(Button)<{ $isSelected: boolean; $color: string }>`
//   min-width: 120px;
//   margin: 4px;
//   border-color: ${props => props.$isSelected ? props.$color : '#d9d9d9'};
//   background-color: ${props => props.$isSelected ? props.$color : 'white'};
//   color: ${props => props.$isSelected ? 'white' : props.$color};

//   &:hover {
//     border-color: ${props => props.$color};
//     color: ${props => props.$isSelected ? 'white' : props.$color};
//     background-color: ${props => props.$isSelected ? props.$color : 'white'};
//   }

//   &:focus {
//     border-color: ${props => props.$color};
//     color: ${props => props.$isSelected ? 'white' : props.$color};
//     background-color: ${props => props.$isSelected ? props.$color : 'white'};
//   }
// `;

// const TruckTypeContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
//   padding: 4px 0;
// `;

interface TransportDetailsProps {
  trucks: Truck[];
  form: FormInstance;
  editingJob: Job | null;
  initialTruckType?: string | null;
}

// Truck type color mapping
const TRUCK_TYPE_COLORS = {
  'Truck and Dog': '#1890ff', // Blue
  'Body Truck': '#52c41a',    // Green
  'Semi Tipper': '#fa8c16',   // Orange
  '8 Wheeler': '#722ed1',     // Purple
  '10 Wheeler': '#f5222d',    // Red
  'Side Tipper': '#13c2c2'    // Cyan
};

const TRUCK_TYPES = [
  'Truck and Dog',
  'Body Truck',
  'Semi Tipper',
  '8 Wheeler',
  '10 Wheeler'
];

export const TransportDetails: React.FC<TransportDetailsProps> = ({
  trucks,
  form,
  editingJob,
  initialTruckType
}) => {
  const currentTruckType = Form.useWatch('truck_type', form);

  const handleTruckTypeSelect = (type: string) => {
    form.setFieldsValue({
      truck_type: type,
      quantity: undefined,
      num_trucks: undefined,
      quantity_display_mode: 'specific',
      trucks_display: undefined
    });
  };

  return (
    <Row gutter={12}>
      <Col span={24}>
        <Form.Item
          name="truck_type"
          label="Truck Type"
          tooltip="Select the required truck type"
          rules={[{ required: true, message: 'Please select truck type' }]}
        >
          <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', padding: '4px 0', overflowX: 'auto' }}>
            {TRUCK_TYPES.map((type) => {
              const color = TRUCK_TYPE_COLORS[type as keyof typeof TRUCK_TYPE_COLORS];
              const isSelected = currentTruckType === type;
              
              return (
                <Button
                  key={type}
                  type={isSelected ? 'primary' : 'default'}
                  onClick={() => handleTruckTypeSelect(type)}
                  icon={<CarOutlined style={{ 
                    color: isSelected ? 'white' : `${color}88`
                  }} />}
                  style={{
                    minWidth: '120px',
                    margin: '4px',
                    borderColor: isSelected ? color : '#d9d9d9',
                    backgroundColor: isSelected ? color : '#fafafa',
                    color: isSelected ? 'white' : `${color}88`,
                    transition: 'all 0.3s'
                  }}
                  onMouseEnter={(e) => {
                    const button = e.currentTarget;
                    button.style.borderColor = color;
                    button.style.color = isSelected ? 'white' : color;
                    button.style.backgroundColor = isSelected ? color : '#fff';
                  }}
                  onMouseLeave={(e) => {
                    const button = e.currentTarget;
                    button.style.borderColor = isSelected ? color : '#d9d9d9';
                    button.style.color = isSelected ? 'white' : `${color}88`;
                    button.style.backgroundColor = isSelected ? color : '#fafafa';
                  }}
                >
                  {type}
                </Button>
              );
            })}
          </div>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name="trucks_allocated"
          label="Allocated Trucks"
          tooltip="Select trucks to allocate to this job"
        >
          <Select
            mode="multiple"
            placeholder="Select trucks"
            style={{ width: '100%' }}
            optionFilterProp="children"
            suffixIcon={<CarOutlined />}
          >
            {trucks.map((truck: Truck) => (
              <Option key={truck.id} value={truck.id}>
                {truck.fleet_number}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};
