import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PrintingContextType {
  isPrinting: boolean;
  setIsPrinting: (printing: boolean) => void;
}

const PrintingContext = createContext<PrintingContextType | undefined>(undefined);

export const PrintingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isPrinting, setIsPrinting] = useState(false);

  return (
    <PrintingContext.Provider value={{ isPrinting, setIsPrinting }}>
      {children}
    </PrintingContext.Provider>
  );
};

export const usePrinting = () => {
  const context = useContext(PrintingContext);
  if (context === undefined) {
    throw new Error('usePrinting must be used within a PrintingProvider');
  }
  return context;
};
