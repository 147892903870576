import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Typography, Table, Spin, message, Card, Row, Col, Tag, Button, Tooltip } from 'antd';
import { supabase } from '../../../lib/supabaseClient';
import { TruckOutlined, FileTextOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import DocketModal from './DocketModal';
import { PulseDocket } from '../types';

const { Title, Text } = Typography;

interface JobDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  jobId: string | null;
  docketId?: string | null;
  onUpdate?: () => void;
}

interface DocketData {
  id: string;
  docket_number: number;
  job_id: string | null;
  truck_id: string;
  customer_id: string;
  delivery_address: string;
  product_id: string;
  quantity: number | null;
  gross_weight: number | null;
  tare_weight: number | null;
  net_weight: number | null;
  is_xbin: boolean;
  xbin_job_number: string | null;
  fleet_number: string;
  created_at: string;
  order_number: string | null;
}

interface JobData {
  id: string;
  job_number: number;
  quantity: string;
  progressive: string;
  status: string;
  customer_id: string;
  truck_progress?: {
    fleet_number: string;
    progressive: number;
  }[];
}

const JobDetailsDrawer: React.FC<JobDetailsDrawerProps> = ({ open, onClose, jobId, docketId, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [docketData, setDocketData] = useState<DocketData | null>(null);
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [customerName, setCustomerName] = useState<string>('');
  const [productDetails, setProductDetails] = useState<{ name: string; type: string | null } | null>(null);
  const [relatedDockets, setRelatedDockets] = useState<DocketData[]>([]);
  const [selectedDocket, setSelectedDocket] = useState<PulseDocket | null>(null);
  const [isDocketModalVisible, setIsDocketModalVisible] = useState(false);
  const [truckDetails, setTruckDetails] = useState<{ registration: string } | null>(null);

  useEffect(() => {
    if (open) {
      if (docketId) {
        fetchDocketDetails(docketId);
      } else if (jobId) {
        fetchJobDetails(jobId);
      }
    } else {
      // Clear state when drawer closes
      setDocketData(null);
      setJobData(null);
      setCustomerName('');
      setProductDetails(null);
      setRelatedDockets([]);
      setSelectedDocket(null);
      setTruckDetails(null);
    }
  }, [open, jobId, docketId]);

  const fetchDocketDetails = async (id: string) => {
    setLoading(true);
    try {
      const { data: docket, error: docketError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .eq('id', id)
        .single();

      if (docketError) throw docketError;

      setDocketData(docket);

      if (docket.is_xbin) {
        // For xbin_trucks, fetch company from xbin_trucks table
        const { data: xbinTruckData, error: xbinTruckError } = await supabase
          .from('xbin_trucks')
          .select('company, registration')
          .eq('id', docket.truck_id)
          .single();

        if (xbinTruckError) throw xbinTruckError;
        setCustomerName(xbinTruckData.company);
        setTruckDetails({ registration: xbinTruckData.registration });
      } else if (docket.customer_id) {
        // For regular dockets, fetch customer name from customers table
        const { data: customerData, error: customerError } = await supabase
          .from('customers')
          .select('customer_name')
          .eq('id', docket.customer_id)
          .single();

        if (customerError) throw customerError;
        setCustomerName(customerData.customer_name);

        // Fetch truck registration
        const { data: truckData, error: truckError } = await supabase
          .from('trucks')
          .select('truck_rego')
          .eq('id', docket.truck_id)
          .single();

        if (truckError) throw truckError;
        setTruckDetails({ registration: truckData.truck_rego });
      }

      if (docket.product_id) {
        const { data: productData, error: productError } = await supabase
          .from('products')
          .select('name, type')
          .eq('id', docket.product_id)
          .single();

        if (productError) throw productError;
        setProductDetails(productData);
      }

      if (docket.is_xbin && docket.order_number) {
        const { data: relatedDocketsData, error: relatedDocketsError } = await supabase
          .from('pulse_dockets')
          .select('*')
          .eq('order_number', docket.order_number)
          .order('created_at', { ascending: true });

        if (relatedDocketsError) throw relatedDocketsError;
        setRelatedDockets(relatedDocketsData);
      } else if (docket.job_id) {
        // Fetch job details using job_id from the docket
        const { data: job, error: jobError } = await supabase
          .from('jobs')
          .select('*')
          .eq('id', docket.job_id)
          .single();

        if (jobError) throw jobError;
        setJobData(job);

        // Get truck progress for this job
        const { data: truckProgress, error: progressError } = await supabase
          .rpc('get_truck_progress_for_job', { job_id: docket.job_id });

        if (progressError) throw progressError;

        setJobData(prevJob => ({
          ...prevJob!,
          truck_progress: truckProgress
        }));

        // Fetch all dockets for this job
        const { data: jobDockets, error: jobDocketsError } = await supabase
          .from('pulse_dockets')
          .select('*')
          .eq('job_id', docket.job_id)
          .order('created_at', { ascending: true });

        if (jobDocketsError) throw jobDocketsError;
        setRelatedDockets(jobDockets);
      }
    } catch (error) {
      console.error('Error fetching docket details:', error);
      message.error('Failed to fetch docket details');
    } finally {
      setLoading(false);
    }
  };

  const fetchJobDetails = async (id: string) => {
    setLoading(true);
    try {
      // Fetch job details using job_id
      const { data: job, error: jobError } = await supabase
        .from('jobs')
        .select('*')
        .eq('id', id)
        .single();

      if (jobError) throw jobError;
      setJobData(job);

      // Get truck progress for this job
      const { data: truckProgress, error: progressError } = await supabase
        .rpc('get_truck_progress_for_job', { job_id: id });

      if (progressError) throw progressError;

      setJobData(prevJob => ({
        ...prevJob!,
        truck_progress: truckProgress
      }));

      // Fetch all dockets for this job
      const { data: jobDockets, error: jobDocketsError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .eq('job_id', id)
        .order('created_at', { ascending: true });

      if (jobDocketsError) throw jobDocketsError;
      setRelatedDockets(jobDockets);
    } catch (error) {
      console.error('Error fetching job details:', error);
      message.error('Failed to fetch job details');
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status: string): string => {
    const colors: { [key: string]: string } = {
      'entered': '#ffffff',
      'started': '#fafa7a',
      'completed': '#7afa8e',
      'on-hold': '#faab7a',
      'cancelled': '#fa7a7a',
      'moved': '#7adcfa'
    };
    return colors[status.toLowerCase().replace(' ', '-')] || '#ffffff';
  };

  const shouldUseDarkText = (backgroundColor: string): boolean => {
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128;
  };

  const renderDocketDetails = () => {
    if (!docketData) return null;

    return (
      <Card
        title={<Title level={4}><FileTextOutlined /> Docket Details</Title>}
        extra={<Tag color="blue">#{docketData.docket_number}</Tag>}
        style={{ marginBottom: 20 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Customer:</Text>
            <Text> {customerName}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Delivery Address:</Text>
            <Text> {docketData.delivery_address}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Product:</Text>
            <Text> {productDetails?.name} {productDetails?.type ? `(${productDetails.type})` : ''}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Quantity:</Text>
            <Text> {docketData.quantity !== null ? `${docketData.quantity.toFixed(2)} t` : 'N/A'}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Truck:</Text>
            <Text> {docketData.fleet_number}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Date:</Text>
            <Text> {dayjs(docketData.created_at).format('DD/MM/YYYY HH:mm')}</Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={8}>
            <Card size="small" title="Tare Weight">
              <Text strong>{docketData.tare_weight !== null ? `${docketData.tare_weight.toFixed(2)} t` : 'N/A'}</Text>
            </Card>
          </Col>
          <Col span={8}>
            <Card size="small" title="Gross Weight">
              <Text strong>{docketData.gross_weight !== null ? `${docketData.gross_weight.toFixed(2)} t` : 'N/A'}</Text>
            </Card>
          </Col>
          <Col span={8}>
            <Card size="small" title="Net Weight">
              <Text strong>{docketData.net_weight !== null ? `${docketData.net_weight.toFixed(2)} t` : 'N/A'}</Text>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderRelatedDockets = () => {
    if (!relatedDockets.length) return null;

    const columns = [
      {
        title: 'Docket Number',
        dataIndex: 'docket_number',
        key: 'docket_number',
        render: (text: number, record: any) => (
          <Tag color={record.is_credit ? 'red' : 'blue'}>#{text}</Tag>
        )
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text: string) => dayjs(text).format('DD/MM/YYYY HH:mm')
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text: number | null) => text !== null ? `${Number(text).toFixed(2)} t` : 'N/A'
      },
      {
        title: 'Net Weight',
        dataIndex: 'net_weight',
        key: 'net_weight',
        render: (text: number | null) => text !== null ? `${Number(text).toFixed(2)} t` : 'N/A'
      },
      {
        title: 'View',
        key: 'view',
        render: (_: any, record: any) => (
          <Tooltip title="View Details">
            <Button
              type="primary"
              style={{ backgroundColor: '#52c41a' }}
              icon={<EyeOutlined />}
              onClick={() => {
                const pulseDocket: PulseDocket = {
                  id: record.id,
                  docket_number: record.docket_number,
                  job_id: record.job_id || '',
                  truck_id: record.truck_id,
                  fleet_number: record.fleet_number,
                  customer_id: record.customer_id,
                  delivery_address: record.delivery_address,
                  product_id: record.product_id,
                  gross_weight: record.gross_weight || 0,
                  tare_weight: record.tare_weight || 0,
                  net_weight: record.net_weight || 0,
                  body_gross: null,
                  body_tare: null,
                  trailer_gross: null,
                  trailer_tare: null,
                  total_gross: record.gross_weight || 0,
                  total_tare: record.tare_weight || 0,
                  quantity: record.quantity || 0,
                  progressive: 0,
                  is_credit: record.is_credit || false,
                  created_at: record.created_at,
                  is_xbin: record.is_xbin || false,
                  xbin_job_number: record.xbin_job_number,
                  order_number: record.order_number,
                  jobs: {
                    job_number: jobData?.job_number || 0,
                    progress: jobData?.progressive ? Number(jobData.progressive) : 0
                  }
                };
                setSelectedDocket(pulseDocket);
                setIsDocketModalVisible(true);
              }}
            />
          </Tooltip>
        ),
      },
    ];

    return (
      <Card title={<Title level={4}>{docketData?.is_xbin ? <TruckOutlined /> : <FileTextOutlined />} Related Dockets</Title>} style={{ marginBottom: 20 }}>
        {docketData?.is_xbin && (
          <Row>
            <Col span={24}>
              <Text strong>Order Number:</Text>
              <Tag color="green" style={{ marginLeft: 8 }}>{docketData.order_number}</Tag>
            </Col>
          </Row>
        )}
        <Table
          dataSource={relatedDockets}
          columns={columns}
          rowKey="id"
          pagination={false}
          size="small"
        />
      </Card>
    );
  };

  const renderJobDetails = () => {
    if (!jobData) return null;

    const columns = [
      {
        title: 'Truck',
        dataIndex: 'fleet_number',
        key: 'fleet_number',
        render: (text: string) => <Tag icon={<TruckOutlined />} color="blue">{text}</Tag>
      },
      {
        title: 'Total',
        dataIndex: 'progressive',
        key: 'progressive',
        render: (text: number) => `${Number(text).toFixed(2)} t`
      },
      {
        title: '% of Progressive',
        dataIndex: 'progressive',
        key: 'progressPercentage',
        render: (text: number) => {
          const percentage = (Number(text) / Number(jobData.quantity)) * 100;
          return `${percentage.toFixed(2)}%`;
        }
      },
    ];

    return (
      <Card title={<Title level={4}><FileTextOutlined /> Job Details</Title>} style={{ marginBottom: 20 }}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Job Number:</Text>
            <Text> {jobData.job_number}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Customer:</Text>
            <Text> {customerName}</Text>
          </Col>
          <Col span={12}>
            <Text strong>Total Quantity:</Text>
            <Text> {Number(jobData.quantity).toFixed(2)} t</Text>
          </Col>
          <Col span={12}>
            <Text strong>Job Progressive:</Text>
            <Text> {Number(jobData.progressive).toFixed(2)} t</Text>
          </Col>
          <Col span={12}>
            <Text strong>Status: </Text>
            {(() => {
              const bgColor = getStatusColor(jobData.status);
              const textColor = shouldUseDarkText(bgColor) ? '#000000' : '#ffffff';
              return (
                <Tag color={bgColor} style={{ color: textColor }}>
                  {jobData.status}
                </Tag>
              );
            })()}
          </Col>
        </Row>
        <Title level={5} style={{ marginTop: 20, marginBottom: 16 }}>Truck Tracker</Title>
        <Table
          dataSource={jobData.truck_progress}
          columns={columns}
          rowKey="fleet_number"
          pagination={false}
          size="small"
        />
      </Card>
    );
  };

  const handleDrawerClose = () => {
    setDocketData(null);
    setJobData(null);
    setCustomerName('');
    setTruckDetails(null);
    setProductDetails(null);
    if (onUpdate) {
      onUpdate();
    }
    onClose();
  };

  return (
    <>
      <Drawer
        title={
          <Title level={3}>
            Job Details
          </Title>
        }
        placement="right"
        onClose={handleDrawerClose}
        open={open}
        width={720}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            {renderDocketDetails()}
            {docketData?.is_xbin ? (
              <>
                {renderRelatedDockets()}
                {renderJobDetails()}
              </>
            ) : (
              <>
                {renderJobDetails()}
                {renderRelatedDockets()}
              </>
            )}
          </>
        )}
      </Drawer>
      <DocketModal
        open={isDocketModalVisible}
        onClose={() => setIsDocketModalVisible(false)}
        docket={selectedDocket}
        customerName={customerName}
        productDetails={productDetails}
        truckDetails={truckDetails}
      />
    </>
  );
};

export type { JobDetailsDrawerProps };
export default JobDetailsDrawer;