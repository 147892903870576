import React, { useState } from 'react';
import { Table, Button, Space, Tooltip, Tag, Modal, App } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PulseDocket } from '../types';
import dayjs from 'dayjs';
import { EyeOutlined, DeleteOutlined, DollarOutlined } from '@ant-design/icons';
import { supabase } from '../../../lib/supabaseClient';
import Credit from './credit/Credit';
import JobDetailsDrawer, { JobDetailsDrawerProps } from './JobDetailsDrawer';
import { recalculateJobProgressive } from '../services/docketService';

interface DocketListProps {
  dockets: PulseDocket[];
  loading?: boolean;
  onDocketDeleted?: () => void;
  onDocketClick?: (docket: PulseDocket) => void;
  onUpdate?: () => void;
}

const DocketList: React.FC<DocketListProps> = ({
  dockets,
  loading = false,
  onDocketDeleted,
  onDocketClick,
  onUpdate
}) => {
  const { notification } = App.useApp();
  const [creditModalVisible, setCreditModalVisible] = useState(false);
  const [selectedDocket, setSelectedDocket] = useState<PulseDocket | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleDelete = async (record: PulseDocket) => {
    Modal.confirm({
      title: 'Delete Docket',
      content: (
        <div>
          <p>Are you sure you want to delete this {record.is_credit ? 'credit' : 'docket'}?</p>
          <p style={{ color: '#ff4d4f', marginTop: 8 }}>
            <strong>Warning:</strong> This action cannot be undone and will affect job progress calculations.
          </p>
          <ul style={{ marginTop: 8 }}>
            <li>Docket Number: #{record.docket_number}</li>
            <li>Job Number: {record.jobs?.job_number || '-'}</li>
            <li>Quantity: {record.quantity}t</li>
            <li>Created: {dayjs(record.created_at).format('DD/MM/YYYY HH:mm')}</li>
          </ul>
        </div>
      ),
      okText: 'Yes, Delete',
      okButtonProps: {
        danger: true,
      },
      cancelText: 'No, Cancel',
      onOk: async () => {
        try {
          const { error } = await supabase
            .from('pulse_dockets')
            .delete()
            .eq('id', record.id);

          if (error) throw error;

          // Recalculate the job's progressive after deleting the docket
          if (record.job_id) {
            await recalculateJobProgressive(record.job_id);
          }

          notification.success({
            message: 'Success',
            description: 'Docket deleted successfully',
            placement: 'topRight'
          });

          onDocketDeleted?.();
        } catch (error) {
          console.error('Error deleting docket:', error);
          notification.error({
            message: 'Error',
            description: 'Failed to delete docket',
            placement: 'topRight'
          });
        }
      },
    });
  };

  const handleCreditCreated = () => {
    setCreditModalVisible(false);
    setSelectedDocket(null);
    onDocketDeleted?.();
  };

  const columns: ColumnsType<PulseDocket> = [
    {
      title: 'Docket Number',
      dataIndex: 'docket_number',
      key: 'docket_number',
      responsive: ['lg'],
    },
    {
      title: 'Job Number',
      key: 'job_number',
      responsive: ['lg'],
      render: (_, record) => record.jobs?.job_number || '-',
    },
    {
      title: 'Fleet Number',
      dataIndex: 'fleet_number',
      key: 'fleet_number',
      responsive: ['lg'],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['md'],
      render: (text: number) => `${Number(text).toFixed(2)} t`,
    },
    {
      title: 'Progressive',
      dataIndex: 'progressive',
      key: 'progressive',
      responsive: ['md'],
      render: (text: number) => text ? `${Number(text).toFixed(2)} t` : '-',
    },
    {
      title: 'Progress',
      key: 'progress',
      responsive: ['md'],
      render: (_, record) => {
        const progress = record.jobs?.progress;
        return progress ? `${Number(progress).toFixed(2)}%` : '-';
      },
    },
    {
      title: 'Type',
      key: 'type',
      responsive: ['md'],
      render: (_, record) => (
        <Tag color={record.is_credit ? 'red' : record.is_xbin ? 'blue' : 'green'}>
          {record.is_credit ? 'Credit' : record.is_xbin ? 'xBin' : 'Regular'}
        </Tag>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => dayjs(date).format('DD/MM/YYYY HH:mm'),
      responsive: ['sm'],
    },
    {
      title: 'Actions',
      key: 'actions',
      responsive: ['sm'],
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View Details">
            <Button
              type="primary"
              style={{ backgroundColor: '#52c41a' }}
              icon={<EyeOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedDocket(record);
                setDrawerVisible(true);
                onDocketClick?.(record);
              }}
            />
          </Tooltip>
          {!record.is_credit && (
            <Tooltip title="Create Credit">
              <Button
                type="primary"
                style={{ backgroundColor: '#1890ff' }}
                icon={<DollarOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDocket(record);
                  setCreditModalVisible(true);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(record);
              }}
            />
          </Tooltip>
        </Space>
      ),
      width: '120px',
      fixed: 'right'
    }
  ];

  return (
    <>
      <Table
        dataSource={dockets}
        columns={columns}
        loading={loading}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => onDocketClick?.(record)
        })}
        scroll={{ x: 'max-content' }}
        size="middle"
        pagination={{
          responsive: true,
          position: ['bottomCenter']
        }}
      />
      <Modal
        title="Create Credit Docket"
        open={creditModalVisible && selectedDocket?.id !== undefined}
        onCancel={() => {
          setCreditModalVisible(false);
          setSelectedDocket(null);
        }}
        footer={null}
        width={600}
        destroyOnClose
      >
        {creditModalVisible && selectedDocket?.id && (
          <Credit 
            docketId={selectedDocket.id} 
            onCreditCreated={handleCreditCreated}
          />
        )}
      </Modal>
      <JobDetailsDrawer
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        jobId={selectedDocket?.job_id || null}
        docketId={selectedDocket?.id || null}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default DocketList;
