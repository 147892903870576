import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Space, Select, Tabs, Alert } from 'antd';
import { BarChartOutlined, ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { supabase } from '../../../lib/supabaseClient';
import type { Metrics } from '../types';

interface PerformancePanelProps {
  metrics: Metrics[];
  loading: boolean;
}

type TimeRange = 'day' | 'week' | 'month' | 'year' | 'all';

interface Stats {
  totalDelivered: number;
  tonnesPerHour: number;
  totalTonnes: number;
  averageDeliveryTime: number;
}

const { TabPane } = Tabs;

export const PerformancePanel: React.FC<PerformancePanelProps> = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>('week');
  const [metrics, setMetrics] = useState<Metrics[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        setError(null);

        const now = new Date();
        let startDate: Date;

        switch (timeRange) {
          case 'day':
            startDate = new Date(now.setHours(0, 0, 0, 0));
            break;
          case 'week':
            startDate = new Date(now);
            startDate.setDate(startDate.getDate() - 7);
            break;
          case 'month':
            startDate = new Date(now.getFullYear(), now.getMonth(), 1);
            break;
          case 'year':
            startDate = new Date(now.getFullYear(), 0, 1);
            break;
          default:
            startDate = new Date(2020, 0, 1); // All time
        }

        const { data, error } = await supabase
          .rpc('get_performance_metrics', {
            start_date: startDate.toISOString(),
            end_date: new Date().toISOString()
          });

        if (error) throw error;
        const validMetrics = (data || []).map((metric: Partial<Metrics>) => ({
          id: metric.id || '',
          date: metric.date || new Date().toISOString(),
          total_deliveries: metric.total_deliveries || 0,
          total_tonnes: metric.total_tonnes || 0,
          total_distance: metric.total_distance || 0,
          efficiency_score: metric.efficiency_score || 0,
          on_time_percentage: metric.on_time_percentage || 0,
          active_drivers: metric.active_drivers || 0,
          active_trucks: metric.active_trucks || 0,
          deliveries_completed: metric.deliveries_completed || 0,
          tonnes_per_hour: metric.tonnes_per_hour || 0,
          avg_delivery_time: metric.avg_delivery_time || 0
        } as Metrics));
        setMetrics(validMetrics);
      } catch (err) {
        console.error('Error fetching metrics:', err);
        setError('Failed to load performance metrics');
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
    const interval = setInterval(fetchMetrics, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [timeRange]);

  const calculateStats = (): Stats => {
    if (!metrics.length) return {
      totalDelivered: 0,
      tonnesPerHour: 0,
      totalTonnes: 0,
      averageDeliveryTime: 0
    };

    const validMetrics = metrics.filter(m => 
      m.deliveries_completed != null && 
      m.tonnes_per_hour != null && 
      m.total_tonnes != null && 
      m.avg_delivery_time != null
    );

    if (!validMetrics.length) return {
      totalDelivered: 0,
      tonnesPerHour: 0,
      totalTonnes: 0,
      averageDeliveryTime: 0
    };

    return {
      totalDelivered: validMetrics.reduce((acc, m) => acc + (m.deliveries_completed || 0), 0),
      tonnesPerHour: validMetrics.reduce((acc, m) => acc + (m.tonnes_per_hour || 0), 0) / validMetrics.length,
      totalTonnes: validMetrics.reduce((acc, m) => acc + (m.total_tonnes || 0), 0),
      averageDeliveryTime: validMetrics.reduce((acc, m) => acc + (m.avg_delivery_time || 0), 0) / validMetrics.length
    };
  };

  const formatDate = (date: string) => {
    try {
      return new Date(date).toLocaleDateString('en-AU', {
        day: 'numeric',
        month: 'short'
      });
    } catch (e) {
      return 'Invalid Date';
    }
  };

  const chartData = metrics.map(m => ({
    ...m,
    date: formatDate(m.date),
    tonnes_per_hour: m.tonnes_per_hour || 0,
    total_tonnes: m.total_tonnes || 0,
    deliveries_completed: m.deliveries_completed || 0
  }));

  const stats = calculateStats();

  if (error) {
    return (
      <Card>
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      </Card>
    );
  }

  return (
    <Card
      className="dashboard-panel"
      title={
        <Space>
          <BarChartOutlined />
          Performance Overview
          <Select<TimeRange>
            value={timeRange}
            onChange={setTimeRange}
            style={{ width: 120, marginLeft: 16 }}
            disabled={loading}
          >
            <Select.Option value="day">Today</Select.Option>
            <Select.Option value="week">This Week</Select.Option>
            <Select.Option value="month">This Month</Select.Option>
            <Select.Option value="year">This Year</Select.Option>
            <Select.Option value="all">All Time</Select.Option>
          </Select>
        </Space>
      }
    >
      <Tabs 
        defaultActiveKey="stats"
        items={[
          {
            key: 'stats',
            label: 'Statistics',
            children: (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Statistic
                    title="Total Deliveries"
                    value={stats.totalDelivered}
                    prefix={loading ? <LoadingOutlined /> : null}
                    valueStyle={{ color: '#52c41a' }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Tonnes Per Hour"
                    value={stats.tonnesPerHour}
                    precision={2}
                    suffix="t/h"
                    prefix={loading ? <LoadingOutlined /> : null}
                    valueStyle={{ color: '#1890ff' }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Total Tonnes"
                    value={stats.totalTonnes}
                    precision={1}
                    suffix="t"
                    prefix={loading ? <LoadingOutlined /> : null}
                    valueStyle={{ color: '#722ed1' }}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Avg Delivery Time"
                    value={stats.averageDeliveryTime}
                    precision={0}
                    suffix="min"
                    prefix={loading ? <LoadingOutlined /> : <ClockCircleOutlined />}
                    valueStyle={{ color: '#fa8c16' }}
                  />
                </Col>
              </Row>
            )
          },
          {
            key: 'trends',
            label: 'Trends',
            children: loading ? (
              <div style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <LoadingOutlined style={{ fontSize: 24 }} />
              </div>
            ) : chartData.length === 0 ? (
              <Alert
                message="No Data"
                description="No performance data available for the selected time range."
                type="info"
                showIcon
              />
            ) : (
              <div style={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="date" 
                      tickFormatter={(value) => value}
                    />
                    <YAxis 
                      yAxisId="left"
                      label={{ value: 'Tonnes', angle: -90, position: 'insideLeft' }}
                    />
                    <YAxis 
                      yAxisId="right" 
                      orientation="right"
                      label={{ value: 'Tonnes/Hour', angle: 90, position: 'insideRight' }}
                    />
                    <Tooltip
                      labelFormatter={(value) => value}
                      formatter={(value: number) => [value.toFixed(2), '']}
                    />
                    <Legend />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="total_tonnes"
                      stroke="#722ed1"
                      name="Total Tonnes"
                      dot={false}
                    />
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="tonnes_per_hour"
                      stroke="#1890ff"
                      name="Tonnes/Hour"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )
          }
        ]}
      />
    </Card>
  );
};