import React, { useState, useEffect, useMemo } from 'react';
import { Card, Space, Row, Col, Empty, Button, Typography, Input, Select, Tooltip, Badge, Spin } from 'antd';
import { TeamOutlined, EyeOutlined, EyeInvisibleOutlined, SearchOutlined, SortAscendingOutlined, CheckCircleOutlined, CalendarOutlined, MedicineBoxOutlined, CloudOutlined, StopOutlined, SwapOutlined, InfoCircleOutlined } from '@ant-design/icons';
import type { Database } from '../../../types/supabase';
import { supabase } from '../../../lib/supabaseClient';
import { DriverCard } from './DriverCard';
import { DriverModal } from './DriverModal';
import { type DriverStatus } from '../../drivers/types';

interface DatabaseDriver {
  id: string;
  name: string;
  fleet_number: string;
  phone_number: string;
  avatar: string;
  notes: string;
  status: DriverStatus;
  license_number: string;
  license_type: string;
  email: string;
  address: string;
  date_added: string;
  dob: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  location_id: string;
}

interface TruckAssignment {
  driver_id: string;
  truck: Array<{
    type: string;
  }>;
}

interface Driver extends DatabaseDriver {
  truck_type?: string;
  total_loads?: number;
  total_tonnes?: number;
  efficiency_score?: number;
}

type SortField = 'name' | 'status' | 'fleet_number';
type SortOrder = 'asc' | 'desc';

const DRIVER_STATUS_LABELS: Record<DriverStatus, string> = {
  'Available': 'Available',
  'Annual Leave': 'Annual Leave',
  'Sick': 'Sick',
  'Wet Day': 'Wet Day',
  'Inactive': 'Inactive',
  'SubContracting': 'SubContracting'
};

const getStatusColor = (status: DriverStatus): { bg: string; text: string } => {
  switch (status) {
    case 'Available':
      return { bg: '#237804', text: '#f6ffed' };  // Green
    case 'Annual Leave':
      return { bg: '#ad4e00', text: '#fff7e6' };  // Orange
    case 'Sick':
      return { bg: '#a8071a', text: '#fff1f0' };  // Red
    case 'Wet Day':
      return { bg: '#0050b3', text: '#e6f7ff' };  // Blue
    case 'Inactive':
      return { bg: '#434343', text: '#fafafa' };  // Grey
    case 'SubContracting':
      return { bg: '#531dab', text: '#f9f0ff' };  // Purple
    default:
      return { bg: '#d9d9d9', text: '#ffffff' };
  }
};

const getStatusTagColor = (status: DriverStatus): string => {
  switch (status) {
    case 'Available':
      return 'success';
    case 'Annual Leave':
      return 'volcano';
    case 'Sick':
      return 'red';
    case 'Wet Day':
      return 'blue';
    case 'Inactive':
      return 'default';
    case 'SubContracting':
      return 'purple';
    default:
      return 'default';
  }
};

const getStatusIcon = (status: DriverStatus) => {
  switch (status) {
    case 'Available':
      return <CheckCircleOutlined />;
    case 'Annual Leave':
      return <CalendarOutlined />;
    case 'Sick':
      return <MedicineBoxOutlined />;
    case 'Wet Day':
      return <CloudOutlined />;
    case 'Inactive':
      return <StopOutlined />;
    case 'SubContracting':
      return <SwapOutlined />;
    default:
      return <InfoCircleOutlined />;
  }
};

export const DriversPanel: React.FC = () => {
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<SortField>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const { data: driversData, error: driversError } = await supabase
          .rpc('get_driver_stats')
          .select();

        if (driversError) throw driversError;

        const { data: assignmentsData, error: assignmentsError } = await supabase
          .from('driver_truck_assignments')
          .select(`
            driver_id,
            truck:trucks(
              id,
              fleet_number,
              type,
              status
            )
          `)
          .is('end_date', null);

        if (assignmentsError) throw assignmentsError;

        const driversWithTrucks = driversData.map(driver => {
          const assignment = assignmentsData?.find(a => a.driver_id === driver.id);
          const driverWithTruck = {
            ...driver,
            truck_type: assignment?.truck?.[0]?.type,
            total_loads: driver.total_loads || 0,
            total_tonnes: driver.total_tonnes || 0,
            efficiency_score: driver.efficiency || 0
          };
          return driverWithTruck;
        });

        setDrivers(driversWithTrucks);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDrivers();
    const interval = setInterval(fetchDrivers, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleStatusToggle = (status: string) => {
    setSelectedStatus(prev => 
      prev.includes(status)
        ? prev.filter(s => s !== status)
        : [...prev, status]
    );
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleSort = (value: string) => {
    const [field, order] = value.split('-') as [SortField, SortOrder];
    setSortBy(field);
    setSortOrder(order);
  };

  const filteredDrivers = useMemo(() => {
    let filtered = [...drivers];

    // Apply search filter
    if (searchText) {
      const searchLower = searchText.toLowerCase();
      filtered = filtered.filter(driver => 
        driver.name.toLowerCase().includes(searchLower) ||
        driver.fleet_number.toLowerCase().includes(searchLower) ||
        driver.phone_number.toLowerCase().includes(searchLower)
      );
    }

    // Apply status filter
    if (selectedStatus.length > 0) {
      filtered = filtered.filter(driver => selectedStatus.includes(driver.status));
    }

    // Apply sorting
    switch (sortBy) {
      case 'name':
        filtered.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'status':
        filtered.sort((a, b) => a.status.localeCompare(b.status));
        break;
      case 'fleet_number':
        filtered.sort((a, b) => a.fleet_number.localeCompare(b.fleet_number));
        break;
    }

    if (sortOrder === 'desc') {
      filtered.reverse();
    }

    return filtered;
  }, [drivers, searchText, selectedStatus, sortBy, sortOrder]);

  const renderStatusToggle = (status: DriverStatus) => {
    const count = drivers.filter(d => d.status === status).length;
    const colors = getStatusColor(status);
    const isActive = selectedStatus.includes(status);

    return (
      <Tooltip key={status} title={`${isActive ? 'Hide' : 'Show'} ${DRIVER_STATUS_LABELS[status]} Drivers`}>
        <Button
          type={isActive ? "primary" : "default"}
          icon={getStatusIcon(status)}
          onClick={() => handleStatusToggle(status)}
          style={{
            marginRight: '8px',
            marginBottom: '8px',
            backgroundColor: isActive ? colors.bg : 'white',
            borderColor: colors.bg,
            color: isActive ? colors.text : colors.bg,
            fontWeight: isActive ? 'bold' : 'normal',
            display: 'inline-flex',
            alignItems: 'center',
            boxShadow: isActive ? '0 2px 4px rgba(0,0,0,0.2)' : 'none',
          }}
        >
          {DRIVER_STATUS_LABELS[status]} ({count})
        </Button>
      </Tooltip>
    );
  };

  const allStatuses: DriverStatus[] = [
    'Available',
    'Annual Leave',
    'Sick',
    'Wet Day',
    'Inactive',
    'SubContracting'
  ];

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px', gap: '16px' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            <TeamOutlined /> Driver Overview
          </Typography.Title>
          <Input.Search
            placeholder="Search drivers..."
            style={{ width: 200 }}
            allowClear
            onChange={e => handleSearch(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <Space>
            <SortAscendingOutlined />
            <Select
              defaultValue="name-asc"
              style={{ width: 150 }}
              onChange={handleSort}
            >
              <Select.Option value="name-asc">Name (A-Z)</Select.Option>
              <Select.Option value="name-desc">Name (Z-A)</Select.Option>
              <Select.Option value="status-asc">Status (A-Z)</Select.Option>
              <Select.Option value="status-desc">Status (Z-A)</Select.Option>
              <Select.Option value="fleet_number-asc">Fleet # (A-Z)</Select.Option>
              <Select.Option value="fleet_number-desc">Fleet # (Z-A)</Select.Option>
            </Select>
          </Space>
        </div>
        
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          {allStatuses.map(status => renderStatusToggle(status))}
        </div>
      </div>

      <Card styles={{ body: { padding: '12px' } }}>
        {loading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin />
          </div>
        ) : filteredDrivers.length === 0 ? (
          <Empty description="No drivers found" />
        ) : (
          <div className="driver-grid">
            {filteredDrivers.map((driver) => (
              <DriverCard
                key={driver.id}
                driver={driver}
                onClick={() => setSelectedDriver(driver)}
              />
            ))}
          </div>
        )}
      </Card>

      {selectedDriver && (
        <DriverModal
          driver={selectedDriver}
          visible={!!selectedDriver}
          onClose={() => setSelectedDriver(null)}
        />
      )}
    </div>
  );
};