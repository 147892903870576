import React, { useState, useEffect, useMemo } from 'react';
import { Card, List, Tag, Space, Typography, Spin, Tooltip, message, Select, Radio, Empty } from 'antd';
import { 
  CarOutlined, 
  ClockCircleOutlined, 
  EnvironmentOutlined,
  UserOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  WarningOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  SortAscendingOutlined
} from '@ant-design/icons';
import { supabase } from '../../../lib/supabaseClient';
import { formatTime } from '../utils/formatters';
import type { Delivery } from '../types';
import { STATUS_COLORS, DeliveryStatusType } from '../utils/constants';
import dayjs from 'dayjs';
import './DeliveryPanel.css';

const { Text, Paragraph } = Typography;
const { Option } = Select;

type SortOption = 'time' | 'progress' | 'quantity';

export const DeliveryPanel: React.FC = () => {
  const [deliveries, setDeliveries] = useState<Delivery[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<SortOption>('time');

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        setLoading(true);
        
        // Check for active session
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          message.error('Authentication error. Please log in again.');
          return;
        }

        const today = dayjs();

        // Fetch jobs for today
        const { data: jobsData, error: jobsError } = await supabase
          .from('jobs')
          .select(`
            *,
            customers:customer_id (id, customer_name)
          `)
          .eq('delivery_date', today.format('YYYY-MM-DD'))
          .order('delivery_time', { ascending: true });

        if (jobsError) {
          if (jobsError.code === '401') {
            message.error('Session expired. Please log in again.');
            return;
          }
          throw jobsError;
        }

        // Format the jobs data with customer names
        const formattedDeliveries: Delivery[] = jobsData.map((job: any) => ({
          id: job.id,
          scheduled_time: job.delivery_time || '',
          location_name: job.delivery_address || '',
          tonnes_requested: parseFloat(job.quantity) || 0,
          status: (job.status || 'entered').toLowerCase() as DeliveryStatusType,
          customer_name: job.customers?.customer_name || job.customer_name || '',
          job_number: job.job_number || '',
          suburb: job.suburb || '',
          site_contact_name: job.site_contact_name || '',
          contact_number: job.contact_number || '',
          notes: job.notes || '',
          product: job.product || '',
          uhf_channel: job.uhf_channel || '',
          trucks_allocated: job.trucks_allocated || [],
          progressive: job.progressive || '',
          truck_registration: job.truck_registration || '',
          delivery_date: job.delivery_date || '',
          quantity: parseFloat(job.quantity) || 0,
          truck_id: job.truck_id || '',
          driver_id: job.driver_id || ''
        }));
        
        setDeliveries(formattedDeliveries);
      } catch (error) {
        console.error('Error fetching deliveries:', error);
        message.error('Failed to load deliveries');
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveries();
    const interval = setInterval(fetchDeliveries, 60000);
    return () => clearInterval(interval);
  }, []);

  const sortedDeliveries = useMemo(() => {
    const sorted = [...deliveries];
    switch (sortBy) {
      case 'time':
        return sorted.sort((a, b) => (a.scheduled_time || '').localeCompare(b.scheduled_time || ''));
      case 'progress':
        // Sort by status priority: started -> entered -> completed -> cancelled
        const statusPriority: { [key in DeliveryStatusType]: number } = {
          'started': 0,
          'active': 0,
          'entered': 1,
          'completed': 2,
          'cancelled': 3,
          'on-hold': 4,
          'moved': 5
        };
        return sorted.sort((a, b) => 
          (statusPriority[a.status] || 99) - (statusPriority[b.status] || 99)
        );
      case 'quantity':
        return sorted.sort((a, b) => (b.tonnes_requested || 0) - (a.tonnes_requested || 0));
      default:
        return sorted;
    }
  }, [deliveries, sortBy]);

  const getDeliveryStatus = (delivery: Delivery) => {
    const status = delivery.status || 'entered';
    
    switch (status) {
      case 'completed':
        return { 
          text: 'Completed', 
          color: STATUS_COLORS.completed,
          icon: <CheckCircleOutlined />
        };
      case 'started':
      case 'active':
        return { 
          text: 'In Progress', 
          color: STATUS_COLORS.active,
          icon: <SyncOutlined spin />
        };
      case 'on-hold':
        return {
          text: 'On Hold',
          color: STATUS_COLORS['on-hold'],
          icon: <ClockCircleOutlined />
        };
      case 'cancelled':
        return {
          text: 'Cancelled',
          color: STATUS_COLORS.cancelled,
          icon: <WarningOutlined />
        };
      case 'moved':
        return {
          text: 'Moved',
          color: STATUS_COLORS.moved,
          icon: <InfoCircleOutlined />
        };
      case 'entered':
      default:
        return { 
          text: 'Entered', 
          color: STATUS_COLORS.entered,
          icon: <LoadingOutlined />
        };
    }
  };

  const formatDeliveryTime = (time: string): string => {
    if (!time) return 'No Time';
    
    const timeStr = time.toLowerCase();
    if (timeStr.includes('first')) return 'First Round';
    if (timeStr.includes('second')) return 'Second Round';
    if (timeStr.includes('am')) return 'This Morning';
    if (timeStr.includes('pm')) return 'This Afternoon';
    
    // For specific time slots, keep the original time
    // This handles custom times like "10:30" or other specific slots
    return time;
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: '16px', display: 'flex', gap: '8px' }}>
        <Radio.Group 
          value={sortBy} 
          onChange={e => setSortBy(e.target.value)}
          size="small"
        >
          <Radio.Button value="time">Time</Radio.Button>
          <Radio.Button value="progress">Progress</Radio.Button>
          <Radio.Button value="quantity">Quantity</Radio.Button>
        </Radio.Group>
      </div>

      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin />
        </div>
      ) : sortedDeliveries.length === 0 ? (
        <Empty description="No deliveries scheduled for today" />
      ) : (
        <div style={{ 
          flexGrow: 1, 
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}>
          {sortedDeliveries.map((delivery) => (
            <List.Item 
              key={delivery.id} 
              style={{ 
                padding: '12px 16px', 
                borderBottom: '1px solid #f0f0f0',
                backgroundColor: '#ffffff',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                overflow: 'hidden',
                borderLeft: `4px solid ${getDeliveryStatus(delivery).color}`,
                background: '#ffffff',
                margin: '0',
                boxShadow: 'none'
              }}
              className="delivery-item"
              onMouseEnter={(e) => {
                const target = e.currentTarget;
                target.style.transform = 'translateX(4px)';
                target.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
              }}
              onMouseLeave={(e) => {
                const target = e.currentTarget;
                target.style.transform = 'translateX(0)';
                target.style.boxShadow = '0 1px 2px rgba(0,0,0,0.05)';
              }}
            >
              <div style={{ width: '100%' }}>
                {/* Customer Info and Job Number */}
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  marginBottom: '2px'
                }}>
                  <Space size="small" align="center">
                    <UserOutlined style={{ color: '#1890ff', fontSize: '16px' }} />
                    <Text strong style={{ fontSize: '16px' }}>{delivery.customer_name}</Text>
                    <Text type="secondary" style={{ fontSize: '14px' }}>#{delivery.job_number}</Text>
                  </Space>
                  <Tooltip title="Requested Quantity">
                    <Tag 
                      color="blue" 
                      style={{ 
                        padding: '4px 8px',
                        fontSize: '14px',
                        fontWeight: 500
                      }}
                    >
                      {delivery.tonnes_requested.toFixed(1)}t
                    </Tag>
                  </Tooltip>
                </div>

                {/* Time and Status */}
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  marginBottom: '4px'
                }}>
                  <Text style={{ fontSize: '13px', color: '#666' }}>
                    <ClockCircleOutlined style={{ marginRight: '4px', color: '#1890ff' }} />
                    Delivery scheduled for {formatDeliveryTime(delivery.scheduled_time)}
                  </Text>
                  <Space direction="vertical" size={2} style={{ alignItems: 'flex-end' }}>
                    <Tag 
                      icon={getDeliveryStatus(delivery).icon}
                      color={getDeliveryStatus(delivery).color}
                      style={{ 
                        padding: '0 8px',
                        borderRadius: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        fontSize: '13px',
                        margin: 0
                      }}
                    >
                      {getDeliveryStatus(delivery).text}
                    </Tag>
                    {delivery.progressive && (
                      <Text style={{ fontSize: '12px', color: '#8c8c8c', lineHeight: 1 }}>
                        {parseFloat(delivery.progressive).toFixed(2)}t delivered
                      </Text>
                    )}
                  </Space>
                </div>

                {/* Location */}
                <div style={{ display: 'flex', alignItems: 'start', gap: '4px' }}>
                  <EnvironmentOutlined style={{ color: '#52c41a', marginTop: '2px', fontSize: '14px' }} />
                  <Text 
                    ellipsis
                    style={{ 
                      margin: 0,
                      fontSize: '13px',
                      color: '#595959',
                      flex: 1,
                      lineHeight: '1.3',
                      WebkitLineClamp: 2,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden'
                    }}
                  >
                    {delivery.location_name}
                    {delivery.suburb && `, ${delivery.suburb}`}
                  </Text>
                </div>
              </div>
            </List.Item>
          ))}
        </div>
      )}
    </div>
  );
};