import React from 'react';
import { Card, Row, Col, Space, Statistic, Button, Select, Tabs, Empty, Alert } from 'antd';
import { CarOutlined, ReloadOutlined, ToolOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useFleet } from '../hooks/useFleet';
import { TruckCard } from './TruckCard';
import { TruckDrawer } from './TruckDrawer';
import { TruckStatus } from '../../trucks/types';

export const FleetPanel: React.FC = () => {
  const { state, actions } = useFleet();
  const { 
    filteredTrucks, 
    selectedTruck, 
    isDrawerVisible, 
    stats, 
    loading, 
    error,
    statusFilter 
  } = state;

  if (error) {
    return (
      <Card>
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      </Card>
    );
  }

  const activeTrucks = filteredTrucks.filter(t => t.status === 'InUse');
  const maintenanceTrucks = filteredTrucks.filter(t => t.status === 'Workshop');
  const availableTrucks = filteredTrucks.filter(t => t.status === 'Available');

  const tabItems = [
    {
      key: 'active',
      label: `Active (${activeTrucks.length})`,
      children: activeTrucks.length > 0 ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '16px' }}>
          {activeTrucks.map(truck => (
            <TruckCard key={truck.id} truck={truck} onClick={() => actions.selectTruck(truck)} />
          ))}
        </div>
      ) : (
        <Empty description="No active trucks" />
      )
    },
    {
      key: 'maintenance',
      label: `Maintenance (${maintenanceTrucks.length})`,
      children: maintenanceTrucks.length > 0 ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '16px' }}>
          {maintenanceTrucks.map(truck => (
            <TruckCard key={truck.id} truck={truck} onClick={() => actions.selectTruck(truck)} />
          ))}
        </div>
      ) : (
        <Empty description="No trucks in maintenance" />
      )
    },
    {
      key: 'available',
      label: `Available (${availableTrucks.length})`,
      children: availableTrucks.length > 0 ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '16px' }}>
          {availableTrucks.map(truck => (
            <TruckCard key={truck.id} truck={truck} onClick={() => actions.selectTruck(truck)} />
          ))}
        </div>
      ) : (
        <Empty description="No available trucks" />
      )
    }
  ];

  return (
    <Card
      className="dashboard-panel"
      title={
        <Space>
          <CarOutlined />
          Fleet Overview
        </Space>
      }
      extra={
        <Space>
          <Select
            value={statusFilter}
            onChange={actions.filterTrucks}
            style={{ width: 120 }}
            placeholder="Filter Status"
            allowClear
          >
            <Select.Option value="InUse">Active</Select.Option>
            <Select.Option value="Workshop">Maintenance</Select.Option>
            <Select.Option value="Available">Available</Select.Option>
          </Select>
          <Button 
            icon={<ReloadOutlined spin={loading} />} 
            onClick={actions.fetchFleet}
            loading={loading}
          />
        </Space>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Active Fleet"
              value={stats.active}
              suffix={`/ ${stats.total}`}
              prefix={<CarOutlined />}
              valueStyle={{ color: '#52c41a' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="In Maintenance"
              value={stats.maintenance}
              prefix={<ToolOutlined />}
              valueStyle={{ color: '#faad14' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Available"
              value={stats.available}
              prefix={<CheckCircleOutlined />}
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total Tonnes Today"
              value={stats.total_tonnes}
              precision={1}
              suffix="t"
              valueStyle={{ color: '#722ed1' }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Average Tonnes per Truck"
              value={stats.efficiency}
              precision={1}
              suffix="t"
              valueStyle={{ color: '#13c2c2' }}
            />
          </Card>
        </Col>
      </Row>

      <div style={{ marginTop: '16px' }}>
        <Tabs items={tabItems} />
      </div>

      <TruckDrawer
        truck={selectedTruck}
        visible={isDrawerVisible}
        onClose={actions.closeDrawer}
      />
    </Card>
  );
};