import React, { useState, useEffect } from 'react';
import { Upload, Button, Space, Modal, Input, Row, Col, Card, Empty, Spin, App, notification } from 'antd';
import { UploadOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';

interface LogoUploaderProps {
  initialLogo: string | null;
  onLogoChange: (logoPath: string | null) => void;
  disabled?: boolean;
  businessName?: string;
}

interface LogoSearchResult {
  url: string;
  thumbnail: string;
  title: string;
  dimensions: string;
  id: string;
}

export const LogoUploader: React.FC<LogoUploaderProps> = ({ initialLogo, onLogoChange, disabled = false, businessName }) => {
  const { notification } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [logoSearchVisible, setLogoSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [logoResults, setLogoResults] = useState<LogoSearchResult[]>([]);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (initialLogo) {
      setPreviewUrl(initialLogo);
    }
  }, [initialLogo]);

  useEffect(() => {
    console.log('LogoUploader businessName:', businessName);
  }, [businessName]);

  const handleUpload = async (file: File) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('logo', file);

      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
      const response = await fetch(`${apiUrl}/upload-logo`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to upload logo');
      }

      const data = await response.json();
      if (!data.success || !data.filePath) {
        throw new Error('Invalid response from server');
      }

      onLogoChange(data.filePath);
      setPreviewUrl(data.filePath);
      notification.success({
        message: 'Logo Uploaded',
        description: 'Logo has been uploaded successfully.',
      });
    } catch (error) {
      console.error('Error uploading logo:', error);
      notification.error({
        message: 'Upload Failed',
        description: error instanceof Error ? error.message : 'Failed to upload logo',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLogoSelect = async (logo: LogoSearchResult) => {
    try {
      setLoading(true);
      
      // Use a proxy endpoint to fetch the image to avoid CORS issues
      const proxyResponse = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:5000'}/proxy-image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageUrl: logo.url }),
      });

      if (!proxyResponse.ok) {
        throw new Error(`Failed to fetch image: ${proxyResponse.status}`);
      }

      const blob = await proxyResponse.blob();
      const contentType = proxyResponse.headers.get('content-type') || '';
      
      // Validate content type
      if (!contentType.startsWith('image/')) {
        throw new Error('Invalid file type. Only images are allowed');
      }

      // Get file extension from content type
      let extension = 'jpg';
      if (contentType.includes('png')) {
        extension = 'png';
      } else if (contentType.includes('gif')) {
        extension = 'gif';
      }

      const timestamp = Date.now();
      const randomStr = Math.random().toString(36).substring(7);
      const fileName = `logo-${timestamp}-${randomStr}.${extension}`;
      
      const file = new File([blob], fileName, { type: contentType });
      await handleUpload(file);
      
      setLogoSearchVisible(false);
    } catch (error) {
      console.error('Error handling logo selection:', error);
      notification.error({
        message: 'Logo Selection Failed',
        description: error instanceof Error ? error.message : 'Failed to save logo',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLogoSearch = async () => {
    if (!businessName?.trim()) {
      notification.error({
        message: 'Missing Business Name',
        description: 'Please enter a business name to search for logos.',
      });
      return;
    }

    setLoading(true);
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const searchEngineId = process.env.REACT_APP_GOOGLE_CSE_ID;
      
      if (!apiKey || !searchEngineId) {
        throw new Error('Google Search API configuration is missing');
      }

      const response = await fetch(
        `https://customsearch.googleapis.com/customsearch/v1?` +
        `key=${apiKey}&` +
        `cx=${searchEngineId}&` +
        `q=${encodeURIComponent(businessName + ' logo')}&` +
        `searchType=image&` +
        `imgType=clipart&` +
        `safe=active&` +
        `num=10`
      );

      if (!response.ok) {
        throw new Error('Failed to search for logos');
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error.message);
      }

      if (data.items?.length > 0) {
        const results = data.items.map((item: any) => ({
          url: item.link,
          thumbnail: item.image.thumbnailLink,
          title: item.title,
          dimensions: `${item.image.width}x${item.image.height}`,
        }));
        setLogoResults(results);
        setLogoSearchVisible(true);
      } else {
        notification.info({
          message: 'No Results',
          description: 'No logo images found for this business name.',
        });
      }
    } catch (error) {
      console.error('Error searching logos:', error);
      notification.error({
        message: 'Logo Search Failed',
        description: error instanceof Error ? error.message : 'Failed to search for logos',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload: UploadProps['beforeUpload'] = async (file) => {
    try {
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      await handleUpload(file);
      return false; // Prevent default upload
    } catch (error) {
      console.error('Error handling file upload:', error);
      return false;
    }
  };

  const handleDeleteLogo = async () => {
    try {
      setLoading(true);

      if (initialLogo) {
        // Send the full path for the backend to handle
        const response = await fetch(`${process.env.REACT_APP_API_URL || 'http://localhost:5000'}/delete-logo`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ logoPath: initialLogo }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to delete logo file');
        }

        const result = await response.json();
        console.log('Logo deletion result:', result);
      }

      // Clear the logo state and update UI
      setPreviewUrl(null);
      onLogoChange(null);
      notification.success({
        message: 'Logo Removed',
        description: 'Logo has been removed successfully.',
      });
    } catch (error: any) {
      console.error('Error deleting logo:', error);
      notification.error({
        message: 'Delete Failed',
        description: error.message || 'Failed to delete logo',
      });
    } finally {
      setLoading(false);
    }
  };

  const renderLogoPreview = () => {
    if (loading) {
      return <Spin />;
    }

    if (previewUrl) {
      return (
        <div style={{ 
          marginBottom: 16,
          width: '100px',
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
          overflow: 'hidden'
        }}>
          <img
            src={previewUrl.startsWith('http') ? previewUrl : `${process.env.REACT_APP_API_URL || 'http://localhost:5000'}${previewUrl}`}
            alt="Logo preview"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          />
        </div>
      );
    }

    return null;
  };

  const renderLogoResults = () => {
    if (loading) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin />
          <div style={{ marginTop: 8 }}>Searching for logos...</div>
        </div>
      );
    }

    if (logoResults.length === 0) {
      return <Empty description="No logos found" />;
    }

    return (
      <Row gutter={[16, 16]}>
        {logoResults.map((logo) => (
          <Col key={logo.id} span={8}>
            <div
              style={{
                cursor: 'pointer',
                padding: '8px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'all 0.3s'
              }}
              onClick={() => handleLogoSelect(logo)}
            >
              <img 
                src={logo.thumbnail}
                alt={logo.title}
                style={{ maxWidth: '100%', maxHeight: '120px', objectFit: 'contain' }}
              />
              <div style={{ marginTop: '8px', fontSize: '12px', color: '#666' }}>
                {logo.dimensions}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <App>
      <div className="logo-uploader">
        {renderLogoPreview()}
        <Space size="middle">
          <Upload
            accept="image/*"
            showUploadList={false}
            beforeUpload={handleFileUpload}
            disabled={disabled || loading}
          >
            <Button 
              type="primary"
              icon={<UploadOutlined />}
              loading={loading} 
              disabled={disabled}
              style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
            >
              Upload Logo
            </Button>
          </Upload>
          <Button 
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              setLogoSearchVisible(true);
              handleLogoSearch();
            }}
            loading={loading}
            disabled={disabled}
            style={{ backgroundColor: '#1890ff', borderColor: '#1890ff' }}
          >
            Search Logo
          </Button>
          {previewUrl && (
            <Button 
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={handleDeleteLogo}
              disabled={disabled || loading}
            >
              Remove Logo
            </Button>
          )}
        </Space>

        <Modal
          title="Logo Search Results"
          open={logoSearchVisible}
          onCancel={() => setLogoSearchVisible(false)}
          footer={null}
          width={800}
        >
          {renderLogoResults()}
        </Modal>
      </div>
    </App>
  );
};
