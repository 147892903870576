import dayjs from 'dayjs';

export const formatDate = (date: string | null): string => {
  if (!date) return '';
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatTime = (time: string | null): string => {
  if (!time) return '';
  return dayjs(time, 'HH:mm').format('HH:mm');
};

export const formatNumber = (num: number | null, precision: number = 1): string => {
  if (num === null) return '0';
  return num.toFixed(precision);
};

export const formatTonnes = (tonnes: number | null): string => {
  return `${formatNumber(tonnes)}t`;
};