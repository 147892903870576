import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Table, Space, Input, Select, Tag, Tooltip, Modal, Card, Statistic, App, Typography, notification, Row, Col, Spin, Avatar } from 'antd';
import { 
  DeleteOutlined, 
  EditOutlined, 
  EyeOutlined, 
  UserOutlined, 
  MailOutlined, 
  PhoneOutlined, 
  EnvironmentOutlined, 
  GlobalOutlined,
  PlusOutlined,
  SearchOutlined,
  ShopOutlined,
  SortAscendingOutlined
} from '@ant-design/icons';
import type { SortOrder } from 'antd/es/table/interface';
import { supabase } from '../../lib/supabaseClient';
import CustomerDetails from './components/CustomerDetails';
import { CustomerForm } from './components/CustomerForm';
import { Customer, CustomerInput, CustomerStatistic, Job, CustomerContact } from './types';
import './Customers.css';

const { Option } = Select;

const Customers: React.FC = () => {
  const { notification } = App.useApp();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [sortField, setSortField] = useState<string>('customer_name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascend');
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [customerStats, setCustomerStats] = useState<CustomerStatistic[]>([]);
  const [orders, setOrders] = useState<Job[]>([]);
  const [contacts, setContacts] = useState<CustomerContact[]>([]);
  const [editingCustomer, setEditingCustomer] = useState<Customer | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [failedLogos, setFailedLogos] = useState<{[key: string]: boolean}>({});
  const [statusChangeModal, setStatusChangeModal] = useState<{visible: boolean; record: Customer | null; newStatus: string | null}>({
    visible: false,
    record: null,
    newStatus: null
  });

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('customers')
        .select('*')
        .order('customer_name', { ascending: true, nullsFirst: false });
      if (error) throw error;
      
      // Sort the data case-sensitively in JavaScript to ensure exact case preservation
      const sortedData = (data || []).sort((a, b) => {
        const nameA = a.customer_name || '';
        const nameB = b.customer_name || '';
        return nameA.localeCompare(nameB, undefined, { sensitivity: 'case' });
      });
      
      setCustomers(sortedData);
    } catch (error) {
      console.error('Error fetching customers:', error);
      notification.error({
        message: 'Failed to fetch customers',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        },
        key: 'fetch-customers-error'
      });
    } finally {
      setLoading(false);
    }
  }, [notification]);

  const fetchCustomerStats = useCallback(() => {
    try {
      const totalCustomers = customers.length;
      // Count customers with 'Active' status
      const activeCustomers = customers.filter(c => c.status === 'Active').length;
      const newCustomersThisMonth = customers.filter(c => {
        if (!c.created_at) return false;
        const createdAt = new Date(c.created_at);
        const now = new Date();
        return createdAt.getMonth() === now.getMonth() && createdAt.getFullYear() === now.getFullYear();
      }).length;

      setCustomerStats([
        {
          title: 'Total Customers',
          value: totalCustomers,
          icon: <UserOutlined />,
          color: '#1890ff'
        },
        {
          title: 'Active Customers',
          value: activeCustomers,
          icon: <ShopOutlined />,
          color: '#52c41a'
        },
        {
          title: 'New This Month',
          value: newCustomersThisMonth,
          icon: <GlobalOutlined />,
          color: '#722ed1'
        }
      ]);
    } catch (error) {
      console.error('Error calculating customer stats:', error);
    }
  }, [customers]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    fetchCustomerStats();
  }, [customers, fetchCustomerStats]);

  const fetchCustomerOrders = useCallback(async (customerId: string) => {
    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('*')
        .eq('customer_id', customerId)
        .order('delivery_date', { ascending: false });
      if (error) throw error;
      setOrders(data || []);
    } catch (error) {
      notification.error({
        message: 'Failed to fetch customer orders',
        description: 'Failed to fetch customer orders. Please try again.',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      console.error('Error fetching customer orders:', error);
    }
  }, [notification]);

  const fetchCustomerContacts = useCallback(async (customerId: string) => {
    try {
      const { data, error } = await supabase
        .from('customer_contacts')
        .select('*')
        .eq('customer_id', customerId)
        .order('is_primary', { ascending: false });
      if (error) throw error;
      setContacts(data || []);
    } catch (error) {
      notification.error({
        message: 'Failed to fetch customer contacts',
        description: 'Failed to fetch customer contacts. Please try again.',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      console.error('Error fetching customer contacts:', error);
    }
  }, [notification]);

  const handleViewDetails = useCallback(async (customer: Customer) => {
    setSelectedCustomer(customer);
    setDetailsVisible(true);
    await Promise.all([
      fetchCustomerOrders(customer.id),
      fetchCustomerContacts(customer.id),
    ]);
  }, [fetchCustomerOrders, fetchCustomerContacts]);

  const handleEdit = useCallback((customer: Customer) => {
    setEditingCustomer(customer);
    setModalVisible(true);
  }, []);

  const handleDelete = useCallback(async (id: string) => {
    try {
      // Show confirmation dialog
      Modal.confirm({
        title: 'Delete Customer',
        content: 'Are you sure you want to delete this customer? This will also delete all associated files and data.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          setLoading(true);
          try {
            // First delete associated files from storage
            const { data: customerData } = await supabase
              .from('customers')
              .select('company_logo')
              .eq('id', id)
              .single();

            if (customerData?.company_logo) {
              const { error: storageError } = await supabase.storage
                .from('logos')
                .remove([customerData.company_logo]);
              
              if (storageError) {
                console.error('Error deleting logo:', storageError);
              }
            }

            // Delete customer contacts
            const { error: contactsError } = await supabase
              .from('customer_contacts')
              .delete()
              .eq('customer_id', id);

            if (contactsError) {
              throw contactsError;
            }

            // Delete customer site contacts
            const { error: siteContactsError } = await supabase
              .from('customer_site_contacts')
              .delete()
              .eq('customer_id', id);

            if (siteContactsError) {
              throw siteContactsError;
            }

            // Finally delete the customer
            const { error } = await supabase
              .from('customers')
              .delete()
              .eq('id', id);

            if (error) throw error;

            notification.success({
              message: 'Customer Deleted',
              description: 'Customer and all associated data have been deleted successfully.',
              placement: 'topRight',
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              },
              key: 'delete-customer-success'
            });

            // Refresh the customers list
            fetchCustomers();
          } catch (error) {
            console.error('Error deleting customer:', error);
            notification.error({
              message: 'Failed to delete customer',
              description: error instanceof Error ? error.message : 'An error occurred while deleting the customer',
              placement: 'topRight',
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              },
              key: 'delete-customer-error'
            });
          } finally {
            setLoading(false);
          }
        }
      });
    } catch (error) {
      console.error('Error in delete handler:', error);
    }
  }, [fetchCustomers]);

  const handleCustomerSubmit = async (values: CustomerInput): Promise<Customer> => {
    try {
      let result: Customer;

      // Ensure customer_name is not null
      if (!values.customer_name) {
        notification.error({
          message: 'Validation Error',
          description: 'Customer name is required',
          placement: 'topRight',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        });
        throw new Error('Customer name is required');
      }

      // Check for duplicate customer name using case-insensitive comparison
      const { data: existingCustomers } = await supabase
        .from('customers')
        .select('id, customer_name')
        .ilike('customer_name', values.customer_name);

      const duplicate = existingCustomers?.find(customer => 
        customer.customer_name?.toLowerCase() === values.customer_name?.toLowerCase() && 
        (!editingCustomer || customer.id !== editingCustomer.id)
      );

      if (duplicate) {
        notification.error({
          message: 'Duplicate Customer',
          description: `A customer with the name "${duplicate.customer_name}" already exists. Please use a different name.`,
          placement: 'topRight',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        });
        throw new Error('Duplicate customer name');
      }

      // Send exact case to database
      const customerData = {
        ...values,
        customer_name: values.customer_name.toString() // Force string to prevent any type coercion
      };

      if (editingCustomer?.id) {
        const { data, error } = await supabase
          .from('customers')
          .update(customerData)
          .eq('id', editingCustomer.id)
          .select('*')
          .single();

        if (error) throw error;
        result = data;
      } else {
        const { data, error } = await supabase
          .from('customers')
          .insert([customerData])
          .select('*')
          .single();

        if (error) throw error;
        result = data;
      }

      notification.success({
        message: editingCustomer ? 'Customer Updated' : 'Customer Added',
        description: editingCustomer ? 'Customer has been updated successfully.' : 'New customer has been added successfully.',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });

      // Refresh the customer list
      fetchCustomers();
      
      return result;
    } catch (error) {
      console.error('Error saving customer:', error);
      if (error instanceof Error && error.message === 'Duplicate customer name') {
        throw error;
      }
      notification.error({
        message: 'Error',
        description: 'Failed to save customer. Please try again.',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      throw error;
    }
  };

  const handleModalClose = () => {
    setEditingCustomer(null);
    setModalVisible(false);
  };

  const handleSuccess = () => {
    handleModalClose();
    fetchCustomers();
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleStatusFilter = (status: string | null) => {
    setSelectedStatus(selectedStatus === status ? null : status);
  };

  const getLogoUrl = (logoPath: string): string => {
    if (!logoPath) return '';
    if (logoPath.startsWith('blob:') || logoPath.startsWith('http')) {
      return logoPath;
    }
    return `${process.env.REACT_APP_API_URL || 'http://localhost:5000'}${logoPath}`;
  };

  // Common English words that might appear in company names
  const commonWords = new Set([
    'limited', 'pty', 'ltd', 'contractors', 'construction', 'constructions', 'group',
    'holdings', 'australia', 'australian', 'engineering', 'services', 'solutions',
    'industries', 'international', 'company', 'companies', 'corporation', 'corp',
    'incorporated', 'inc', 'and', 'the', 'of', 'trading', 'enterprises', 'global'
  ]);

  // Helper function to format company names - uppercase any non-English words
  const formatCompanyName = (name: string): string => {
    return name.split(' ').map(word => {
      // Clean the word for checking (remove punctuation, make lowercase)
      const cleanWord = word.toLowerCase().replace(/[.,()]/g, '');
      // If it's not a common English word, make it uppercase
      if (!commonWords.has(cleanWord)) {
        return word.toUpperCase();
      }
      return word;
    }).join(' ');
  };

  const filteredCustomers = useMemo(() => {
    let filtered = [...customers];

    // Apply status filter
    if (selectedStatus) {
      filtered = filtered.filter(customer => customer.status === selectedStatus);
    }

    // Apply search filter
    if (searchText) {
      const searchLower = searchText.toLowerCase();
      filtered = filtered.filter(customer =>
        customer.customer_name?.toLowerCase().includes(searchLower) ||
        customer.email?.toLowerCase().includes(searchLower) ||
        customer.contact_number?.toLowerCase().includes(searchLower)
      );
    }

    // Apply sorting
    return filtered.sort((a, b) => {
      const aValue = (a[sortField as keyof Customer] || '').toString();
      const bValue = (b[sortField as keyof Customer] || '').toString();
      // Use case-sensitive comparison for customer_name
      if (sortField === 'customer_name') {
        return sortOrder === 'ascend' ? aValue.localeCompare(bValue, undefined, { sensitivity: 'case' }) : bValue.localeCompare(aValue, undefined, { sensitivity: 'case' });
      }
      // Use case-insensitive comparison for other fields
      return sortOrder === 'ascend' 
        ? aValue.toLowerCase().localeCompare(bValue.toLowerCase()) 
        : bValue.toLowerCase().localeCompare(aValue.toLowerCase());
    });
  }, [customers, searchText, selectedStatus, sortField, sortOrder]);

  const handleStatusChange = async (record: Customer, newStatus: string) => {
    if (record.status === 'Stop Credit' && newStatus !== 'Stop Credit') {
      Modal.confirm({
        title: "Warning",
        content: "You are about to remove the Stop Credit status from this customer. This will allow jobs to be created for them again. Are you sure you want to proceed?",
        okText: "Yes, Change Status",
        cancelText: "Cancel",
        onOk: async () => {
          await updateCustomerStatus(record, newStatus);
        }
      });
      return;
    }

    await updateCustomerStatus(record, newStatus);
  };

  const updateCustomerStatus = async (record: Customer, newStatus: string) => {
    try {
      const { error } = await supabase
        .from('customers')
        .update({ status: newStatus })
        .eq('id', record.id);

      if (error) throw error;

      notification.success({
        message: 'Status Updated',
        description: `Customer status has been updated to ${newStatus}`,
        placement: 'topRight'
      });

      fetchCustomers();
    } catch (error) {
      console.error('Error updating status:', error);
      notification.error({
        message: 'Failed to update status',
        description: error instanceof Error ? error.message : 'An error occurred',
        placement: 'topRight'
      });
    }
  };

  const handleStatusConfirm = async () => {
    if (statusChangeModal.record && statusChangeModal.newStatus) {
      await updateCustomerStatus(statusChangeModal.record, statusChangeModal.newStatus);
    }
    setStatusChangeModal({ visible: false, record: null, newStatus: null });
  };

  const columns = useMemo(
    () => [
      {
        title: 'Business Name',
        dataIndex: 'customer_name',
        key: 'customer_name',
        render: (text: string, record: Customer) => (
          <Space>
            {record.company_logo ? (
              <Avatar
                size="small"
                src={getLogoUrl(record.company_logo)}
                style={{ marginRight: 8 }}
              />
            ) : (
              <Avatar
                size="small"
                style={{ backgroundColor: '#f56a00', marginRight: 8 }}
              >
                {text?.charAt(0)}
              </Avatar>
            )}
            <Tooltip title={text}>
              <Typography.Text ellipsis style={{ maxWidth: 200 }}>
                {formatCompanyName(text)}
              </Typography.Text>
            </Tooltip>
          </Space>
        ),
        sorter: (a: Customer, b: Customer) => {
          const nameA = (a.customer_name || '').toString();
          const nameB = (b.customer_name || '').toString();
          return nameA.localeCompare(nameB, undefined, { sensitivity: 'case' });
        },
        sortDirections: ['ascend', 'descend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
      },
      {
        title: 'Account Type',
        dataIndex: 'account_type',
        key: 'account_type',
        render: (text: string) => (
          <Tag color="blue">{text}</Tag>
        ),
      },
      {
        title: 'ABN',
        dataIndex: 'abn',
        key: 'abn',
      },
      {
        title: 'Contact',
        key: 'contact',
        render: (text: string, record: Customer) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {record.email && (
              <span style={{ fontSize: '13px', color: '#595959' }}>
                <MailOutlined style={{ marginRight: 8 }} />
                {record.email}
              </span>
            )}
            {record.primary_contact_phone && (
              <span style={{ fontSize: '13px', color: '#595959' }}>
                <PhoneOutlined style={{ marginRight: 8 }} />
                {record.primary_contact_phone}
              </span>
            )}
          </div>
        ),
        width: '25%',
      },
      {
        title: 'Location',
        key: 'location',
        render: (text: string, record: Customer) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {record.address && (
              <Tooltip title={record.address}>
                <span style={{ fontSize: '13px', color: '#595959' }}>
                  <EnvironmentOutlined style={{ marginRight: 8 }} />
                  {record.suburb ? `${record.suburb}, ${record.state}` : record.state || 'No address'}
                </span>
              </Tooltip>
            )}
            {record.website && (
              <span style={{ fontSize: '13px', color: '#595959' }}>
                <GlobalOutlined style={{ marginRight: 8 }} />
                <Typography.Link href={record.website.startsWith('http') ? record.website : `https://${record.website}`} 
                   target="_blank" 
                   rel="noopener noreferrer">
                  {record.website.replace(/^https?:\/\//, '').split('/')[0]}
                </Typography.Link>
              </span>
            )}
          </div>
        ),
        width: '25%',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (type: string) => {
          const color = type === 'Xbin' ? 'blue' : type === 'Supplier' ? 'orange' : 'default';
          return <Tag color={color}>{type || 'Regular'}</Tag>;
        },
        filters: [
          { text: 'Regular', value: 'Regular' },
          { text: 'Xbin', value: 'Xbin' },
          { text: 'Supplier', value: 'Supplier' }
        ],
        onFilter: (value: any, record: Customer) => record.type === value
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (status: string) => {
          const color = status === 'Active' ? 'green' 
            : status === 'Inactive' ? 'red' 
            : status === 'Stop Credit' ? 'volcano'
            : 'orange';
          return <Tag color={color}>{status}</Tag>;
        },
        filters: [
          { text: 'Stop Credit', value: 'Stop Credit' }
        ],
        onFilter: (value: any, record: Customer) => record.status === value
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text: string, record: Customer) => (
          <Space size="middle">
            <Tooltip title="View Details">
              <Button
                type="primary"
                style={{ backgroundColor: '#52c41a' }}
                icon={<EyeOutlined />}
                onClick={() => handleViewDetails(record)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <Button
                type="primary"
                style={{ backgroundColor: '#1890ff' }}
                icon={<EditOutlined />}
                onClick={() => handleEdit(record)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(record.id)}
              />
            </Tooltip>
          </Space>
        ),
        width: '120px',
      },
    ],
    []
  );

  return (
    <div className="customers-container">
      <Spin spinning={loading} tip="Loading customers..." className="customers-spinner">
        <Space direction="vertical" className="customers-space" size="middle">
          {/* Statistics Cards */}
          <div className="section-container">
            <Row gutter={[16, 16]}>
              {customerStats.map((stat) => (
                <Col key={`stat-${stat.title.toLowerCase()}`} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable 
                    className={`stat-card ${selectedStatus === stat.title.toLowerCase() ? 'selected' : ''}`}
                    onClick={() => setSelectedStatus(selectedStatus === stat.title.toLowerCase() ? null : stat.title.toLowerCase())}
                    style={{ minWidth: '200px' }}
                  >
                    <Statistic
                      title={stat.title}
                      value={stat.value}
                      prefix={stat.icon}
                      valueStyle={{ color: stat.color }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          {/* Filter Controls */}
          <div className="section-container">
            <div className="filter-controls">
              <Row gutter={16} align="middle">
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={4} className="search-container">
                    <span className="control-label">Search customers</span>
                    <Input
                      placeholder="Search by name, email, or phone..."
                      prefix={<SearchOutlined />}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="search-input"
                      allowClear
                    />
                  </Space>
                </Col>
                <Col xs={24} md={12}>
                  <Space size={16} align="end" className="control-buttons">
                    <Space direction="vertical" size={4}>
                      <span className="control-label">Sort by</span>
                      <Select
                        className="sort-select"
                        value={sortField}
                        onChange={(value) => setSortField(value)}
                        style={{ minWidth: 120 }}
                      >
                        <Select.Option value="customer_name">Name</Select.Option>
                        <Select.Option value="customer_number">Customer Number</Select.Option>
                        <Select.Option value="email">Email</Select.Option>
                        <Select.Option value="primary_contact_phone">Phone</Select.Option>
                        <Select.Option value="suburb">Location</Select.Option>
                        <Select.Option value="website">Website</Select.Option>
                        <Select.Option value="type">Type</Select.Option>
                        <Select.Option value="status">Status</Select.Option>
                      </Select>
                    </Space>
                    <Space direction="vertical" size={4}>
                      <span className="control-label">&nbsp;</span>
                      <Button
                        icon={<SortAscendingOutlined />}
                        onClick={() => setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend')}
                      >
                        {sortOrder === 'ascend' ? 'Ascending' : 'Descending'}
                      </Button>
                    </Space>
                    <Space direction="vertical" size={4}>
                      <span className="control-label">&nbsp;</span>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setModalVisible(true)}
                        className="add-button"
                      >
                        Add Customer
                      </Button>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </div>
          </div>

          {/* Customer Table */}
          <div className="section-container">
            <Table
              dataSource={filteredCustomers}
              columns={columns}
              rowKey="id"
              loading={loading}
              onChange={(pagination, filters, sorter) => {
                if (Array.isArray(sorter)) return;
                const { field, order } = sorter as any;
                setSortField(field as string);
                setSortOrder(order as SortOrder);
              }}
              pagination={{
                total: filteredCustomers.length,
                defaultPageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} customers`,
                pageSizeOptions: ['10', '20', '50', '100'],
                style: { marginTop: '16px' }
              }}
              style={{ width: '100%' }}
              className="customers-table"
            />
          </div>

          {/* Modals */}
          <Modal
            title={editingCustomer ? 'Edit Customer' : 'New Customer'}
            open={modalVisible}
            onCancel={() => {
              setModalVisible(false);
              setEditingCustomer(null);
            }}
            footer={null}
            width={800}
            destroyOnClose={true}
          >
            <CustomerForm
              initialValues={editingCustomer || undefined}
              onSubmit={handleCustomerSubmit}
              onCancel={() => {
                setModalVisible(false);
                setEditingCustomer(null);
              }}
              onSuccess={() => {
                setModalVisible(false);
                setEditingCustomer(null);
              }}
            />
          </Modal>

          <Modal
            title="Warning"
            open={statusChangeModal.visible}
            onOk={handleStatusConfirm}
            onCancel={() => setStatusChangeModal({ visible: false, record: null, newStatus: null })}
            okText="Yes, Change Status"
            cancelText="Cancel"
          >
            <p>
              You are about to remove the Stop Credit status from this customer. This will allow jobs to be created for them again.
              Are you sure you want to proceed?
            </p>
          </Modal>

          {selectedCustomer && (
            <CustomerDetails
              customer={selectedCustomer}
              open={detailsVisible}
              onClose={() => setDetailsVisible(false)}
              onEdit={(customer) => {
                setEditingCustomer(customer);
                setDetailsVisible(false);
                setModalVisible(true);
              }}
              onAddContact={() => {
                // Handle adding new contact
                notification.info({
                  message: 'Coming Soon',
                  description: 'This feature is not yet implemented.',
                });
              }}
              onEditContact={(contact) => {
                // Handle editing contact
                notification.info({
                  message: 'Coming Soon',
                  description: 'This feature is not yet implemented.',
                });
              }}
              onDeleteContact={(contactId) => {
                // Handle deleting contact
                notification.info({
                  message: 'Coming Soon',
                  description: 'This feature is not yet implemented.',
                });
              }}
            />
          )}
        </Space>
      </Spin>
    </div>
  );
};

export default Customers;
