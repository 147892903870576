import React, { useEffect, useState } from 'react';
import { Tabs, Button, Typography, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DriverDetails } from './DriverDetails';
import AssignmentHistory from '../../trucks/components/AssignmentHistory';
import { useDrivers } from '../hooks/useDrivers';
import type { Driver } from '../types';
import { Modal } from '../../../components/common/Modal';
import './DriverDetailsModal.css';

const { Title } = Typography;

interface DriverDetailsModalProps {
    /** Whether the modal is visible */
    visible: boolean;
    /** ID of the driver to display details for */
    driverId: string | null;
    /** Callback when modal is closed */
    onClose: () => void;
    /** Callback when edit button is clicked */
    onEdit: (driver: Driver) => void;
}

/**
 * Modal component that displays detailed information about a driver
 * Including their personal details and assignment history
 */
export const DriverDetailsModal: React.FC<DriverDetailsModalProps> = ({
    visible,
    driverId,
    onClose,
    onEdit,
}) => {
    const [activeTab, setActiveTab] = useState('1');
    const { getDriver } = useDrivers();
    const [driver, setDriver] = useState<Driver | null>(null);

    useEffect(() => {
        let mounted = true;

        const loadDriver = async () => {
            if (!driverId || !visible) {
                setDriver(null);
                return;
            }

            try {
                const { data } = await getDriver(driverId);
                if (mounted && data) {
                    setDriver(data);
                }
            } catch (error) {
                console.error('Error loading driver:', error);
                message.error('Failed to load driver details');
            }
        };

        loadDriver();

        return () => {
            mounted = false;
        };
    }, [driverId, visible, getDriver]);

    const handleEdit = () => {
        if (driver) {
            onEdit(driver);
        }
    };

    if (!visible) return null;

    const LoadingState = () => (
        <div className="loading-state">
            Loading...
        </div>
    );

    const items = [
        {
            key: '1',
            label: 'Driver Details',
            children: driver ? <DriverDetails driver={driver} /> : <LoadingState />,
        },
        {
            key: '2',
            label: 'Assignment History',
            children: driver ? <AssignmentHistory driverId={driver.id} /> : <LoadingState />,
        },
    ];

    return (
        <Modal
            title={
                <Title level={4} className="modal-title">
                    Driver Details
                </Title>
            }
            open={visible}
            onCancel={onClose}
            destroyOnClose
            className="driver-details-modal"
            maskClosable={false}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>,
                <Button
                    key="edit"
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={handleEdit}
                    disabled={!driver}
                >
                    Edit Driver
                </Button>
            ]}
        >
            <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} />
        </Modal>
    );
};
