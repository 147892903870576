import React from 'react';
import { Form, Input, Typography, Alert } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Driver } from '../types';
import { Modal } from '../../../components/common/Modal';

const { Text } = Typography;

interface DeleteDriverModalProps {
    visible: boolean;
    driver: Driver | null;
    onConfirm: () => void;
    onCancel: () => void;
}

export const DeleteDriverModal: React.FC<DeleteDriverModalProps> = ({
    visible,
    driver,
    onConfirm,
    onCancel,
}) => {
    const [confirmDisabled, setConfirmDisabled] = React.useState(true);
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!visible) {
            setConfirmDisabled(true);
        }
    }, [visible]);

    const handleNameChange = () => {
        const value = form.getFieldValue('confirmName');
        setConfirmDisabled(value !== driver?.name);
    };

    const handleOk = () => {
        if (!confirmDisabled) {
            onConfirm();
            form.resetFields();
        }
    };

    if (!visible) return null;

    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: '24px' }} />
                    <span>Archive Driver</span>
                </div>
            }
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
            okText="Archive"
            cancelText="Cancel"
            okButtonProps={{
                danger: true,
                disabled: confirmDisabled,
            }}
            centered
            destroyOnClose
        >
            <div style={{ marginBottom: 24 }}>
                <Alert
                    message="Warning: This action cannot be undone"
                    description={
                        <>
                            <p>Archiving this driver will:</p>
                            <ul>
                                <li>Hide them from the active drivers list</li>
                                <li>Maintain all historical records and assignments</li>
                                <li>Prevent new assignments or modifications</li>
                            </ul>
                            <p>The driver will be marked as inactive but their data will be preserved.</p>
                        </>
                    }
                    type="warning"
                    showIcon
                    style={{ marginBottom: 24 }}
                />
                
                <Text>
                    To confirm archiving driver <Text strong>{driver?.name}</Text>, please type their full name below:
                </Text>
                
                <Form 
                    form={form} 
                    preserve={false}
                    onValuesChange={() => handleNameChange()}
                >
                    <Form.Item
                        name="confirmName"
                        required
                        style={{ marginTop: '16px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please type the driver\'s name to confirm archiving'
                            }
                        ]}
                    >
                        <Input 
                            placeholder={driver?.name}
                            autoComplete="off"
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
