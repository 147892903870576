import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Form, Button, Space, Row, Col, Card, Input, Select, Spin, Typography, DatePicker, App, Modal, notification } from 'antd';
import { 
  PlusOutlined, 
  CalendarOutlined,
  SortAscendingOutlined,
  CheckCircleOutlined,
  FileAddOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  StopOutlined,
  SwapOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import dayjs from 'dayjs';

import JobForm from './components/JobForm/JobForm';
import { JobTable } from './components/JobTable';
import JobDetailsDrawer from '../dockets/components/JobDetailsDrawer';
import { useJobs } from './hooks/useJobs';
import { useTrucks } from './hooks/useTrucks';
import { useLocations } from './hooks/useLocations';
import { useCustomers } from './hooks/useCustomers';
import { useProducts } from './hooks/useProducts';
import { Job, JobStatus } from './types';
import { useAuth } from '../../contexts/AuthContext';
import { DocketProvider } from '../dockets/context/DocketContext';
import './DailyPlanner.css';

const { Title } = Typography;
const { Search } = Input;

interface JobStatistic {
  title: string;
  status: JobStatus;
  icon: React.ReactNode;
  color: string;
}

const JOB_STATISTICS: JobStatistic[] = [
  { title: 'Entered', status: 'entered', icon: <FileAddOutlined style={{ fontSize: '24px' }} />, color: '#d9d9d9' },
  { title: 'Started', status: 'started', icon: <PlayCircleOutlined style={{ fontSize: '24px' }} />, color: '#1677ff' },
  { title: 'Completed', status: 'completed', icon: <CheckCircleOutlined style={{ fontSize: '24px' }} />, color: '#52c41a' },
  { title: 'On Hold', status: 'on-hold', icon: <PauseCircleOutlined style={{ fontSize: '24px' }} />, color: '#faad14' },
  { title: 'Cancelled', status: 'cancelled', icon: <StopOutlined style={{ fontSize: '24px' }} />, color: '#ff4d4f' },
  { title: 'Moved', status: 'moved', icon: <SwapOutlined style={{ fontSize: '24px' }} />, color: '#13c2c2' }
];

const SORT_OPTIONS = [
  { value: 'job_number', label: 'Job Number' },
  { value: 'customer_name', label: 'Customer' },
  { value: 'delivery_date', label: 'Delivery Date' },
  { value: 'status', label: 'Status' }
];

type TruckType = 'Truck and Dog' | 'Body Truck' | 'Semi Tipper' | '8 Wheeler' | '10 Wheeler';

const TRUCK_CAPACITIES: Record<TruckType, number> = {
  'Truck and Dog': 38,
  'Body Truck': 12,
  'Semi Tipper': 28,
  '8 Wheeler': 27,
  '10 Wheeler': 20,
} as const;

export const DailyPlanner: React.FC = () => {
  const { message: messageApi, modal, notification } = App.useApp();
  const [modalVisible, setModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [editingJob, setEditingJob] = useState<Job | null>(null);
  const [nextJobNumber, setNextJobNumber] = useState<number>(1);
  const [filterDate, setFilterDate] = useState(dayjs());
  const [searchText, setSearchText] = useState('');
  const [sortField, setSortField] = useState<keyof Job>('job_number');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedStatus, setSelectedStatus] = useState<JobStatus | null>(null);
  const [truckTypeModalVisible, setTruckTypeModalVisible] = useState(false);
  const [selectedTruckType, setSelectedTruckType] = useState<string | null>(null);

  const { user, isAuthenticated } = useAuth();
  const { selectedLocationId, locations, setSelectedLocationId, loading: locationLoading } = useLocations(user?.id);

  const { jobs, loading, todayJobsCount, tomorrowJobsCount, fetchJobs } = useJobs(selectedLocationId);
  const { trucks, fetchTrucks, getTruckColor, handleTruckSelection } = useTrucks(fetchJobs, selectedLocationId);
  const { customers } = useCustomers();
  const { products } = useProducts();
  const supabaseClient = useSupabaseClient();

  // Check authentication status
  useEffect(() => {
    if (!isAuthenticated) {
      notification.error({
        message: 'Authentication Required',
        description: 'Please sign in to access the Daily Planner.',
        placement: 'topRight',
        key: 'auth-error',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      return;
    }
  }, [isAuthenticated, user?.id, selectedLocationId, locations]);

  const fetchNextJobNumber = useCallback(async () => {
    try {
      const { data, error } = await supabaseClient
        .from('jobs')
        .select('job_number')
        .order('job_number', { ascending: false })
        .limit(1);

      if (error) throw error;

      const nextNumber = data && data.length > 0 ? data[0].job_number + 1 : 1;
      setNextJobNumber(nextNumber);
    } catch (error) {
      notification.error({
        message: 'Fetch Error',
        description: 'Error fetching next job number. Please try again.',
        placement: 'topRight',
        key: 'job-number-error',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    }
  }, [supabaseClient, messageApi]);

  useEffect(() => {
    fetchJobs();
    fetchTrucks();
    fetchNextJobNumber();
  }, [fetchJobs, fetchTrucks, fetchNextJobNumber]);

  const handleSaveJob = async (values: any) => {
    try {
      const deliveryDate = values.delivery_date ? 
        (dayjs.isDayjs(values.delivery_date) ? values.delivery_date : dayjs(values.delivery_date)) : 
        null;

      // Remove fields that shouldn't be sent to the database
      const {
        delivery_date,
        delivery_time,
        ...restValues
      } = values;

      // Calculate final quantity
      let quantity: number;
      if (values.quantity_display_mode === 'trucks' && values.num_trucks && values.truck_type) {
        const truckCapacity = TRUCK_CAPACITIES[values.truck_type as TruckType];
        quantity = values.num_trucks * truckCapacity * (values.is_turning ? 5 : 1);
      } else {
        quantity = typeof values.quantity === 'string' ? parseInt(values.quantity) : (values.quantity || 0);
      }

      const jobData = {
        ...restValues,
        delivery_date: deliveryDate ? deliveryDate.format('YYYY-MM-DD') : null,
        delivery_time: values.delivery_time ? 
          (dayjs.isDayjs(values.delivery_time) ? values.delivery_time.format('HH:mm:ss') : values.delivery_time) : null,
        quantity: quantity,
        quantity_display_mode: values.quantity_display_mode || 'specific',
        trucks_display: values.trucks_display || null,
        num_trucks: values.num_trucks ? parseInt(values.num_trucks.toString()) : null,
        is_turning: values.is_turning || false,
        product: values.product ? values.product.toUpperCase() : null
      };

      // Remove any undefined values to prevent database errors
      Object.keys(jobData).forEach(key => {
        if (jobData[key] === undefined) {
          delete jobData[key];
        }
      });

      if (editingJob) {
        const { error: updateError } = await supabaseClient
          .from('jobs')
          .update(jobData)
          .eq('id', editingJob.id);

        if (updateError) throw updateError;
        notification.success({
          message: 'Job Updated',
          description: 'Job has been successfully updated.',
          placement: 'topRight',
          key: 'job-update-success',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        });
      } else {
        // For new jobs, use the selected location if no location_id is provided
        if (!jobData.location_id) {
          if (!selectedLocationId) {
            notification.error({
              message: 'Location Required',
              description: 'Please select a location before creating a job.',
              placement: 'topRight',
              key: 'job-location-error',
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              }
            });
            return;
          }
          jobData.location_id = selectedLocationId;
        }

        const { data: insertedJob, error: insertError } = await supabaseClient
          .from('jobs')
          .insert([{ ...jobData, job_number: nextJobNumber }])
          .select()
          .single();

        if (insertError) throw insertError;
        if (!insertedJob) throw new Error('No job data returned after insert');
        
        notification.success({
          message: 'Job Created',
          description: 'Job has been successfully created.',
          placement: 'topRight',
          key: 'job-create-success',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        });
        setNextJobNumber(prev => prev + 1);
      }

      setModalVisible(false);
      fetchJobs();
    } catch (error) {
      console.error('Failed to save job:', error);
      notification.error({
        message: 'Save Failed',
        description: 'Failed to save job. Please try again.',
        placement: 'topRight',
        key: 'job-save-error',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    }
  };

  const handleViewDetails = (id: string) => {
    setSelectedJobId(id);
    setDrawerVisible(true);
  };

  const handleCreateJob = () => {
    setTruckTypeModalVisible(true);
  };

  const handleEditJob = (job: Job) => {
    setEditingJob(job);
    setModalVisible(true);
  };

  const handleDeleteJob = async (id: string) => {
    modal.confirm({
      title: 'Delete Job',
      content: 'Are you sure you want to delete this job? This action cannot be undone.',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk: async () => {
        try {
          // Delete related records first
          await supabaseClient
            .from('customer_previous_orders')
            .delete()
            .eq('job_id', id);

          await supabaseClient
            .from('driver_job_assignments')
            .delete()
            .eq('job_id', id);

          await supabaseClient
            .from('truck_job_assignments')
            .delete()
            .eq('job_id', id);

          await supabaseClient
            .from('job_products')
            .delete()
            .eq('job_id', id);

          // Finally delete the job
          const { error } = await supabaseClient
            .from('jobs')
            .delete()
            .eq('id', id);

          if (error) throw error;
          notification.success({
            message: 'Job Deleted',
            description: 'Job has been successfully deleted.',
            placement: 'topRight',
            key: 'job-delete-success',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
            }
          });
          fetchJobs();
        } catch (error) {
          console.error('Error deleting job:', error);
          notification.error({
            message: 'Delete Failed',
            description: 'Failed to delete job. Please try again.',
            placement: 'topRight',
            key: 'job-delete-error',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
            }
          });
        }
      }
    });
  };

  const handleModalClose = () => {
    setEditingJob(null);
    setModalVisible(false);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleStatusFilter = (status: JobStatus) => {
    setSelectedStatus(status === selectedStatus ? null : status);
  };

  const handleSort = (value: string) => {
    if (value === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(value as keyof Job);
      setSortOrder('asc');
    }
  };

  const handleTruckTypeSelect = (truckType: string) => {
    setSelectedTruckType(truckType);
    setTruckTypeModalVisible(false);
    setModalVisible(true);
    setEditingJob(null);
  };

  const handleTruckTypeCancel = () => {
    setTruckTypeModalVisible(false);
    setSelectedTruckType(null);
  };

  const jobMatchesSelectedDate = useCallback((job: Job, selectedDate: dayjs.Dayjs) => {
    if (!job.delivery_date) return false;
    return job.delivery_date === selectedDate.format('YYYY-MM-DD');
  }, []);

  const jobStats = useMemo(() => {
    const stats: Record<JobStatus, number> = {
      entered: 0,
      started: 0,
      completed: 0,
      'on-hold': 0,
      cancelled: 0,
      moved: 0
    };
    
    jobs.forEach(job => {
      // Normalize status to lowercase and ensure it's a valid JobStatus
      const status = job.status?.toLowerCase() as JobStatus;
      if (status && jobMatchesSelectedDate(job, filterDate) && status in stats) {
        stats[status]++;
      }
    });

    return stats;
  }, [jobs, filterDate, jobMatchesSelectedDate]);

  const filteredJobs = useMemo(() => {
    const filtered = jobs.filter(job => {
      if (!jobMatchesSelectedDate(job, filterDate)) {
        return false;
      }
      if (selectedStatus && job.status !== selectedStatus) {
        return false;
      }
      
      if (searchText) {
        const searchLower = searchText.toLowerCase();
        const matches = (
          job.customer_name.toLowerCase().includes(searchLower) ||
          job.delivery_address.toLowerCase().includes(searchLower) ||
          job.job_number.toString().includes(searchLower)
        );
        if (!matches) {
          return false;
        }
        return true;
      }
      
      return true;
    });

    return filtered;
  }, [jobs, selectedStatus, searchText, filterDate, jobMatchesSelectedDate]);

  const selectedJob = selectedJobId ? jobs.find(job => job.id === selectedJobId) || null : null;

  return (
    <App>
      <DocketProvider onDocketCreated={fetchJobs}>
        <div className="daily-planner" style={{ padding: '16px', height: 'calc(100vh - 96px)', overflow: 'hidden', display: 'flex', flexDirection: 'column', width: '100%', background: '#f0f2f5' }}>
          <Space direction="vertical" size={8} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Header Section */}
            <Row justify="space-between" align="middle">
              <Col>
                <Title level={2} style={{ margin: 0, fontSize: '24px' }}>Daily Planner</Title>
              </Col>
              <Col>
                <Space>
                  <Space direction="vertical" size={4}>
                    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>Location</span>
                    <Select
                      value={selectedLocationId}
                      onChange={setSelectedLocationId}
                      style={{ width: 200 }}
                      placeholder="Select location"
                      loading={locationLoading}
                      disabled={locationLoading}
                    >
                      {locations.map(location => (
                        <Select.Option key={location.id} value={location.id}>
                          {location.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                  <Space direction="vertical" size={4}>
                    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>Date</span>
                    <DatePicker
                      value={filterDate}
                      format="DD/MM/YYYY"
                      onChange={(date) => date && setFilterDate(date)}
                      suffixIcon={<CalendarOutlined />}
                    />
                  </Space>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleCreateJob}
                  >
                    Add Job
                  </Button>
                </Space>
              </Col>
            </Row>

            {/* Job Counts */}
            <Row justify="end">
              <Col>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div style={{ color: '#8c8c8c', fontWeight: 500, fontSize: '14px' }}>Today's Jobs</div>
                    <div style={{ color: '#1890ff', fontWeight: 'bold', fontSize: '18px' }}>{todayJobsCount}</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div style={{ color: '#8c8c8c', fontWeight: 500, fontSize: '14px' }}>Tomorrow's Jobs</div>
                    <div style={{ color: '#1890ff', fontWeight: 'bold', fontSize: '18px' }}>{tomorrowJobsCount}</div>
                  </div>
                </Space>
              </Col>
            </Row>

            {/* Statistics Cards */}
            <div style={{ width: '100%', marginBottom: 0 }}>
              <p style={{ marginBottom: '8px', color: '#8c8c8c', fontSize: '14px' }}>Click cards to filter by status</p>
              <Row gutter={[8, 8]}>
                {JOB_STATISTICS.map(stat => (
                  <Col xs={12} sm={8} md={4} key={stat.status}>
                    <Card
                      hoverable
                      className={`stat-card ${selectedStatus === stat.status ? 'selected' : ''}`}
                      onClick={() => handleStatusFilter(stat.status)}
                      styles={{ body: { padding: '8px', height: '40px' } }}
                      style={{ 
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
                        transition: 'all 0.3s ease',
                        borderTop: `2px solid ${stat.color}`,
                        backgroundColor: selectedStatus === stat.status ? `${stat.color}15` : 'white'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', whiteSpace: 'nowrap' }}>
                        {React.cloneElement(stat.icon as React.ReactElement, { 
                          style: { 
                            fontSize: '16px',
                            color: stat.color,
                            flexShrink: 0
                          }
                        })}
                        <span style={{ color: stat.color, fontWeight: 500, fontSize: '12px' }}>{stat.title}</span>
                        <span style={{ color: stat.color, fontWeight: 'bold', fontSize: '16px', marginLeft: 'auto' }}>{jobStats[stat.status]}</span>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>

            {/* Controls Section */}
            <div style={{ background: '#fafafa', padding: '12px', borderRadius: '8px', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.03)' }}>
              <Row gutter={[16, 16]} align="middle">
                <Col xs={24} sm={8}>
                  <Space direction="vertical" size={4} style={{ width: '100%' }}>
                    <span style={{ fontSize: '12px', color: '#8c8c8c' }}>Search jobs</span>
                    <Search
                      placeholder="Search jobs..."
                      onChange={e => handleSearch(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </Space>
                </Col>
                <Col xs={24} sm={16}>
                  <Space size={16} style={{ width: '100%', justifyContent: 'flex-end' }}>
                    <Space direction="vertical" size={4}>
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>Sort by</span>
                      <Select
                        style={{ width: 150 }}
                        value={sortField}
                        onChange={handleSort}
                      >
                        {SORT_OPTIONS.map(option => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Space>
                    <Space direction="vertical" size={4}>
                      <span style={{ fontSize: '12px', color: '#8c8c8c' }}>Order</span>
                      <Button
                        icon={<SortAscendingOutlined />}
                        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                      </Button>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </div>

            {/* Job Table */}
            <div style={{ flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column', width: '100%', marginTop: 0 }}>
              {loading ? (
                <div style={{ textAlign: 'center', padding: '50px' }}>
                  <Spin size="large" />
                </div>
              ) : (
                <JobTable
                  jobs={filteredJobs}
                  loading={loading}
                  onEdit={handleEditJob}
                  onDelete={handleDeleteJob}
                  onViewDetails={handleViewDetails}
                  trucks={trucks}
                  handleTruckSelection={handleTruckSelection}
                  getTruckColor={getTruckColor}
                  filterDate={filterDate}
                  fetchJobs={fetchJobs}
                />
              )}
            </div>
          </Space>

          {/* Modals */}
          <JobForm
            visible={modalVisible}
            onCancel={handleModalClose}
            onSave={handleSaveJob}
            editingJob={editingJob}
            customers={customers}
            products={products}
            trucks={trucks}
            nextJobNumber={nextJobNumber}
            initialTruckType={selectedTruckType}
          />

          <JobDetailsDrawer
            open={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            jobId={selectedJob?.id || null}
            onUpdate={fetchJobs}
          />

          <Modal
            title={<Typography.Title level={4} style={{ margin: 0, textAlign: 'center' }}>Select Truck Type</Typography.Title>}
            open={truckTypeModalVisible}
            onCancel={handleTruckTypeCancel}
            footer={null}
            style={{ top: '20%' }}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Button
                block
                size="large"
                type="primary"
                style={{ 
                  height: '60px',
                  backgroundColor: '#1890ff',
                  borderRadius: '8px',
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '8px'
                }}
                onClick={() => handleTruckTypeSelect('Truck and Dog')}
              >
                Truck and Dog
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{ 
                  height: '60px',
                  backgroundColor: '#52c41a',
                  borderRadius: '8px',
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '8px'
                }}
                onClick={() => handleTruckTypeSelect('Body Truck')}
              >
                Body Truck
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{ 
                  height: '60px',
                  backgroundColor: '#722ed1',
                  borderRadius: '8px',
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '8px'
                }}
                onClick={() => handleTruckTypeSelect('Semi Tipper')}
              >
                Semi Tipper
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{ 
                  height: '60px',
                  backgroundColor: '#faad14',
                  borderRadius: '8px',
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '8px'
                }}
                onClick={() => handleTruckTypeSelect('8 Wheeler')}
              >
                8 Wheeler
              </Button>
              <Button
                block
                size="large"
                type="primary"
                style={{ 
                  height: '60px',
                  backgroundColor: '#f5222d',
                  borderRadius: '8px',
                  fontSize: '16px',
                  fontWeight: 500
                }}
                onClick={() => handleTruckTypeSelect('10 Wheeler')}
              >
                10 Wheeler
              </Button>
            </Space>
          </Modal>
        </div>
      </DocketProvider>
    </App>
  );
};
