import React, { useEffect } from 'react';
import { Form, Select, Row, Col } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { Product } from '../../types';
import { QuantityInput } from './QuantityInput';

interface ProductDetailsProps {
  products: Product[];
  form: FormInstance;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({ products, form }) => {
  const handleProductChange = (value: string) => {
    const selectedProduct = products.find(p => p.id === value);
    if (selectedProduct) {
      const displayText = getProductDisplayText(selectedProduct).toUpperCase();
      form.setFieldsValue({
        product: displayText
      });
    }
  };

  const getProductDisplayText = (product: Product) => {
    return `${product.name}${product.type ? ` - ${product.type}` : ''}`.toUpperCase();
  };

  // Initialize product display name when editing
  useEffect(() => {
    const productId = form.getFieldValue('product');
    if (productId) {
      const selectedProduct = products.find(p => p.id === productId);
      if (selectedProduct) {
        const displayText = getProductDisplayText(selectedProduct);
        form.setFieldsValue({
          product: displayText
        });
      }
    }
  }, [form, products]);

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="product"
            label="Product"
            rules={[{ required: true, message: 'Please select a product' }]}
          >
            <Select
              showSearch
              placeholder="Select a product"
              optionFilterProp="label"
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={handleProductChange}
              options={products.map(product => ({
                value: product.id,
                label: getProductDisplayText(product)
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <QuantityInput form={form} />
        </Col>
      </Row>
    </div>
  );
};