import React from 'react';
import { Modal as AntModal } from 'antd';
import type { ModalProps as AntModalProps } from 'antd';

export interface ModalProps extends AntModalProps {
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
    return (
        <AntModal 
            {...props} 
            maskClosable={false}
            styles={{
                ...props.styles,
                body: {
                    padding: '24px',
                    ...props.styles?.body,
                },
                header: {
                    marginBottom: 0,
                    padding: '16px 24px',
                    ...props.styles?.header,
                },
                footer: {
                    padding: '16px 24px',
                    ...props.styles?.footer,
                },
                content: {
                    padding: 0,
                    ...props.styles?.content,
                }
            }}
        >
            {children}
        </AntModal>
    );
};
