import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Table, Space, Input, Select, Tag, Tooltip, Modal, Card, Statistic, App, Typography, Row, Col, Spin, Form, notification } from 'antd';
import { 
  DeleteOutlined, 
  EditOutlined, 
  EyeOutlined, 
  ShoppingOutlined,
  SearchOutlined,
  PlusOutlined,
  ShopOutlined,
  WarningOutlined,
  AppstoreOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  TagsOutlined,
  DollarOutlined,
  SortAscendingOutlined
} from '@ant-design/icons';
import { supabase } from '../../lib/supabaseClient';
import ProductDetails from './components/ProductDetails';
import { ProductForm } from './components/ProductForm';
import type { Product, ProductFormData, ProductStatistic } from './types';
import './Products.css';
import { formatWithAcronyms } from '../../utils/textFormatting';

const { Title } = Typography;
const { Option } = Select;

const Products: React.FC = () => {
  const { notification } = App.useApp();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [sortField, setSortField] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [productStats, setProductStats] = useState<ProductStatistic[]>([]);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    calculateProductStats();
  }, [products]);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('products')
        .select('*')
        .order('name');
      if (error) throw error;
      setProducts(data || []);
    } catch (error) {
      notification.error({
        message: 'Failed to fetch products',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const calculateProductStats = useCallback(() => {
    try {
      const totalProducts = products.length;
      const totalCategories = new Set(products.map(p => p.category)).size;
      const averagePrice = products.reduce((acc, curr) => acc + (curr.price_per_tonne || 0), 0) / products.length;

      setProductStats([
        {
          title: 'Total Products',
          value: totalProducts,
          icon: <AppstoreOutlined />,
          color: '#1890ff'
        },
        {
          title: 'Categories',
          value: totalCategories,
          icon: <TagsOutlined />,
          color: '#52c41a'
        },
        {
          title: 'Avg. Price',
          value: `$${averagePrice.toFixed(2)}`,
          icon: <DollarOutlined />,
          color: '#722ed1'
        }
      ]);
    } catch (error) {
      notification.error({
        message: 'Failed to calculate product stats',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    }
  }, [products]);

  const handleViewDetails = (product: Product) => {
    setSelectedProduct(product);
    setDetailsVisible(true);
  };

  const handleEdit = (product: Product) => {
    setEditingProduct(product);
  };

  const handleAdd = () => {
    setEditingProduct({} as Product);  // Empty product to trigger the form
  };

  const handleDelete = (product: Product) => {
    setSelectedProduct(product);
    setIsDeleteModalOpen(true);
  };

  const handleModalClose = () => {
    setEditingProduct(null);
  };

  const getCategoryColor = (category: string): string => {
    switch (category?.toLowerCase()) {
      case 'roadbase':
        return 'blue';
      case 'aggregate':
        return 'purple';
      case 'sand':
        return 'gold';
      case 'select fill':
        return 'green';
      default:
        return 'default';
    }
  };

  const getTypeColor = (type: string): string => {
    switch (type?.toLowerCase()) {
      case 'drainage':
        return 'cyan';
      case 'blue metal':
        return 'blue';
      case 'brown':
        return 'volcano';
      default:
        return 'default';
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleCategoryFilter = (category: string | null) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const handleTypeFilter = (type: string | null) => {
    setSelectedType(type === selectedType ? null : type);
  };

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const filteredProducts = useMemo(() => {
    let filtered = [...products];

    // Apply category filter
    if (selectedCategory) {
      filtered = filtered.filter(product => product.category === selectedCategory);
    }

    // Apply type filter
    if (selectedType) {
      filtered = filtered.filter(product => product.type === selectedType);
    }

    // Apply search filter
    if (searchText) {
      const searchLower = searchText.toLowerCase();
      filtered = filtered.filter(product =>
        product.name?.toLowerCase().includes(searchLower) ||
        product.product_code?.toLowerCase().includes(searchLower) ||
        product.category?.toLowerCase().includes(searchLower) ||
        product.type?.toLowerCase().includes(searchLower)
      );
    }

    // Apply sorting
    return filtered.sort((a, b) => {
      const aValue = (a[sortField as keyof Product] || '').toString().toLowerCase();
      const bValue = (b[sortField as keyof Product] || '').toString().toLowerCase();
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
  }, [products, searchText, selectedCategory, selectedType, sortField, sortOrder]);

  const columns = [
    {
      title: 'Product Code',
      key: 'product_code',
      dataIndex: 'product_code',
      width: '15%',
      render: (code: string) => formatWithAcronyms(code),
      sorter: (a: Product, b: Product) => (a.product_code || '').localeCompare(b.product_code || '')
    },
    {
      title: 'Product Details',
      key: 'name',
      width: '30%',
      render: (text: string, record: Product) => (
        <Space size="middle" align="center">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Space size="small">
              <span style={{ fontWeight: 500, color: '#262626' }}>
                {formatWithAcronyms(record.name)}
              </span>
              {record.type && <Tag color={getTypeColor(record.type)}>{formatWithAcronyms(record.type)}</Tag>}
            </Space>
          </div>
        </Space>
      ),
      sorter: (a: Product, b: Product) => a.name.localeCompare(b.name)
    },
    {
      title: 'Category',
      key: 'category',
      width: '15%',
      render: (text: string, record: Product) => (
        <Tag color={getCategoryColor(record.category)}>{record.category}</Tag>
      ),
      filters: [
        { text: 'Road Base', value: 'RoadBase' },
        { text: 'Aggregate', value: 'Aggregate' },
        { text: 'Sand', value: 'Sand' },
        { text: 'Select Fill', value: 'Select Fill' }
      ],
      onFilter: (value: any, record: Product) => record.category === value
    },
    {
      title: 'Specifications',
      key: 'specifications',
      width: '25%',
      render: (text: string, record: Product) => (
        <Space direction="vertical" size={2}>
          <span>
            <strong>Density:</strong> {record.tonnes_per_cube ? `${record.tonnes_per_cube} t/m³` : 'N/A'}
          </span>
          <span>
            <strong>Price:</strong> ${record.price_per_tonne?.toFixed(2)}/tonne
          </span>
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (_: any, record: Product) => (
        <Space size="middle">
          <Tooltip title="View Details">
            <Button
              type="primary"
              style={{ backgroundColor: '#52c41a' }}
              icon={<EyeOutlined />}
              onClick={() => handleViewDetails(record)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="primary"
              style={{ backgroundColor: '#1890ff' }}
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record)}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <div className="products-container">
      <Spin spinning={loading} tip="Loading products..." className="products-spinner">
        <Space direction="vertical" className="products-space" size="middle">
          {/* Statistics Cards */}
          <div className="section-container">
            <Row gutter={16} className="products-stats">
              {productStats.map(stat => (
                <Col key={stat.title} flex="1 0 auto">
                  <Card 
                    hoverable 
                    className="stat-card"
                    style={{ minWidth: '200px' }}
                  >
                    <Statistic
                      title={stat.title}
                      value={stat.value}
                      prefix={stat.icon}
                      valueStyle={{ color: stat.color }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          {/* Filter Controls */}
          <div className="section-container">
            <div className="filter-controls">
              <Row gutter={16} align="middle">
                <Col xs={24} md={12}>
                  <Space direction="vertical" size={4} className="search-container">
                    <span className="control-label">Search products</span>
                    <Input
                      placeholder="Search by name, code, category or type..."
                      prefix={<SearchOutlined />}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="search-input"
                      allowClear
                    />
                  </Space>
                </Col>
                <Col xs={24} md={12}>
                  <Space size={16} align="end" className="control-buttons">
                    <Space direction="vertical" size={4}>
                      <span className="control-label">Category</span>
                      <Select
                        className="filter-select"
                        value={selectedCategory}
                        onChange={handleCategoryFilter}
                        allowClear
                        style={{ minWidth: 120 }}
                        placeholder="All categories"
                      >
                        <Option value="RoadBase">Road Base</Option>
                        <Option value="Aggregate">Aggregate</Option>
                        <Option value="Sand">Sand</Option>
                        <Option value="Select Fill">Select Fill</Option>
                      </Select>
                    </Space>
                    <Space direction="vertical" size={4}>
                      <span className="control-label">Type</span>
                      <Select
                        className="filter-select"
                        value={selectedType}
                        onChange={handleTypeFilter}
                        allowClear
                        style={{ minWidth: 120 }}
                        placeholder="All types"
                      >
                        <Option value="Drainage">Drainage</Option>
                        <Option value="Blue Metal">Blue Metal</Option>
                        <Option value="Brown">Brown</Option>
                      </Select>
                    </Space>
                    <Space direction="vertical" size={4}>
                      <span className="control-label">Sort by</span>
                      <Space>
                        <Select
                          className="sort-select"
                          value={sortField}
                          onChange={handleSortChange}
                          style={{ minWidth: 120 }}
                        >
                          <Option value="name">Name</Option>
                          <Option value="product_code">Product Code</Option>
                          <Option value="category">Category</Option>
                          <Option value="type">Type</Option>
                          <Option value="price">Price</Option>
                        </Select>
                        <Button
                          icon={<SortAscendingOutlined />}
                          onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                        >
                          {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                        </Button>
                      </Space>
                    </Space>
                    <Space direction="vertical" size={4}>
                      <span className="control-label">&nbsp;</span>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleAdd}
                        className="add-button"
                      >
                        Add Product
                      </Button>
                    </Space>
                  </Space>
                </Col>
              </Row>
            </div>
          </div>

          {/* Products Table */}
          <div className="section-container">
            <Table
              dataSource={filteredProducts}
              columns={columns}
              rowKey="id"
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} products`
              }}
            />
          </div>
        </Space>
      </Spin>

      {selectedProduct && (
        <ProductDetails
          product={selectedProduct}
          open={detailsVisible}
          onCancel={() => setDetailsVisible(false)}
        />
      )}

      {editingProduct && (
        <ProductForm 
          open={!!editingProduct}
          isEdit={!!editingProduct.id}
          title={editingProduct.id ? 'Edit Product' : 'Add Product'}
          initialValues={editingProduct}
          onSubmit={async (values: ProductFormData) => {
            try {
              if (editingProduct.id) {
                const { error } = await supabase
                  .from('products')
                  .update(values)
                  .eq('id', editingProduct.id);

                if (error) throw error;
              } else {
                const { error } = await supabase
                  .from('products')
                  .insert([values]);

                if (error) throw error;
              }

              handleModalClose();
              fetchProducts();
            } catch (error) {
              throw error;
            }
          }}
          onCancel={handleModalClose}
        />
      )}

      <Modal
        title="Delete Product"
        open={isDeleteModalOpen}
        onOk={async () => {
          if (!selectedProduct) return;
          
          try {
            const { error } = await supabase
              .from('products')
              .delete()
              .eq('id', selectedProduct.id);

            if (error) throw error;
            
            notification.success({
              message: 'Product Deleted',
              description: 'Product has been deleted successfully.',
              placement: 'topRight',
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              }
            });
            setIsDeleteModalOpen(false);
            setSelectedProduct(null);
            fetchProducts();
          } catch (error) {
            notification.error({
              message: 'Delete Failed',
              description: error instanceof Error ? error.message : 'Failed to delete product',
              placement: 'topRight',
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              }
            });
          }
        }}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setSelectedProduct(null);
        }}
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>
    </div>
  );
};

export default Products;
