import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Select, Row, Col, Button } from 'antd';
import { EnvironmentOutlined, CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { TextArea } = Input;

interface DeliveryDetailsProps {
  form: any;
}

const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({ form }) => {
  const [isCustomTime, setIsCustomTime] = useState(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    const values = form.getFieldsValue();
    if (values.delivery_time && typeof values.delivery_time === 'string' && values.delivery_time.includes(':')) {
      setIsCustomTime(true);
    }
    if (values.delivery_date) {
      const date = dayjs.isDayjs(values.delivery_date) ? values.delivery_date : dayjs(values.delivery_date);
      if (date.isValid()) {
        setSelectedDate(date);
      }
    }
  }, [form]);

  const handleTimeChange = (value: string | null) => {
    if (value === 'Custom') {
      setIsCustomTime(true);
      form.setFieldsValue({ delivery_time: null });
    } else {
      setIsCustomTime(false);
      form.setFieldsValue({ delivery_time: value });
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date && date.isValid()) {
      setSelectedDate(date);
      form.setFieldsValue({ delivery_date: date });
    }
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current.isBefore(dayjs().startOf('day'));
  };

  return (
    <div className="delivery-details" style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Row gutter={12}>
        <Col span={16}>
          <Form.Item
            name="delivery_address"
            label="Delivery Address"
            rules={[{ required: true, message: 'Please enter delivery address' }]}
            style={{ marginBottom: 0 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <Input 
              id="delivery_address"
              prefix={<EnvironmentOutlined />}
              placeholder="Start typing to search address..."
              autoComplete="new-address"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="suburb"
            label="Suburb"
            rules={[{ required: true, message: 'Please enter suburb' }]}
            style={{ marginBottom: 0 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <Input 
              prefix={<EnvironmentOutlined />} 
              placeholder="Enter suburb"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="delivery_instructions"
        label="Delivery Instructions"
        tooltip="Specific instructions for delivery, such as access points or special requirements"
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: '0 0 8px 0' } }}
      >
        <TextArea
          rows={3}
          placeholder="Enter any specific delivery instructions..."
        />
      </Form.Item>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            name="delivery_date"
            label="Delivery Date"
            rules={[
              { required: true, message: 'Please select a delivery date' },
              {
                validator: async (_, value) => {
                  if (!value) return Promise.resolve();
                  const date = dayjs.isDayjs(value) ? value : dayjs(value);
                  if (!date.isValid()) {
                    return Promise.reject(new Error('Invalid date'));
                  }
                  if (date.isBefore(dayjs().startOf('day'))) {
                    return Promise.reject(new Error('Date cannot be in the past'));
                  }
                  return Promise.resolve();
                }
              }
            ]}
            style={{ marginBottom: 0 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <DatePicker
              style={{ width: '100%' }}
              suffixIcon={<CalendarOutlined />}
              disabledDate={disabledDate}
              onChange={handleDateChange}
              value={selectedDate}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="Delivery Time"
            style={{ marginBottom: 0 }}
            labelCol={{ style: { padding: '0 0 8px 0' } }}
          >
            <Form.Item
              name="delivery_time"
              rules={[
                {
                  validator: async (_, value) => {
                    if (!value) return Promise.resolve();
                    return Promise.resolve();
                  }
                }
              ]}
              labelCol={{ style: { padding: '0 0 8px 0' } }}
            >
              <Select 
                onChange={handleTimeChange}
                allowClear
                placeholder="Select time"
              >
                <Select.Option value="First">First</Select.Option>
                <Select.Option value="AM">AM</Select.Option>
                <Select.Option value="PM">PM</Select.Option>
                <Select.Option value="Last">Last</Select.Option>
                <Select.OptGroup label="Common Times">
                  <Select.Option value="06:00">6:00 AM</Select.Option>
                  <Select.Option value="07:00">7:00 AM</Select.Option>
                  <Select.Option value="08:00">8:00 AM</Select.Option>
                  <Select.Option value="09:00">9:00 AM</Select.Option>
                  <Select.Option value="10:00">10:00 AM</Select.Option>
                  <Select.Option value="11:00">11:00 AM</Select.Option>
                  <Select.Option value="12:00">12:00 PM</Select.Option>
                  <Select.Option value="13:00">1:00 PM</Select.Option>
                  <Select.Option value="14:00">2:00 PM</Select.Option>
                  <Select.Option value="15:00">3:00 PM</Select.Option>
                  <Select.Option value="16:00">4:00 PM</Select.Option>
                </Select.OptGroup>
              </Select>
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export { DeliveryDetails };