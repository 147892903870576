import React from 'react';
import { Card, Space, Badge, Typography, Tag, Progress, Tooltip } from 'antd';
import { CarOutlined, EnvironmentOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { TruckCardProps } from './types';
import { STATUS_COLORS } from '../utils/constants';
import { formatTonnes } from '../utils/formatters';
import { TruckStatus } from '../../trucks/types';

const { Text } = Typography;

export const TruckCard: React.FC<TruckCardProps> = ({ truck, onClick }) => {
  const statusColor = truck.status ? (STATUS_COLORS[truck.status] || '#ffffff') : '#ffffff';
  const utilization = truck.max_gross 
    ? Math.min(((truck.daily_total || 0) / truck.max_gross) * 100, 100)
    : 0;

  const getStatusIcon = (status: TruckStatus) => {
    switch (status) {
      case 'InUse':
        return <LoadingOutlined style={{ color: '#52c41a' }} />;
      case 'Workshop':
        return <CarOutlined style={{ color: '#faad14' }} />;
      default:
        return <CarOutlined style={{ color: '#1890ff' }} />;
    }
  };

  const getUtilizationColor = (percent: number) => {
    if (percent >= 90) return '#f5222d';
    if (percent >= 70) return '#faad14';
    return '#52c41a';
  };

  return (
    <Card
      size="small"
      style={{ 
        marginBottom: 8, 
        cursor: 'pointer',
        borderLeft: `3px solid ${statusColor}`,
        background: truck.status === 'Workshop' ? '#fffbe6' : undefined
      }}
      onClick={() => onClick(truck)}
      hoverable
    >
      <Space direction="vertical" style={{ width: '100%' }} size={4}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space>
            <Badge color={statusColor} />
            <Space size={4}>
              {getStatusIcon(truck.status)}
              <Text strong>{truck.fleet_number}</Text>
            </Space>
            <Tag color="blue">{truck.type}</Tag>
          </Space>
          <Tooltip title="Daily Total">
            <Text strong style={{ color: '#722ed1' }}>
              {formatTonnes(truck.daily_total || 0)}
            </Text>
          </Tooltip>
        </Space>

        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space>
            <Text type="secondary">
              <UserOutlined style={{ marginRight: 4 }} />
              {truck.driver_name || 'Unassigned'}
            </Text>
          </Space>
          <Space>
            <Text type="secondary">
              <EnvironmentOutlined style={{ marginRight: 4 }} />
              {truck.location_name || 'Unknown Location'}
            </Text>
          </Space>
        </Space>

        {truck.current_assignment && (
          <Space style={{ width: '100%' }}>
            <Tag color="processing">
              Job #{truck.current_assignment.job_number}
            </Tag>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {truck.current_assignment.customer_name}
            </Text>
          </Space>
        )}

        <Tooltip title={`Utilization: ${utilization.toFixed(1)}%`}>
          <Progress 
            percent={utilization} 
            size="small" 
            showInfo={false}
            strokeColor={getUtilizationColor(utilization)}
            trailColor="#f0f0f0"
          />
        </Tooltip>
      </Space>
    </Card>
  );
};