import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { DeliveryPanel } from './DeliveryPanel';
import { DriversPanel } from './DriversPanel/DriversPanel';
import { FleetPanel } from './FleetPanel';
import { PerformancePanel } from './PerformancePanel';

interface Metrics {
  id: string;
  date: string;
  total_deliveries: number;
  total_tonnes: number;
  total_distance: number;
  efficiency_score: number;
  on_time_percentage: number;
  active_drivers: number;
  active_trucks: number;
  deliveries_completed: number;
  tonnes_per_hour: number;
  avg_delivery_time: number;
}

const Dashboard: React.FC = () => {
  const metrics: Metrics[] = [];
  const loading = false;

  const items = [
    {
      key: '1',
      label: "Today's Overview",
      children: (
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <DeliveryPanel />
          </Col>
          <Col xs={24} lg={12}>
            <DriversPanel />
          </Col>
        </Row>
      )
    },
    {
      key: '2',
      label: 'Fleet & Performance',
      children: (
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={12}>
            <FleetPanel />
          </Col>
          <Col xs={24} lg={12}>
            <PerformancePanel metrics={metrics} loading={loading} />
          </Col>
        </Row>
      )
    }
  ];

  return (
    <div className="dashboard-container">
      <Tabs 
        defaultActiveKey="1" 
        className="dashboard-tabs" 
        items={items}
        style={{ padding: '0 24px' }}
      />
    </div>
  );
};

export default Dashboard;
