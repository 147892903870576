export const TRUCK_STATUSES = [
    'Available',
    'InUse',
    'Workshop',
    'SubContracting',
    'Inactive'
] as const;

export const TRUCK_TYPES = [
    'Truck and Dog',
    'Body Truck',
    'Semi',
    '8 Wheeler',
    '10 Wheeler',
    'Side Tipper',
] as const;

export const ASSIGNMENT_STATUSES = [
    'Active',
    'Completed',
    'Cancelled',
] as const;

export const TABLE_PAGINATION = {
    defaultPageSize: 10,
    pageSizeOptions: ['10', '20', '50'],
    showSizeChanger: true,
    showTotal: (total: number) => `Total ${total} trucks`,
};

export const FORM_LAYOUT = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export const FORM_TAIL_LAYOUT = {
    wrapperCol: { offset: 8, span: 16 },
};

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

export const WEIGHT_SUFFIX = 'kg';

export const DEFAULT_ERROR_MESSAGE = 'An error occurred. Please try again.';

export const MODAL_WIDTH = 800;

export const TRUCK_STATUS_MAP = {
    Available: 'Available',
    InUse: 'In Use',
    Workshop: 'Workshop',
    SubContracting: 'Sub-Contracting',
    Inactive: 'Inactive'
} as const;

export const DEFAULT_WEIGHT_CONFIG = {
    'Truck and Dog': {
        body_tare: 0,
        body_max_gross: 23.5,
        trailer_tare: 0,
        trailer_max_gross: 34.0,
        combined_max_gross: 57.5
    },
    'Body Truck': {
        body_tare: 0,
        body_max_gross: 23.5,
        combined_max_gross: 23.5
    },
    'Semi': {
        trailer_tare: 0,
        trailer_max_gross: 42.5,
        combined_max_gross: 42.5
    },
    '8 Wheeler': {
        body_tare: 0,
        body_max_gross: 32.0,
        combined_max_gross: 32.0
    },
    '10 Wheeler': {
        body_tare: 0,
        body_max_gross: 40.0,
        combined_max_gross: 40.0
    },
    'Side Tipper': {
        body_tare: 0,
        body_max_gross: 23.5,
        trailer_tare: 0,
        trailer_max_gross: 34.0,
        combined_max_gross: 57.5
    }
} as const;
