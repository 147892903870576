import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAssignments } from '../hooks/useAssignments';
import dayjs from 'dayjs';

const { Text } = Typography;

interface AssignmentHistoryProps {
    driverId?: string;
    truckId?: string;
    onTruckClick?: (truckId: string) => void;
    onDriverClick?: (driverId: string) => void;
}

interface Assignment {
    id: string;
    start_time: string;
    end_time: string | null;
    status: string;
    drivers: {
        id: string;
        name: string;
        status: string;
    };
    trucks: {
        id: string;
        fleet_number: string;
        status: string;
    };
}

const AssignmentHistory: React.FC<AssignmentHistoryProps> = ({
    driverId,
    truckId,
    onTruckClick,
    onDriverClick,
}) => {
    const { getAssignmentHistory, getDriverAssignmentHistory } = useAssignments();
    const [assignments, setAssignments] = useState<Assignment[]>([]);

    useEffect(() => {
        let mounted = true;

        const fetchAssignments = async () => {
            if (!driverId && !truckId) {
                setAssignments([]);
                return;
            }

            try {
                const data = truckId 
                    ? await getAssignmentHistory(truckId)
                    : driverId 
                    ? await getDriverAssignmentHistory(driverId)
                    : null;

                if (mounted && data) {
                    setAssignments(data);
                }
            } catch (error) {
                console.error('Error fetching assignments:', error);
                if (mounted) {
                    setAssignments([]);
                }
            }
        };

        fetchAssignments();

        return () => {
            mounted = false;
        };
    }, [driverId, truckId, getAssignmentHistory, getDriverAssignmentHistory]);

    const columns: ColumnsType<Assignment> = [
        {
            title: 'Truck',
            dataIndex: 'trucks',
            key: 'truck',
            render: (_, record) => record.trucks?.fleet_number || '-'
        },
        {
            title: 'Start Date',
            dataIndex: 'start_time',
            key: 'startDate',
            render: (date: string) => dayjs(date).format('DD/MM/YYYY')
        },
        {
            title: 'End Date (Current)',
            dataIndex: 'end_time',
            key: 'endDate',
            render: (date: string | null) => date ? dayjs(date).format('DD/MM/YYYY') : 'Current'
        },
        {
            title: 'Duration',
            key: 'duration',
            render: (_, record) => {
                const start = dayjs(record.start_time);
                const end = record.end_time ? dayjs(record.end_time) : dayjs();
                
                // Calculate total hours and days
                const totalHours = end.diff(start, 'hour');
                const days = Math.floor(totalHours / 24);
                const remainingHours = totalHours % 24;

                // Format the duration string
                if (days === 0) {
                    return `${totalHours} ${totalHours === 1 ? 'hour' : 'hours'}`;
                } else {
                    const dayStr = `${days} ${days === 1 ? 'day' : 'days'}`;
                    return remainingHours > 0 
                        ? `${dayStr}, ${remainingHours} ${remainingHours === 1 ? 'hour' : 'hours'}`
                        : dayStr;
                }
            }
        }
    ];

    return (
        <div style={{ padding: '0 20px' }}>
            <Table
                columns={columns}
                dataSource={assignments}
                rowKey={(record) => record.id}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} assignments`
                }}
                size="middle"
            />
        </div>
    );
};

export default AssignmentHistory;
