// Australian phone number validation pattern
// Matches:
// - Mobile numbers (04xx xxx xxx)
// - Landline numbers (02|03|07|08 xxxx xxxx)
// - Numbers with optional +61 prefix
export const PHONE_PATTERN = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;

// Phone number validation rules for form fields
export const phoneValidationRules = (fieldName: string = 'phone number') => [
    { required: true, message: `Please enter ${fieldName}` },
    { 
        pattern: PHONE_PATTERN,
        message: 'Please enter a valid phone number (e.g., 0412 345 678 or 02 9876 5432)'
    }
];

// Phone number formatter - formats input as user types
export const formatPhoneNumber = (value: string): string => {
    if (!value) return value;
    
    // Remove all non-digits
    const digits = value.replace(/\D/g, '');
    
    // Check if it's a mobile number (starting with 04)
    const isMobile = digits.startsWith('04');
    
    // Format based on type
    if (isMobile) {
        // Format as: 04xx xxx xxx
        return digits.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
    } else {
        // Format as: 0x xxxx xxxx
        return digits.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
    }
};
