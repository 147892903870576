import { DriverStatus } from '../../drivers/types';

export type DriverStatusType = DriverStatus;

export const DRIVER_STATUS_COLORS: Record<DriverStatus, string> = {
  'Available': '#52c41a',    // Green
  'Annual Leave': '#faad14', // Orange
  'Sick': '#f5222d',        // Red
  'Wet Day': '#1890ff',     // Blue
  'Inactive': '#d9d9d9',    // Grey
  'SubContracting': '#722ed1' // Purple
};

export const DRIVER_STATUS_LABELS: Record<DriverStatus, string> = {
  'Available': 'Available',
  'Annual Leave': 'Annual Leave',
  'Sick': 'Sick',
  'Wet Day': 'Wet Day',
  'Inactive': 'Inactive',
  'SubContracting': 'SubContracting'
};

// Helper function to determine driver status
export const determineDriverStatus = (
  status: string | null,
  hasCurrentJob: boolean
): DriverStatus => {
  if (!status) return 'Inactive';
  return status as DriverStatus;
};
