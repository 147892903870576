import './TruckForm.css';
import React, { useState, useEffect } from 'react';
import {
    Modal,
    Form,
    Input,
    Select,
    Button,
    Space,
    InputNumber,
    Row,
    Col,
    Card,
    Tag,
    Typography,
    message
} from 'antd';
import { CarOutlined, EditOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { Truck, TruckFormData, TruckWeightConfig, TruckType } from '../types';
import { TRUCK_STATUS_MAP, TRUCK_TYPES, DEFAULT_WEIGHT_CONFIG } from '../constants';
import { validateTruckFleetNumber, validateTruckType, validateTruckStatus, validateTruckRegistration, validateTruckNotes } from '../validation';
import { supabase } from '../../../lib/supabaseClient';

const { Option } = Select;
const { Title } = Typography;

interface Location {
    id: string;
    name: string;
}

interface TruckFormProps {
    visible: boolean;
    onSubmit: (values: TruckFormValues) => void;
    onCancel: () => void;
    initialValues?: Truck;
    title?: string;
    form: FormInstance;
    mode: 'create' | 'edit';
}

interface TruckFormValues extends Omit<TruckFormData, 'year'> {
    weight_config: TruckWeightConfig;
}

export const TruckForm: React.FC<TruckFormProps> = ({
    visible,
    onSubmit,
    onCancel,
    initialValues,
    form,
    mode
}) => {
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedType, setSelectedType] = useState<TruckType>(initialValues?.type || TRUCK_TYPES[0]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchLocations();
        // Convert existing registration values to uppercase
        const truckRego = form.getFieldValue('truck_rego');
        const trailerRego = form.getFieldValue('trailer_rego');
        
        if (truckRego) {
            form.setFieldsValue({ truck_rego: truckRego.toUpperCase() });
        }
        if (trailerRego) {
            form.setFieldsValue({ trailer_rego: trailerRego.toUpperCase() });
        }
    }, [form]);

    const fetchLocations = async () => {
        try {
            const { data, error } = await supabase
                .from('locations')
                .select('id, name')
                .order('name');

            if (error) {
                throw error;
            }

            if (data) {
                setLocations(data);
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
            message.error('Failed to load locations');
        }
    };

    const handleSubmit = async (formValues: TruckFormValues) => {
        setLoading(true);
        try {
            // Convert registration values to uppercase
            if (formValues.truck_rego) {
                formValues.truck_rego = formValues.truck_rego.toUpperCase();
            }
            if (formValues.trailer_rego) {
                formValues.trailer_rego = formValues.trailer_rego.toUpperCase();
            }

            await onSubmit(formValues);
        } catch (error) {
            console.error('Form submission failed:', error);
            message.error('Failed to submit form');
        } finally {
            setLoading(false);
        }
    };

    const hasTrailerConfiguration = (type: string) => {
        return ['Truck and Dog'].includes(type);
    };

    const canDoSplitLoads = (type: string) => {
        return ['Truck and Dog'].includes(type);
    };

    const getWeightFields = (type: string) => {
        switch (type) {
            case 'Truck and Dog':
                return {
                    showTrailer: true,
                    showCombined: true,
                    bodyLabel: 'Truck',
                    trailerLabel: 'Dog Trailer'
                };
            case 'Body Truck':
            case '8 Wheeler':
            case '10 Wheeler':
                return {
                    showTrailer: false,
                    showCombined: false,
                    bodyLabel: 'Truck',
                    trailerLabel: ''
                };
            case 'Semi':
            case 'Side Tipper':
                return {
                    showTrailer: false,
                    showCombined: false,
                    bodyLabel: 'Vehicle',
                    trailerLabel: ''
                };
            default:
                return {
                    showTrailer: false,
                    showCombined: false,
                    bodyLabel: 'Truck',
                    trailerLabel: ''
                };
        }
    };

    const weightConfig = getWeightFields(selectedType);

    return (
        <Modal
            title={
                <Space align="center">
                    <CarOutlined style={{ fontSize: '18px', color: '#1890ff' }} />
                    <span>
                        {mode === 'edit' ? 'Edit Truck' : 'Add New Truck'}
                        {mode === 'edit' && initialValues && (
                            <Tag color="blue">{initialValues.fleet_number}</Tag>
                        )}
                    </span>
                </Space>
            }
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={720}
            centered
            className="truck-form-modal"
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={initialValues}
                className="truck-form"
            >
                <Card title="Basic Information">
                    <Row gutter={16}>
                        {mode === 'create' && (
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="fleet_number"
                                    label="Fleet Number"
                                    rules={validateTruckFleetNumber()}
                                >
                                    <Input 
                                        placeholder="Enter fleet number"
                                        onChange={e => form.setFieldsValue({ fleet_number: e.target.value.toUpperCase() })}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24} sm={mode === 'create' ? 12 : 24}>
                            <Form.Item
                                name="type"
                                label="Type"
                                rules={validateTruckType()}
                            >
                                <Select 
                                    placeholder="Select truck type"
                                    onChange={(value: TruckType) => {
                                        setSelectedType(value);
                                        const defaultConfig = DEFAULT_WEIGHT_CONFIG[value];
                                        const currentTare = {
                                            body_tare: form.getFieldValue('body_tare'),
                                            trailer_tare: form.getFieldValue('trailer_tare')
                                        };
                                        
                                        form.setFieldsValue({
                                            ...defaultConfig,
                                            body_tare: value === 'Semi' ? undefined : currentTare.body_tare,
                                            trailer_tare: ['Truck and Dog', 'Semi', 'Side Tipper'].includes(value) 
                                                ? currentTare.trailer_tare 
                                                : undefined
                                        });

                                        if (value === 'Semi') {
                                            form.setFieldsValue({
                                                body_tare: undefined,
                                                body_max_gross: undefined
                                            });
                                        } else if (!['Truck and Dog', 'Semi', 'Side Tipper'].includes(value)) {
                                            form.setFieldsValue({
                                                trailer_tare: undefined,
                                                trailer_max_gross: undefined
                                            });
                                        }
                                    }}
                                >
                                    {TRUCK_TYPES.map(type => (
                                        <Option key={type} value={type}>{type}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="status"
                                label="Status"
                                rules={validateTruckStatus()}
                            >
                                <Select placeholder="Select status">
                                    {Object.entries(TRUCK_STATUS_MAP).map(([key, value]) => (
                                        <Option key={key} value={key}>{value}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="location_id"
                                label="Location"
                            >
                                <Select placeholder="Select location">
                                    {locations.map(location => (
                                        <Option key={location.id} value={location.id}>
                                            {location.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Registration Details">
                    <Row gutter={16}>
                        <Col xs={24} sm={selectedType === 'Truck and Dog' ? 12 : 24}>
                            <Form.Item
                                name="truck_rego"
                                label="Truck Registration"
                                rules={validateTruckRegistration()}
                            >
                                <Input 
                                    placeholder="Enter truck registration"
                                    onChange={e => form.setFieldsValue({ truck_rego: e.target.value.toUpperCase() })}
                                />
                            </Form.Item>
                        </Col>
                        {selectedType === 'Truck and Dog' && (
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="trailer_rego"
                                    label="Dog Trailer Registration"
                                    rules={validateTruckRegistration()}
                                >
                                    <Input 
                                        placeholder="Enter trailer registration"
                                        onChange={e => form.setFieldsValue({ trailer_rego: e.target.value.toUpperCase() })}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Card>

                <Card title="Weight Configuration">
                    {selectedType !== 'Semi' && (
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="body_tare"
                                    label="Body Tare Weight (t)"
                                    rules={[{ required: true, message: 'Please enter body tare weight' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        step={0.1}
                                        precision={1}
                                        style={{ width: '100%' }}
                                        placeholder="Enter body tare weight"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="body_max_gross"
                                    label="Body Max Gross (t)"
                                    rules={[{ required: true, message: 'Please enter body max gross weight' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        step={0.1}
                                        precision={1}
                                        style={{ width: '100%' }}
                                        placeholder="Enter body max gross"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {(selectedType === 'Truck and Dog' || selectedType === 'Semi' || selectedType === 'Side Tipper') && (
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="trailer_tare"
                                    label={
                                        selectedType === 'Semi' ? 'Trailer Tare Weight (t)' :
                                        selectedType === 'Side Tipper' ? 'Tipper Tare Weight (t)' :
                                        'Dog Trailer Tare (t)'
                                    }
                                    rules={[{ required: true, message: 'Please enter trailer tare weight' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        step={0.1}
                                        precision={1}
                                        style={{ width: '100%' }}
                                        placeholder="Enter trailer tare weight"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    name="trailer_max_gross"
                                    label={
                                        selectedType === 'Semi' ? 'Trailer Max Gross (t)' :
                                        selectedType === 'Side Tipper' ? 'Tipper Max Gross (t)' :
                                        'Dog Trailer Max Gross (t)'
                                    }
                                    rules={[{ required: true, message: 'Please enter trailer max gross weight' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        step={0.1}
                                        precision={1}
                                        style={{ width: '100%' }}
                                        placeholder="Enter trailer max gross"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={16}>
                        <Col xs={24} sm={24}>
                            <Form.Item
                                name="combined_max_gross"
                                label="Combined Max Gross (t)"
                                rules={[{ required: true, message: 'Please enter combined max gross weight' }]}
                            >
                                <InputNumber
                                    min={0}
                                    step={0.1}
                                    precision={1}
                                    style={{ width: '100%' }}
                                    placeholder="Enter combined max gross"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Additional Information">
                    <Form.Item
                        name="notes"
                        label="Notes"
                        rules={validateTruckNotes()}
                    >
                        <Input.TextArea
                            rows={3}
                            placeholder="Enter any additional notes"
                        />
                    </Form.Item>
                </Card>

                <div className="form-actions">
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        icon={mode === 'edit' ? <EditOutlined /> : <CarOutlined />}
                    >
                        {mode === 'edit' ? 'Save Changes' : 'Add Truck'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
