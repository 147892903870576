import React, { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import DocketList from './DocketList';
import { App } from 'antd';
import dayjs from 'dayjs';
import { PulseDocket } from '../types';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';

const DocketListPage: React.FC = () => {
  const [dockets, setDockets] = useState<PulseDocket[]>([]);
  const [loading, setLoading] = useState(false);
  const { notification } = App.useApp();

  useEffect(() => {
    fetchTodaysDockets();

    // Subscribe to changes in pulse_dockets and jobs tables
    const channel = supabase.channel('docket_list_changes');

    // Listen for docket changes
    channel.on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'pulse_dockets' },
      (payload: RealtimePostgresChangesPayload<any>) => {
        console.log('Docket change detected:', payload);
        fetchTodaysDockets(); // Refresh the list when any change occurs
      }
    );

    // Listen for job changes
    channel.on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'jobs' },
      (payload: RealtimePostgresChangesPayload<any>) => {
        console.log('Job change detected:', payload);
        fetchTodaysDockets(); // Refresh the list when any change occurs
      }
    );

    channel.subscribe((status) => {
      console.log('Subscription status:', status);
    });

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const fetchTodaysDockets = async () => {
    try {
      setLoading(true);
      const startOfDay = dayjs().startOf('day').toISOString();
      const endOfDay = dayjs().endOf('day').toISOString();
      
      // First, get all dockets for today
      const { data: docketData, error: docketError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .gte('created_at', startOfDay)
        .lt('created_at', endOfDay)
        .order('created_at', { ascending: false });

      if (docketError) throw docketError;

      // Then get all jobs with their total quantities
      const { data: jobData, error: jobError } = await supabase
        .from('jobs')
        .select('id, job_number, quantity, progressive');

      if (jobError) throw jobError;

      // Create a map of job IDs to job details
      const jobMap = new Map(jobData?.map(job => [job.id, { 
        job_number: job.job_number,
        progress: job.progressive ? Math.min(100, Math.round((Number(job.progressive) / Number(job.quantity)) * 100)) : 0
      }]) || []);

      // Combine the data
      const docketsWithJobs = docketData?.map(docket => ({
        ...docket,
        jobs: {
          job_number: jobMap.get(docket.job_id)?.job_number || Number(docket.job_id || '0'),
          progress: jobMap.get(docket.job_id)?.progress || 0
        }
      })) || [];

      setDockets(docketsWithJobs);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch dockets',
        placement: 'topRight',
      });
      console.error('Error fetching dockets:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDocketClick = (docket: PulseDocket) => {
    // TODO: Implement docket click handler if needed
    console.log('Docket clicked:', docket);
  };

  return (
    <DocketList
      dockets={dockets}
      loading={loading}
      onDocketClick={handleDocketClick}
      onDocketDeleted={fetchTodaysDockets}
      onUpdate={fetchTodaysDockets}
    />
  );
};

export default DocketListPage;
