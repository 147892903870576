import { message } from 'antd';
import { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import type { Product } from '../types';

export const useProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data, error } = await supabase
          .from('products')
          .select('*')
          .order('product_code');

        if (error) throw error;
        setProducts(data || []);
      } catch (error) {
        message.error('Error fetching products');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return { products, loading };
};
