import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, App } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { checkConnection } from '../lib/supabaseClient';

const { Title } = Typography;

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();
  const { notification } = App.useApp();

  useEffect(() => {
    // Check for redirect path in URL parameters
    const params = new URLSearchParams(location.search);
    const redirectPath = params.get('redirect');
    if (redirectPath) {
      localStorage.setItem('redirectPath', redirectPath);
    }

    // Check connection to Supabase
    const verifyConnection = async () => {
      const isConnected = await checkConnection();
      if (!isConnected) {
        notification.error({
          message: 'Connection Error',
          description: 'Unable to connect to the server. Please check your internet connection.',
          placement: 'topRight',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        });
      }
    };

    verifyConnection();
  }, [location, notification]);

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      // First check connection
      console.log('Verifying Supabase connectivity...');
      const isConnected = await checkConnection();
      if (!isConnected) {
        throw new Error('Unable to connect to Supabase. Please check your internet connection or try again later.');
      }

      console.log('Connection verified, attempting sign in...');
      const { error } = await signIn(values.email, values.password);
      
      if (error) {
        console.error('Sign in error:', error);
        throw error;
      }

      notification.success({
        message: 'Login Successful',
        description: 'Successfully logged in',
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      const redirectPath = localStorage.getItem('redirectPath') || '/dashboard';
      localStorage.removeItem('redirectPath');
      navigate(redirectPath);
    } catch (error) {
      console.error('Login process error:', error);
      const errorMessage = (error as Error).message || 'Invalid email or password';
      
      form.setFields([
        {
          name: 'password',
          errors: [errorMessage],
        },
      ]);
      notification.error({
        message: 'Login Failed',
        description: errorMessage,
        placement: 'topRight',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: '#1B263B',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0
    }}>
      <Form
        form={form}
        name="login"
        onFinish={onFinish}
        style={{
          width: 300,
          padding: 24,
          backgroundColor: '#E0E1DD',
          borderRadius: 4
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <img src="/files/logo/logodark.png" alt="AllocateIt Logo" style={{ width: '80%', maxWidth: '200px' }} />
        </div>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' }
          ]}
        >
          <Input 
            prefix={<MailOutlined />} 
            placeholder="Email" 
            size="large"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password 
            prefix={<LockOutlined />} 
            placeholder="Password" 
            size="large"
            autoComplete="current-password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{
              width: '100%',
              backgroundColor: '#0D1B2A',
              borderColor: '#0D1B2A'
            }}
            size="large"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;