interface ElectronAPI {
  // Electron API interface
  printDocket: (content: string) => Promise<{ success: boolean; error?: string }>;
}

declare global {
  interface Window {
    electronAPI?: ElectronAPI;
  }
}

export const isPrinterAvailable = (): boolean => {
  const available = typeof window !== 'undefined' && !!window.electronAPI?.printDocket;
  console.log('[PrintingService] Printer availability check:', {
    available,
    hasWindow: typeof window !== 'undefined',
    hasElectronAPI: !!window?.electronAPI,
    hasPrintFunction: !!window?.electronAPI?.printDocket
  });
  return available;
};

export const printContent = async (content: string): Promise<void> => {
  console.log('[PrintingService] Print request received:', {
    contentLength: content?.length,
    hasContent: !!content
  });

  if (!isPrinterAvailable()) {
    console.error('[PrintingService] Printer not available');
    throw new Error('Printing is only available in the desktop application');
  }

  try {
    console.log('[PrintingService] Sending print request to Electron');
    const result = await window.electronAPI!.printDocket(content);
    console.log('[PrintingService] Print result:', result);
    
    if (!result.success) {
      console.error('[PrintingService] Print failed:', result.error);
      throw new Error(result.error || 'Failed to print document');
    }
  } catch (error) {
    console.error('[PrintingService] Print error:', error);
    throw error;
  }
};
