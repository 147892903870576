import React from 'react';
import { Space, Badge, Typography, Tag } from 'antd';
import { UserOutlined, EnvironmentOutlined, NumberOutlined, CarOutlined, PhoneOutlined } from '@ant-design/icons';
import type { Driver } from '../types';
import { DRIVER_STATUS_COLORS, DRIVER_STATUS_LABELS } from '../utils/driverConstants';
import './DriverCard.css';

const { Text } = Typography;

interface DriverCardProps {
  driver: Driver;
  onClick?: (driver: Driver) => void;
}

const getTruckColor = (type?: string | null): string => {
  if (!type) return '#999999';
  
  const normalizedType = type.toLowerCase().trim();
  switch (normalizedType) {
    case 'truck and dog':
      return '#1890ff';  // blue
    case 'body truck':
      return '#52c41a';  // green
    case 'semi':
      return '#fa8c16';  // orange
    case '8 wheeler':
      return '#722ed1';  // purple
    case '10 wheeler':
      return '#f5222d';  // red
    case 'side tipper':
      return '#13c2c2';  // cyan
    default:
      return '#999999';  // gray
  }
};

const getStatusGradient = (status: string) => {
  switch (status.toLowerCase()) {
    case 'active':
      return 'linear-gradient(135deg, #00b894 0%, #00cec9 100%)';
    case 'inactive':
      return 'linear-gradient(135deg, #636e72 0%, #b2bec3 100%)';
    case 'break':
      return 'linear-gradient(135deg, #fdcb6e 0%, #ffeaa7 100%)';
    default:
      return 'linear-gradient(135deg, #74b9ff 0%, #a3d8f4 100%)';
  }
};

const getStatusTextColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'active':
    case 'inactive':
      return '#fff';
    default:
      return '#2d3436';
  }
};

const getStatusBackground = (status: string): string => {
  switch (status) {
    case 'Available':
      return '#f6ffed';  // Light green
    case 'Annual Leave':
      return '#fff7e6';  // Light orange
    case 'Sick':
      return '#fff1f0';  // Light red
    case 'Wet Day':
      return '#e6f7ff';  // Light blue
    case 'Inactive':
      return '#fafafa';  // Light grey
    case 'SubContracting':
      return '#f9f0ff';  // Light purple
    default:
      return '#ffffff';
  }
};

export const DriverCard: React.FC<DriverCardProps> = ({ driver, onClick }) => {
  const statusColor = DRIVER_STATUS_COLORS[driver.status] || '#d9d9d9';
  const textColor = getStatusTextColor(driver.status);

  return (
    <div
      style={{ 
        cursor: 'pointer',
        borderLeft: `3px solid ${statusColor}`,
        borderRadius: '2px',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        background: getStatusBackground(driver.status),
        fontSize: '12px',
        minHeight: '68px'
      }}
      onClick={() => onClick?.(driver)}
      className="driver-card"
    >
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px', marginBottom: '2px' }}>
          <Badge color={statusColor} />
          <Text strong style={{ fontSize: '12px' }}>{driver.name}</Text>
          <Tag color={statusColor} style={{ marginLeft: 'auto', padding: '0 4px', lineHeight: '16px', height: '18px' }}>
            {DRIVER_STATUS_LABELS[driver.status]}
          </Tag>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Space size={8}>
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <NumberOutlined style={{ fontSize: '12px', color: '#8c8c8c' }} />
              <Text type="secondary" style={{ fontSize: '12px' }}>{driver.fleet_number}</Text>
            </span>
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <PhoneOutlined style={{ fontSize: '12px', color: '#8c8c8c' }} />
              <Text type="secondary" style={{ fontSize: '12px' }}>{driver.phone_number}</Text>
            </span>
          </Space>
          {driver.truck_type && (
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <CarOutlined style={{ fontSize: '12px', color: getTruckColor(driver.truck_type) }} />
              <Text type="secondary" style={{ fontSize: '12px' }}>{driver.truck_type}</Text>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};