import { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import { Job } from '../types';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useAuth } from '../../../contexts/AuthContext';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { usePrinting } from '../../../contexts/PrintingContext';

interface PulseDocket {
  quantity: number;
  is_credit: boolean;
  job_id: string;
  id: string;
}

interface Location {
  id: string;
  name: string;
}

interface Customer {
  id: string;
  customer_name: string;
}

interface Truck {
  id: string;
  fleet_number: string;
}

interface TruckJobAssignment {
  id: string;
  truck_id: string;
  trucks: Truck;
}

interface JobData extends Omit<Job, 'delivery_date'> {
  pulse_dockets?: PulseDocket[];
  locations?: Location;
  customers?: Customer;
  truck_job_assignments?: TruckJobAssignment[];
  delivery_date: string | null;
}

interface SupabasePayload<T> {
  new: T | null;
  old: T | null;
  eventType: 'INSERT' | 'UPDATE' | 'DELETE';
}

/**
 * Custom hook to fetch and manage jobs data.
 * 
 * @param {string | null} selectedLocationId - The ID of the selected location.
 * @returns {object} An object containing jobs data, loading state, and functions to fetch and update jobs.
 */
export const useJobs = (selectedLocationId: string | null) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(false);
  const [todayJobsCount, setTodayJobsCount] = useState(0);
  const [tomorrowJobsCount, setTomorrowJobsCount] = useState(0);
  const { isAuthenticated } = useAuth();
  const mounted = useRef(true);
  const { isPrinting } = usePrinting();

  /**
   * Fetches jobs data from the database.
   */
  const fetchJobs = useCallback(async () => {
    // Don't fetch while printing
    if (isPrinting) {
      return;
    }

    if (!isAuthenticated || !selectedLocationId) {
      setJobs([]);
      return;
    }

    setLoading(true);
    try {
      const { data: jobsData, error: jobsError } = await supabase
        .from('jobs')
        .select(`
          *,
          customers:customer_id (id, customer_name),
          locations (id, name),
          truck_job_assignments (
            id,
            truck_id,
            trucks (
              id,
              fleet_number
            )
          )
        `)
        .eq('location_id', selectedLocationId)
        .order('delivery_date', { ascending: true });

      if (jobsError) {
        if (jobsError.message.includes('JWT')) {
          message.error('Authentication error. Please sign in again.');
          return;
        }
        throw jobsError;
      }

      if (!jobsData) {
        setJobs([]);
        return;
      }

      // Get progress for each job
      const progressPromises = jobsData?.map(async (job) => {
        try {
          const { data: progress, error: progressError } = await supabase
            .from('pulse_dockets')
            .select('quantity, is_credit')
            .eq('job_id', job.id);

          if (progressError) {
            throw progressError;
          }

          // If no progress data exists yet, return 0
          if (!progress || progress.length === 0) {
            return { jobId: job.id, progressive: 0 };
          }

          const progressive = progress.reduce((total, docket) => {
            // Don't negate again - the quantity is already negative for credits
            const quantity = docket.quantity || 0;
            return total + quantity;
          }, 0);
          
          return { jobId: job.id, progressive };
        } catch (error) {
          // Instead of logging error, just return 0 progress for this job
          return { jobId: job.id, progressive: 0 };
        }
      }) || [];

      const jobProgress = await Promise.all(progressPromises);
      const progressMap = jobProgress.reduce((acc, curr) => {
        acc[curr.jobId] = curr.progressive.toString(); 
        return acc;
      }, {} as { [key: string]: string });

      const normalizedJobs = jobsData?.map(job => ({
        ...job,
        status: job.status?.toLowerCase(),
        location_name: job.locations?.name || '',
        customer_name: job.customers?.customer_name || '',
        product: job.product || '',
        delivery_date: job.delivery_date || '',
        delivery_time: job.delivery_time || '',
        delivery_address: job.delivery_address || '',
        job_number: job.job_number || 0,
        trucks_allocated: job.trucks_allocated || [],
        progressive: progressMap[job.id] || '0',
        fleet_number: job.truck_job_assignments?.[0]?.trucks?.fleet_number || '-',
        truck_progressive: '0'
      })) || [];

      setJobs(normalizedJobs);
    } catch (error: any) {
      console.error('Error fetching jobs:', error);
      message.error('Error fetching jobs. Please check your connection and try again.');
      setJobs([]);
    } finally {
      setLoading(false);
    }
  }, [selectedLocationId, isAuthenticated, isPrinting]);

  /**
   * Calculates the number of jobs for today and tomorrow.
   */
  const calculateJobCounts = useCallback(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const todayCount = jobs.filter(job => {
      const jobDate = new Date(job.delivery_date);
      jobDate.setHours(0, 0, 0, 0);
      return jobDate.getTime() === today.getTime();
    }).length;

    const tomorrowCount = jobs.filter(job => {
      const jobDate = new Date(job.delivery_date);
      jobDate.setHours(0, 0, 0, 0);
      return jobDate.getTime() === tomorrow.getTime();
    }).length;

    setTodayJobsCount(todayCount);
    setTomorrowJobsCount(tomorrowCount);
  }, [jobs]);

  useEffect(() => {
    calculateJobCounts();
  }, [calculateJobCounts]);

  useEffect(() => {
    if (isAuthenticated && selectedLocationId) {
      fetchJobs();

      const channel = supabase.channel(`daily_planner_changes:${selectedLocationId}`);
      let isSubscribed = true;

      // Subscribe to job changes
      channel
        .on(
          'postgres_changes',
          { 
            event: '*', 
            schema: 'public', 
            table: 'jobs',
            filter: `location_id=eq.${selectedLocationId}`
          },
          async (payload: RealtimePostgresChangesPayload<Job>) => {
            if (!isSubscribed || isPrinting) return;
            
            if (payload.eventType === 'INSERT') {
              await fetchJobs();
            } else if (payload.eventType === 'UPDATE' && payload.new && payload.old) {
              const progressiveChanged = payload.new.progressive !== payload.old.progressive;
              const statusChanged = payload.new.status !== payload.old.status;
              
              if (progressiveChanged || statusChanged) {
                await fetchJobs();
              }
            }
          }
        )
        .subscribe();

      return () => {
        isSubscribed = false;
        channel.unsubscribe();
      };
    }
  }, [selectedLocationId, isAuthenticated, fetchJobs, isPrinting]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    jobs,
    loading,
    todayJobsCount,
    tomorrowJobsCount,
    fetchJobs
  };
};
