import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { Spin } from 'antd';

interface ProtectedRouteProps {
  children: React.ReactNode | (() => React.ReactNode);
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, session } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Wait a bit for the session to be restored
        await new Promise(resolve => setTimeout(resolve, 100));
        setIsLoading(false);
      } catch (error) {
        console.error('Auth check error:', error);
        setIsLoading(false);
      }
    };
    
    checkAuth();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin spinning={isLoading} size="large">
          <div style={{ padding: '50px' }} />
        </Spin>
      </div>
    );
  }

  if (!user || !session) {
    // Save the attempted URL
    const currentPath = location.pathname;
    if (currentPath !== '/login') {
      localStorage.setItem('redirectPath', currentPath);
    }
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{typeof children === 'function' ? children() : children}</>;
};

export default ProtectedRoute;