import React from 'react';
import { Layout, Typography, Button } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const DocketLayout: React.FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <Layout style={{ 
      minHeight: '100vh',
      maxWidth: '100vw',
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}>
      <Header style={{ 
        background: '#fff', 
        padding: '0 24px',
        height: '48px',
        lineHeight: '48px',
        borderBottom: '1px solid #d9d9d9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src="/files/logo/logosmllight.png" 
            alt="Logo" 
            style={{ height: '32px', marginRight: '16px' }}
          />
          <Text strong>Dockets Management</Text>
        </div>
        <Button type="link" onClick={handleSignOut}>Sign Out</Button>
      </Header>
      <Content style={{ 
        position: 'relative',
        height: 'calc(100vh - 96px)',
        overflow: 'auto',
        margin: 0,
        padding: '24px',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: 0,
          width: '100%',
          boxSizing: 'border-box'
        }}>
          <Outlet />
        </div>
      </Content>
      <Footer style={{ 
        textAlign: 'center', 
        height: '48px',
        lineHeight: '47px',
        padding: 0,
        borderTop: '1px solid #d9d9d9',
        flexShrink: 0,
        background: '#fff',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <Text type="secondary">AllocateIt Dockets Management System</Text>
      </Footer>
    </Layout>
  );
};

export default DocketLayout;
