            import React, { useState } from 'react';
import { Table, Button, Modal, Tooltip, Input } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { CustomerContact } from '../types';

interface CustomerContactsProps {
  contacts: CustomerContact[];
  onAddContact: () => void;
  onEditContact: (contact: CustomerContact) => void;
  onDeleteContact: (contactId: string) => void;
}

const CustomerContacts: React.FC<CustomerContactsProps> = ({
  contacts,
  onAddContact,
  onEditContact,
  onDeleteContact
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredContacts = contacts.filter(contact => 
    contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (contact.phone && contact.phone.includes(searchTerm))
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: CustomerContact, b: CustomerContact) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, record: CustomerContact) => (
        <div>
          <Tooltip title="Edit Contact">
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditContact(record)}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
          <Tooltip title="Delete Contact">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to delete this contact?',
                  content: 'This action cannot be undone.',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk: () => onDeleteContact(record.id)
                });
              }}
            />
          </Tooltip>
        </div>
      )
    }
  ];

  return (
    <div>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <Input.Search
          placeholder="Search contacts..."
          onChange={e => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddContact}
        >
          Add Contact
        </Button>
      </div>
      <Table
        dataSource={filteredContacts}
        columns={columns}
        rowKey="id"
      />
    </div>
  );
};

export default CustomerContacts;
