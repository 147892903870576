import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Select, InputNumber, message } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { supabase } from '../../../lib/supabaseClient';
import { formatCurrency } from '../../../utils/format';
import { CustomerProduct } from '../types';

interface Product {
  id: string;
  name: string;
  price_per_tonne: number;
}

interface CustomerProductsProps {
  customerId: string;
}

export const CustomerProducts: React.FC<CustomerProductsProps> = ({ customerId }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [customerProducts, setCustomerProducts] = useState<CustomerProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    fetchProducts();
    fetchCustomerProducts();
  }, [customerId]);

  const fetchProducts = async () => {
    const { data, error } = await supabase
      .from('products')
      .select('id, name, price_per_tonne');

    if (error) {
      message.error('Error fetching products');
      return;
    }

    setProducts(data || []);
  };

  const fetchCustomerProducts = async () => {
    const { data, error } = await supabase
      .from('customer_products')
      .select('*')
      .eq('customer_id', customerId);

    if (error) {
      message.error('Error fetching customer products');
      return;
    }

    setCustomerProducts(data || []);
    setLoading(false);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingId(null);
    setModalVisible(true);
  };

  const handleEdit = (record: CustomerProduct) => {
    form.setFieldsValue({
      product_id: record.product_id,
      base_price: record.base_price,
      discount_percentage: record.discount_percentage,
    });
    setEditingId(record.id);
    setModalVisible(true);
  };

  const handleDelete = async (id: string) => {
    const { error } = await supabase
      .from('customer_products')
      .delete()
      .eq('id', id);

    if (error) {
      message.error('Error deleting product');
      return;
    }

    message.success('Product deleted successfully');
    fetchCustomerProducts();
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const product = products.find(p => p.id === values.product_id);
      
      if (!product) {
        message.error('Selected product not found');
        return;
      }

      const customerProduct = {
        customer_id: customerId,
        product_id: values.product_id,
        base_price: values.base_price || product.price_per_tonne,
        discount_percentage: values.discount_percentage || 0,
        is_active: true,
      };

      if (editingId) {
        const { error } = await supabase
          .from('customer_products')
          .update(customerProduct)
          .eq('id', editingId);

        if (error) {
          message.error('Error updating product');
          return;
        }

        message.success('Product updated successfully');
      } else {
        const { error } = await supabase
          .from('customer_products')
          .insert([customerProduct]);

        if (error) {
          message.error('Error adding product');
          return;
        }

        message.success('Product added successfully');
      }

      setModalVisible(false);
      fetchCustomerProducts();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product_id',
      key: 'product_id',
      render: (productId: string) => {
        const product = products.find(p => p.id === productId);
        return product?.name || 'Unknown Product';
      },
    },
    {
      title: 'Base Price',
      dataIndex: 'base_price',
      key: 'base_price',
      render: (price: number) => formatCurrency(price),
    },
    {
      title: 'Discount',
      dataIndex: 'discount_percentage',
      key: 'discount_percentage',
      render: (discount: number) => `${discount}%`,
    },
    {
      title: 'Final Price',
      key: 'final_price',
      render: (record: CustomerProduct) => {
        const finalPrice = record.base_price * (1 - record.discount_percentage / 100);
        return formatCurrency(finalPrice);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: CustomerProduct) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: 'Are you sure you want to delete this product?',
                content: 'This action cannot be undone.',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: () => handleDelete(record.id),
              });
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAdd}
        style={{ marginBottom: 16 }}
      >
        Add Product
      </Button>
      <Table
        loading={loading}
        dataSource={customerProducts}
        columns={columns}
        rowKey="id"
      />
      <Modal
        title={editingId ? 'Edit Product' : 'Add Product'}
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="product_id"
            label="Product"
            rules={[{ required: true, message: 'Please select a product' }]}
          >
            <Select>
              {products.map(product => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name} ({formatCurrency(product.price_per_tonne)})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="base_price"
            label="Base Price"
            rules={[{ required: true, message: 'Please enter a base price' }]}
          >
            <InputNumber<number>
              style={{ width: '100%' }}
              formatter={value => `$${value}`}
              parser={value => value ? Math.max(0, Number(value.replace('$', ''))) : 0}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="discount_percentage"
            label="Discount Percentage"
            initialValue={0}
          >
            <InputNumber<number>
              style={{ width: '100%' }}
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => {
                if (!value) return 0;
                const num = Number(value.replace('%', ''));
                return Math.min(100, Math.max(0, num));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
