import React from 'react';
import { Modal, Descriptions, Button } from 'antd';
import { PulseDocket } from '../types';
import dayjs from 'dayjs';

interface DocketModalProps {
  docket: PulseDocket | null;
  open: boolean;
  onClose: () => void;
  onPrint?: () => void;
  customerName?: string;
  productDetails?: {
    name: string;
    type: string | null;
  } | null;
  truckDetails?: {
    registration: string;
  } | null;
}

const DocketModal: React.FC<DocketModalProps> = ({
  docket,
  open,
  onClose,
  onPrint,
  customerName,
  productDetails,
  truckDetails,
}) => {
  if (!docket) return null;

  return (
    <Modal
      title={`Docket #${docket.docket_number}`}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="print" type="primary" onClick={onPrint}>
          Print
        </Button>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800}
    >
      <Descriptions column={2}>
        <Descriptions.Item label="Fleet Number">{docket.fleet_number}</Descriptions.Item>
        <Descriptions.Item label="Job ID">{docket.job_id}</Descriptions.Item>
        <Descriptions.Item label="Customer">{customerName || docket.customer_id}</Descriptions.Item>
        {productDetails && (
          <>
            <Descriptions.Item label="Product">{productDetails.name}</Descriptions.Item>
            {productDetails.type && (
              <Descriptions.Item label="Product Type">{productDetails.type}</Descriptions.Item>
            )}
          </>
        )}
        {truckDetails && (
          <Descriptions.Item label="Registration">{truckDetails.registration}</Descriptions.Item>
        )}
        <Descriptions.Item label="Gross Weight">{docket.gross_weight}</Descriptions.Item>
        <Descriptions.Item label="Tare Weight">{docket.tare_weight}</Descriptions.Item>
        <Descriptions.Item label="Net Weight">{docket.net_weight}</Descriptions.Item>
        <Descriptions.Item label="Quantity">{docket.quantity}</Descriptions.Item>
        <Descriptions.Item label="Progressive">{docket.progressive}</Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dayjs(docket.created_at).format('DD/MM/YYYY HH:mm')}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default DocketModal;
