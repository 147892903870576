import React, { useState } from 'react';
import { App, Typography } from 'antd';
import { DocketProvider } from '../context/DocketContext';
import DocketForm from './DocketForm';
import XbinDocket from './xbin/XbinDocket';
import XbinTruck from './xbin/XbinTruck';
import DocketListPage from './DocketListPage';
import DocketNavigation from './DocketNavigation';
import './Dockets.css';

const { Title } = Typography;

const Dockets: React.FC = () => {
  const [currentPage, setCurrentPage] = useState('dockets');

  const renderContent = () => {
    switch (currentPage) {
      case 'dockets':
        return (
          <DocketProvider>
            <DocketForm />
          </DocketProvider>
        );
      case 'xbinDocket':
        return <XbinDocket />;
      case 'xbinTruck':
        return <XbinTruck />;
      case 'docketList':
        return <DocketListPage />;
      default:
        return null;
    }
  };

  const getPageTitle = () => {
    switch (currentPage) {
      case 'dockets':
        return 'Create New Docket';
      case 'xbinDocket':
        return 'Xbin Docket';
      case 'xbinTruck':
        return 'Xbin Truck';
      case 'docketList':
        return "All of Today's Dockets";
      default:
        return '';
    }
  };

  return (
    <div className="dockets-container">
      <div className="dockets-content">
        <div className="section-container">
          <DocketNavigation 
            currentPage={currentPage} 
            onPageChange={setCurrentPage} 
          />
        </div>
        <div className="section-container">
          <Title level={3} style={{ margin: '4px 0', textAlign: 'center' }}>{getPageTitle()}</Title>
        </div>
        <div className="section-container">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dockets;
