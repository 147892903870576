import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import { UserOutlined, PhoneOutlined } from '@ant-design/icons';
import { formatPhoneNumber } from '../../../../constants/validation';
import type { FormInstance } from 'antd/lib/form';
import type { Customer } from '../../types';

interface ContactDetailsProps {
  form: FormInstance;
  customers: Customer[];
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({ form, customers }) => {
  const handlePhoneChange = (value: string) => {
    const formatted = formatPhoneNumber(value);
    if (formatted !== value) {
      form.setFieldValue('contact_number', formatted);
    }
  };

  return (
    <Row gutter={12}>
      <Col span={8}>
        <Form.Item
          name="site_contact_name"
          label="Site Contact Name"
          tooltip="Name of the contact person at the delivery site"
        >
          <Input prefix={<UserOutlined />} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="contact_number"
          label="Contact Number"
          tooltip="Phone number for the site contact"
          rules={[
            { pattern: /^[0-9() -]*$/, message: 'Please enter a valid phone number' }
          ]}
        >
          <Input
            prefix={<PhoneOutlined />}
            onChange={(e) => handlePhoneChange(e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="uhf_channel"
          label="UHF Channel"
          tooltip="UHF radio channel for communication at the site"
        >
          <Input prefix={<PhoneOutlined />} />
        </Form.Item>
      </Col>
    </Row>
  );
};
