import React from 'react';
import { Table, Tag, Space, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, UserAddOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Truck } from '../types';
import { getStatusColor } from '../utils/truckUtils';
import { TRUCK_TYPES } from '../constants';

const iconStyle = { fontSize: '16px' };

export interface TruckTableProps {
    trucks: Truck[];
    loading: boolean;
    onEdit: (truck: Truck) => void;
    onDelete: (truck: Truck) => void;
    onAssign: (truck: Truck) => void;
    onViewDetails: (truck: Truck) => void;
}

export const TruckTable: React.FC<TruckTableProps> = ({
    trucks,
    loading,
    onEdit,
    onDelete,
    onAssign,
    onViewDetails,
}) => {
    const columns: ColumnsType<Truck> = [
        {
            title: 'Fleet Number',
            dataIndex: 'fleet_number',
            key: 'fleet_number',
            sorter: (a, b) => a.fleet_number.localeCompare(b.fleet_number),
            width: 150,
        },
        {
            title: 'Driver',
            key: 'driver',
            width: 150,
            render: (_, record) => (
                record.current_assignment?.driver ? (
                    <Tag color="processing">
                        {record.current_assignment.driver.name}
                    </Tag>
                ) : (
                    <Tag>Unassigned</Tag>
                )
            ),
            sorter: (a, b) => {
                const aName = a.current_assignment?.driver?.name || '';
                const bName = b.current_assignment?.driver?.name || '';
                return aName.localeCompare(bName);
            },
        },
        {
            title: 'Registration',
            dataIndex: 'registration',
            key: 'registration',
            width: 150,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            filters: TRUCK_TYPES.map(type => ({
                text: type,
                value: type,
            })),
            onFilter: (value, record) => record.type === value,
        },
        {
            title: 'Max Gross',
            key: 'max_gross',
            width: 150,
            render: (_, record) => {
                if (record.type === 'Semi') {
                    return `${record.trailer_max_gross?.toFixed(1)}t`;
                } else if (record.type === 'Truck and Dog') {
                    return (
                        <Tooltip title={`Body: ${record.body_max_gross?.toFixed(1)}t + Trailer: ${record.trailer_max_gross?.toFixed(1)}t`}>
                            {record.combined_max_gross?.toFixed(1)}t
                        </Tooltip>
                    );
                } else {
                    return `${record.body_max_gross?.toFixed(1)}t`;
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status: string) => (
                <Tag color={getStatusColor(status)}>{status}</Tag>
            ),
            filters: Array.from(new Set(trucks.map(t => t.status))).map(status => ({
                text: status,
                value: status,
            })),
            onFilter: (value, record) => record.status === String(value),
        },
        {
            title: 'Location',
            dataIndex: 'location_name',
            key: 'location',
            width: 200,
            filters: Array.from(new Set(trucks.map(t => t.location_name).filter((location): location is string => !!location))).map(location => ({
                text: location,
                value: location,
            })),
            onFilter: (value, record) => record.location_name === value,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 250,
            render: (_, record) => (
                <Space size="small">
                    <Tooltip title="Assign to Driver">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#1890ff' }}
                            icon={<UserAddOutlined />}
                            onClick={() => onAssign(record)}
                        />
                    </Tooltip>
                    <Tooltip title="View Details">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#52c41a' }}
                            icon={<EyeOutlined />}
                            onClick={() => onViewDetails(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Edit">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#faad14' }}
                            icon={<EditOutlined />}
                            onClick={() => onEdit(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Remove">
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete(record)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const [pageSize, setPageSize] = React.useState(10);

    return (
        <Table<Truck>
            columns={columns}
            dataSource={trucks}
            loading={loading}
            rowKey="id"
            pagination={{
                total: trucks.length,
                pageSize: pageSize,
                pageSizeOptions: ['5', '10', '20', '50', '100'],
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} trucks`,
                onShowSizeChange: (_, size) => setPageSize(size),
                position: ['bottomCenter']
            }}
            scroll={{ y: 700 }}
            size="middle"
            style={{ width: '100%', height: '100%' }}
        />
    );
};
