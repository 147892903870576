import React, { useState, useEffect, useRef } from 'react';
import { Card, Typography, Form, Input, Select, InputNumber, Button, Table, Row, Col, Spin, Tag, Statistic, App } from 'antd';
import { supabase } from '../../../../lib/supabaseClient';
import dayjs from 'dayjs';
import { FileTextOutlined, TruckOutlined, SearchOutlined } from '@ant-design/icons';
import ReactDOMServer from 'react-dom/server';
import { PrintTemplate } from '../../components/PrintTemplate';
import './XbinDocket.css';

// Define interfaces for our data structures
interface XbinTruck {
  id: string;
  registration: string;
  company: string;
  driver_name: string;
  fleet_number: string;
  tare: number;
  order_number: string;
}

interface Product {
  id: string;
  name: string;
  type: string | null;
  product_code: string;
  lot_number: string;
}

interface DailyDocket {
  docket_number: number;
  product: string;
  product_type: string | null;
  created_at: string;
  net_weight: number;
  is_xbin: boolean;
}

const { Title } = Typography;
const { Option } = Select;

const XbinDocket: React.FC = () => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [trucks, setTrucks] = useState<XbinTruck[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [dailyDockets, setDailyDockets] = useState<DailyDocket[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextDocketNumber, setNextDocketNumber] = useState<number>(0);
  const [dailyProgressive, setDailyProgressive] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedTruck, setSelectedTruck] = useState<XbinTruck | null>(null);
  const [grossWeight, setGrossWeight] = useState<number | null>(null);
  const [netWeight, setNetWeight] = useState<number | null>(null);

  const grossWeightInputRef = useRef<any>(null);

  useEffect(() => {
    fetchTrucks();
    fetchProducts();
    fetchNextDocketNumber();
    fetchDailyProgressive();
  }, []);

  useEffect(() => {
    if (grossWeight && selectedTruck) {
      const calculatedNetWeight = grossWeight - selectedTruck.tare;
      setNetWeight(calculatedNetWeight);
      form.setFieldsValue({ net_weight: calculatedNetWeight });
    } else {
      setNetWeight(null);
      form.setFieldsValue({ net_weight: null });
    }
  }, [grossWeight, selectedTruck, form]);

  // Fetch trucks from the database
  const fetchTrucks = async () => {
    try {
      const { data: truckData, error: truckError } = await supabase
        .from('xbin_trucks')
        .select('*');

      if (truckError) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch trucks',
          placement: 'topRight'
        });
        throw truckError;
      }

      if (truckData) {
        setTrucks(truckData);
      }
    } catch (error) {
      console.error('Error fetching trucks:', error);
    }
  };

  // Fetch products from the database
  const fetchProducts = async () => {
    const { data, error } = await supabase.from('products').select('*');
    if (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch products',
        placement: 'topRight'
      });
    } else {
      setProducts(data);
    }
  };

  // Get the next available docket number
  const fetchNextDocketNumber = async () => {
    try {
      const { data, error } = await supabase
        .from('pulse_dockets')
        .select('docket_number')
        .order('docket_number', { ascending: false })
        .limit(1)
        .single();

      if (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch next docket number',
          placement: 'topRight'
        });
        throw error;
      }
      setNextDocketNumber((data?.docket_number || 0) + 1);
    } catch (error) {
      console.error('Error fetching next docket number:', error);
    }
  };

  // Fetch daily progressive total
  const fetchDailyProgressive = async () => {
    const today = dayjs().startOf('day').toISOString();
    const { data, error } = await supabase
      .from('pulse_dockets')
      .select('net_weight')
      .eq('is_xbin', true)
      .gte('created_at', today);

    if (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch daily progressive total',
        placement: 'topRight'
      });
      console.error('Error fetching daily progressive:', error);
    } else {
      const total = data.reduce((sum, docket) => sum + (docket.net_weight || 0), 0);
      setDailyProgressive(total);
    }
  };

  // Fetch daily dockets for a specific truck
  const fetchDailyDockets = async (registration?: string) => {
    try {
      const today = dayjs().startOf('day').toISOString();
      const tomorrow = dayjs().endOf('day').toISOString();

      let query = supabase
        .from('pulse_dockets')
        .select('*')
        .eq('is_xbin', true)
        .gte('created_at', today)
        .lte('created_at', tomorrow)
        .order('created_at', { ascending: false });

      if (registration) {
        query = query.eq('fleet_number', registration);
      }

      const { data, error } = await query;

      if (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch daily dockets',
          placement: 'topRight'
        });
        throw error;
      }
      setDailyDockets(data || []);
    } catch (error) {
      console.error('Error fetching daily dockets:', error);
    }
  };

  // Handle truck selection
  const onTruckSelect = async (value: string) => {
    const selectedTruck = trucks.find(truck => truck.registration === value);
    if (selectedTruck) {
      form.setFieldsValue({
        customer: selectedTruck.company,
        tare_weight: selectedTruck.tare,
        order_number: '', // Clear the order number field
      });

      fetchDailyDockets(selectedTruck.registration);
    }
  };

  // Handle search for truck
  const handleSearch = async () => {
    if (!searchValue) return;
    setLoading(true);
    try {
      const { data: truckData, error: truckError } = await supabase
        .from('xbin_trucks')
        .select()
        .ilike('registration', `%${searchValue}%`)
        .single();

      if (truckError) {
        notification.error({
          message: 'Error',
          description: 'Failed to find truck',
          placement: 'topRight'
        });
        throw truckError;
      }

      if (truckData) {
        setSelectedTruck(truckData);
        form.setFieldsValue({
          customer: truckData.company,
          tare_weight: truckData.tare,
        });
        fetchDailyDockets(truckData.registration);
      }
    } catch (error) {
      console.error('Error searching for truck:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle save and print
  const handleSaveAndPrint = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Ensure we have all required values
      if (!selectedTruck || !grossWeight || !netWeight) {
        notification.error({
          message: 'Error',
          description: 'Please fill in all required fields',
          placement: 'topRight'
        });
        return;
      }

      // Create xbin order first
      const xbinOrder = {
        xbin_truck_id: selectedTruck.id,
        order_number: values.order_number || null,
        xbin_job_number: `xb-${nextDocketNumber.toString().padStart(5, '0')}`,
        created_at: dayjs().toISOString(),
        updated_at: dayjs().toISOString(),
      };

      const { data: newOrder, error: orderError } = await supabase
        .from('xbin_orders')
        .insert([xbinOrder])
        .select()
        .single();

      if (orderError) {
        notification.error({
          message: 'Error',
          description: 'Failed to create xBin docket',
          placement: 'topRight'
        });
        throw orderError;
      }

      // Create corresponding pulse docket
      const pulseDocket = {
        docket_number: nextDocketNumber,
        truck_id: selectedTruck.id,
        delivery_address: 'xBin',
        product_id: values.product_id,
        quantity: netWeight,
        gross_weight: grossWeight,
        tare_weight: selectedTruck.tare,
        net_weight: netWeight,
        fleet_number: selectedTruck.registration,
        is_xbin: true,
        xbin_job_number: `xb-${nextDocketNumber.toString().padStart(5, '0')}`,
        order_number: values.order_number || null,
        xbin_order_id: newOrder.id,
        created_at: dayjs().toISOString(),
      };

      const { error: docketError } = await supabase
        .from('pulse_dockets')
        .insert([pulseDocket]);

      if (docketError) {
        notification.error({
          message: 'Error',
          description: 'Failed to create xBin docket',
          placement: 'topRight'
        });
        throw docketError;
      }

      notification.success({
        message: 'Success',
        description: 'XBin docket created successfully',
        placement: 'topRight'
      });

      form.resetFields();
      setGrossWeight(null);
      setNetWeight(null);
      setSelectedTruck(null);
      fetchDailyProgressive();

    } catch (error: any) {
      console.error('Error creating xBin docket:', error);
    } finally {
      setLoading(false);
    }
  };

  // Print the docket
  const printXbinDocket = async (docketData: any, productData: any) => {
    const printContent = ReactDOMServer.renderToString(
      <PrintTemplate
        docketData={{
          docket_number: docketData.docket_number,
          plant: docketData.plant || '',
          customer: docketData.customer,
          customer_number: docketData.customer_number || '',
          delivery_address: 'xBin',
          suburb: docketData.suburb || '',
          order_number: docketData.order_number || '',
          product_name: productData.name,
          product_type: productData.type || '',
          product_code: productData.product_code || '',
          lot_number: productData.lot_number || '',
          xbin_job_number: docketData.xbin_job_number,
          fleet_number: docketData.fleet_number,
          progressive: docketData.progressive,
          gross_weight: docketData.gross_weight,
          tare_weight: docketData.tare_weight,
          net_weight: docketData.net_weight,
          quantity: Number(docketData.net_weight),
          location: docketData.location || 'Mt Cotton',
          truck_registration: docketData.truck_registration || docketData.fleet_number,
          registration: docketData.fleet_number || '',
          location_id: docketData.location_id || ''
        }}
        type="xbinDocket"
      />
    );

    try {
      // Create a temporary container for the print content
      const container = document.createElement('div');
      container.innerHTML = printContent;
      container.style.display = 'none';
      document.body.appendChild(container);

      // Add print styles
      const style = document.createElement('style');
      style.textContent = `
        @media print {
          body * {
            visibility: hidden;
          }
          #${container.id}, #${container.id} * {
            visibility: visible;
          }
          #${container.id} {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
          }
          @page {
            size: A4;
            margin: 10mm;
          }
        }
      `;
      document.head.appendChild(style);

      // Print
      window.print();

      // Cleanup
      setTimeout(() => {
        document.body.removeChild(container);
        document.head.removeChild(style);
      }, 1000);

      notification.success({
        message: 'Success',
        description: 'Docket sent to printer',
        placement: 'topRight'
      });

    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Failed to print docket: ${error instanceof Error ? error.message : 'Unknown error'}`,
        placement: 'topRight'
      });
      console.error('Error printing docket:', error);
    }
  };

  // Define columns for the daily dockets table
  const columns = [
    {
      title: 'Docket Number',
      dataIndex: 'docket_number',
      key: 'docket_number',
      render: (text: number) => text.toString().padStart(2, '0')
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text: string, record: DailyDocket) => `${text} ${record.product_type ? `(${record.product_type})` : ''}`
    },
    {
      title: 'Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string) => dayjs(text).format('HH:mm:ss')
    },
    { 
      title: 'Net Weight', 
      dataIndex: 'net_weight', 
      key: 'net_weight',
      render: (weight: number) => `${weight.toFixed(2)} t`
    },
    {
      title: 'Progressive',
      key: 'progressive',
      render: (_: any, record: DailyDocket, index: number) => {
        const progressive = dailyDockets
          .slice(index)
          .reduce((sum, docket) => sum + (docket.net_weight || 0), 0);
        return `${progressive.toFixed(2)} t`;
      }
    }
  ];

  return (
    <div className={`docket-container ${!selectedTruck ? 'search-only' : ''}`}>
      <div className="docket-main">
        <div className="search-container">
          <Title level={4} className="card-title">Enter XBin Registration</Title>
          <Input.Search
            placeholder="Enter XBin registration (e.g. XB01FT)"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={handleSearch}
            loading={loading}
            maxLength={10}
            size="large"
          />
        </div>

        {selectedTruck && (
          <div className="docket-content">
            <Card className="details-card">
              <Title level={4} className="card-title">Truck Details</Title>
              <Row gutter={[24, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <div className="detail-label">Registration</div>
                  <div className="detail-value">{selectedTruck.registration}</div>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <div className="detail-label">Driver</div>
                  <div className="detail-value">{selectedTruck.driver_name}</div>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <div className="detail-label">Company</div>
                  <div className="detail-value">{selectedTruck.company}</div>
                </Col>
              </Row>
            </Card>

            <Card className="details-card">
              <Title level={4} className="card-title">Docket Details</Title>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSaveAndPrint}
                initialValues={{
                  order_number: '',
                }}
              >
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Product"
                      name="product_id"
                      rules={[{ required: true, message: 'Please select a product' }]}
                    >
                      <Select placeholder="Select product">
                        {products.map((product) => (
                          <Option key={product.id} value={product.id}>
                            {product.name}
                            {product.type && <Tag color="blue" style={{ marginLeft: 8 }}>{product.type}</Tag>}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Order Number"
                      name="order_number"
                    >
                      <Input placeholder="Enter order number (optional)" />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="weight-input-container">
                  <div className="detail-label">Gross Weight (tonnes)</div>
                  <InputNumber
                    ref={grossWeightInputRef}
                    className="weight-input"
                    value={grossWeight}
                    onChange={(value) => setGrossWeight(value)}
                    min={0}
                    max={100}
                    step={0.01}
                    precision={2}
                    placeholder="Enter gross weight"
                  />
                </div>

                {netWeight !== null && (
                  <div className="weight-result">
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Statistic
                          title="Gross Weight"
                          value={grossWeight || 0}
                          precision={2}
                          suffix="t"
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title="Tare Weight"
                          value={selectedTruck?.tare || 0}
                          precision={2}
                          suffix="t"
                        />
                      </Col>
                      <Col span={8}>
                        <Statistic
                          title="Net Weight"
                          value={netWeight}
                          precision={2}
                          suffix="t"
                          valueStyle={{ color: '#1890ff' }}
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                <div className="action-buttons">
                  <Button 
                    type="primary" 
                    icon={<FileTextOutlined />}
                    htmlType="submit"
                    disabled={!netWeight}
                    loading={loading}
                    size="large"
                  >
                    Save & Print
                  </Button>
                </div>
              </Form>
            </Card>

            <Card className="details-card daily-dockets-table">
              <Title level={4} className="card-title">Today's Dockets</Title>
              <Table
                dataSource={dailyDockets}
                columns={[
                  {
                    title: 'Docket #',
                    dataIndex: 'docket_number',
                    key: 'docket_number',
                  },
                  {
                    title: 'Product',
                    dataIndex: 'product',
                    key: 'product',
                    render: (text, record) => (
                      <span>
                        {text}
                        {record.product_type && <Tag color="blue" style={{ marginLeft: 8 }}>{record.product_type}</Tag>}
                      </span>
                    ),
                  },
                  {
                    title: 'Net Weight',
                    dataIndex: 'net_weight',
                    key: 'net_weight',
                    render: (weight) => `${weight.toFixed(2)} t`,
                  },
                  {
                    title: 'Time',
                    dataIndex: 'created_at',
                    key: 'created_at',
                    render: (date) => dayjs(date).format('HH:mm:ss'),
                  },
                ]}
                pagination={false}
                size="small"
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default XbinDocket;
