import React, { useState } from 'react';
import { Modal, Descriptions, Tag, Typography, Space, Divider, Tabs } from 'antd';
import { Product } from '../types';
import { ProductPricing } from './ProductPricing';

const { Text } = Typography;
const { TabPane } = Tabs;

interface ProductDetailsProps {
  open: boolean;
  onCancel: () => void;
  product: Product;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  open,
  onCancel,
  product,
}) => {
  const [activeTab, setActiveTab] = useState<string>('1');

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const getStockStatusColor = (status: string) => {
    switch (status) {
      case 'In Stock':
        return 'success';
      case 'Low Stock':
        return 'warning';
      case 'Out of Stock':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Modal
      title={
        <Space>
          <Text strong>{product.name}</Text>
          <Tag color="blue">{product.type}</Tag>
        </Space>
      }
      open={open}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Details" key="1">
          <Descriptions column={2} bordered>
            <Descriptions.Item label="Product Code" span={1}>
              {product.product_code || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Category" span={1}>
              {product.category}
            </Descriptions.Item>
            <Descriptions.Item label="Type" span={1}>
              {product.type}
            </Descriptions.Item>
            <Descriptions.Item label="Times Ordered" span={1}>
              {product.times_ordered || 0}
            </Descriptions.Item>
            <Descriptions.Item label="Tonnes per Cube" span={1}>
              {product.tonnes_per_cube ? `${product.tonnes_per_cube} t/m³` : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Price per Tonne" span={1}>
              {product.price_per_tonne ? `$${product.price_per_tonne.toFixed(2)}` : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Description" span={2}>
              {product.description || 'No description available'}
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab="Pricing" key="2">
          <ProductPricing productId={product.id} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ProductDetails;
