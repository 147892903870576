import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../lib/supabaseClient';
import { Session, User } from '@supabase/supabase-js';
import { App } from 'antd';

interface AuthContextType {
  session: Session | null;
  user: User | null;
  signIn: (email: string, password: string) => Promise<{ error: Error | null }>;
  signOut: () => Promise<void>;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { notification } = App.useApp();
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Get the initial session
        const { data: { session: initialSession }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;

        if (initialSession) {
          setSession(initialSession);
          setUser(initialSession.user);
          setIsAuthenticated(true);
          
          // Verify the session is valid by making a test query
          const { error: testError } = await supabase
            .from('customers')
            .select('count', { count: 'exact', head: true });
            
          if (testError) {
            console.warn('Session may be invalid:', testError);
            if (testError.message.includes('JWT')) {
              await supabase.auth.refreshSession();
            }
          }
        }

        // Set up the auth state listener
        const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event: string, currentSession: Session | null) => {
          console.log('Auth state changed:', event, 'Session:', currentSession?.expires_at);
          
          setSession(currentSession);
          setUser(currentSession?.user ?? null);
          setIsAuthenticated(!!currentSession);

          if (event === 'SIGNED_OUT') {
            setIsAuthenticated(false);
          } else if (event === 'SIGNED_IN') {
            setIsAuthenticated(true);
          } else if (event === 'TOKEN_REFRESHED') {
            console.log('Token refreshed successfully');
            setIsAuthenticated(true);
          }
        });

        return () => {
          subscription.unsubscribe();
        };
      } catch (error) {
        console.error('Error initializing auth:', error);
        notification.error({
          message: 'Error Initializing Authentication',
          description: (error as Error).message,
          placement: 'topRight',
          key: 'auth-init-error',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
          }
        });
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [notification]);

  const signIn = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ 
        email, 
        password 
      });
      
      if (error) throw error;
      
      setIsAuthenticated(true);
      notification.success({
        message: 'Welcome Back',
        description: 'You have successfully signed in.',
        placement: 'topRight',
        key: 'sign-in-success',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      return { error: null };
    } catch (error) {
      console.error('Sign in error:', error);
      setIsAuthenticated(false);
      notification.error({
        message: 'Sign In Failed',
        description: (error as Error).message,
        placement: 'topRight',
        key: 'sign-in-error',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
      return { error: error as Error };
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      setIsAuthenticated(false);
      notification.info({
        message: 'Signed Out',
        description: 'You have been successfully signed out.',
        placement: 'topRight',
        key: 'sign-out-success',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    } catch (error) {
      console.error('Sign out error:', error);
      notification.error({
        message: 'Sign Out Failed',
        description: (error as Error).message,
        placement: 'topRight',
        key: 'sign-out-error',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }
      });
    }
  };

  if (loading) {
    return <div>Loading authentication...</div>;
  }

  const value: AuthContextType = {
    session,
    user,
    signIn,
    signOut,
    isAuthenticated
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};