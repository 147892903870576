import React, { useState, useEffect, useCallback } from 'react';
import { 
  Form, 
  Input, 
  Select, 
  Button, 
  Row, 
  Col, 
  Space, 
  App, 
  Upload, 
  Modal, 
  Spin, 
  Empty,
  Card,
  AutoComplete
} from 'antd';
import { 
  UploadOutlined, 
  SearchOutlined, 
  GlobalOutlined,
  UserOutlined,
  NumberOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  BankOutlined,
  CheckCircleOutlined,
  TagOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import { Customer, CustomerInput } from '../types';
import { supabase } from '../../../lib/supabaseClient';
import { LogoUploader } from './LogoUploader';
import debounce from 'lodash/debounce';
import { searchABN } from '../../../utils/api';

interface CustomerFormProps {
  initialValues?: Customer;
  onSubmit: (values: CustomerInput) => Promise<Customer>;
  onCancel: () => void;
  onSuccess?: () => void;
}

interface ABNSearchResult {
  Abn: string;
  AbnStatus: string;
  BusinessName: string;
  TradingName: string;
  EntityType: string;
  State: string;
  Postcode: string;
  DisplayName: string;
  AddressDetails?: {
    State: string;
    Postcode: string;
    AddressLine1: string;
    Suburb: string;
  };
}

interface AddressSuggestion {
  value: string;
  address: any;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export const CustomerForm: React.FC<CustomerFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  onSuccess,
}) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [logoPath, setLogoPath] = useState<string | null>(initialValues?.company_logo || null);
  const [abnResults, setAbnResults] = useState<ABNSearchResult[]>([]);
  const [loadingABN, setLoadingABN] = useState(false);
  const [businessName, setBusinessName] = useState<string>(initialValues?.customer_name || '');
  const [visible, setVisible] = useState(false);
  const [showStatusConfirm, setShowStatusConfirm] = useState(false);
  const [statusChangeValue, setStatusChangeValue] = useState<string | null>(null);
  const [showStopCreditWarning, setShowStopCreditWarning] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState<any>(null);

  useEffect(() => {
    if (!visible) return;

    const loadGooglePlaces = async () => {
      if (!window.google?.maps?.places) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        
        await new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
      }

      const input = document.getElementById('address');
      if (!input) return;

      const autocomplete = new google.maps.places.Autocomplete(input as HTMLInputElement, {
        types: ['address'],
        componentRestrictions: { country: 'AU' }
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.address_components) return;

        let streetNumber = '';
        let streetName = '';
        let suburb = '';
        let state = '';
        let postcode = '';

        place.address_components.forEach(component => {
          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          } else if (component.types.includes('route')) {
            streetName = component.long_name;
          } else if (component.types.includes('locality')) {
            suburb = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.short_name;
          } else if (component.types.includes('postal_code')) {
            postcode = component.long_name;
          }
        });

        form.setFieldsValue({
          address: `${streetNumber} ${streetName}`,
          suburb,
          state,
          postcode
        });
      });

      return () => {
        google.maps.event.clearInstanceListeners(input);
      };
    };

    loadGooglePlaces().catch(console.error);
  }, [visible, form]);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleABNSearch = async (businessName: string) => {
    if (!businessName || businessName.length < 3) return;

    setLoadingABN(true);
    try {
      const abnResponse = await searchABN(businessName);
      const abnData = abnResponse.data;

      const detailedResults = await Promise.all(
        (abnData.Names || [])
          .filter((result: any) => result.Abn && result.Name?.trim())
          .map(async (result: any) => {
            try {
              const detailResponse = await searchABN(result.Abn);
              const detailData = detailResponse.data;

              // Use the exact case from the ABN search
              return {
                Abn: result.Abn,
                BusinessName: detailData.EntityName || result.Name,
                TradingName: detailData.BusinessName || '',
                EntityType: detailData.EntityType?.replace(/\([^)]*\)/g, '').trim() || '',
                State: detailData.AddressDetails?.State || '',
                Postcode: detailData.AddressDetails?.Postcode || '',
                AbnStatus: detailData.EntityStatus,
                DisplayName: `${detailData.EntityName || result.Name}${detailData.AddressDetails?.State ? ` - ${detailData.AddressDetails.State}` : ''}`
              };
            } catch (error) {
              console.error('Error fetching ABN details:', error);
              return null;
            }
          })
      );

      setAbnResults(detailedResults.filter((result: any): result is ABNSearchResult => result !== null));
    } catch (error) {
      console.error('Error searching ABN:', error);
    } finally {
      setLoadingABN(false);
    }
  };

  const generateCustomerNumber = () => {
    const prefix = 'C';
    const randomNum = Math.floor(Math.random() * 1000000000);
    return `${prefix}${randomNum.toString().padStart(9, '0')}`;
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setBusinessName(initialValues.customer_name || '');
    }
  }, [initialValues, form]);

  const searchBusinessInfo = async (businessName: string) => {
    try {
      // Search for business website using Google Custom Search API
      const searchResponse = await fetch(
        `https://www.googleapis.com/customsearch/v1?key=${process.env.REACT_APP_GOOGLE_API_KEY}&cx=${process.env.REACT_APP_GOOGLE_CSE_ID}&q=${encodeURIComponent(businessName)}`
      );
      const searchData = await searchResponse.json();
      
      if (searchData.items && searchData.items.length > 0) {
        const firstResult = searchData.items[0];
        form.setFieldsValue({
          website: firstResult.link,
          domain: new URL(firstResult.link).hostname
        });
      }
    } catch (error) {
      console.error('Error searching business info:', error);
    }
  };

  const handleBusinessNameChange = useCallback((value: string) => {
    if (value.length >= 3) {
      handleABNSearch(value);
    } else {
      setAbnResults([]);
    }
  }, []);

  const handleLogoChange = (path: string | null) => {
    setLogoPath(path);
  };

  const handleFinish = async (values: CustomerInput) => {
    setLoading(true);
    try {
      // Preserve exact case from form values
      const formData = {
        ...values,
        customer_name: values.customer_name // Use the exact case from the form
      };

      const updatedCustomer = await onSubmit(formData);
      if (onSuccess) {
        onSuccess();
      }
      return updatedCustomer;
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'topRight'
        });
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const submitForm = async (values: any) => {
    try {
      // Ensure customer number exists
      if (!values.customer_number) {
        values.customer_number = generateCustomerNumber();
      }

      const customerData = {
        ...values,
        company_logo: logoPath,
        customer_number: values.customer_number
      };

      // Submit the customer data
      const result = await onSubmit(customerData);
      
      // Clear form and state
      form.resetFields();
      setLogoPath(null);
      setPendingSubmission(null);
      
      // Call success callback
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error('Error saving customer:', error);
      throw error;
    }
  };

  const handleStopCreditConfirm = async () => {
    if (pendingSubmission) {
      await submitForm(pendingSubmission);
    }
    setShowStopCreditWarning(false);
  };

  const handleStopCreditCancel = () => {
    form.setFieldsValue({ status: 'Stop Credit' });
    setPendingSubmission(null);
    setShowStopCreditWarning(false);
  };

  const handleStatusChange = (value: string) => {
    const currentStatus = form.getFieldValue('status');
    if (currentStatus === 'Stop Credit' && value !== 'Stop Credit') {
      setStatusChangeValue(value);
      setShowStatusConfirm(true);
    } else {
      form.setFieldsValue({ status: value });
    }
  };

  const handleStatusConfirm = () => {
    if (statusChangeValue) {
      form.setFieldsValue({ status: statusChangeValue });
    }
    setShowStatusConfirm(false);
    setStatusChangeValue(null);
  };

  const handleStatusCancel = () => {
    setShowStatusConfirm(false);
    setStatusChangeValue(null);
    form.setFieldsValue({ status: 'Stop Credit' });
  };

  const formatPhoneNumber = (value: string): string => {
    if (!value) return value;

    // Remove all non-digit characters
    const number = value.replace(/\D/g, '');

    // Check if it's an international format starting with +61
    if (value.startsWith('+61')) {
      // Mobile number: +61 4XX XXX XXX
      if (number.length === 11 && number.startsWith('614')) {
        return `+61 ${number.slice(3, 4)}${number.slice(4, 7)} ${number.slice(7, 10)}`;
      }
      // Landline: +61 X XXXX XXXX
      if (number.length === 11) {
        return `+61 ${number.slice(3, 4)} ${number.slice(4, 8)} ${number.slice(8)}`;
      }
    }

    // Check for mobile number starting with 04
    if (number.startsWith('04')) {
      // Mobile format: 04XX XXX XXX
      return number.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
    }

    // Must be landline: 0X XXXX XXXX
    return number.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
  };

  const validatePhoneNumber = (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }

    // Remove all non-digit characters for validation
    const number = value.replace(/\D/g, '');

    // Valid formats:
    // 1. Mobile: 04XX XXX XXX (10 digits) or +61 4XX XXX XXX (11 digits)
    // 2. Landline: 0X XXXX XXXX (10 digits) or +61 X XXXX XXXX (11 digits)
    const isMobile = number.startsWith('04') || (number.startsWith('614') && number.length === 11);
    const isLandline = (number.startsWith('0') && !number.startsWith('04')) || 
                      (number.startsWith('61') && !number.startsWith('614') && number.length === 11);

    if (number.length !== 10 && number.length !== 11) {
      return Promise.reject('Please enter a valid Australian phone number');
    }

    if (!isMobile && !isLandline) {
      return Promise.reject('Please enter a valid Australian phone number format');
    }

    return Promise.resolve();
  };

  const handleCancel = () => {
    // Reset all form fields and states
    form.resetFields();
    setAbnResults([]);
    setLogoPath('');
    if (!initialValues) {
      form.setFieldsValue({ customer_number: generateCustomerNumber() });
    }
    onCancel();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{ customer_number: generateCustomerNumber() }}
      style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
    >
      {/* Basic Information */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Customer Number"
            name="customer_number"
          >
            <Input 
              prefix={<NumberOutlined />}
              placeholder="Enter customer number"
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Business Name"
            name="customer_name"
            rules={[{ required: true, message: 'Please enter business name' }]}
            normalize={(value) => value} // Preserve exact case
          >
            <Space.Compact style={{ width: '100%' }}>
              <Form.Item 
                name="customer_name" 
                noStyle
                initialValue={initialValues?.customer_name || ''}
                normalize={(value: string) => value}
              >
                <Input
                  style={{ width: '100%' }}
                  prefix={<BankOutlined />}
                  placeholder="Enter business name"
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </Form.Item>
              <Button
                icon={<SearchOutlined />}
                onClick={() => handleBusinessNameChange(businessName)}
                disabled={!businessName}
              >
                Find ABN
              </Button>
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>

      {/* ABN Field */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="ABN"
            name="abn"
          >
            <Input
              prefix={<NumberOutlined />}
              placeholder="Enter ABN"
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Contact Information */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Contact Name"
            name="primary_contact_name"
          >
            <Input prefix={<UserOutlined />} placeholder="Enter contact name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Enter email" />
          </Form.Item>
        </Col>
      </Row>

      {/* Additional Contact Information */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Phone"
            name="primary_contact_phone"
            rules={[
              { validator: validatePhoneNumber }
            ]}
            getValueFromEvent={(e) => formatPhoneNumber(e.target.value)}
            normalize={formatPhoneNumber}
          >
            <Input prefix={<PhoneOutlined />} placeholder="Enter phone number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Website"
            name="website"
          >
            <Input prefix={<GlobalOutlined />} placeholder="Enter website" />
          </Form.Item>
        </Col>
      </Row>

      {/* Address, Type and Status */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label="Address"
            style={{ marginBottom: 0 }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Form.Item
                name="address"
              >
                <Input
                  id="address"
                  placeholder="Start typing to search address"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Space style={{ width: '100%' }} size="small">
                <Form.Item
                  name="suburb"
                  style={{ marginBottom: 0 }}
                >
                  <Input prefix={<EnvironmentOutlined />} placeholder="Suburb" />
                </Form.Item>
                <Form.Item
                  name="state"
                  style={{ marginBottom: 0 }}
                >
                  <Input prefix={<EnvironmentOutlined />} placeholder="State" />
                </Form.Item>
                <Form.Item
                  name="postcode"
                  style={{ marginBottom: 0 }}
                >
                  <Input prefix={<EnvironmentOutlined />} placeholder="Postcode" />
                </Form.Item>
              </Space>
            </Space>
          </Form.Item>
        </Col>
      </Row>

      {/* Type */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <TagOutlined />
                <span>Type</span>
              </Space>
            }
            name="type"
          >
            <Select
              allowClear
              placeholder="Select type"
              options={[
                { value: 'Xbin', label: 'Xbin' },
                { value: 'Supplier', label: 'Supplier' },
                { value: 'Regular', label: 'Regular' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Space>
                <CheckCircleOutlined />
                <span>Status</span>
              </Space>
            }
            name="status"
            initialValue={initialValues?.status || 'Active'}
          >
            <Select onChange={handleStatusChange}>
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Stop Credit">Stop Credit</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* Account Type */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label={
              <Space>
                <TagOutlined />
                <span>Account Type</span>
              </Space>
            }
            name="account_type"
          >
            <Select
              allowClear
              placeholder="Select account type"
              options={[
                { value: 'Account', label: 'Account' },
                { value: '7 Day Account', label: '7 Day Account' },
                { value: 'Cash Account', label: 'Cash Account' }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Logo Upload */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item label="Company Logo">
            <LogoUploader
              initialLogo={logoPath}
              onLogoChange={handleLogoChange}
              businessName={initialValues?.customer_name || form.getFieldValue('customer_name')}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* ABN Search Results */}
      {loadingABN && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin />
        </div>
      )}
      
      {abnResults.length > 0 && !loadingABN && (
        <Row>
          <Col span={24}>
            <Card title="ABN Search Results" size="small" style={{ marginBottom: '16px' }}>
              {abnResults.map((result, index) => (
                <div
                  key={`abn-${result.Abn}-${index}`}
                  onClick={() => {
                    // Create a copy of the form data to preserve case
                    const formData: Partial<CustomerInput> = {
                      ...form.getFieldsValue(),
                      abn: result.Abn,
                      customer_name: result.BusinessName || result.TradingName,
                      state: result.State,
                      postcode: result.Postcode,
                      type: result.EntityType,
                      status: result.AbnStatus === 'Active' ? 'Active' : 'Inactive',
                      account_type: 'Account'
                    };

                    // Only set address and suburb if they exist
                    if (result.AddressDetails) {
                      formData.suburb = result.AddressDetails.Suburb;
                      formData.address = result.AddressDetails.AddressLine1;
                    }

                    // Set form values and update state
                    form.setFieldsValue(formData);
                    setBusinessName(formData.customer_name || '');
                    setAbnResults([]);
                  }}
                  style={{
                    padding: '12px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    marginBottom: '8px',
                    cursor: 'pointer',
                    transition: 'all 0.3s',
                  }}
                  onMouseEnter={(e) => {
                    const target = e.currentTarget;
                    target.style.borderColor = '#40a9ff';
                    target.style.boxShadow = '0 0 0 2px rgba(24,144,255,0.2)';
                  }}
                  onMouseLeave={(e) => {
                    const target = e.currentTarget;
                    target.style.borderColor = '#d9d9d9';
                    target.style.boxShadow = 'none';
                  }}
                >
                  <div><strong>{result.BusinessName || result.TradingName}</strong></div>
                  <div>ABN: {result.Abn}</div>
                  <div>{result.EntityType}</div>
                  {result.State && result.Postcode && (
                    <div>{result.State}, {result.Postcode}</div>
                  )}
                  <Button 
                    type="link" 
                    size="small" 
                    style={{ padding: 0, height: 'auto', marginTop: '4px' }}
                  >
                    Use This Business
                  </Button>
                </div>
              ))}
            </Card>
          </Col>
        </Row>
      )}

      {/* Form Actions */}
      <Row justify="end" gutter={[16, 0]}>
        <Col>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {initialValues ? 'Update' : 'Create'} Customer
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Single Modal for Stop Credit Warning */}
      <Modal
        title="Warning: Changing from Stop Credit Status"
        open={showStopCreditWarning || showStatusConfirm}
        onOk={showStopCreditWarning ? handleStopCreditConfirm : handleStatusConfirm}
        onCancel={showStopCreditWarning ? handleStopCreditCancel : handleStatusCancel}
        okText="Yes, Change Status"
        cancelText="No, Keep Stop Credit"
      >
        <p>Warning: Changing this customer's status from Stop Credit will allow jobs to be created for this customer again.</p>
        <p>Are you sure you want to proceed with this change?</p>
      </Modal>
    </Form>
  );
};
