import React, { useState, useEffect } from 'react';
import { Layout as AntLayout, Dropdown, Button, Menu, Typography } from 'antd';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { UserOutlined, DashboardOutlined, CalendarOutlined, TeamOutlined, CarOutlined, ShopOutlined, DownOutlined, FileTextOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabaseClient';
import type { MenuProps } from 'antd';
import { versionInfo } from '../version';

const { Header, Content, Sider, Footer } = AntLayout;
const { Text } = Typography;

const Layout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user) {
        const { data: authUser } = await supabase.auth.getUser();
        if (authUser && authUser.user) {
          setUsername(authUser.user.user_metadata.display_name || authUser.user.email);
        }
      }
    };

    fetchUserDetails();
  }, [user]);

  const openDocketsWindow = () => {
    window.open('/dockets', 'Dockets', 'width=1024,height=768');
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/login');
  };

  const getIcon = (icon: React.ReactNode, color: string) => (
    <div style={{
      fontSize: '24px',
      color: color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      background: `${color}15`,
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    }}>
      {icon}
    </div>
  );

  const menuItems: MenuProps['items'] = [
    {
      key: '/dashboard',
      icon: getIcon(<DashboardOutlined />, '#1890ff'),
      label: 'Dashboard',
      className: 'menu-item'
    },
    {
      key: '/planner',
      icon: getIcon(<CalendarOutlined />, '#52c41a'),
      label: 'Daily Planner',
      className: 'menu-item'
    },
    {
      key: '/customers',
      icon: getIcon(<TeamOutlined />, '#722ed1'),
      label: 'Customers',
      className: 'menu-item'
    },
    {
      key: '/products',
      icon: getIcon(<ShopOutlined />, '#fa8c16'),
      label: 'Products',
      className: 'menu-item'
    },
    {
      key: '/drivers',
      icon: getIcon(<UserOutlined />, '#eb2f96'),
      label: 'Drivers',
      className: 'menu-item'
    },
    {
      key: '/trucks',
      icon: getIcon(<CarOutlined />, '#13c2c2'),
      label: 'Trucks',
      className: 'menu-item'
    },
  ];

  const userMenuItems: MenuProps['items'] = [
    {
      key: 'profile',
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: 'logout',
      label: 'Logout',
      onClick: handleSignOut,
    },
  ];

  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={!isHovering && collapsed}
        onCollapse={setCollapsed}
        theme="light"
        width={240}
        collapsedWidth={80}
        style={{
          position: 'fixed',
          height: '100vh',
          left: 0,
          top: 0,
          zIndex: 1000,
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          background: '#ffffff',
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
        }}
      >
        <div className="logo" style={{
          height: 80,
          padding: '4px',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid #f0f0f0',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          overflow: 'hidden'
        }}>
          <img
            src={(!isHovering && collapsed) ? "files/logo/logo.png" : "files/logo/logodark.png"}
            alt="Logo"
            style={{
              width: (!isHovering && collapsed) ? '72px' : '140px',
              height: (!isHovering && collapsed) ? '72px' : '40px',
              objectFit: 'contain',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
            }}
          />
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={menuItems}
          onClick={({ key }) => navigate(key)}
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            flex: 1,
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
          }}
        />
        <div style={{
          borderTop: '1px solid #f0f0f0',
          padding: '16px 0',
          marginTop: 'auto'
        }}>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[]}
            items={[
              {
                key: 'dockets',
                icon: getIcon(<FileTextOutlined />, '#1890ff'),
                label: 'Open Dockets',
                onClick: openDocketsWindow,
                className: 'menu-item'
              }
            ]}
            style={{
              border: 'none',
              backgroundColor: 'transparent'
            }}
          />
        </div>
      </Sider>
      <AntLayout style={{
        marginLeft: 80,
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        position: 'relative',
        zIndex: 1
      }}>
        <Header style={{
          background: '#fff',
          width: 'calc(100% - 80px)',
          padding: '0px',
          height: '48px',
          lineHeight: '48px',
          borderBottom: '1px solid #d9d9d9',
          borderTop: '1px solid #d9d9d9',
          position: 'fixed',
          zIndex: 1
        }}>
          <div style={{ float: 'right' }}>
            {user && (
              <Dropdown menu={{ items: userMenuItems }} trigger={['click']}>
                <Button type="link" className="ant-dropdown-link">
                  <UserOutlined style={{ marginRight: 8 }} />
                  {username || 'User'} <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </Header>
        <Content style={{
          margin: '48px 0 48px',
          height: 'calc(100vh - 96px)',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
        }}>
          <div className="page-content" style={{ height: '100%' }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{
          textAlign: 'center',
          height: '48px',
          lineHeight: '47px',
          padding: 0,
          borderTop: '1px solid #d9d9d9',
          position: 'fixed',
          width: '100%',
          bottom: 0,
          zIndex: 1
        }}>
          <Text type="secondary">AllocateIt v{versionInfo.version} {new Date(versionInfo.buildDate).getFullYear()} Redbay Digital</Text>
        </Footer>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;