import React, { useState, useEffect, useCallback } from 'react';
import { Form, InputNumber, Button, Card, Typography, Row, Col, Tag, Spin, App, Space, Divider, Alert } from 'antd';
import { supabase } from '../../../../lib/supabaseClient';
import { FileTextOutlined, CreditCardOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface CreditProps {
  docketId: string;
  onCreditCreated: () => void;
}

interface DocketData {
  id: string;
  quantity: number;
  is_credit: boolean;
}

const Credit: React.FC<CreditProps> = ({ docketId, onCreditCreated }) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [originalDocket, setOriginalDocket] = useState<any>(null);

  const fetchOriginalDocket = useCallback(async () => {
    if (!docketId) {
      notification.error({
        message: 'Error',
        description: 'No docket ID provided',
        placement: 'topRight'
      });
      return;
    }

    setLoading(true);
    try {
      // First fetch the docket
      const { data: docketData, error: docketError } = await supabase
        .from('pulse_dockets')
        .select('*')
        .eq('id', docketId)
        .single();

      if (docketError) throw docketError;

      if (!docketData) {
        throw new Error('No docket found');
      }

      let jobData = null;
      let productData = null;
      let customerData = null;

      if (docketData.job_id) {
        const { data: job } = await supabase
          .from('jobs')
          .select('id, job_number, progressive')
          .eq('id', docketData.job_id)
          .single();
        jobData = job;
      }

      if (docketData.product_id) {
        const { data: product } = await supabase
          .from('products')
          .select('name, type')
          .eq('id', docketData.product_id)
          .single();
        productData = product;
      }

      if (docketData.customer_id) {
        const { data: customer } = await supabase
          .from('customers')
          .select('customer_name')
          .eq('id', docketData.customer_id)
          .single();
        customerData = customer;
      }

      const combinedData = {
        ...docketData,
        job: jobData,
        product: productData || { name: 'Unknown', type: null },
        customer: customerData?.customer_name || 'Unknown Customer'
      };

      setOriginalDocket(combinedData);
      form.setFieldsValue({
        quantity: combinedData.quantity,
        net_weight: combinedData.net_weight,
      });
    } catch (error: any) {
      console.error('Error fetching original docket:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to fetch original docket details',
        placement: 'topRight'
      });
    } finally {
      setLoading(false);
    }
  }, [docketId, form, notification]);

  useEffect(() => {
    if (docketId) {
      fetchOriginalDocket();
    }
  }, [fetchOriginalDocket, docketId]);

  const handleCreditSubmit = async (values: any) => {
    if (!originalDocket) {
      notification.error({
        message: 'Error',
        description: 'No original docket data available',
        placement: 'topRight'
      });
      return;
    }

    setLoading(true);
    try {
      console.log('Creating credit with values:', values);
      console.log('Original docket:', originalDocket);

      // First get the current progressive
      const { data: currentJob } = await supabase
        .from('jobs')
        .select('progressive')
        .eq('id', originalDocket.job_id)
        .single();

      console.log('Current job progressive:', currentJob?.progressive);

      // Calculate new progressive - ALWAYS subtract the credit amount
      const currentProgressive = parseFloat(currentJob?.progressive || '0');
      const creditAmount = Math.abs(values.quantity); // Make sure it's positive
      const newProgressive = currentProgressive - creditAmount; // Always subtract

      console.log('Progressive calculation:', {
        currentProgressive,
        creditAmount,
        newProgressive,
        operation: 'SUBTRACT' // Log the operation for clarity
      });

      const creditDocket = {
        docket_number: originalDocket.docket_number, // Use the same docket number
        job_id: originalDocket.job_id,
        truck_id: originalDocket.truck_id,
        customer_id: originalDocket.customer_id,
        delivery_address: originalDocket.delivery_address,
        product_id: originalDocket.product_id,
        quantity: -Math.abs(values.quantity), // Ensure quantity is negative
        net_weight: -Math.abs(values.net_weight), // Ensure net_weight is negative
        is_credit: true,
        parent_docket_id: docketId,
        created_at: new Date().toISOString(),
        progressive: newProgressive.toString() // Add progressive to the docket
      };

      console.log('Creating credit docket:', creditDocket);

      // Create the credit docket with the negative progressive value
      const { data: insertedDocket, error: insertError } = await supabase
        .from('pulse_dockets')
        .insert(creditDocket)
        .select()
        .single();

      if (insertError) throw insertError;
      if (!insertedDocket) throw new Error('Failed to create credit docket');

      console.log('Inserted credit docket:', insertedDocket);

      // Update the job's progressive value directly with the calculated value
      const { error: updateError } = await supabase
        .from('jobs')
        .update({ progressive: newProgressive.toString() })
        .eq('id', originalDocket.job_id);

      if (updateError) throw updateError;

      // Double-check the update worked
      const { data: verifyJob } = await supabase
        .from('jobs')
        .select('progressive')
        .eq('id', originalDocket.job_id)
        .single();

      console.log('Final job progressive after update:', verifyJob?.progressive);

      notification.success({
        message: 'Success',
        description: 'Credit docket created successfully',
        placement: 'topRight'
      });

      onCreditCreated();
    } catch (error: any) {
      console.error('Error creating credit:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to create credit docket',
        placement: 'topRight'
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!originalDocket) {
    return (
      <Card>
        <Alert
          message="No Docket Data"
          description="Unable to load docket information"
          type="error"
          showIcon
        />
      </Card>
    );
  }

  return (
    <App>
      <Card className="credit-card">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Alert
            message="Creating Credit Docket"
            description="Please verify all details carefully before proceeding. This action will create a credit against the original docket."
            type="warning"
            showIcon
            icon={<WarningOutlined />}
            style={{ marginBottom: '24px' }}
          />

          <Card 
            title={
              <Space>
                <FileTextOutlined style={{ color: '#1890ff' }} />
                <Typography.Text strong>Original Docket Details</Typography.Text>
                <Tag color="blue">#{originalDocket.docket_number}</Tag>
              </Space>
            }
            className="details-card"
          >
            <Row gutter={[24, 16]}>
              <Col span={12}>
                <Typography.Text type="secondary">Customer</Typography.Text>
                <Typography.Title level={5}>{originalDocket.customer}</Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Job Number</Typography.Text>
                <Typography.Title level={5}>{originalDocket.job?.job_number || 'N/A'}</Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Product</Typography.Text>
                <Typography.Title level={5}>{originalDocket.product.name}</Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Current Progressive</Typography.Text>
                <Typography.Title level={5}>{originalDocket.job?.progressive || '0'} t</Typography.Title>
              </Col>
              <Col span={24}>
                <Typography.Text type="secondary">Created</Typography.Text>
                <Typography.Title level={5}>
                  {dayjs(originalDocket.created_at).format('DD/MM/YYYY HH:mm')}
                </Typography.Title>
              </Col>
            </Row>
          </Card>

          <Card 
            title={
              <Space>
                <CreditCardOutlined style={{ color: '#f5222d' }} />
                <Typography.Text strong>Credit Details</Typography.Text>
              </Space>
            }
            className="credit-details-card"
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleCreditSubmit}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="quantity"
                    label="Quantity to Credit"
                    rules={[
                      { required: true, message: 'Please enter quantity' },
                      {
                        type: 'number',
                        max: originalDocket.quantity,
                        message: 'Cannot exceed original quantity'
                      }
                    ]}
                  >
                    <InputNumber 
                      style={{ width: '100%' }}
                      placeholder="Enter quantity to credit"
                      prefix={<Tag color="red">-</Tag>}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="net_weight"
                    label="Net Weight to Credit"
                    rules={[
                      { required: true, message: 'Please enter net weight' },
                      {
                        type: 'number',
                        max: originalDocket.net_weight,
                        message: 'Cannot exceed original net weight'
                      }
                    ]}
                  >
                    <InputNumber 
                      style={{ width: '100%' }}
                      placeholder="Enter net weight to credit"
                      prefix={<Tag color="red">-</Tag>}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading}
                  icon={<CheckCircleOutlined />}
                  danger
                >
                  Create Credit Docket
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </Card>

      <style>
        {`
          .credit-card {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 8px rgba(0,0,0,0.15);
          }
          .details-card {
            background: #fafafa;
            border: 1px solid #f0f0f0;
          }
          .credit-details-card {
            background: #fff1f0;
            border: 1px solid #ffccc7;
          }
          .ant-typography.ant-typography-secondary {
            font-size: 12px;
          }
          .ant-typography.ant-typography-h5 {
            margin-top: 4px !important;
            margin-bottom: 0 !important;
          }
        `}
      </style>
    </App>
  );
};

export default Credit;