import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, Switch, Space, Row, Col, Card, Typography, Spin, Tooltip, App } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined, TruckOutlined } from '@ant-design/icons';
import { supabase } from '../../../../lib/supabaseClient';

const { Title } = Typography;
const { Option } = Select;

interface XbinTruck {
  id: string;
  company: string;
  driver_name: string;
  driver_inducted: boolean;
  date_inducted: string | null;
  contact_number: string;
  truck_type: string;
  tare: number;
  max_gross: number;
  registration: string;
  date_added: string;
}

const XbinTruckComponent: React.FC = () => {
  const { notification } = App.useApp();
  const [trucks, setTrucks] = useState<XbinTruck[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingTruck, setEditingTruck] = useState<XbinTruck | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTrucks();
  }, []);

  const fetchTrucks = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('xbin_trucks')
        .select('*')
        .order('company', { ascending: true });

      if (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch trucks',
          placement: 'topRight'
        });
        throw error;
      }
      setTrucks(data || []);
    } catch (error) {
      console.error('Error fetching trucks:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTruck = async (values: any) => {
    try {
      const formattedValues = {
        ...values,
        date_inducted: values.driver_inducted ? new Date().toISOString() : null,
        date_added: new Date().toISOString(),
      };

      if (editingTruck) {
        const { error } = await supabase
          .from('xbin_trucks')
          .update(formattedValues)
          .eq('id', editingTruck.id);

        if (error) {
          notification.error({
            message: 'Error',
            description: 'Failed to save truck',
            placement: 'topRight'
          });
          throw error;
        }
        notification.success({
          message: 'Success',
          description: 'Truck updated successfully',
          placement: 'topRight'
        });
      } else {
        const { error } = await supabase
          .from('xbin_trucks')
          .insert(formattedValues);

        if (error) {
          notification.error({
            message: 'Error',
            description: 'Failed to save truck',
            placement: 'topRight'
          });
          throw error;
        }
        notification.success({
          message: 'Success',
          description: 'Truck added successfully',
          placement: 'topRight'
        });
      }

      setModalVisible(false);
      form.resetFields();
      fetchTrucks();
    } catch (error) {
      console.error('Error saving truck:', error);
    }
  };

  const handleDeleteTruck = async (id: string) => {
    try {
      const { error } = await supabase
        .from('xbin_trucks')
        .delete()
        .eq('id', id);

      if (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to delete truck',
          placement: 'topRight'
        });
        throw error;
      }
      notification.success({
        message: 'Success',
        description: 'Truck deleted successfully',
        placement: 'topRight'
      });
      fetchTrucks();
    } catch (error) {
      console.error('Error deleting truck:', error);
    }
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Driver Name',
      dataIndex: 'driver_name',
      key: 'driver_name',
    },
    {
      title: 'Truck Type',
      dataIndex: 'truck_type',
      key: 'truck_type',
    },
    {
      title: 'Registration',
      dataIndex: 'registration',
      key: 'registration',
    },
    {
      title: 'Inducted',
      dataIndex: 'driver_inducted',
      key: 'driver_inducted',
      render: (inducted: boolean) => (inducted ? 'Yes' : 'No'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: XbinTruck) => (
        <Space size="small">
          <Tooltip title="Edit">
            <Button
              type="primary"
              style={{ backgroundColor: '#1890ff' }}
              icon={<EditOutlined />}
              onClick={() => {
                setEditingTruck(record);
                form.setFieldsValue({
                  ...record,
                });
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteTruck(record.id)}
            />
          </Tooltip>
          <Tooltip title="View Details">
            <Button
              type="primary"
              style={{ backgroundColor: '#52c41a' }}
              icon={<EyeOutlined />}
              onClick={() => {
                // Implement view more details functionality
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const filteredTrucks = trucks.filter(truck =>
    truck.registration.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ padding: '16px' }}>
      <Card>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={12}>
            <Title level={4}><TruckOutlined /> Xbin Trucks</Title>
          </Col>
          <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
            <Space direction="horizontal" wrap>
              <Input
                placeholder="Search trucks..."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '100%', maxWidth: '300px' }}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditingTruck(null);
                  setModalVisible(true);
                }}
              >
                Add Truck
              </Button>
            </Space>
          </Col>
        </Row>

        <Table
          dataSource={filteredTrucks}
          columns={columns}
          loading={loading}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          pagination={{
            responsive: true,
            position: ['bottomCenter']
          }}
        />
      </Card>

      <Modal
        title={editingTruck ? 'Edit Truck' : 'Add New Truck'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        footer={null}
        width={600}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSaveTruck}
          initialValues={editingTruck || {}}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="company"
                label="Company"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="driver_name"
                label="Driver Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="contact_number"
                label="Contact Number"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="registration"
                label="Registration"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="truck_type"
                label="Truck Type"
                rules={[{ required: true }]}
              >
                <Select>
                  <Option value="Rigid">Rigid</Option>
                  <Option value="Semi">Semi</Option>
                  <Option value="Truck & Dog">Truck & Dog</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="driver_inducted"
                label="Driver Inducted"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="tare"
                label="Tare Weight"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="max_gross"
                label="Max Gross Weight"
                rules={[{ required: true }]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
            <Space>
              <Button onClick={() => {
                setModalVisible(false);
                form.resetFields();
              }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                {editingTruck ? 'Update' : 'Add'} Truck
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default XbinTruckComponent;