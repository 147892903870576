import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../../../lib/supabaseClient';
import type { Truck, TruckJob, PulseDocket } from '../FleetPanel/types';
import { TruckStatus } from '../../trucks/types';

interface FleetState {
  trucks: Truck[];
  filteredTrucks: Truck[];
  selectedTruck: Truck | null;
  isDrawerVisible: boolean;
  loading: boolean;
  error: string | null;
  statusFilter: TruckStatus | null;
  sortField: 'fleet_number' | 'status' | 'total_carted';
  sortDirection: 'asc' | 'desc';
  stats: {
    total: number;
    active: number;
    maintenance: number;
    available: number;
    total_tonnes: number;
    efficiency: number;
  };
}

export const useFleet = () => {
  const [state, setState] = useState<FleetState>({
    trucks: [],
    filteredTrucks: [],
    selectedTruck: null,
    isDrawerVisible: false,
    loading: false,
    error: null,
    statusFilter: null,
    sortField: 'fleet_number',
    sortDirection: 'asc',
    stats: {
      total: 0,
      active: 0,
      maintenance: 0,
      available: 0,
      total_tonnes: 0,
      efficiency: 0
    }
  });

  const calculateStats = useCallback((trucks: Truck[]) => {
    const totalTonnes: number = trucks.reduce((sum: number, d: Truck) => 
      sum + (d.daily_total || 0), 0
    );

    return {
      total: trucks.length,
      active: trucks.filter(t => t.status === 'InUse').length,
      maintenance: trucks.filter(t => t.status === 'Workshop').length,
      available: trucks.filter(t => t.status === 'Available').length,
      total_tonnes: totalTonnes,
      efficiency: trucks.length > 0 ? totalTonnes / trucks.length : 0
    };
  }, []);

  const fetchFleet = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      
      // First fetch basic truck data
      const { data: trucksData, error: trucksError } = await supabase
        .from('trucks')
        .select('*')
        .in('status', ['Available', 'InUse', 'Workshop', 'SubContracting', 'Inactive'] as TruckStatus[]);

      if (trucksError) throw trucksError;

      // Then fetch pulse dockets for these trucks
      const truckIds = trucksData.map(t => t.id);
      const { data: docketsData } = await supabase
        .from('pulse_dockets')
        .select('*')
        .in('truck_id', truckIds);

      // Fetch job assignments
      const { data: assignmentsData } = await supabase
        .from('truck_job_assignments')
        .select(`
          job_id,
          truck_id,
          jobs (
            job_number,
            customer_name,
            product,
            quantity,
            status
          )
        `)
        .in('truck_id', truckIds);

      // Combine the data
      const processedTrucks = (trucksData || []).map((truck: any) => {
        const truckDockets = (docketsData || []).filter(d => d.truck_id === truck.id);
        const truckAssignments = (assignmentsData || []).filter(a => a.truck_id === truck.id);
        const currentJobData = truckAssignments[0]?.jobs?.[0];
        
        return {
          ...truck,
          pulse_dockets: truckDockets,
          truck_job_assignments: truckAssignments,
          daily_total: truckDockets.reduce((sum: number, d: PulseDocket) => {
            const docketDate = new Date(d.created_at).toDateString();
            const today = new Date().toDateString();
            return docketDate === today ? sum + (d.quantity || 0) : sum;
          }, 0),
          current_assignment: currentJobData ? {
            job_number: currentJobData.job_number,
            customer_name: currentJobData.customer_name,
            product: currentJobData.product,
            quantity: currentJobData.quantity,
            status: currentJobData.status
          } as TruckJob : null
        };
      });

      const stats = calculateStats(processedTrucks);
      
      setState(prev => ({
        ...prev,
        trucks: processedTrucks,
        filteredTrucks: processedTrucks,
        stats,
        loading: false
      }));

    } catch (error) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to fetch fleet data'
      }));
    }
  }, [calculateStats]);

  const filterTrucks = useCallback((status: TruckStatus | null) => {
    setState(prev => ({
      ...prev,
      statusFilter: status,
      filteredTrucks: status 
        ? prev.trucks.filter(t => t.status === status)
        : prev.trucks
    }));
  }, []);

  const sortTrucks = useCallback((field: FleetState['sortField']) => {
    setState(prev => {
      const direction = 
        prev.sortField === field && prev.sortDirection === 'asc' 
          ? 'desc' 
          : 'asc';

      const sorted = [...prev.filteredTrucks].sort((a: Truck, b: Truck) => {
        const modifier = direction === 'asc' ? 1 : -1;
        
        switch (field) {
          case 'fleet_number':
            return modifier * ((a.fleet_number || '').localeCompare(b.fleet_number || ''));
          case 'status':
            return modifier * ((a.status || '').localeCompare(b.status || ''));
          case 'total_carted':
            return modifier * ((a.daily_total || 0) - (b.daily_total || 0));
          default:
            return 0;
        }
      });

      return {
        ...prev,
        filteredTrucks: sorted,
        sortField: field,
        sortDirection: direction
      };
    });
  }, []);

  const selectTruck = useCallback((truck: Truck) => {
    setState(prev => ({
      ...prev,
      selectedTruck: truck,
      isDrawerVisible: true
    }));
  }, []);

  const closeDrawer = useCallback(() => {
    setState(prev => ({
      ...prev,
      isDrawerVisible: false,
      selectedTruck: null
    }));
  }, []);

  useEffect(() => {
    const subscription = supabase
      .channel('fleet-changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'trucks' },
        fetchFleet
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [fetchFleet]);

  useEffect(() => {
    fetchFleet();
  }, [fetchFleet]);

  return {
    state,
    actions: {
      fetchFleet,
      filterTrucks,
      sortTrucks,
      selectTruck,
      closeDrawer
    }
  };
};